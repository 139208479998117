import React, { Component, createRef } from "react";
import { withRouter } from "../../../../components/Router/withRouter";
import { Alert, Card, CardBody, Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import { AdminBodyTitle } from "../../../../components/Typography/Titles";
import { API_LINK, MAIN_LINK } from "../../../../utils/constants";
import { DefaultButton } from "../../../../components/Buttons/default";
import { AiOutlineLoading3Quarters, AiOutlineSave } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import { DefaultInput } from "../../../../components/Forms/Inputs/default";
import { TbPhoto } from "react-icons/tb";
import './styles.css';
import TagsInput from "react-tagsinput";
import { DefaultSelect } from "../../../../components/Forms/Inputs/Select";
import { BottomLeftAlert } from "../../../../components/Alerts";
import TextEditor from "../../../../components/TextEditor";
import { ConfirmationModal } from "../../../../components/Modals/Confirmation";

class WebsitesDataPostPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      categoriesData: null,
      categoriesLoading: true,

      createLoading: true,
      updateLoading: false,

      postData: {
        id: props?.params?.id || ''
      },
      postTags: [],

      destroyModalShown: false,
      destroyLoading: false,

      alertText: null,
      alertType: 'danger',

      downCursor: false,
    }

    this.handleScroll = this.handleScroll.bind(this);
    this.imageInputRef = createRef();
  }

  componentDidMount() {
    this.getCategories();
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    const currentScrollPos = window.scrollY;

    this.setState({
      downCursor: currentScrollPos,
    });
  }

  createPost() {
    this.setState({createLoading: true});
    const user = localStorage.getItem('user');
    const website_id = JSON.parse(this.props.website.website).id;

    fetch(API_LINK+'/blog/post/store', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        id: this.state.postData.id,
        website_id: website_id
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {        
        this.setState({
          postData: json.post,
          postTags: json.post.tags && json.post.tags !== 'null' ? json.post.tags.split(',') : [],
          createLoading: false
        });
      }
    })
  }

  updatePost() {
    this.setState({updateLoading: true});
    const user = localStorage.getItem('user');

    const formData = new FormData();
    
    formData.append('post_id', this.state.postData.id || '');
    formData.append('category_id', this.state.postData.category_id || '');
    formData.append('subcategory_id', this.state.postData.subcategory_id || '');
    formData.append('title', this.state.postData.title || '');
    formData.append('content', this.state.postData.content || '');
    formData.append('tags', this.state.postTags || '');
    if(this.state.postData.image) {
      formData.append('image', this.state.postData.image);
    }


    formData.append('image_alt', this.state.postData.image_alt || '');
    formData.append('og_title', this.state.postData.og_title || '');
    formData.append('og_description', this.state.postData.og_description || '');
    formData.append('status', this.state.postData.status || '');
    formData.append('lang', this.state.postData.lang || '');

    fetch(API_LINK+'/blog/post/update', {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: formData
    })
    .then((response) => response.json())
    .then((json) => {      
      if(json.success) {
        this.setState({
          updateLoading: false,
          alertType: 'success',
          alertText: 'لقد تم حفظ البيانات بنجاح.'
        });
      } else {
        this.setState({
          updateLoading: false,
          alertType: 'danger',
          alertText: json.message
        });
      }
    })
  }

  destroyPost() {
    this.setState({destroyLoading: true});
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/blog/post/destroy', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        id: [this.state.postData.id]
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.props.navigate('/dashboard/blog/posts');
      }
    })
  }

  getCategories() {
    this.setState({categoriesLoading: true});
    const user = localStorage.getItem('user');
    const website_id = JSON.parse(this.props.website.website).id;

    fetch(API_LINK+'/categories/'+website_id, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        this.setState({
          categoriesData: json.categories,
          categoriesLoading: false
        }, () => {
          this.createPost();
        });
      }
      else
      {
        this.setState({
          alertText: json.message,
          categoriesLoading: false
        });
      }
    })
  }

  handleChange(event) {
    const { name, value } = event.target;

    this.setState((prevState) => ({
      postData: {
        ...prevState.postData,
        [name]: value
      }
    }));
  }

  handleImageClick() {
    this.imageInputRef.current.click();
  }

  handleImageChange(event) {
    const file = event.target.files[0];
    if (file) {      
      this.setState((prevState) => ({
        postData: {
          ...prevState.postData,
          image: file,
        },
      }));
    }
  }

  render() {
    const s = this.state;    

    if(s.createLoading) {
      return(
        <div className="text-center">
          <AiOutlineLoading3Quarters className="animation-spin h2" />
        </div>
      );
    }

    return(
      <Container>
        <div className="bg-white shadow-sm product-header-control align-items-center" style={{display: s.downCursor >= 200 ? 'flex' : 'none'}}>
          <Container>
            <Row>
              <Col md="8">
                <h4 className="fw-bold">مقال {s.postData.title}</h4>
              </Col>
              <Col md="2">
                <DefaultButton 
                  color={'danger'} 
                  size={'md'} 
                  block
                  onClick={() => this.setState({destroyModalShown: true})} 
                >
                  <MdDelete />{' '}
                  حذف
                </DefaultButton>
              </Col>
              <Col md="2">
                <DefaultButton 
                  color={'dark'} 
                  size={'md'} 
                  block
                  onClick={() => this.updatePost()}
                  isLoading={s.updateLoading}
                >
                  <AiOutlineSave />{' '}
                  حفظ
                </DefaultButton>
              </Col>
            </Row>
          </Container>
        </div>

        <AdminBodyTitle>مقال {s.postData.title}</AdminBodyTitle>

        <Row>
          <Col md="8">
            <Card>
              <CardBody>
                <FormGroup>
                  <Label>الإسم: </Label>
                  <DefaultInput 
                    name={'title'}
                    onChange={(event) => this.handleChange(event)}
                    value={s.postData.title} 
                  />
                </FormGroup>
                <FormGroup>
                  <Label>الصورة: </Label>
                  {!s.postData.image && !s.postData.image_url &&
                    <div className="blog-post-picker cursor-pointer" onClick={() => this.handleImageClick()}>
                      <TbPhoto />
                    </div>
                  }
                  {s.postData.image &&
                    <img width="100%" src={URL.createObjectURL(s.postData.image)} onClick={() => this.handleImageClick()} />
                  }
                  {s.postData.image_url &&
                    <img width="100%" src={MAIN_LINK+s.postData.image_url} onClick={() => this.handleImageClick()} />
                  }
                  <input 
                    type="file" 
                    name="image" 
                    className="d-none" 
                    ref={this.imageInputRef} accept="image/*"
                    onChange={(event) => this.handleImageChange(event)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>الوصف: </Label>
                  <TextEditor 
                    onChange={(editor) => this.setState((prevState) => ({
                      postData: {
                        ...prevState.postData,
                        content: editor
                      }
                    }))}
                    value={s.postData.content}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>كلمات مفتاحية: </Label>
                  <TagsInput 
                    value={s.postTags} 
                    onChange={(postTags) => this.setState({postTags})} 
                    onlyUnique
                    inputProps={{
                      placeholder: ''
                    }}
                    maxTags={10}
                    className="bliz-form-control form-control-lg form-control"
                  />
                </FormGroup>
              </CardBody>
            </Card>
          </Col>
          <Col md="4">
            <Card className="mb-3">
              <CardBody>
                <DefaultButton 
                  color={'danger'} 
                  block 
                  className="mb-3"
                  onClick={() => this.setState({destroyModalShown: true})}
                >
                  <MdDelete />{' '}
                  حذف
                </DefaultButton>
                <DefaultButton 
                  color={'dark'} 
                  block
                  onClick={() => this.updatePost()}
                  isLoading={s.updateLoading}
                >
                  <AiOutlineSave />{' '}
                  حفظ
                </DefaultButton>
              </CardBody>
            </Card>

            <Card className="mb-3">
              <CardBody>
                <Label>الحالة: </Label>
                <DefaultSelect 
                  name={'status'} 
                  onChange={(event) => this.handleChange(event)}
                  value={s.postData.status}
                >
                  <option value="1">نشط</option>
                  <option value="0">مسودة</option>
                </DefaultSelect>
              </CardBody>
            </Card>

            <Card className="mb-3">
              <CardBody>
                <Label>التصنيف: </Label>
                <div>
                  {s.categoriesLoading ?
                    <AiOutlineLoading3Quarters className="animation-spin" />
                  :
                    <ul className="ps-0">
                      {s.categoriesData.length > 0 ?
                        s.categoriesData.map((category, categoryIndex) => {                          
                          return(
                            <li key={categoryIndex} className="mb-2">
                              <Input 
                                type="checkbox" 
                                checked={String(s.postData.category_id) === String(category.id) ? true : false}
                                onChange={(event) => this.handleChange(event)}
                                value={String(s.postData.category_id) === String(category.id) ? null : category.id}
                                name="category_id"
                              />{' '}
                              {category.name}
                              {category.subcategories?.length > 0 &&
                                <ul>
                                  {category.subcategories.map((subcategory, subcategoryIndex) => (
                                    <li key={subcategoryIndex}>
                                      <Input 
                                        type="checkbox" 
                                        checked={String(s.postData.subcategory_id) === String(subcategory.id) ? true : false}
                                        onChange={(event) => this.handleChange(event)}
                                        value={String(s.postData.subcategory_id) === String(subcategory.id) ? null : subcategory.id}
                                        name="subcategory_id"
                                      />{' '}
                                      {subcategory.name}
                                    </li>
                                  ))}
                                </ul>
                              }
                            </li>
                          );
                        })
                      :
                        <div>
                          <Alert color="info">لا توجد لديك تصنيفات</Alert>
                        </div>
                      }
                    </ul>
                  }
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {s.alertText &&
          <BottomLeftAlert color={s.alertType} onClose={() => this.setState({alertText: null})}>
            {s.alertText}
          </BottomLeftAlert>
        }

        <ConfirmationModal
          isOpen={s.destroyModalShown}
          confirmationText={'حذف'}
          cancelText={'إلغاء'}
          toggle={() => this.setState({destroyModalShown: false})}
          onConfirm={() => this.destroyPost()}
          isLoading={s.destroyLoading}
        >
          هل حقا تريد حذف هذه البيانات؟
        </ConfirmationModal>
      </Container>
    );
  }
}

export default withRouter(WebsitesDataPostPage);
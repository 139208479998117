import React, { Component } from "react";
import { Card, CardBody, CardFooter, Col, Container, Input, Row, Table} from "reactstrap";
import { AdminBodyTitle } from "../../../components/Typography/Titles";
import { API_LINK } from "../../../utils/constants";
import { BottomLeftAlert } from "../../../components/Alerts";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { MdDelete } from 'react-icons/md';
import { DefaultButton } from "../../../components/Buttons/default";
import { Link } from "react-router-dom";
import './styles.css';
import { ConfirmationModal } from "../../../components/Modals/Confirmation";
import { RxEnvelopeClosed } from "react-icons/rx";
import SmartPagination from "../../../components/Pagination/Smart";
import { withRouter } from "../../../components/Router/withRouter";
import { i18n } from "../../../i18n";

class CRMContactsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contactsData: null,
      contactsLoading: true,
      selectAll: false,
      paginationInfo: null,
      selectedRows: [],

      currentPage: props.params?.page || 1,

      destroyLoading: false,
      destroyModalShown: false,

      alertText: null
    }
  }

  componentDidMount() {
    this.getContacts();
  }

  getContacts() {
    this.setState({contactsLoading: true});
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/crm/contacts?page='+this.state.currentPage, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        this.setState({
          contactsData: json.contacts.data.map((contact) => ({
            ...contact,
            selected: false
          })),
          paginationInfo: json.contacts,
          contactsLoading: false
        });
      }
      else
      {
        this.setState({
          alertText: json.message,
          contactsLoading: false
        });
      }
    })
  }

  destroyContacts() {
    this.setState({destroyLoading: true});
    const user = localStorage.getItem('user');
    const SELECTED_IDS = this.state.selectedRows.map(row => row.id);

    fetch(API_LINK+'/crm/contact/destroy', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        id: SELECTED_IDS
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.setState({
          selectedRows: [],
          destroyLoading: false,
          destroyModalShown: false
        }, () => {
          this.getContacts();
        });
      }
    })
  }

  handlePageChange(page) {
    this.setState({
      currentPage: page
    }, () => {
      this.props.navigate('/dashboard/crm/contacts/'+page);
      this.getContacts();
    });
  }

  handleCheckboxChange(row) {
    const updatedRows = this.state.contactsData.map((r) =>
      r.id === row.id ? { ...r, selected: !r.selected } : r
    );
  
    const updatedSelectedRows = updatedRows.filter((r) => r.selected);
  
    this.setState({
      contactsData: updatedRows,
      selectedRows: updatedSelectedRows
    });
  }

  handleSelectAllChange() {
    const updatedRows = this.state.contactsData.map((row) => ({
      ...row,
      selected: !this.state.selectAll,
    }));
  
    const selectAll = !this.state.selectAll;
  
    this.setState({
      contactsData: updatedRows,
      selectAll,
      selectedRows: selectAll ? updatedRows : [],
    });
  }

  render() {
    const s = this.state;

    return(
      <Container>
        <Row>
          <Col md="8" xs="6">
            <AdminBodyTitle>{ i18n.t('contacts.title') }</AdminBodyTitle>
          </Col>
          <Col md="4" xs="6" className="text-end">
            <Link to={'/dashboard/crm/contact/add'}>
              <DefaultButton color={'dark'}>+ { i18n.t('contacts.add') }</DefaultButton>
            </Link>
          </Col>
        </Row>
        <Card>
          <CardBody>
            <Table responsive className="table-bliz">
              <thead>
                <tr>
                  <th>
                    <Input type="checkbox" checked={s.selectAll} onChange={() => this.handleSelectAllChange()} />
                  </th>
                  <th>#</th>
                  <th>{ i18n.t('contacts.table.fullname') }</th>
                  <th>{ i18n.t('contacts.table.email') }</th>
                  <th>{ i18n.t('contacts.table.phone_number') }</th>
                  <th>{ i18n.t('contacts.table.source') }</th>
                </tr>
              </thead>
              <tbody>
                {s.contactsLoading ?
                  <tr>
                    <td colSpan={'6'} align="center">
                      <AiOutlineLoading3Quarters className="animation-spin" />
                    </td>
                  </tr>
                :
                  s.contactsData?.length > 0 ?
                    s.contactsData.map((data, key) => {
                      return(
                        <tr key={key}>
                          <td>
                            <Input type="checkbox" checked={data.selected} onChange={() => this.handleCheckboxChange(data)} />
                          </td>
                          <td>{ data.id }</td>
                          <td>
                            <Link to={'/dashboard/crm/contact/view/'+data.id}>
                              { data.firstname+' '+data.lastname }
                            </Link>
                          </td>
                          <td>{ data.email }</td>
                          <td>
                            <span dir="ltr">{ data.country_code}{data.phone_number }</span>
                          </td>
                          <td>{data.lead_source}</td>
                        </tr>
                      );
                    })
                  :
                    <tr>
                      <td colSpan={'6'} align="center">{ i18n.t('contacts.table.no_data') }</td>
                    </tr>
                }
              </tbody>
            </Table>
          </CardBody>

          {s.contactsData?.length > 0 &&
            <CardFooter>
              {s.paginationInfo &&
                <SmartPagination 
                  currentPage={s.currentPage}
                  totalPages={s.paginationInfo.last_page}
                  onPageChange={(page) => this.handlePageChange(page)}
                />
              }
            </CardFooter>
          }
        </Card>

        {s.alertText &&
          <BottomLeftAlert color={'danger'} onClose={() => this.setState({alertText: null})}>
            {s.alertText}
          </BottomLeftAlert>
        }

        {s.selectedRows?.length > 0 &&
          <div className="fixed-delete bg-danger rounded-pill shadow cursor-pointer p-3" onClick={() => this.setState({destroyModalShown: true})}>
            <MdDelete size={30} color="#fff" />
          </div>
        }

        {s.selectedRows?.length > 0 &&
          <div className="fixed-email bg-secondary rounded-pill shadow cursor-pointer p-3">
            <RxEnvelopeClosed size={30} color="#fff" />
          </div>
        }

        <ConfirmationModal
          isOpen={s.destroyModalShown}
          confirmationText={ i18n.t('modals.delete.buttons.delete') }
          cancelText={ i18n.t('modals.delete.buttons.cancel') }
          toggle={() => this.setState({destroyModalShown: false})}
          onConfirm={() => this.destroyContacts()}
          isLoading={s.destroyLoading}
        >
          { i18n.t('modals.delete.title') }
        </ConfirmationModal>
      </Container>
    );
  }
}

export default withRouter(CRMContactsPage);
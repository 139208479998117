import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import './styles.css';
import { BottomLeftAlert } from "../../components/Alerts";
import { AccountSettingsForm } from "./Forms/Account";
import { AdvancedSettingsForm } from "./Forms/Advanced";
import { PasswordSettingsForm } from "./Forms/Password";
import { ProfilePictureForm } from "./Forms/ProfilePicture";
import { EmailConfirmationModal } from "./Modals/EmailConfirmation";
import { UpdateEmailModal } from "./Modals/UpdateEmail";
import { UpdatePhoneNumberModal } from "./Modals/UpdatePhoneNumber";
import PhoneNumberConfirmationModal from "./Modals/PhoneNumberConfirmation";

class ProfilePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstname: null,
      lastname: null,
      username: null,
      phone_number: null,
      country_code: null,
      email: null,
      email_verified_at: null,
      birthdate: null,
      profile_picture: null,
      current_password: null,
      new_password: null,
      new_password_confirmation: null,

      alertText: null,
      alertType: 'danger',

      emailConfirmationModalShown: false,
      phoneNumberConfirmationModalShown: false,
      updateEmailModalShown: false,
      updatePhoneNumberModalShown: false
    }
  }

  componentDidMount() {
    this.setUserData();
  }

  setUserData() {
    const user = localStorage.getItem('user');

    this.setState({
      firstname: JSON.parse(user).firstname,
      lastname: JSON.parse(user).lastname,
      username: JSON.parse(user).username,
      birthdate: JSON.parse(user).birthdate,
      email: JSON.parse(user).email,
      email_verified_at: JSON.parse(user).email_verified_at,
      phone_number: JSON.parse(user).phone_number,
      phone_number_verified_at: JSON.parse(user).phone_number_verified_at,
      country_code: JSON.parse(user).country_code,
      profile_picture: JSON.parse(user).profile_picture_path
    });
  }

  handleChange(event) {
    const value = event.target.value;
    this.setState({
      ...this.state,
      [event.target.name]: value
    });
  }

  render() {
    const s = this.state;

    return(
      <Container fluid>
        <Row>
          <Col md="8">
            <AccountSettingsForm 
              values={s} 
              handleInputChange={(event) => this.handleChange(event)} 
              setAlert={(content, type) => this.setState({alertText: content, alertType: type})}
              setUserData={() => this.setUserData()}
            />

            <AdvancedSettingsForm 
              values={s} 
              handleInputChange={(event) => this.handleChange(event)} 
              emailConfirmationModalShown={() => this.setState({emailConfirmationModalShown: true})}
              phoneNumberConfirmationModalShown={() => this.setState({phoneNumberConfirmationModalShown: true})}
              updateEmailModalShown={() => this.setState({updateEmailModalShown: true})}
              updatePhoneNumberModalShown={() => this.setState({updatePhoneNumberModalShown: true})}
            />
          </Col>
          <Col md="4">
            <ProfilePictureForm 
              values={s}
              setAlert={(content, type) => this.setState({alertText: content, alertType: type})}
              setUserData={() => this.setUserData()}
            />

            <PasswordSettingsForm 
              values={s}
              handleInputChange={(evt) => this.handleChange(evt)}
              setAlert={(content, type) => this.setState({alertText: content, alertType: type})}
              setUserData={() => this.setUserData()}
            />
          </Col>
        </Row>

        {s.alertText &&
          <BottomLeftAlert color={s.alertType} onClose={() => this.setState({alertText: null})}>
            {s.alertText}
          </BottomLeftAlert>
        }

        <EmailConfirmationModal 
          isOpen={s.emailConfirmationModalShown}
          toggle={() => this.setState({emailConfirmationModalShown: false}, () => this.setUserData())}
          setAlert={(content, type) => this.setState({alertText: content, alertType: type})}
        />

        <PhoneNumberConfirmationModal 
          isOpen={s.phoneNumberConfirmationModalShown}
          toggle={() => this.setState({phoneNumberConfirmationModalShown: false}, () => this.setUserData())}
          setAlert={(content, type) => this.setState({alertText: content, alertType: type})}
        />

        <UpdateEmailModal 
          isOpen={s.updateEmailModalShown}
          toggle={() => this.setState({updateEmailModalShown: false})}
          setAlert={(content, type) => this.setState({alertText: content, alertType: type})}
        />

        <UpdatePhoneNumberModal 
          isOpen={s.updatePhoneNumberModalShown}
          toggle={() => this.setState({updatePhoneNumberModalShown: false})}
          setAlert={(content, type) => this.setState({alertText: content, alertType: type})}
          phoneNumber={s.phone_number}
          countryCode={s.country_code}
          setUserData={() => this.setUserData()}
        />
      </Container>
    );
  }
}

export default ProfilePage;
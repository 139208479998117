import React from "react";
import { Input } from "reactstrap";
import propTypes from 'prop-types';

export const DefaultSelect = (props) => {
  return(
    <Input 
      {...props}
      type="select" 
      className={'bliz-form-control '+props.className || ''} 
      bsSize={props.size ? props.size : 'lg'} 
    >
      {props.children}
    </Input>
  );
}

DefaultSelect.propTypes = {
  size: propTypes.string,
  value: propTypes.string,
  onChange: propTypes.func,
  name: propTypes.string,
  type: propTypes.string,
  className: propTypes.string
}
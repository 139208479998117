import React from "react";
import { DefaultModal } from "../../../components/Modals/Default";
import { i18n } from "../../../i18n";

const PhoneNumberConfirmationModal = (props) => {
  return(
    <DefaultModal
      isOpen={props.isOpen}
      title={ i18n.t('profile.advanced_settings.confirm_phone_number_modal.title') }
      cancelText={i18n.t('modals.default.buttons.close')}
      toggle={props.toggle}
      isLoading={false}
    >
      <h5>{ i18n.t('profile.advanced_settings.confirm_phone_number_modal.alert') }</h5>
    </DefaultModal>
  );
}

export default PhoneNumberConfirmationModal;
import React, { useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, FormGroup, Input } from "reactstrap";
import { IoMdAttach, IoMdDocument } from 'react-icons/io';
import { DefaultInput } from "../../../../../components/Forms/Inputs/default";
import { DefaultButton } from "../../../../../components/Buttons/default";
import { API_LINK } from "../../../../../utils/constants";
import CRMContactNoteCard from "../Cards/Note";
import { AiOutlineDelete } from "react-icons/ai";
import { i18n } from "../../../../../i18n";

const CRMContactNotes = (props) => {
  const [note, setNote] = useState('');
  const [addLoading, setAddLoading] = useState(false);
  const [notesLoading, setNotesLoading] = useState(false);
  const [notesData, setNotesData] = useState(props.notes);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const store = () => {
    setAddLoading(true);
    const user = localStorage.getItem('user');

    let formData = new FormData();

    if(props.contact) {
      formData.append('contact_id', props.contact.id);
    }
    if(props.task) {
      formData.append('task_id', props.task);
    }

    formData.append('content', note);
    
    selectedFiles.forEach((file) => {
      formData.append('files[]', file);
    });

    fetch(API_LINK+'/crm/note/store', {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: formData
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        setAddLoading(false);
        setNote('');
        setSelectedFiles([]);
        get();
      } else {
        props.onFailedAdd(json.message)
      }
    })
  };

  const get = () => {
    setNotesLoading(true);
    const user = localStorage.getItem('user');
    const LINK = props.contact ? API_LINK+'/crm/contact_notes/'+props.contact.id : API_LINK+'/crm/task_notes/'+props.task;

    fetch(LINK, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        setNotesData(json.notes)
        setNotesLoading(false);
      }
    })
  }

  const handleFileChange = (e) => {
    const files = e.target.files;
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const removeFile = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  return(
    <Card className="mb-5">
      <CardHeader>
        <CardTitle className="mb-0">{ i18n.t('contact.notes') }</CardTitle>
      </CardHeader>
      <CardBody>
        {notesData &&
          notesData.map((data, key) => {
            return(
              <CRMContactNoteCard 
                data={data} 
                key={key} 
                onDestroy={() => get()}
              />
            );
          })
        }

        <div className="border border-2 border-dark mb-3">
          <FormGroup>
            <DefaultInput 
              type={'textarea'} 
              placeholder={ i18n.t('contact.form.note_placeholder') }
              onChange={(event) => setNote(event.target.value)}
              value={note}
              className="border-0"
            />
            <Input multiple type="file" id="file" onChange={(event) => handleFileChange(event)} hidden />
          </FormGroup>
          <hr />
          {selectedFiles &&
            selectedFiles.map((data, key) => {
              return(
                <div className="p-2" key={key}>
                  <IoMdDocument />
                  {data.name}
                  <AiOutlineDelete className="text-danger cursor-pointer" onClick={() => removeFile(key)} />
                </div>
              );
            })
          }
          <FormGroup className="px-2">
            <a onClick={() => document.getElementById('file').click()}>
              <IoMdAttach />
              { i18n.t('contact.form.attach_file') }
            </a>
          </FormGroup>
        </div>
        <DefaultButton 
          color={'dark'}
          disabled={addLoading || !note}
          onClick={() => store()}
          isLoading={addLoading}
        >
          { i18n.t('contact.form.submit') }
        </DefaultButton>
      </CardBody>
    </Card>
  );
}

export default CRMContactNotes;
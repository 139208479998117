import React, { Component, Fragment } from "react";
import { Badge, Card, CardBody, Col, Container, Row } from "reactstrap";
import { AdminBodyTitle } from "../../../components/Typography/Titles";
import { DefaultInput } from "../../../components/Forms/Inputs/default";
import { MdWebAssetOff } from "react-icons/md";
import { DefaultButton } from "../../../components/Buttons/default";
import { Link } from "react-router-dom";
import { API_LINK } from "../../../utils/constants";
import { BottomLeftAlert } from "../../../components/Alerts";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { i18n } from "../../../i18n";

class BuyDomainePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      domain: null,
      domainesSearchLoading: false,
      domainesData: null,

      alertText: null
    }
  }

  searchDomaines() {
    this.setState({domainesSearchLoading: true});
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/domaines/search', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        domain: this.state.domain
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(!json.domaines || json.domaines.result === 'ERROR')
      {
        this.setState({
          domainesSearchLoading: false,
          alertText: 'حدث خطأ في النظام، يرجى المحاولة لاحقا.'
        });
      }
      else
      {
        this.setState({
          domainesData: json.domaines,
          domainesSearchLoading: false
        });
      }
    })
  }

  handleChange(event) {
    const inputValue = event.target.value;
    this.setState({
      domain: inputValue
    });
  };

  render() {
    const s = this.state;

    return(
      <Container>
        <AdminBodyTitle>{ i18n.t('add_domain.title') }</AdminBodyTitle>
        <Card className="mb-4">
          <CardBody>
            <Row>
              <Col md="9">
                <DefaultInput 
                  placeholder={ i18n.t('add_domain.search.form.keyword_placeholder') }
                  value={s.domain}
                  onChange={(event) => this.handleChange(event)} 
                  onKeyDown={(event) => {event.key === 'Enter' && this.searchDomaines()}}
                />
              </Col>
              <Col md="3">
                <DefaultButton
                  color={'dark'}
                  block
                  disabled={!s.domain || s.domainesSearchLoading}
                  onClick={() => this.searchDomaines()}
                >
                  { i18n.t('add_domain.search.form.submit') }
                </DefaultButton>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card className="mb-5">
          <CardBody className="py-0">
            {s.domainesSearchLoading ?
              <div className="text-center py-5">
                <AiOutlineLoading3Quarters className="animation-spin" size={40} />
              </div>
            :
              s.domainesData?.length > 0 ?
                <Fragment>
                  {s.domainesData.map((data, key) => (
                    <div className="d-flex justify-content-between align-items-center py-3 border-bottom" key={key}>
                      <div>
                        <b>{data.DomainName}.{data.TLD}</b>
                        <Badge className="ms-2" color={data.Status === 'notavailable' ? 'secondary' : 'success'}>{data.Status === 'notavailable' ? i18n.t('add_domain.unavailable') : i18n.t('add_domain.available')}</Badge>
                      </div>
                      {data.Status !== 'notavailable' &&
                        <div>
                          <b>{data.Price} {data.Currency}</b>
                            <Link to={'/dashboard/domain/pay/'} state={{domain: data.DomainName+'.'+data.TLD, price: data.Price}}>
                              <DefaultButton
                                size={'sm'}
                                color={'dark'}
                                className="ms-2"
                              >
                                { i18n.t('add_domain.buy') }
                              </DefaultButton>
                            </Link>
                        </div>
                      }
                    </div>
                  ))}
                </Fragment>
              :
                <div className="text-center py-5">
                  <MdWebAssetOff size={150} color="#dedede" />
                  <h5 className="text-muted mt-3">{ i18n.t('add_domain.empty') }</h5>
                </div>
            }
          </CardBody>
        </Card>

        {s.alertText &&
          <BottomLeftAlert color={'danger'} onClose={() => this.setState({alertText: null})}>
            {s.alertText}
          </BottomLeftAlert>
        }
      </Container>
    );
  }
}

export default BuyDomainePage;
import React, { useState } from "react";
import { DefaultModal } from "../../../components/Modals/Default";
import { FormGroup, Label } from "reactstrap";
import { DefaultInput } from "../../../components/Forms/Inputs/default";
import { PasswordInput } from "../../../components/Forms/Inputs/Password";
import { API_LINK } from "../../../utils/constants";
import { i18n } from "../../../i18n";

export const UpdateEmailModal = (props) => {
  const [ loading, setLoading ] = useState(false);
  const [ email, setEmail ] = useState(null);
  const [ password, setPassword ] = useState(null);

  const update = () => {
    setLoading(true);
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/account/email/update', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        email: email,
        password: password
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        localStorage.setItem('user', JSON.stringify(json.user));
        props.setAlert(json.message, 'success');
        props.toggle();
      } else {
        props.setAlert(json.message, 'danger');
      }
      setLoading(false);
    })
  }

  return(
    <DefaultModal
      isOpen={props.isOpen}
      title={ i18n.t('profile.advanced_settings.change_email_modal.title') }
      confirmationText={i18n.t('modals.default.buttons.confirm')}
      cancelText={i18n.t('modals.default.buttons.close')}
      isLoading={loading}
      onConfirm={update}
      toggle={props.toggle}
    >
      <FormGroup>
        <Label>{ i18n.t('profile.advanced_settings.change_email_modal.new_email') }: </Label>
        <DefaultInput 
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <Label>{ i18n.t('profile.advanced_settings.change_email_modal.password') }: </Label>
        <PasswordInput 
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
      </FormGroup>
    </DefaultModal>
  );
}
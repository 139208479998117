export function setTaskStatus(status) {
  status = Number(status);

  switch (status) {
    case 0:
      return {
        label: 'قيد الإنتظار',
        color: '',
        bg: 'bg-primary'
      }
      break;

    case 1:
      return {
        label: 'قيد التنفيذ',
        color: 'text-warning',
        bg: 'bg-warning'
      }
      break;

    case 2:
      return {
        label: 'منتهية',
        color: 'text-success',
        bg: 'bg-success'
      }
      break;

    case 3:
      return {
        label: 'ملغية',
        color: 'text-danger',
        bg: 'bg-danger'
      }
      break;
  
    default:
      return {
        label: 'قيد الإنتظار',
        color: '',
        bg: 'bg-primary'
      }
      break;
  }
}

export function setTaskPriority(priority) {
  priority = Number(priority);

  switch (priority) {
    case 0:
      return {
        label: 'منخفضة',
        color: 'text-warning',
        bg: 'bg-primary'
      }
      break;

    case 1:
      return {
        label: 'عادية',
        color: 'text-info',
        bg: 'bg-info'
      }
      break;

    case 2:
      return {
        label: 'مرتفعة',
        color: 'text-danger',
        bg: 'bg-danger'
      }
      break;
  
    default:
      return {
        label: 'منخفضة',
        color: 'text-warning',
        bg: 'bg-warning'
      }
      break;
  }
}

export function setTaskType(type) {
  type = String(type);

  switch (type) {
    case 'email':
      return 'بريد إلكتروني';
      break;

    case 'call':
      return 'مكالمة';
      break;

    case 'meeting':
      return 'إجتماع';
      break;
  
    default:
      return 'آخر';
      break;
  }
}

export function isURL(str) {
  const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
  return urlRegex.test(str);
}

export function getQueryParamValue(url, param) {
  if(isURL(url)) {
    const urlParams = new URLSearchParams(new URL(url).search);
    return urlParams.get(param);
  }

  return url;
};

export function updateQueryParam(url, paramName, paramValue) {
  const urlObject = new URL(url);
  const searchParams = new URLSearchParams(urlObject.search);

  searchParams.set(paramName, paramValue);

  urlObject.search = searchParams.toString();

  const updatedUrl = urlObject.toString();
  
  return updatedUrl;
}

export function extractUrlFromBackgroundImage(string) {
  if(string) {
    var urlRegex = /url\("([^"]+)"\)/;
    var match = string.match(urlRegex);
  
    if (match && match.length > 1) {
        return match[1];
    }
  }
  
  return null;
}

export function generateRandomHex(size) {
  const array = new Uint8Array(size);
  window.crypto.getRandomValues(array);
  return Array.from(array, byte => byte.toString(16).padStart(2, '0')).join('');
}

export function addBlizIdToTags(html) {
  if (html) {
    if (html.match(/data-bliz-id\s*=\s*["'][^"']*["']/gi)) {
      return html;
    } else {
      return html.replace(/<([a-z1-6]+)([^>]*)>/gi, (match, tag, attributes) => {
        if (tag.toLowerCase() === 'br') {
          return match;
        }
        const randomValue = generateRandomHex(8);
        const newAttribute = ` data-bliz-id="${randomValue}"`;
        return `<${tag}${attributes}${newAttribute}>`;
      });
    }
  }

  return '';
}

export function calculateDaysUntil(futureDateStr) {
  if(futureDateStr) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
  
    const futureDate = new Date(futureDateStr);
    futureDate.setHours(0, 0, 0, 0);
  
    const timeDifference = futureDate - today;
  
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  
    return daysDifference;
  }
};

export function subtractDays(date, days) {
  if(date && days) {
    const result = new Date(date);
    result.setDate(result.getDate() - days);
    return result;
  }
}

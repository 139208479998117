import React, { Component } from "react";
import { Card, CardBody, Col, Container, FormGroup, Label, Row, Table } from "reactstrap";
import { AdminBodyTitle } from "../../../../components/Typography/Titles";
import { DefaultButton } from "../../../../components/Buttons/default";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { API_LINK, MAIN_LINK } from "../../../../utils/constants";
import { withRouter } from "../../../../components/Router/withRouter";
import { ConfirmationModal } from "../../../../components/Modals/Confirmation";
import { DefaultModal } from "../../../../components/Modals/Default";
import { DefaultSelect } from "../../../../components/Forms/Inputs/Select";
import { DefaultInput } from "../../../../components/Forms/Inputs/default";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";

class WebsitesDataBrandsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      languagesData: null,
      languagesLoading: true,

      brandsData: null,
      brandsLoading: true,

      addLoading: false,
      editLoading: false,
      addModalShown: false,
      editModalShown: false,

      dataToAdd: {},
      dataToEdit: {},

      destroyLoading: false,
      brandToDestroy: null,

      alertText: null
    }
  }

  componentDidMount() {
    this.getBrands();
    this.getLanguages();
  }

  getLanguages() {
    this.setState({languagesLoading: true});

    const user = localStorage.getItem('user');

    fetch(API_LINK+'/languages', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        this.setState({
          languagesData: json.languages,
          languagesLoading: false
        });
      }
      else
      {
        this.setState({
          alertText: json.message,
          languagesLoading: false
        });
      }
    })
  }

  getBrands() {
    this.setState({brandsLoading: true});
    const user = localStorage.getItem('user');
    const website_id = JSON.parse(this.props.website.website).id;

    fetch(API_LINK+'/brands/'+website_id, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        this.setState({
          brandsData: json.brands,
          brandsLoading: false
        });
      }
      else
      {
        this.setState({
          alertText: json.message,
          brandsLoading: false
        });
      }
    })
  }

  storeBrand() {
    this.setState({addLoading: true});
    const user = localStorage.getItem('user');
    const website_id = JSON.parse(this.props.website.website).id;

    let formData = new FormData();
    formData.append('website_id', website_id || '');
    formData.append('name', this.state.dataToAdd.name || '');
    formData.append('lang', this.state.dataToAdd.lang || '');
    formData.append('status', this.state.dataToAdd.status || '');
    formData.append('icon', this.state.dataToAdd.icon || '');

    fetch(API_LINK+'/brand/store', {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: formData
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        this.setState({
          addLoading: false,
          addModalShown: false,
          dataToAdd: {}
        }, () => {
          this.getBrands();
        });
      }
      else
      {
        this.setState({
          alertText: json.message,
          addLoading: false
        });
      }
    })
  }

  updateBrand() {
    this.setState({editLoading: true});
    const user = localStorage.getItem('user');
    const website_id = JSON.parse(this.props.website.website).id;

    let formData = new FormData();
    formData.append('website_id', website_id || '');
    formData.append('id', this.state.dataToEdit.id || '');
    formData.append('name', this.state.dataToEdit.name || '');
    formData.append('lang', this.state.dataToEdit.lang || '');
    formData.append('status', this.state.dataToEdit.status || '');
    formData.append('icon', this.state.dataToEdit?.icon?.includes('storage/websites') ? '' : this.state.dataToEdit.icon || '');

    fetch(API_LINK+'/brand/update', {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: formData
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        this.setState({
          editLoading: false,
          editModalShown: false,
          dataToEdit: {}
        }, () => {
          this.getBrands();
        });
      }
      else
      {
        this.setState({
          alertText: json.message,
          editLoading: false
        });
      }
    })
  }

  destroyBrand() {
    this.setState({destroyLoading: true});
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/brand/destroy', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        id: this.state.brandToDestroy
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.setState({
          destroyLoading: false,
          brandToDestroy: null
        }, () => {
          this.getBrands();
        });
      }
    })
  }

  handleChange(e) {
    const { name, value, type } = e.target;

    this.setState((prevState) => ({
      dataToAdd: {
        ...prevState.dataToAdd,
        [name]: type === 'file' ? e.target.files[0] : value
      }
    }));
  }

  handleEditChange(e) {
    const { name, value, type } = e.target;

    this.setState((prevState) => ({
      dataToEdit: {
        ...prevState.dataToEdit,
        [name]: type === 'file' ? e.target.files[0] : value
      }
    }));
  }

  render() {
    const s = this.state;

    return(
      <Container>
        <Row>
          <Col xs="6">
            <AdminBodyTitle>العلامات التجارية</AdminBodyTitle>
          </Col>
          <Col xs="6" className="text-end">
            <DefaultButton color={'dark'} onClick={() => this.setState({addModalShown: true})}>+ إضافة علامة تجارية</DefaultButton>
          </Col>
        </Row>
        <Card>
          <CardBody>
            <Table className="table-bliz" responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>اللغة</th>
                  <th>الإسم</th>
                  <th>الأيقونة</th>
                  <th>عدد المنتجات</th>
                  <th>الحالة</th>
                  <th>خيارات</th>
                </tr>
              </thead>
              <tbody>
                {s.brandsLoading ?
                  <tr>
                    <td colSpan={'7'} align="center">
                      <AiOutlineLoading3Quarters className="animation-spin" />
                    </td>
                  </tr>
                :
                  s.brandsData.length > 0 ?
                    s.brandsData.map((data, key) => (
                      <tr key={key}>
                        <td>{data.id}</td>
                        <td>{data.lang}</td>
                        <td>{data.name}</td>
                        <td>
                          {data.icon &&
                            <img src={MAIN_LINK+data.icon} width={'24px'} height={'24px'} />
                          }
                        </td>
                        <td>0</td>
                        <td>{data.status === 1 ? <span className="text-success">نشط</span> : <span className="text-danger">معطل</span>}</td>
                        <td>
                          <DefaultButton size={'xs'} color={'success'} onClick={() => this.setState({dataToEdit: data, editModalShown: true})}>
                            <FiEdit />
                          </DefaultButton>
                          {' '}
                          <DefaultButton size={'xs'} color={'danger'} onClick={() => this.setState({brandToDestroy: data.id})}>
                            <MdDelete />
                          </DefaultButton>
                        </td>
                      </tr>
                    ))
                  :
                    <tr>
                      <td colSpan={'7'} align="center">لا توجد بيانات</td>
                    </tr>
                }
              </tbody>
            </Table>
          </CardBody>
        </Card>

        <DefaultModal
          isOpen={s.addModalShown}
          toggle={() => this.setState({addModalShown: false})}
          title={'إضافة علامة تجارية'}
          isLoading={s.addLoading}
          confirmationText={'إضافة'}
          cancelText={'غلق'}
          onConfirm={() => this.storeBrand()}
        >
          <FormGroup>
            <Label>اللغة: </Label>
            <DefaultSelect
              name={'lang'} 
              value={s.dataToAdd.lang} 
              onChange={(event) => this.handleChange(event)} 
            >
              <option value="">---</option>
              {s.languagesData?.map((data, key) => (
                <option value={data.code} key={key}>{data.name}</option>
              ))}
            </DefaultSelect>
          </FormGroup>
          <FormGroup>
            <Label>الإسم: </Label>
            <DefaultInput 
              name={'name'} 
              value={s.dataToAdd.name} 
              onChange={(event) => this.handleChange(event)} 
            />
          </FormGroup>
          <FormGroup>
            <Label>الأيقونة: </Label>
            <DefaultInput 
              type={'file'} 
              name={'icon'}
              onChange={(event) => this.handleChange(event)}
            />
          </FormGroup>
          <FormGroup>
            <Label>الحالة: </Label>
            <DefaultSelect
              name={'status'} 
              value={s.dataToAdd.status} 
              onChange={(event) => this.handleChange(event)} 
            >
              <option value="">----</option>
              <option value="1">نشط</option>
              <option value="0">معطل</option>
            </DefaultSelect>
          </FormGroup>
        </DefaultModal>

        <DefaultModal
          isOpen={s.editModalShown}
          toggle={() => this.setState({editModalShown: false})}
          title={'إضافة علامة تجارية'}
          isLoading={s.editLoading}
          confirmationText={'حفظ'}
          cancelText={'غلق'}
          onConfirm={() => this.updateBrand()}
        >
          <FormGroup>
            <Label>اللغة: </Label>
            <DefaultSelect
              name={'lang'} 
              value={s.dataToEdit.lang} 
              onChange={(event) => this.handleEditChange(event)} 
            >
              <option value="">---</option>
              {s.languagesData?.map((data, key) => (
                <option value={data.code} key={key}>{data.name}</option>
              ))}
            </DefaultSelect>
          </FormGroup>
          <FormGroup>
            <Label>الإسم: </Label>
            <DefaultInput 
              name={'name'} 
              value={s.dataToEdit.name} 
              onChange={(event) => this.handleEditChange(event)} 
            />
          </FormGroup>
          <FormGroup>
            <Label>الأيقونة: </Label>
            <DefaultInput 
              type={'file'} 
              name={'icon'}
              onChange={(event) => this.handleEditChange(event)}
            />
          </FormGroup>
          <FormGroup>
            <Label>الحالة: </Label>
            <DefaultSelect
              name={'status'} 
              value={s.dataToEdit.status} 
              onChange={(event) => this.handleEditChange(event)} 
            >
              <option value="">----</option>
              <option value="1">نشط</option>
              <option value="0">معطل</option>
            </DefaultSelect>
          </FormGroup>
        </DefaultModal>

        <ConfirmationModal
          isOpen={s.brandToDestroy}
          confirmationText={'حذف'}
          cancelText={'إلغاء'}
          toggle={() => this.setState({brandToDestroy: false})}
          onConfirm={() => this.destroyBrand()}
          isLoading={s.destroyLoading}
        >
          هل حقا تريد حذف هذه البيانات؟
        </ConfirmationModal>
      </Container>
    );
  }
}

export default withRouter(WebsitesDataBrandsPage);
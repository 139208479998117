import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import { DefaultButton } from "../../components/Buttons/default";
import { Link } from "react-router-dom";

class NeedUpgradePage extends Component {
  render() {
    return(
      <Container>
        <Row className="mt-5">
          <Col md="6" className="offset-md-3 offset-lg-4 text-center" lg="4">
            <img src={ require('../../assets/browser.png') } width={'100%'} />
            <h5 className="mt-4 mb-3">باقتك لا توفر هذه الميزة</h5>
            <Link to={'/dashboard/packages'}>
              <DefaultButton color={'dark'} size={'md'}>ترقية الباقة</DefaultButton>
            </Link>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default NeedUpgradePage;
import React, { useState } from "react";
import { DefaultModal } from "../../../../components/Modals/Default";
import { FormGroup, InputGroup, InputGroupText, Label } from "reactstrap";
import { DefaultInput } from "../../../../components/Forms/Inputs/default";
import { useWebsite } from "../../../../context/Website";
import { API_LINK } from "../../../../utils/constants";

const BuilderAddPageModal = (props) => {
  const website = useWebsite();
  const [addLoading, setAddLoading] = useState(false);
  const [data, setData] = useState({
    name: '',
    slug: '',
    title: ''
  });

  const store = () => {
    setAddLoading(true);
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/builder/page/store', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        website_id: JSON.parse(website.website).id,
        lang: props.selectedLanguage.language.short,
        name: data.name,
        slug: data.slug,
        title: data.title
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        props.toggle();
        props.onSuccess();
      } else {
        props.onError(json.message);
      }
      setAddLoading(false);
    })
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    
    setData((prev) => ({ ...prev, [name]: value }));
  }

  return(
    <DefaultModal
      isOpen={props.isOpen}
      title={'صفحة جديدة'}
      confirmationText={'حفظ'}
      cancelText={'غلق'}
      toggle={props.toggle}
      onConfirm={() => store()}
      isLoading={addLoading}
    >
      <FormGroup>
        <Label>إسم الصفحة: </Label>
        <DefaultInput 
          name={'name'}
          onChange={(event) => handleChange(event)}
          value={data.name}
        />
      </FormGroup>
      <FormGroup>
        <Label>الرابط: </Label>
        <InputGroup dir="ltr">
          <InputGroupText className="border-start-0">
            /
          </InputGroupText>
          <DefaultInput 
            name={'slug'}
            onChange={(event) => handleChange(event)}
            value={data.slug}
          />
        </InputGroup>
      </FormGroup>
      <FormGroup>
        <Label>عنوان الصفحة: </Label>
        <DefaultInput 
          name={'title'}
          onChange={(event) => handleChange(event)}
          value={data.title}
        />
      </FormGroup>
    </DefaultModal>
  );
}

export default BuilderAddPageModal;
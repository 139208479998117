import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Alert, Col, Container, Row } from "reactstrap";
import { DefaultButton } from "../../../components/Buttons/default";
import { DefaultInput } from "../../../components/Forms/Inputs/default";
import { PasswordInput } from "../../../components/Forms/Inputs/Password";
import { API_LINK, MAIN_LINK } from "../../../utils/constants";
import { withRouter } from "../../../components/Router/withRouter";
import { i18n } from "../../../i18n";
import { CiGlobe } from "react-icons/ci";
import LanguagesModal from "../../../components/Modals/Languages";

class SetNewPasswordPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      changeLanguageModalShown: false,
      email: props.params.email,
      confirmation_code: '',
      new_password: '',
      new_password_confirmation: ''
    }
  }

  handleChange(name, event) {
    this.setState({
      ...this.state,
      [name]: event.target.value
    })
  }

  setPassword() {
    this.setState({isLoading: true});
    fetch(API_LINK+'/password/reset', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: this.state.email,
        confirmation_code: this.state.confirmation_code,
        new_password: this.state.new_password,
        new_password_confirmation: this.state.new_password_confirmation
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        this.setState({isLoading: false});
        this.props.navigate('/login');
      }
      else
      {
        this.setState({
          isLoading: false,
          alertText: json.message
        });
      }
    })
  }

  render() {
    const s = this.state;

    return(
      <Container>
        <Row style={{height: '100vh'}} className="align-items-center">
          <Col lg="5" md="6" className="mx-auto mt-5">
            <div className="login-card bg-white p-5">
              <div className="row align-items-center mb-3">
                <div className="col-8">
                  <img src={ require('../../../assets/brand-black.png') } width={50} />
                </div>
                <div className="col-4 text-end">
                  <CiGlobe 
                    size={26} 
                    onClick={() => this.setState({changeLanguageModalShown: true})} 
                    style={{cursor: "pointer"}}
                  />
                </div>
              </div>

              <h4 className="mb-5 fw-bold">{ i18n.t('auth.new_password.title') }</h4>
              <div className="mb-3">
                <label className="form-label">{ i18n.t('auth.new_password.form.code') }: </label>
                <DefaultInput bsSize={'lg'} value={s.confirmation_code} onChange={(event) => this.handleChange('confirmation_code', event)} />
              </div>
              <div className="mb-3">
                <label className="form-label">{ i18n.t('auth.new_password.form.new_password') }: </label>
                <PasswordInput bsSize={'lg'} value={s.new_password} onChange={(event) => this.handleChange('new_password', event)} />
              </div>
              <div className="mb-3">
                <label className="form-label">{ i18n.t('auth.new_password.form.new_password_confirmation') }: </label>
                <PasswordInput bsSize={'lg'} value={s.new_password_confirmation} onChange={(event) => this.handleChange('new_password_confirmation', event)} />
              </div>
              <div className="mb-3">
                <DefaultButton 
                  size={'lg'} 
                  type="submit" 
                  color="dark"
                  isLoading={s.isLoading}
                  onClick={() => this.setPassword()}
                  disabled={!s.confirmation_code || s.confirmation_code.length < 6 || !s.new_password || s.new_password.length < 8 || !s.new_password_confirmation || s.new_password_confirmation.length < 8 || s.new_password !== s.new_password_confirmation || s.isLoading}
                  className={'rounded-pill px-5'}
                >
                  { i18n.t('auth.new_password.form.submit') }
                </DefaultButton>
              </div>
              <div className="mb-3 text-center">
                <Link to="/password/recovery" className="text-primary">{ i18n.t('auth.new_password.back') }</Link>
              </div>
              {s.alertText &&
                <Alert className="bg-danger text-light border-danger rounded-0">{ s.alertText }</Alert>
              }
            </div>
          </Col>
        </Row>

        {s.changeLanguageModalShown &&
          <LanguagesModal 
            toggle={() => this.setState({changeLanguageModalShown: false})}
          />
        }
      </Container>
    );
  }
}

export default withRouter(SetNewPasswordPage);
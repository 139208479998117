import React, { Component, Fragment } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Container, FormGroup, Label, Media, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import { AiFillDelete, AiOutlineLoading3Quarters } from "react-icons/ai";
import { API_LINK, MAIN_LINK } from "../../../../utils/constants";
import { BottomLeftAlert } from "../../../../components/Alerts";
import { withRouter } from "../../../../components/Router/withRouter";
import CRMContactNotes from "../../Contacts/View/Forms/Notes";
import { AdminBodyTitle } from "../../../../components/Typography/Titles";
import { setTaskPriority, setTaskStatus, setTaskType } from "../../../../utils/helpers";
import { BiCog, BiEdit, BiEnvelope, BiPhone } from "react-icons/bi";
import { Link } from "react-router-dom";
import { DefaultButton } from "../../../../components/Buttons/default";
import { IoMdPerson } from "react-icons/io";
import { ConfirmationModal } from "../../../../components/Modals/Confirmation";
import Select from "react-select";

class CRMViewTaskPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      taskData: null,
      taskLoading: true,

      contactsLoading: true,
      contactsOptions: [],
      contact_id: null,
      contactsKeyword: '',

      destroyModalShown: null,
      destroyLoading: false,

      alertText: null,

      activeTab: "1"
    }
  }

  componentDidMount() {
    this.getTask();
  }

  getTask() {
    this.setState({taskLoading: true});
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/crm/task/'+this.props.params.task, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        this.setState({
          taskData: json.task,
          taskLoading: false
        }, () => {
          this.getContacts();
        });
      }
      else
      {
        this.setState({
          alertText: json.message,
          taskLoading: false
        });
      }
    })
  }

  getContacts() {
    this.setState({contactsLoading: true});
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/crm/contacts?keyword='+this.state.contactsKeyword, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        this.setState({
          contactsData: json.contacts.data.map((contact) => ({
            ...contact,
            selected: false
          })),
          contactsLoading: false
        }, () => {
          this.state.contactsData.forEach(contact => {
            this.setState((prevState) => {
              const newValue = {
                label: contact.firstname+' '+contact.lastname,
                value: contact.id
              };
            
              if (!prevState.contactsOptions.some(option => option.value === newValue.value)) {
                return {
                  contactsOptions: [...prevState.contactsOptions, newValue]
                };
              } else {
                return prevState;
              }
            });
          });
        });
      }
      else
      {
        this.setState({
          alertText: json.message,
          contactsLoading: false
        });
      }
    })
  }

  update() {
    this.setState({taskLoading: true});
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/crm/task/update', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        id: this.state.taskData.id,
        title: this.state.taskData.title,
        contact_id: this.state.contact_id.value,
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.getTask();
      } else {
        this.setState({
          alertText: json.message
        });
      }

      this.setState({taskLoading: false});
    })
  }

  destroy() {
    this.setState({destroyLoading: true});
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/crm/task/destroy', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        id: [this.state.taskData.id]
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.props.navigate('/dashboard/crm/tasks');
      }
    })
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {
    const s = this.state;

    if(s.taskLoading) {
      return(
        <Container className="text-center">
          <AiOutlineLoading3Quarters className="animation-spin h1" />
        </Container>
      );
    }

    return(
      <Container>
        <AdminBodyTitle>
          تفاصيل المهمة
        </AdminBodyTitle>

        <Row>
          <Col md="8">
            <Card className="mb-3">
              <CardHeader>
                <CardTitle>{setTaskType(s.taskData.type)} | {s.taskData.title}</CardTitle>
              </CardHeader>
              <CardBody>
                <Row className="mb-3">
                  <Col md="4" lg="3">
                    <Label>العميل: </Label>
                  </Col>
                  <Col md="6" lg="7">
                    {s.taskData?.contact?.firstname || '-'} {s.taskData?.contact?.lastname}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md="4" lg="3">
                    <Label>الأولوية: </Label>
                  </Col>
                  <Col md="6" lg="7" className={setTaskPriority(s.taskData.priority).color}>
                    {setTaskPriority(s.taskData.priority).label}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md="4" lg="3">
                    <Label>الحالة: </Label>
                  </Col>
                  <Col md="6" lg="7" className={setTaskStatus(s.taskData.status).color}>
                    {setTaskStatus(s.taskData.status).label}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md="4" lg="3">
                    <Label>تاريخ الإنتهاء: </Label>
                  </Col>
                  <Col md="6" lg="7">
                    {s.taskData.date} - {s.taskData.time}
                  </Col>
                </Row>
                <hr />
                <FormGroup>
                  <Label>الوصف:</Label>
                  <div>
                    {s.taskData.description}
                  </div>
                </FormGroup>
              </CardBody>
            </Card>

            <CRMContactNotes 
              notes={s.taskData.notes}
              task={this.props.params.task}
              onFailedAdd={(error) => this.setState({alertText: error})}
            />
          </Col>
          <Col md="4">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={s.activeTab === '1' && 'active'}
                  onClick={() => { this.toggleTab('1'); }}
                >
                  <IoMdPerson />{' '}
                  العميل
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={s.activeTab === '2' && 'active'}
                  onClick={() => { this.toggleTab('2'); }}
                >
                  <BiCog />{' '}
                  إعدادات
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={s.activeTab}>
              <TabPane tabId="1">
                <Card>
                  <CardBody>
                    {s.taskData.contact ?
                      <Fragment>
                        <Link to={'/dashboard/crm/contact/view/'+s.taskData.contact.id} className="text-dark">
                          <Media className="d-flex align-items-center">
                            <Media className="float-start" href="#">
                              <img width={'60px'} height={'60px'} src={s.taskData.contact.profile_picture_path ? MAIN_LINK+s.taskData.contact.profile_picture_path : require('../../../../assets/default-user.png')} className="rounded-pill border" />
                            </Media>
                            <Media body className="ms-3">
                              <Media heading>
                                {s.taskData.contact.firstname} {s.taskData.contact.lastname}
                              </Media>
                            </Media>
                          </Media>
                        </Link>

                        <hr />

                        <FormGroup>
                          <BiPhone />{' '}
                          <span dir="ltr">{s.taskData.contact.country_code}{s.taskData.contact.phone_number}</span>
                        </FormGroup>
                        <FormGroup>
                          <BiEnvelope />{' '}
                          {s.taskData.contact.email}
                        </FormGroup>

                        <hr />

                        <FormGroup>
                          <b>عدد المهام</b> (2)
                        </FormGroup>
                        <FormGroup>
                          <b>عدد الملاحظات</b> ({s.taskData.contact.notes.length})
                        </FormGroup>
                      </Fragment>
                    :
                      <div>
                        <Label>إختر عميلا: </Label>
                        <Select
                          className="bliz-form-control form-control"
                          placeholder={'إختر العميل'}
                          isLoading={s.contactsLoading}
                          isClearable={true}
                          isRtl={true}
                          isSearchable={true}
                          name="color"
                          options={s.contactsOptions}
                          value={s.contact_id}
                          onChange={(selectedOption) => this.setState({contact_id: selectedOption}, () => this.update())}
                        />
                      </div>
                    }
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId="2">
                <Card>
                  <CardBody>
                    <FormGroup>
                      <Link to={'/dashboard/crm/task/edit/'+s.taskData.id}>
                        <DefaultButton block>
                          <BiEdit />{' '}
                          تعديل
                        </DefaultButton>
                      </Link>
                    </FormGroup>
                    <div>
                      <DefaultButton color={'danger'} block onClick={() => this.setState({destroyModalShown: true})}>
                        <AiFillDelete />{' '}
                        حذف
                      </DefaultButton>
                    </div>
                  </CardBody>
                </Card>
              </TabPane>
            </TabContent>
          </Col>
        </Row>

        <ConfirmationModal
          isOpen={s.destroyModalShown}
          confirmationText={'حذف'}
          cancelText={'إلغاء'}
          toggle={() => this.setState({destroyModalShown: false})}
          onConfirm={() => this.destroy()}
          isLoading={s.destroyLoading}
        >
          هل حقا تريد حذف هذه المهمة؟
        </ConfirmationModal>

        {s.alertText &&
          <BottomLeftAlert color={'danger'} onClose={() => this.setState({alertText: null})}>
            {s.alertText}
          </BottomLeftAlert>
        }
      </Container>
    );
  }
}

export default withRouter(CRMViewTaskPage);
import React, { useState } from "react";
import { DefaultModal } from "../../../../components/Modals/Default";
import { DefaultButton } from "../../../../components/Buttons/default";
import BuilderAddPageModal from "./Add";
import { FaPlus } from "react-icons/fa6";
import './styles.css';
import { Col, Row } from "reactstrap";
import { BiEditAlt } from "react-icons/bi";
import { AiOutlineDelete } from "react-icons/ai";
import { ConfirmationModal } from "../../../../components/Modals/Confirmation";
import { API_LINK } from "../../../../utils/constants";
import BuilderEditPageModal from "./Edit";

const BuilderPagesModal = (props) => {
  const [addPageModalShown, setAddPageModalShown] = useState(false);
  const [editPageModalShown, setEditPageModalShown] = useState(false);
  const [pageToEdit, setPageToEdit] = useState(null);
  const [deleteModalShown, setDeleteModalShown] = useState(false);
  const [selectedToDelete, setSelectedToDelete] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const destroy = () => {
    setDeleteLoading(true);
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/builder/page/destroy', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        id: selectedToDelete
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        props.onSuccess();
      } else {
        props.onError(json.message);
      }
      setDeleteLoading(false);
      setDeleteModalShown(false);
    })
  }

  return(
    <DefaultModal
      isOpen={props.isOpen}
      title={'صفحات الموقع'}
      toggle={props.toggle}
      cancelText={'غلق'}
    >
      {props.pages?.length > 0 &&
        <ul className="ps-0 builder-pages-container">
          {props.pages.map((data, key) => (
            <li key={key} className={props.selectedPage === data && 'active'}>
              <Row className="align-items-center">
                <Col md="8" xs="8" onClick={() => props.onPageChange(data)}>
                  {data.name}
                </Col>
                <Col md="4" xs="4" className="text-end">
                  <DefaultButton size={'sm'} disabled={Number(data.can_delete) === 0 ? true : false} color={'danger'} onClick={() => {setSelectedToDelete(data.id); setDeleteModalShown(true)}}>
                    <AiOutlineDelete />
                  </DefaultButton>
                  {' '}
                  <DefaultButton size={'sm'} color={'success'} onClick={() => {setPageToEdit(data); setEditPageModalShown(true);}}>
                    <BiEditAlt />
                  </DefaultButton>
                </Col>
              </Row>
            </li>
          ))}
        </ul>
      }

      <DefaultButton 
        size={'sm'} 
        onClick={() => setAddPageModalShown(true)} 
        className={'rounded-pill'} 
        color={'dark'}
      >
        <FaPlus />
      </DefaultButton>

      <BuilderAddPageModal 
        isOpen={addPageModalShown}
        toggle={() => setAddPageModalShown(false)}
        onSuccess={props.onSuccess}
        onError={props.onError}
        selectedLanguage={props.selectedLanguage}
      />

      {pageToEdit &&
        <BuilderEditPageModal 
          isOpen={editPageModalShown}
          toggle={() => {setEditPageModalShown(false); setPageToEdit(null)}}
          onSuccess={props.onSuccess}
          onError={props.onError}
          data={pageToEdit}
        />
      }

      <ConfirmationModal 
        isOpen={deleteModalShown}
        confirmationText={'حذف'}
        cancelText={'إلغاء'}
        toggle={() => setDeleteModalShown(false)}
        onConfirm={() => destroy()}
        isLoading={deleteLoading}
      >
        هل أنت متأكد من حذف هذه الصفحة؟
      </ConfirmationModal>
    </DefaultModal>
  );
}

export default BuilderPagesModal;
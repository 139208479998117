import React, { Component } from "react";
import { Alert, Card, CardBody, CardHeader, CardTitle, Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import ReactQuill from 'react-quill';
import { withRouter } from "../../../../components/Router/withRouter";
import { AdminBodyTitle } from "../../../../components/Typography/Titles";
import { DefaultInput } from "../../../../components/Forms/Inputs/default";
import { DefaultSelect } from "../../../../components/Forms/Inputs/Select";
import { API_LINK, MAIN_LINK } from "../../../../utils/constants";
import { AiOutlineLoading3Quarters, AiOutlineSave } from "react-icons/ai";
import { MdDelete } from 'react-icons/md';
import { IoIosRemoveCircle } from "react-icons/io";
import ImagePicker from "../../../../components/Images/Picker";
import { BottomLeftAlert } from "../../../../components/Alerts";
import './styles.css';
import { DefaultButton } from "../../../../components/Buttons/default";
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import { ConfirmationModal } from "../../../../components/Modals/Confirmation";

class WebsitesDataProductPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      categoriesData: null,
      categoriesLoading: true,

      brandsData: null,
      brandsLoading: true,

      createLoading: true,
      updateLoading: false,

      productData: {
        id: props?.params?.id || '',
        shipping: 1
      },
      selectedCategories: [],
      selectedSubcategories: [],
      productImages: null,
      productImagesLoading: false,
      productTags: [],

      destroyModalShown: false,
      destroyLoading: false,

      alertText: null,
      alertType: 'danger',

      downCursor: false
    }

    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    this.getCategories();
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    const currentScrollPos = window.scrollY;

    this.setState({
      downCursor: currentScrollPos,
    });
  }

  createProduct() {
    this.setState({createLoading: true});
    const user = localStorage.getItem('user');
    const website_id = JSON.parse(this.props.website.website).id;

    fetch(API_LINK+'/product/store', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        id: this.state.productData.id,
        website_id: website_id
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        this.setState({
          productData: json.product,
          productImages: json.product.images,
          selectedCategories: json.product.categories.map(row => row.category_id),
          selectedSubcategories: json.product.subcategories.map(row => row.subcategory_id),
          productTags: json.product.tags ? JSON.parse(json.product.tags) : [],
          createLoading: false
        });
      }
    })
  }

  updateProduct() {
    this.setState({updateLoading: true});
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/product/update', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        product_id: this.state.productData.id,
        brand_id: this.state.productData.brand_id,
        name: this.state.productData.name,
        description: this.state.productData.description,
        tags: this.state.productTags,
        price: this.state.productData.price,
        special_price: this.state.productData.special_price,
        purchase_price: this.state.productData.purchase_price,
        sku: this.state.productData.sku,
        height: this.state.productData.height,
        wide: this.state.productData.wide,
        length: this.state.productData.length,
        weight: this.state.productData.weight,
        stock_availability: this.state.productData.stock_availability,
        shipping: this.state.productData.shipping,
        categories: this.state.selectedCategories,
        subcategories: this.state.selectedSubcategories
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        this.setState({
          updateLoading: false,
          alertType: 'success',
          alertText: 'لقد تم حفظ البيانات بنجاح.'
        });
      }
    })
  }

  destroyProduct() {
    this.setState({destroyLoading: true});
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/product/destroy', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        id: [this.state.productData.id]
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.props.navigate('/dashboard/store/product');
      }
    })
  }

  getImages(loading = false) {
    if(loading)
    {
      this.setState({productImagesLoading: true});
    }
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/product/images/'+this.state.productData.id, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        this.setState({
          productImages: json.images,
          productImagesLoading: false
        });
      }
    })
  }

  destroyImage(image) {
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/product/image/destroy', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        product_id: Number(this.state.productData.id),
        image_id: Number(image)
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.getImages();
      } else {
        this.setState({
          alertText: 'لا يمكنك القيام بهذه العملية'
        });
      }
    })
  }

  getCategories() {
    this.setState({categoriesLoading: true});
    const user = localStorage.getItem('user');
    const website_id = JSON.parse(this.props.website.website).id;

    fetch(API_LINK+'/categories/'+website_id, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        this.setState({
          categoriesData: json.categories,
          categoriesLoading: false
        }, () => {
          this.getBrands();
        });
      }
      else
      {
        this.setState({
          alertText: json.message,
          categoriesLoading: false
        });
      }
    })
  }

  getBrands() {
    this.setState({brandsLoading: true});
    const user = localStorage.getItem('user');
    const website_id = JSON.parse(this.props.website.website).id;

    fetch(API_LINK+'/brands/'+website_id, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        this.setState({
          brandsData: json.brands,
          brandsLoading: false
        }, () => {
          this.createProduct();
        });
      }
      else
      {
        this.setState({
          alertText: json.message,
          brandsLoading: false
        });
      }
    })
  }

  handleChange(event) {
    const { name, value } = event.target;

    this.setState((prevState) => ({
      productData: {
        ...prevState.productData,
        [name]: value
      }
    }));
  }

  handleCategoriesChange(categoryId) {
    const { selectedCategories } = this.state;
    const isSelected = selectedCategories?.includes(categoryId);

    if (isSelected) {
      const updatedCategories = selectedCategories.filter((id) => id !== categoryId);
      this.setState({ selectedCategories: updatedCategories });
    } else {
      this.setState({ selectedCategories: [...selectedCategories, categoryId] });
    }
  }

  handleSubcategoriesChange(subcategoryId) {
    const { selectedSubcategories } = this.state;
    const isSelected = selectedSubcategories?.includes(subcategoryId);

    if (isSelected) {
      const updatedCategories = selectedSubcategories.filter((id) => id !== subcategoryId);
      this.setState({ selectedSubcategories: updatedCategories });
    } else {
      this.setState({ selectedSubcategories: [...selectedSubcategories, subcategoryId] });
    }
  }

  render() {
    const s = this.state;

    return(
      <Container className="position-relative">
        <div className="bg-white shadow-sm product-header-control align-items-center" style={{display: s.downCursor >= 200 ? 'flex' : 'none'}}>
          <Container>
            <Row>
              <Col md="8">
                <h4 className="fw-bold">منتج {s.productData.name}</h4>
              </Col>
              <Col md="2">
                <DefaultButton color={'danger'} size={'md'} block><MdDelete /> حذف</DefaultButton>
              </Col>
              <Col md="2">
                <DefaultButton 
                  color={'dark'} 
                  size={'md'} 
                  block
                  onClick={() => this.updateProduct()}
                >
                  <AiOutlineSave />{' '}
                  حفظ
                </DefaultButton>
              </Col>
            </Row>
          </Container>
        </div>

        <AdminBodyTitle>منتج {s.productData.name}</AdminBodyTitle>
        <Row className="mb-5">
          <Col md="8">
            <Card className="mb-3">
              <CardHeader>
                <CardTitle>معلومات رئيسية</CardTitle>
              </CardHeader>
              <CardBody>
                <FormGroup>
                  <Label>الإسم: </Label>
                  <DefaultInput 
                    name={'name'}
                    onChange={(event) => this.handleChange(event)}
                    value={s.productData.name} 
                  />
                </FormGroup>
                <FormGroup>
                  <Label>الوصف: </Label>
                  <ReactQuill 
                    theme="snow" 
                    onChange={(editor) => this.setState((prevState) => ({
                      productData: {
                        ...prevState.productData,
                        description: editor
                      }
                    }))}
                    value={s.productData.description}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>كلمات مفتاحية: </Label>
                  <TagsInput 
                    value={s.productTags} 
                    onChange={(productTags) => this.setState({productTags})} 
                    onlyUnique
                    inputProps={{
                      placeholder: ''
                    }}
                    maxTags={10}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>الصور: </Label>
                  {s.productImagesLoading ?
                    <div className="text-center">
                      <AiOutlineLoading3Quarters className="animation-spin" size={40} />
                    </div>
                  :
                    <Row>
                      {s.productImages?.length > 0 && s.productImages?.map((data, key) => (
                        <Col md="4" className="mb-3" key={key}>
                          <div className="position-relative">
                            <IoIosRemoveCircle 
                              className="text-danger image-remove-icon cursor-pointer" 
                              size={24} 
                              onClick={() => this.destroyImage(data.id)}
                            />
                            <img 
                              key={key} 
                              src={MAIN_LINK+data.image_url} 
                              width={'100%'} 
                              height={'100%'} 
                              className="border"
                            />
                          </div>
                        </Col>
                      ))}
                      <Col md="4">
                        <ImagePicker 
                          product_id={this.state.productData.id}
                          onSuccessAdd={() => this.getImages()}
                          onErrorAdd={() => this.setState({alertText: 'حدث خطأ ما أثناء إرسال البيانات.'})}
                        />
                      </Col>
                    </Row>
                  }
                </FormGroup>
              </CardBody>
            </Card>

            <Card className="mb-3">
              <CardHeader>
                <CardTitle>التسعير</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="6" className="mb-3">
                    <Label>السعر: </Label>
                    <DefaultInput 
                      name={'price'}
                      onChange={(event) => this.handleChange(event)}
                      value={s.productData.price}
                    />
                  </Col>
                  <Col md="6" className="mb-3">
                    <Label>سعر خاص: </Label>
                    <DefaultInput 
                      name={'special_price'}
                      onChange={(event) => this.handleChange(event)}
                      value={s.productData.special_price}
                    />
                  </Col>
                  <Col md="6" className="mb-3">
                    <Label>سعر الشراء: </Label>
                    <DefaultInput 
                      name={'purchase_price'}
                      onChange={(event) => this.handleChange(event)}
                      value={s.productData.purchase_price}
                    />
                  </Col>
                  <Col md="6" className="mb-3">
                    <Label>رمز التخزين التعريفي: </Label>
                    <DefaultInput 
                      name={'sku'}
                      onChange={(event) => this.handleChange(event)}
                      value={s.productData.sku}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card className="mb-3">
              <CardHeader>
                <CardTitle>معلومات متقدمة</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="6" className="mb-3">
                    <Label>الوزن (غرام): </Label>
                    <DefaultInput 
                      name={'weight'}
                      onChange={(event) => this.handleChange(event)}
                      value={s.productData.weight}
                    />
                  </Col>
                  <Col md="6" className="mb-3">
                    <Label>الإرتفاع (سنتيمتر): </Label>
                    <DefaultInput 
                      name={'height'}
                      onChange={(event) => this.handleChange(event)}
                      value={s.productData.height}
                    />
                  </Col>
                  <Col md="6" className="mb-3">
                    <Label>الطول (سنتيمتر): </Label>
                    <DefaultInput 
                      name={'length'}
                      onChange={(event) => this.handleChange(event)}
                      value={s.productData.length}
                    />
                  </Col>
                  <Col md="6" className="mb-3">
                    <Label>العرض (سنتيمتر): </Label>
                    <DefaultInput 
                      name={'wide'}
                      onChange={(event) => this.handleChange(event)}
                      value={s.productData.wide}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>المخزون و التوصيل</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="6" className="mb-3">
                    <Label>توفر التوصيل: </Label>
                    <DefaultSelect
                      name={'shipping'}
                      value={s.productData.shipping}
                      onChange={(event) => this.handleChange(event)}
                    >
                      <option value="1">نعم</option>
                      <option value="0">لا</option>
                    </DefaultSelect>
                  </Col>
                  <Col md="6" className="mb-3">
                    <Label>عدد المخزون: </Label>
                    <DefaultInput 
                      type={'number'}
                      value={s.productData.stock_availability}
                      name={'stock_availability'}
                      onChange={(event) => this.handleChange(event)}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md="4">
            <Card className="mb-3">
              <CardBody>
                <DefaultButton 
                  color={'danger'} 
                  block 
                  className="mb-3"
                  onClick={() => this.setState({destroyModalShown: true})}
                >
                  <MdDelete />{' '}
                  حذف
                </DefaultButton>
                <DefaultButton 
                  color={'dark'} 
                  block
                  onClick={() => this.updateProduct()}
                >
                  <AiOutlineSave />{' '}
                  حفظ
                </DefaultButton>
              </CardBody>
            </Card>
            <Card className="mb-3">
              <CardBody>
                <Label>الحالة: </Label>
                <DefaultSelect 
                  name={'status'} 
                  onChange={(event) => this.handleChange(event)}
                  value={s.productData.status}
                >
                  <option value="1">نشط</option>
                  <option value="0">مسودة</option>
                </DefaultSelect>
              </CardBody>
            </Card>

            <Card className="mb-3">
              <CardBody>
                <Label>التصنيف: </Label>
                <div>
                  {s.categoriesLoading ?
                    <AiOutlineLoading3Quarters className="animation-spin" />
                  :
                    <ul className="ps-0">
                      {s.categoriesData.length > 0 ?
                        s.categoriesData.map((category, categoryIndex) => (
                          <li key={categoryIndex} className="mb-2">
                            <Input 
                              type="checkbox" 
                              checked={this.state.selectedCategories?.includes(category.id)}
                              onChange={() => this.handleCategoriesChange(category.id)}
                            />{' '}
                            {category.name}
                            {category.subcategories?.length > 0 &&
                              <ul>
                                {category.subcategories.map((subcategory, subcategoryIndex) => (
                                  <li key={subcategoryIndex}>
                                    <Input 
                                      type="checkbox" 
                                      checked={this.state.selectedSubcategories?.includes(subcategory.id)}
                                      onChange={() => this.handleSubcategoriesChange(subcategory.id)}
                                    />{' '}
                                    {subcategory.name}
                                  </li>
                                ))}
                              </ul>
                            }
                          </li>
                        ))
                      :
                        <li>لا توجد بيانات</li>
                      }
                    </ul>
                  }
                </div>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <Label>العلامة التجارية: </Label>
                {s.brandsLoading ?
                  <AiOutlineLoading3Quarters className="animation-spin" />
                :
                  s.brandsData?.length > 0 ?
                    <DefaultSelect
                      name={'brand_id'}
                      onChange={(event) => this.handleChange(event)}
                      value={s.productData.brand_id}
                    >
                      <option value="">----</option>
                      {s.brandsData.map((data, key) => (
                        <option value={data.id} key={key}>{data.name}</option>
                      ))}
                    </DefaultSelect>
                  :
                    <div>
                      <Alert color="info">لا توجد في متجرك علامات تجارية</Alert>
                    </div>
                }
              </CardBody>
            </Card>
          </Col>
        </Row>

        {s.alertText &&
          <BottomLeftAlert color={s.alertType} onClose={() => this.setState({alertText: null})}>
            {s.alertText}
          </BottomLeftAlert>
        }

        <ConfirmationModal
          isOpen={s.destroyModalShown}
          confirmationText={'حذف'}
          cancelText={'إلغاء'}
          toggle={() => this.setState({destroyModalShown: false})}
          onConfirm={() => this.destroyProduct()}
          isLoading={s.destroyLoading}
        >
          هل حقا تريد حذف هذه البيانات؟
        </ConfirmationModal>
      </Container>
    );
  }
}

export default withRouter(WebsitesDataProductPage);
import React, { Component } from "react";
import { Badge, Card, CardBody, Col, Container, FormGroup, Label, Row, Table } from "reactstrap";
import { AdminBodyTitle } from "../../components/Typography/Titles";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { MdWebAssetOff } from "react-icons/md";
import { API_LINK } from "../../utils/constants";
import { DefaultButton } from "../../components/Buttons/default";
import { Link } from "react-router-dom";
import { DefaultModal } from "../../components/Modals/Default";
import { DefaultInput } from "../../components/Forms/Inputs/default";
import { DefaultSelect } from "../../components/Forms/Inputs/Select";
import { BottomLeftAlert } from "../../components/Alerts";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { i18n } from "../../i18n";

class DomainesPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      domainesData: null,
      domainesLoading: false,

      websitesData: null,
      websitesLoading: false,

      linkModalShown: false,
      selectedDomain: '',
      selectedWebsite: '',
      domainLinkLoading: false,

      alertText: null
    }
  }

  componentDidMount() {
    this.getDomaines();
  }

  getDomaines() {
    this.setState({domainesLoading: true});
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/domaines', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        this.setState({
          domainesData: json.domaines,
          domainesLoading: false
        });
      }
      else
      {
        this.setState({
          domainesLoading: false
        });
      }
    })
  }

  getWebsites() {
    this.setState({websitesLoading: true});
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/websites', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        this.setState({
          websitesData: json.websites,
          websitesLoading: false,
          linkModalShown: true
        });
      }
      else
      {
        this.setState({
          alertText: json.message,
          websitesLoading: false
        });
      }
    })
  }

  linkDomain() {
    this.setState({domainLinkLoading: true});
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/domain/link', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        domain_name: this.state.selectedDomain,
        website_id: this.state.selectedWebsite
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        this.setState({
          domainLinkLoading: false,
          linkModalShown: false
        }, () => {
          this.getDomaines();
        });
      }
      else
      {
        this.setState({
          alertText: json.message,
          domainLinkLoading: false
        });
      }
    })
  }

  render() {
    const s = this.state;

    return(
      <Container>
        <Row className="align-items-center">
          <Col xs="8" md="8">
            <AdminBodyTitle>{ i18n.t('domaines.title') }</AdminBodyTitle>
          </Col>
          <Col xs="4" md="4" className="text-end">
            <Link to={'/dashboard/domain/buy'}>
              <DefaultButton
                size={'md'}
                color={'dark'}
              >
                + { i18n.t('domaines.add_button') }
              </DefaultButton>
            </Link>
          </Col>
        </Row>
        {s.domainesLoading ?
          <div className="text-center py-5">
            <AiOutlineLoading3Quarters className="animation-spin" size={40} />
          </div>
        :
          s.domainesData?.length > 0 ?
            <Card>
              <CardBody>
                <Table responsive className="table-bliz">
                  <thead>
                    <tr>
                      <th>{ i18n.t('domaines.table.domain_name') }</th>
                      <th>{ i18n.t('domaines.table.website') }</th>
                      <th>{ i18n.t('domaines.table.status') }</th>
                      <th>{ i18n.t('domaines.table.payment_status') }</th>
                      <th>{ i18n.t('domaines.table.expiry') }</th>
                    </tr>
                  </thead>
                  <tbody>
                    {s.domainesData.map((data, key) => (
                      <tr key={key}>
                        <td>{data.domain_name}</td>
                        <td>
                          {data.website?.name || <DefaultButton size={'sm'} onClick={() => this.setState({selectedDomain: data.domain_name}, () => this.getWebsites())}>{ i18n.t('domaines.table.website_link_button') }</DefaultButton>}
                          <HiOutlinePencilAlt style={{cursor: 'pointer'}} onClick={() => this.setState({selectedWebsite: data.website?.id, selectedDomain: data.domain_name}, () => this.getWebsites())} />
                        </td>
                        <td>
                          {(new Date(data.end_date) < new Date()) ?
                            <Badge color="danger">{ i18n.t('domaines.table.expired') }</Badge>
                          :
                            <Badge color="success">{ i18n.t('domaines.table.active') }</Badge>
                          }
                        </td>
                        <td>
                          {data.payment_status !== 'paid' ?
                            <Badge color="danger">{ i18n.t('domaines.table.unpayed') }</Badge>
                          :
                            <Badge color="success">{ i18n.t('domaines.table.payed') }</Badge>
                          }
                        </td>
                        <td>{data.end_date}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          :
            <div className="text-center py-5">
              <MdWebAssetOff size={150} color="#dedede" />
              <h5 className="text-muted">{ i18n.t('alerts.no_data') }</h5>
            </div>
        }

        {s.alertText &&
          <BottomLeftAlert color={'danger'} onClose={() => this.setState({alertText: null})}>
            {s.alertText}
          </BottomLeftAlert>
        }

        <DefaultModal
          isOpen={s.linkModalShown}
          toggle={() => this.setState({linkModalShown: false})}
          title={ i18n.t('domaines.link_modal.title') }
          confirmationText={ i18n.t('modals.default.buttons.confirm') }
          cancelText={ i18n.t('modals.default.buttons.close') }
          isLoading={s.websitesLoading || s.domainLinkLoading}
          onConfirm={() => this.linkDomain()}
        >
          <FormGroup>
            <Label>{ i18n.t('domaines.link_modal.form.domain') }: </Label>
            <DefaultInput readOnly value={s.selectedDomain} />
          </FormGroup>
          <FormGroup>
            <Label>{ i18n.t('domaines.link_modal.form.website') }: </Label>
            <DefaultSelect value={s.selectedWebsite} onChange={(event) => this.setState({selectedWebsite: event.target.value})}>
              <option value="">{ i18n.t('domaines.link_modal.form.website_select') }</option>
              {s.websitesData?.map((data, key) => (
                <option value={data.id} key={key}>{data.name}</option>
              ))}
            </DefaultSelect>
          </FormGroup>
        </DefaultModal>
      </Container>
    );
  }
}

export default DomainesPage;
import React, { Component, Fragment } from "react";
import { Card, CardBody, Col, Container, FormGroup, FormText, Label, Row } from "reactstrap";
import { AdminBodyTitle } from "../../../components/Typography/Titles";
import { DefaultSelect } from "../../../components/Forms/Inputs/Select";
import './styles.css'
import { withRouter } from "../../../components/Router/withRouter";
import { API_LINK, MAIN_DOMAIN } from "../../../utils/constants";
import { DefaultInput } from "../../../components/Forms/Inputs/default";
import { DefaultButton } from "../../../components/Buttons/default";
import { Link } from "react-router-dom";
import { BottomLeftAlert } from "../../../components/Alerts";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

class MarketingSEOPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userData: null,
      websiteLoading: false,
      websitePagesData: null,
      selectedPage: '',

      pageTitle: '',
      pageDescription: '',
      pageKeywords: '',

      ogTitle: '',
      ogDescription: '',
      ogImage: '',

      updateLoading: false,

      alertText: null,
      alertType: 'success'
    }
  }

  componentDidMount() {
    this.setUserData();
    this.getWebsite();
  }

  setUserData() {
    const user = JSON.parse(localStorage.getItem('user'));
    this.setState({
      userData: user
    });
  }

  getWebsite() {
    this.setState({websiteLoading: true});
    const user = localStorage.getItem('user');
    const website = JSON.parse(this.props.website.website);

    fetch(API_LINK+'/website/'+website.id, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.setState({
          websitePagesData: json.website.pages,
          selectedPage: json.website.pages[0].id,
          pageTitle: json.website.pages[0]?.page_settings?.title,
          pageDescription: json.website.pages[0]?.page_settings?.description,
          pageKeywords: json.website.pages[0]?.page_settings?.keywords,
          ogTitle: json.website.pages[0]?.page_settings?.og_title,
          ogImage: json.website.pages[0]?.page_settings?.og_image,
          ogDescription: json.website.pages[0]?.page_settings?.og_description,
          websiteLoading: false
        });
      }
    })
  }

  updatePageSettings() {
    this.setState({updateLoading: true});
    const user = localStorage.getItem('user');
    const website = JSON.parse(this.props.website.website);

    fetch(API_LINK+'/builder/page_settings/'+website.id+'/'+this.state.selectedPage+'/update', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        title: this.state.pageTitle,
        description: this.state.pageDescription,
        keywords: this.state.pageKeywords,
        og_title: this.state.ogTitle,
        og_description: this.state.ogDescription,
        og_image: this.state.ogImage
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.setState({
          alertType: 'success',
          alertText: json.message
        }, () => {
          this.getWebsite();
        });
      } else {
        this.setState({
          alertType: 'danger',
          alertText: json.message
        });
      }

      this.setState({
        updateLoading: false
      });
    })
  }

  handleSelectChange(event) {
    const value = event.target.value;
    const settings = this.state.websitePagesData.find(page => page.id.toString() === value);
    this.setState({
      selectedPage: event.target.value,
      pageTitle: settings?.page_settings?.title,
      pageDescription: settings?.page_settings?.description,
      pageKeywords: settings?.page_settings?.keywords,
      ogDescription: settings?.page_settings?.og_description,
      ogImage: settings?.page_settings?.og_image
    });
  }
  
  render() {
    const s = this.state;

    return(
      <Container>
        <AdminBodyTitle>تحسين محركات البحث</AdminBodyTitle>
        
        {s.websiteLoading ?
          <div className="text-center py-4">
            <AiOutlineLoading3Quarters className="animation-spin" size={30} />
          </div>
        :
          <Card className="mb-3">
            <CardBody>
              <Label>الصفحة: </Label>
              <DefaultSelect value={s.selectedPage} onChange={(event) => this.handleSelectChange(event)}>
                <option value="">اختر الصفحة التي تريد تحسينها</option>
                {s.websitePagesData && s.websitePagesData.map((data, key) => (
                  <option value={data.id} key={key}>{data.name}</option>
                ))}
              </DefaultSelect>
            </CardBody>
          </Card>
        }

        {Number(s.selectedPage) >= 1 &&
          <Fragment>
            <Row className="mb-4">
              <Col md="8">
                <Card>
                  <CardBody>
                    <h5 className="fw-bold mb-3">محركات البحث</h5>
                    <FormGroup>
                      <Label>عنوان الصفحة: </Label>
                      <DefaultInput 
                        value={s.pageTitle} 
                        onChange={(event) => this.setState({pageTitle: event.target.value})}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>وصف الصفحة: </Label>
                      <DefaultInput 
                        type={'textarea'}
                        value={s.pageDescription || ''} 
                        onChange={(event) => this.setState({pageDescription: event.target.value})}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>كلمات مفتاحية: </Label>
                      <DefaultInput 
                        type={'textarea'}
                        value={s.pageKeywords || ''} 
                        onChange={(event) => this.setState({pageKeywords: event.target.value})}
                        placeholder={'مثال:  كتب مجانية، مقالات علمية'}
                      />
                    </FormGroup>
                    <DefaultButton 
                      color={'dark'} 
                      block 
                      onClick={() => this.updatePageSettings()}
                      isLoading={s.updateLoading}
                      disabled={s.updateLoading}
                    >
                      حفظ
                    </DefaultButton>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card>
                  <CardBody>
                    <h5 className="fw-bold mb-3">العرض في محرك البحث</h5>
                    <div class="seo-google-search-result">
                      <div class="seo-google-search-result-title">
                        <a 
                          href={JSON.parse(this.props.website.website).domaine ? 'https://www.google.com/search?q=inurl:'+JSON.parse(this.props.website.website).domaine.domain_name : 'https://www.google.com/search?q=inurl:'+s.userData.username+'.'+MAIN_DOMAIN+'/'+JSON.parse(this.props.website.website)?.name} 
                          target="_blank"
                        >
                          {JSON.parse(this.props.website.website).name} - {s.pageTitle}
                        </a>
                      </div>
                      <div class="seo-google-search-result-url">
                        https://{ JSON.parse(this.props.website.website).domaine ? JSON.parse(this.props.website.website).domaine.domain_name  : s.userData.username+'.'+MAIN_DOMAIN+'/'+JSON.parse(this.props.website.website)?.name}
                      </div>
                      <div class="seo-google-search-result-snippet">
                        {s.pageDescription}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="8">
                <Card>
                  <CardBody>
                    <h5 className="fw-bold mb-3">مواقع التواصل</h5>
                    <FormGroup>
                      <Label>العنوان: </Label>
                      <DefaultInput 
                        value={s.ogTitle}
                        onChange={(event) => this.setState({ogTitle: event.target.value})}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>رابط الصورة: </Label>
                      <DefaultInput 
                        value={s.ogImage || ''}
                        onChange={(event) => this.setState({ogImage: event.target.value})}
                      />
                      <FormText>
                        <Link to={'/dashboard/cms/files'}>رفع صورة من إدارة المحتوى</Link>
                      </FormText>
                    </FormGroup>
                    <FormGroup>
                      <Label>الوصف: </Label>
                      <DefaultInput 
                        type={'textarea'} 
                        value={s.ogDescription || ''}
                        onChange={(event) => this.setState({ogDescription: event.target.value})} 
                      />
                    </FormGroup>
                    <DefaultButton 
                      color={'dark'} 
                      block 
                      onClick={() => this.updatePageSettings()}
                      isLoading={s.updateLoading}
                      disabled={s.updateLoading}
                    >
                      حفظ
                    </DefaultButton>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card>
                  <CardBody>
                    <h5 className="fw-bold mb-3">العرض في مواقع التواصل</h5>
                    <div class="og-preview">
                      <div class="og-image border-bottom">
                        <img src={s.ogImage || 'https://bliz.one/assets/home/images/og_image.png'} alt="Preview Image" />
                      </div>
                      <div class="og-content">
                        <div class="og-title">{JSON.parse(this.props.website.website).name} - { s.ogTitle }</div>
                        <div class="og-url">
                          https://{ JSON.parse(this.props.website.website).domaine ? JSON.parse(this.props.website.website).domaine.domain_name  : s.userData.username+'.'+MAIN_DOMAIN+'/'+JSON.parse(this.props.website.website)?.name}
                        </div>
                        <div class="og-description">
                          {s.ogDescription}
                        </div>
                      </div>
                  </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Fragment>
        }

          {s.alertText &&
            <BottomLeftAlert color={s.alertType} onClose={() => this.setState({alertText: null})}>
              {s.alertText}
            </BottomLeftAlert>
          }
      </Container>
    );
  }
}

export default withRouter(MarketingSEOPage);
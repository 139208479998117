import React, { useState } from "react";
import { DefaultModal } from "../../../../components/Modals/Default";
import { FormGroup, Input, Label } from "reactstrap";
import { DefaultInput } from "../../../../components/Forms/Inputs/default";
import { MdOutlineCloudUpload } from "react-icons/md";
import { API_LINK } from "../../../../utils/constants";
import { IoDocumentOutline } from "react-icons/io5";

const CMSFileUploadModal = (props) => {
  const [state, setState] = useState({
    title: '', 
    description: '', 
    files: []
  });
  const [uploadLoading, setUploadLoading] = useState(false);

  const upload = async () => {
    setUploadLoading(true);
    const user = localStorage.getItem('user');

    let formData = new FormData();
    if(state.files?.length > 0) {
      state.files.forEach(file => {
        formData.append('files[]', file);
      });
    }
    if(state.title) {
      formData.append('title', state.title);
    }
    if(state.description) {
      formData.append('description', state.description);
    }
    if(props.selectedFolder) {
      formData.append('folder_id', props.selectedFolder);
    }

    fetch(API_LINK+'/cms/file/store', {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: formData
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        setState({title: '', description: '', files: []});
        setUploadLoading(false);
        props.onSuccess();
        props.toggle();
      } else {
        setUploadLoading(false);
      }
    })
  }

  const updateState = (name, value) => {
    setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const addFiles = (files) => {
    setState(prevState => ({
      ...prevState,
      files: [...prevState.files, ...files],
    }));
  };

  const removeFile = (fileToRemove) => {
    setState(prevState => ({
      ...prevState,
      files: prevState.files.filter(file => file !== fileToRemove),
    }));
  };

  return(
    <DefaultModal
      title={'رفع ملف'}
      isOpen={props.isOpen}
      confirmationText={'حفظ'}
      cancelText={'غلق'}
      toggle={props.toggle}
      onConfirm={upload}
      isLoading={uploadLoading}
      confirmationButtonDisabled={state.files?.length <= 0}
    >
      <FormGroup>
        <Label>اسم الملف: </Label>
        <DefaultInput 
          value={state.title}
          onChange={(event) => updateState('title', event.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <Label>الملف: </Label>
        <div className="cms-file-picker" onClick={() => document.getElementById('cms-file-upload').click()}>
          <div>
            <MdOutlineCloudUpload size={100} />
            <h6 className="text-center">إختر الملف</h6>
          </div>
        </div>
        <Input 
          type="file" 
          className="d-none" 
          id="cms-file-upload" 
          multiple 
          onChange={(event) => addFiles(event.target.files)}
        />
      </FormGroup>
      {state.files?.length > 0 &&
        <div className="cms-file-upload-preview-container">
          {state.files.map((data, key) => (
            <div className="cms-file-upload-preview pt-2 pb-2">
              <div className="cms-file-upload-preview-attachment me-2 ">
                {data.type.split('/')[0] === 'image' ?
                  <img src={URL.createObjectURL(data)} alt="" width="30px" height={'30px'} />
                :
                  <IoDocumentOutline size={30} />
                }
              </div>
              <div className="cms-file-upload-preview-name">{data.name}</div>
              <div className="cms-file-upload-preview-btn text-danger" onClick={() => removeFile(data)}>x</div>
            </div>
          ))}
        </div>
      }
    </DefaultModal>
  );
}

export default CMSFileUploadModal;
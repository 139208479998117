import React, { useState } from "react";
import { AiOutlineCamera } from "react-icons/ai";
import { Card, CardBody, Input, Label } from "reactstrap";
import { DefaultButton } from "../../../components/Buttons/default";
import { API_LINK, MAIN_LINK } from "../../../utils/constants";
import { i18n } from "../../../i18n";

export const ProfilePictureForm = (props) => {
  const [ loading, setLoading ] = useState(false);
  const [ profilePictureSrc, setProfilePictureSrc ] = useState(null);

  const update = () => {
    setLoading(true);
    const user = localStorage.getItem('user');

    let formData = new FormData();
    formData.append('image', profilePictureSrc);

    fetch(API_LINK+'/account/profile_picture/update', {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: formData
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        localStorage.setItem('user', JSON.stringify(json.user));
        props.setUserData();
        props.setAlert(json.message, 'success');
      } else {
        props.setAlert(json.message, 'danger');
      }
      setProfilePictureSrc(null);
      setLoading(false);
    })
  }

  const handleFileChange = (event) => {
    setProfilePictureSrc(event.target.files[0]);
  }

  return(
    <Card className="mb-3">
      <CardBody>
        <Label>{ i18n.t('profile.profile_picture') }: </Label>
        <div className="account-img-selection border border-dark p-3" onClick={() => document.getElementById('pimage').click() }>
          <img src={ profilePictureSrc ? URL.createObjectURL(profilePictureSrc) : props.values.profile_picture ? MAIN_LINK+props.values.profile_picture : require('../../../assets/brand-black.png') } id="pimageView" width="100%" />
          <div className="account-img-icon-container">
            <AiOutlineCamera className="account-img-icon" />
          </div>
        </div>
        <Input 
          type="file" 
          id="pimage"
          name="image" 
          onChange={(event) => handleFileChange(event)}
          accept="image/*" 
          hidden
        />
        {profilePictureSrc &&
          <DefaultButton 
            color="dark" 
            className="mt-3" 
            block
            onClick={update}
            isLoading={loading}
            disabled={loading}
          >
            حفظ
          </DefaultButton>
        }
      </CardBody>
    </Card>
  );
}
import React, { Component, useState } from "react";
import { DefaultModal } from "../../../../components/Modals/Default";
import { Col, Row } from "reactstrap";
import { DefaultButton } from "../../../../components/Buttons/default";
import { AiOutlineDelete } from "react-icons/ai";
import { BiEditAlt } from "react-icons/bi";
import { FaPlus } from "react-icons/fa6";
import BuilderAddLanguageModal from "./Add";
import { ConfirmationModal } from "../../../../components/Modals/Confirmation";
import { API_LINK } from "../../../../utils/constants";

const BuilderLanguagesModal = (props) => {
  const [addLanguageModalShown, setAddLanguageModalShown] = useState(false);
  const [editLanguageModalShown, setEditLanguageModalShown] = useState(false);
  const [languageToEdit, setLanguageToEdit] = useState(null);
  const [deleteModalShown, setDeleteModalShown] = useState(false);
  const [selectedToDelete, setSelectedToDelete] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const destroy = () => {
    setDeleteLoading(true);
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/builder/language/destroy', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        id: selectedToDelete
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        props.onSuccess();
      } else {
        props.onError(json.message);
      }
      setDeleteLoading(false);
      setDeleteModalShown(false);
    })
  }

  return(
    <DefaultModal
      isOpen={props.isOpen}
      title={'لغات الموقع'}
      toggle={props.toggle}
      cancelText={'غلق'}
    >
      {props.languages?.length > 0 &&
        <ul className="ps-0 builder-pages-container">
          {props.languages.map((data, key) => (
            <li key={key} className={props.selectedLanguage === data && 'active'}>
              <Row className="align-items-center">
                <Col md="8" xs="8" onClick={() => props.onLanguageChange(data)}>
                  {data.language.name}
                </Col>
                <Col md="4" xs="4" className="text-end">
                  <DefaultButton disabled={props.languages.length <= 1} size={'sm'} color={'danger'} onClick={() => {setSelectedToDelete(data.id); setDeleteModalShown(true)}}>
                    <AiOutlineDelete />
                  </DefaultButton>
                </Col>
              </Row>
            </li>
          ))}
        </ul>
      }

      <DefaultButton 
        size={'sm'} 
        onClick={() => setAddLanguageModalShown(true)} 
        className={'rounded-pill'} 
        color={'dark'}
      >
        <FaPlus />
      </DefaultButton>

      <BuilderAddLanguageModal 
        isOpen={addLanguageModalShown}
        toggle={() => setAddLanguageModalShown(false)}
        onSuccess={props.onSuccess}
        onError={props.onError}
      />

      <ConfirmationModal 
        isOpen={deleteModalShown}
        confirmationText={'حذف'}
        cancelText={'إلغاء'}
        toggle={() => setDeleteModalShown(false)}
        onConfirm={() => destroy()}
        isLoading={deleteLoading}
      >
        هل أنت متأكد من حذف هذه اللغة؟
      </ConfirmationModal>
    </DefaultModal>
  );
}

export default BuilderLanguagesModal;
import React, { useState } from "react";
import { DefaultModal } from "../../../components/Modals/Default";
import { FormGroup, Label } from "reactstrap";
import { DefaultInput } from "../../../components/Forms/Inputs/default";
import { API_LINK } from "../../../utils/constants";

const AddBankModal = (props) => {
  const [state, setState] = useState({});
  const [addLoading, setAddLoading] = useState(false);
  
  const store = () => {
    setAddLoading(true);
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/payments/bank_info/store', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        firstname: state.firstname,
        middlename: state.middlename,
        lastname: state.lastname,
        bank_name: state.bank_name,
        swift_code: state.swift_code,
        iban: state.iban
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        props.onSuccess();
      } else {
        props.onError(json.message);
      }

      setAddLoading(false);
    })
  }

  const updateState = (key, value) => {
    setState(prevState => ({
      ...prevState,
      [key]: value
    }));
  };

  return(
    <DefaultModal
      isOpen={props.isOpen}
      toggle={props.toggle}
      title={'إضافة حساب بنكي'}
      confirmationText={'حفظ'}
      cancelText={'غلق'}
      isLoading={addLoading}
      onConfirm={() => store()}
    >
      <FormGroup>
        <Label>الإسم الأول: </Label>
        <DefaultInput 
          onChange={(event) => updateState('firstname', event.target.value)}
          value={state.firstname} 
        />
      </FormGroup>
      <FormGroup>
        <Label>الإسم الثاني (إختياري): </Label>
        <DefaultInput 
          onChange={(event) => updateState('middlename', event.target.value)}
          value={state.middlename}
        />
      </FormGroup>
      <FormGroup>
        <Label>إسم العائلة: </Label>
        <DefaultInput 
          onChange={(event) => updateState('lastname', event.target.value)}
          value={state.lastname}
        />
      </FormGroup>
      <FormGroup>
        <Label>إسم البنك: </Label>
        <DefaultInput 
          onChange={(event) => updateState('bank_name', event.target.value)}
          value={state.bank_name}
        />
      </FormGroup>
      <FormGroup>
        <Label>الإيبان</Label>
        <DefaultInput 
          onChange={(event) => updateState('iban', event.target.value)}
          value={state.iban}
        />
      </FormGroup>
      <FormGroup>
        <Label>كود السويفت (إختياري): </Label>
        <DefaultInput 
          onChange={(event) => updateState('swift_code', event.target.value)}
          value={state.swift_code}
        />
      </FormGroup>
    </DefaultModal>
  );
}

export default AddBankModal;
import React from "react";
import { Button } from "reactstrap";
import PropTypes from 'prop-types';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import "./default.css";

export const DefaultButton = (props) => {
  return(
    <Button {...props} color={props.color} className={"bliz-btn "+props.className} size={props.size || 'lg'} disabled={props.disabled}>
      {props.isLoading ?
        <AiOutlineLoading3Quarters className="animation-spin" />
      :    
        props.children
      }
    </Button>
  );
};

DefaultButton.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string
}
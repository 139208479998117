import React, { Component } from "react";
import { Col, Container, FormGroup, Label, Row } from "reactstrap";
import { AdminBodyTitle } from "../../../components/Typography/Titles";
import { API_LINK } from "../../../utils/constants";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { BottomLeftAlert } from "../../../components/Alerts";
import { Link } from "react-router-dom";
import { DefaultButton } from "../../../components/Buttons/default";
import { withRouter } from "../../../components/Router/withRouter";
import { WebsiteContext } from "../../../context/Website";
import WebsiteSelector from "../../../components/Cards/Website/Selector";
import { ConfirmationModal } from "../../../components/Modals/Confirmation";
import { DefaultModal } from "../../../components/Modals/Default";
import { DefaultInput } from "../../../components/Forms/Inputs/default";

class MyWebsitesPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      websitesData: null,
      websitesLoading: true,

      selectedToDestroy: null,
      destroyLoading: false,

      selectedToChangeOwner: null,
      changeOwnerLoading: false,
      newOwnerEmail: null,

      websiteToEdit: {},
      editLoading: null,

      alertText: null
    }
  }

  componentDidMount() {
    this.getWebsites();
  }

  getWebsites() {
    this.setState({websitesLoading: true});
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/websites', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        this.setState({
          websitesData: json.websites,
          websitesLoading: false
        });
      }
      else
      {
        this.setState({
          alertText: json.message,
          websitesLoading: false
        });
      }
    })
  }

  updateWebsite() {
    this.setState({editLoading: true});
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/website/'+this.state.websiteToEdit.id+'/update', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        name: this.state.websiteToEdit.name,
        email: this.state.websiteToEdit.email,
        phone_number: this.state.websiteToEdit.phone_number,
        address: this.state.websiteToEdit.address,
        description: this.state.websiteToEdit.description
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        this.setState({
          websiteToEdit: {},
          editLoading: false
        }, () => {
          this.setWebsite(json.website);
          this.getWebsites();
        });
      }
      else
      {
        this.setState({
          alertText: json.message,
          editLoading: false
        });
      }
    })
  }

  destroy() {
    this.setState({destroyLoading: true});
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/website/'+this.state.selectedToDestroy+'/destroy', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        if(json.websites?.length > 0) {
          this.setWebsite(json.websites[0]);
        } else {
          this.setWebsite(null);
        }

        this.setState({
          destroyLoading: false
        }, () => {
          this.getWebsites();
        });
      }
      else
      {
        this.setState({
          alertText: json.message,
          destroyLoading: false
        });
      }
    })
  }

  copyWebsite(websiteId) {
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/website/'+websiteId+'/copy', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.getWebsites();
      } else {
        this.setState({
          alertText: json.message
        });
      }
    })
  }

  onChangeOwner() {
    this.setState({changeOwnerLoading: true});
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/website/'+this.state.selectedToChangeOwner+'/owner/change', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        email: this.state.newOwnerEmail
      })
    })
    .then((response) => response.json())
    .then((json) => {
      this.setState({changeOwnerLoading: false, selectedToChangeOwner: null});
      if(json.success) {
        this.getWebsites();
      } else {
        this.setState({
          alertText: json.message
        });
      }
    })
  }

  static contextType = WebsiteContext;

  setWebsite(website) {
    const { updateWebsite } = this.context;

    const newWebsiteValue = website;
    updateWebsite(newWebsiteValue);

    this.setState({selectedToDestroy: null});
    this.props.navigate('/dashboard/home');
  }

  handleEditChange(event) {
    const { name, value } = event.target;

    this.setState((prevState) => ({
      websiteToEdit: {
        ...prevState.websiteToEdit,
        [name]: value
      }
    }));
  }

  render() {
    const s = this.state;

    return(
      <Container>
        <Row>
          <Col>      
            <AdminBodyTitle>كل مواقعي</AdminBodyTitle>
          </Col>
          <Col className="text-end">
            <Link to={'/website/new'}>
              <DefaultButton color={'dark'}>+ إضافة موقع</DefaultButton>
            </Link>
          </Col>
        </Row>

        <hr />
        
        {s.websitesLoading ?
          <div className="text-center">
            <AiOutlineLoading3Quarters className="animation-spin h1" />
          </div>
        :
          s.websitesData?.length > 0 ?
            s.websitesData.map((data, key) => {
              return(
                <WebsiteSelector 
                  data={data} 
                  key={key} 
                  onClick={() => this.setWebsite(data)} 
                  onDestroy={(id) => this.setState({selectedToDestroy: id})}
                  onEditClick={() => this.setState({websiteToEdit: data})}
                  onCopyClick={() => this.copyWebsite(data.id)}
                  onChangeOwner={() => this.setState({selectedToChangeOwner: data.id})}
                />
              );
            })
          :
            <Col lg="4" md="6" className="mb-3 offset-lg-4 offset-md-3 mt-5 text-center">
              <img src={ require('../../../assets/empty-box.png') } width={'80%'} />
              <div className="h4 mt-3">لا توجد بيانات</div>
            </Col>
            
        }

        {s.alertText &&
          <BottomLeftAlert color={'danger'} onClose={() => this.setState({alertText: null})}>
            {s.alertText}
          </BottomLeftAlert>
        }

        <DefaultModal
          isOpen={s.websiteToEdit.name}
          toggle={() => this.setState({websiteToEdit: {}})}
          title={'تعديل الموقع'}
          isLoading={s.editLoading}
          confirmationText={'حفظ'}
          cancelText={'غلق'}
          onConfirm={() => this.updateWebsite()}
        >
          <FormGroup>
            <Label>إسم الموقع: </Label>
            <DefaultInput 
              bsSize={'lg'} 
              name="name"
              value={s.websiteToEdit.name}
              onChange={(event) => this.handleEditChange(event)}
            />
          </FormGroup>
          <FormGroup>
            <Label>بريد الموقع: </Label>
            <DefaultInput 
              bsSize={'lg'} 
              name="email"
              value={s.websiteToEdit.email}
              onChange={(event) => this.handleEditChange(event)}
            />
          </FormGroup>
          <FormGroup>
            <Label>رقم الهاتف: </Label>
            <DefaultInput 
              bsSize={'lg'} 
              name="phone_number"
              value={s.websiteToEdit.phone_number}
              onChange={(event) => this.handleEditChange(event)}
            />
          </FormGroup>
          <FormGroup>
            <Label>العنوان: </Label>
            <DefaultInput 
              bsSize={'lg'} 
              name="address"
              value={s.websiteToEdit.address}
              onChange={(event) => this.handleEditChange(event)}
            />
          </FormGroup>
          <FormGroup>
            <Label>وصف الموقع: </Label>
            <DefaultInput 
              type={'textarea'} 
              bsSize={'lg'} 
              name="description"
              value={s.websiteToEdit.description}
              onChange={(event) => this.handleEditChange(event)}
            />
          </FormGroup>
        </DefaultModal>

        <DefaultModal 
          isOpen={s.selectedToChangeOwner ? true : false}
          toggle={() => this.setState({selectedToChangeOwner: null})}
          title={'نقل الملكية'}
          isLoading={s.changeOwnerLoading}
          confirmationText={'نقل الملكية'}
          cancelText={'غلق'}
          onConfirm={() => this.onChangeOwner()}
          confirmationButtonDisabled={!s.newOwnerEmail || !s.newOwnerEmail.includes('@')}
        >
          <FormGroup>
            <Label>البريد الإلكتروني: </Label>
            <DefaultInput 
              value={s.newOwnerEmail} 
              onChange={(event) => this.setState({newOwnerEmail: event.target.value})} 
            />
          </FormGroup>
        </DefaultModal>

        <ConfirmationModal 
          isOpen={s.selectedToDestroy}
          confirmationText={'حذف'}
          cancelText={'إلغاء'}
          isLoading={s.destroyLoading}
          onConfirm={() => this.destroy()}
          toggle={() => this.setState({selectedToDestroy: null})}
          size={'lg'}
        >
          هل حقا تريد حذف هذا الموقع؟ حذف الموقع سيجعلك تخسر كل بياناته، ننصحك بأرشفته في حالة لم تكن متأكدا من قرار الحذف
        </ConfirmationModal>
      </Container>
    );
  }
}

export default withRouter(MyWebsitesPage);
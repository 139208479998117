import React from "react";
import { Col } from "reactstrap";
import { DefaultButton } from "../../../components/Buttons/default";
import { FaCaretDown, FaMobileScreenButton, FaRegEye } from "react-icons/fa6";
import { TbDeviceIpadHorizontal } from "react-icons/tb";
import { RiComputerLine } from "react-icons/ri";
import { MAIN_LINK } from "../../../utils/constants";
import { MdOutlinePublish } from "react-icons/md";
import propTypes from 'prop-types';
import './styles.css';
import { Link } from "react-router-dom";
import { CiGlobe } from "react-icons/ci";
import { i18n } from "../../../i18n";

const BuilderHeader = (props) => {
  return(
    <header className="builder-header border-bottom">
      <div className="d-flex align-items-center">
        <Col md="4" xs="7" className="d-flex">
          <div className="px-2">
            <Link to={'/dashboard/home/'}>
              <img src={ require('../../../assets/brand-black.png') } height={54} />
            </Link>
          </div>
          <DefaultButton 
            outline 
            color={'dark'} 
            className="border-top-0 border-bottom-0 ms-1 rounded-0 text-start pt-0" 
            onClick={props.onPageClick}
            size={'md'}
          >
            <span className="text-muted" style={{fontSize: 8}}>{ i18n.t('builder.header.current_page') }</span>
            <br />
            {props.selectedPage.name}
            {' '}
            <FaCaretDown />
          </DefaultButton>
          <DefaultButton 
            outline 
            color={'dark'} 
            className="border-top-0 border-bottom-0 border-start-0 rounded-0 text-start pt-0" 
            onClick={props.onLanguageClick}
            size={'md'}
          >
            <span className="text-muted" style={{fontSize: 8}}>{ i18n.t('builder.header.current_language') }</span>
            <br />
            <CiGlobe />{' '}
            {props.selectedLanguage?.language.name || 'غير محدد'}
            {' '}
            <FaCaretDown />
          </DefaultButton>
        </Col>
        <Col md="4" className="text-center d-none d-sm-inline-block">
          <DefaultButton 
            className={'border-0 rounded mx-1'} 
            size={'md'} 
            outline={props.preview !== 'mobile'} 
            color={'dark'} 
            onClick={() => props.onPreviewChange('mobile')}
          >
            <FaMobileScreenButton size={25} />
          </DefaultButton>
          <DefaultButton 
            size={'md'} 
            className={'border-0 rounded mx-1'} 
            outline={props.preview !== 'tablet'} 
            color={'dark'} 
            onClick={() => props.onPreviewChange('tablet')}
          >
            <TbDeviceIpadHorizontal size={25} />
          </DefaultButton>
          <DefaultButton 
            size={'md'} 
            className={'border-0 rounded mx-1'} 
            outline={props.preview !== 'desktop'} 
            color={'dark'} 
            onClick={() => props.onPreviewChange('desktop')}
          >
            <RiComputerLine size={25} />
          </DefaultButton>
        </Col>
        <Col md="4" xs="5" className="text-end">
          <a href={props.websiteData?.domaine ? 'https://'+props.websiteData.domaine.domain_name+'/'+props.selectedPage.slug+'/'+props.selectedLanguage.language.short : MAIN_LINK+'/w/'+JSON.parse(localStorage.getItem('user')).username+'/'+props.websiteData?.name+'/'+props.selectedPage.slug+'/'+props.selectedLanguage.language.short} target="_blank">
            <DefaultButton color={'dark'} className={'builder-header-btn bg-white text-dark px-5 border-0'}>
              <FaRegEye />{' '}
              <span className="d-none d-sm-inline-block">{ i18n.t('builder.header.preview') }</span>
            </DefaultButton>
          </a>
          <DefaultButton 
            isLoading={props.isPublishLoading}
            color={'dark'} 
            className={'builder-header-btn rounded-0 px-5'} 
            onClick={props.onPublish}
            style={{height: 54, width: 150}}
            disabled={props.isPublishLoading}
          >
            <MdOutlinePublish />{' '}
            <span className="d-none d-sm-inline-block">{ i18n.t('builder.header.publish') }</span>
          </DefaultButton>
        </Col>
      </div>
    </header>
  );
}

export default BuilderHeader;

BuilderHeader.propTypes = {
  preview: propTypes.string,
  onPreviewChange: propTypes.func,
  onPublish: propTypes.func,
  websiteData: propTypes.object
}

import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { MdDragIndicator } from "react-icons/md";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { DefaultButton } from "../../../components/Buttons/default";
import './styles.css';
import { BiCog } from "react-icons/bi";
import BuilderAddNavbarLinkModal from "../Modals/Navbar/Add";
import BuilderEditNavbarLinkModal from "../Modals/Navbar/Edit";
import { useWebsite } from "../../../context/Website";
import { API_LINK } from "../../../utils/constants";

const BuilderEditNavbarModal = (props) => {
  const website = useWebsite();
  const [links, setLinks] = useState(props.data.links);
  const [ addLinkModalShown, setAddLinkModalShown ] = useState(false);
  const [ editLinkModalShown, setEditLinkModalShown ] = useState(false);
  const [ linkToUpdateData, setLinkToUpdateData ] = useState(null);
  const [ linkToUpdateKey, setLinkToUpdateKey ] = useState(null);

  useEffect(() => {
    setLinks(props.data.links);
  }, [props.data.links]);

  const updateIframe = (reorderedLinks) => {
    const message = {
      type: 'updateNavlinkOrder',
      sectionIndex: props.data.parentInfo.sectionIndex,
      links: reorderedLinks
    };

    const iframe = document.getElementById('builder-iframe');
    iframe.contentWindow.postMessage(message, '*');
  }

  const store = (reorderedLinks) => {
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/builder/navbar/links/update_order', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        website_id: JSON.parse(website.website).id,
        page_id: props.selectedPage.id,
        lang: props.selectedLanguage.language.short,
        sectionIndex: props.data.parentInfo.sectionIndex,
        links: reorderedLinks
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        
      } else {
        props.onError(json.message);
      }
    })
  }

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedLinks = Array.from(links);
    const [movedLink] = reorderedLinks.splice(result.source.index, 1);
    reorderedLinks.splice(result.destination.index, 0, movedLink);

    setLinks(reorderedLinks);

    updateIframe(reorderedLinks);
    store(reorderedLinks);
  };  

  return(
    <div className="builder-edit-container">
      <div className="builder-add-header p-3 border-bottom">
        <Row className="align-items-center">
          <Col xs="8">
            <h5 className="fw-bold mb-0">تعديل القائمة</h5>
          </Col>
          <Col xs="4" className="text-end">
            <DefaultButton size={'sm'} color={'dark'} onClick={() => props.toggle()}>حفظ</DefaultButton>
          </Col>
        </Row>
      </div>

      <div className="builder-add-body px-3 pt-3">
        <h5 className="mb-2 fw-bold">الروابط</h5>
        <div className="builder-navbar-modal-links">
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {links && links.map((data, key) => (
                    <Draggable key={data.index} draggableId={data.index.toString()} index={key}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="builder-navbar-modal-link border mb-3"
                        >
                          <Row className="align-items-center">
                            <Col xs="9" className="align-items-center">
                              <MdDragIndicator className="drag-handle" style={{ cursor: 'grab' }} />
                              {data.text}
                            </Col>
                            <Col xs="3" className="text-end">
                              <BiCog size={20} onClick={() => { setEditLinkModalShown(true); setLinkToUpdateData(data); setLinkToUpdateKey(key) }} />
                            </Col>
                          </Row>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>

        <DefaultButton 
          size={'md'} 
          block 
          color={'success'} 
          onClick={() => setAddLinkModalShown(true)}
        >
          + إضافة عنصر جديد
        </DefaultButton>
      </div>

      <BuilderAddNavbarLinkModal 
        isOpen={addLinkModalShown}
        toggle={() => setAddLinkModalShown(false)}
        pages={props.pages}
        selectedPage={props.selectedPage}
        selectedLanguage={props.selectedLanguage}
        sectionIndex={props.data.parentInfo.sectionIndex}
        onError={props.onError}
      />

      {linkToUpdateData &&
        <BuilderEditNavbarLinkModal 
          isOpen={editLinkModalShown}
          toggle={() => setEditLinkModalShown(false)}
          pages={props.pages}
          selectedPage={props.selectedPage}
          selectedLanguage={props.selectedLanguage}
          sectionIndex={props.data.parentInfo.sectionIndex}
          onError={props.onError}
          link={linkToUpdateData}
          linkKey={linkToUpdateKey}
        />
      }
    </div>
  );
}

export default BuilderEditNavbarModal;
import React, { Component } from "react";
import { Card, CardBody, CardFooter, Col, Container, Input, Row, Table } from "reactstrap";
import { AdminBodyTitle } from "../../../components/Typography/Titles";
import { DefaultButton } from "../../../components/Buttons/default";
import { Link } from "react-router-dom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { API_LINK } from "../../../utils/constants";
import { setTaskPriority, setTaskStatus, setTaskType } from "../../../utils/helpers";
import { MdDelete } from "react-icons/md";
import { ConfirmationModal } from "../../../components/Modals/Confirmation";
import SmartPagination from "../../../components/Pagination/Smart";
import { withRouter } from "../../../components/Router/withRouter";

class CRMTasksPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tasksData: null,
      tasksLoading: true,
      paginationInfo: null,
      selectAll: false,
      selectedRows: [],

      currentPage: props.params?.page || 1,

      destroyLoading: false,
      destroyModalShown: false,
    }
  }

  componentDidMount() {
    this.getTasks();
  }

  getTasks() {
    this.setState({tasksLoading: true});
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/crm/tasks?page='+this.state.currentPage, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        this.setState({
          tasksData: json.tasks.data.map((contact) => ({
            ...contact,
            selected: false
          })),
          paginationInfo: json.tasks,
          tasksLoading: false
        });
      }
      else
      {
        this.setState({
          alertText: json.message,
          tasksLoading: false
        });
      }
    })
  }

  destroyTasks() {
    this.setState({destroyLoading: true});
    const user = localStorage.getItem('user');
    const SELECTED_IDS = this.state.selectedRows.map(row => row.id);

    fetch(API_LINK+'/crm/task/destroy', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        id: SELECTED_IDS
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.setState({
          selectedRows: [],
          destroyLoading: false,
          destroyModalShown: false
        }, () => {
          this.getTasks();
        });
      }
    })
  }

  handlePageChange(page) {
    this.setState({
      currentPage: page
    }, () => {
      this.props.navigate('/dashboard/crm/tasks/'+page);
      this.getTasks();
    });
  }

  handleCheckboxChange(row) {
    const updatedRows = this.state.tasksData.map((r) =>
      r.id === row.id ? { ...r, selected: !r.selected } : r
    );
  
    const updatedSelectedRows = updatedRows.filter((r) => r.selected);
  
    this.setState({
      tasksData: updatedRows,
      selectedRows: updatedSelectedRows
    });
  }

  handleSelectAllChange() {
    const updatedRows = this.state.tasksData.map((row) => ({
      ...row,
      selected: !this.state.selectAll,
    }));
  
    const selectAll = !this.state.selectAll;
  
    this.setState({
      tasksData: updatedRows,
      selectAll,
      selectedRows: selectAll ? updatedRows : [],
    });
  }

  render() {
    const s = this.state;

    return(
      <Container>
        <Row>
          <Col md="8" xs="6">
            <AdminBodyTitle>المهام</AdminBodyTitle>
          </Col>
          <Col md="4" xs="6" className="text-end">
            <Link to={'/dashboard/crm/task/add'}>
              <DefaultButton color={'dark'}>+ إضافة مهمة</DefaultButton>
            </Link>
          </Col>
        </Row>

        <Card>
          <CardBody>
            <Table responsive className="table-bliz">
              <thead>
                <tr>
                  <td>
                    <Input type="checkbox" checked={s.selectAll} onChange={() => this.handleSelectAllChange()} />
                  </td>
                  <th>#</th>
                  <th>العنوان</th>
                  <th>النوع</th>
                  <th>التاريخ</th>
                  <th>العميل</th>
                  <th>الأولوية</th>
                  <th>الحالة</th>
                </tr>
              </thead>
              <tbody>
                {s.tasksLoading ?
                  <tr>
                    <td colSpan={'8'} align="center">
                      <AiOutlineLoading3Quarters className="animation-spin" />
                    </td>
                  </tr>
                :
                  s.tasksData?.length > 0 ?
                    s.tasksData.map((data, key) => {
                      return(
                        <tr key={key}>
                          <td>
                            <Input type="checkbox" checked={data.selected} onChange={() => this.handleCheckboxChange(data)} />
                          </td>
                          <td>{data.id}</td>
                          <td>
                            <Link to={'/dashboard/crm/task/view/'+data.id}>
                              {data.title}
                            </Link>
                          </td>
                          <td>{setTaskType(data.type)}</td>
                          <td>{data.date} - {data.time}</td>
                          <td>
                            <Link to={'/dashboard/crm/contact/view/'+data.contact?.id}>
                              {data.contact?.firstname} {data.contact?.lastname}
                            </Link>
                          </td>
                          <td className={setTaskPriority(data.priority).color}>{setTaskPriority(data.priority).label}</td>
                          <td className={setTaskStatus(data.status).color}>{setTaskStatus(data.status).label}</td>
                        </tr>
                      );
                    })
                  :
                  <tr>
                    <td colSpan={'8'} align="center">لا توجد بيانات</td>
                  </tr>
                }
              </tbody>
            </Table>
          </CardBody>

          {s.tasksData?.length > 0 &&
            <CardFooter>
              {s.paginationInfo &&
                <SmartPagination 
                  currentPage={s.currentPage}
                  totalPages={s.paginationInfo.last_page}
                  onPageChange={(page) => this.handlePageChange(page)}
                />
              }
            </CardFooter>
          }
        </Card>

        {s.selectedRows?.length > 0 &&
          <div className="fixed-delete bg-danger rounded-pill shadow cursor-pointer p-3" onClick={() => this.setState({destroyModalShown: true})}>
            <MdDelete size={30} color="#fff" />
          </div>
        }

        <ConfirmationModal
          isOpen={s.destroyModalShown}
          confirmationText={'حذف'}
          cancelText={'إلغاء'}
          toggle={() => this.setState({destroyModalShown: false})}
          onConfirm={() => this.destroyTasks()}
          isLoading={s.destroyLoading}
        >
          هل حقا تريد حذف هذه البيانات؟
        </ConfirmationModal>
      </Container>
    );
  }
}

export default withRouter(CRMTasksPage);
import React, { Component } from "react";
import { Container } from "reactstrap";
import { NotificationCard } from "../../components/Cards/Notification";
import { AdminBodyTitle } from "../../components/Typography/Titles";
import { MdWebAssetOff } from "react-icons/md";

class NotificationsPage extends Component {
  render() {
    return(
      <Container fluid>
        <AdminBodyTitle>التنبيهات</AdminBodyTitle>
        <div className="text-center py-5">
          <MdWebAssetOff size={150} color="#dedede" />
          <h5 className="text-muted">لا توجد بيانات</h5>
        </div>
        {/* {[...Array(1)].map((key) => {
          return(
            <NotificationCard date={'12-02-2023'} important unread key={key}>
              هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها.
            </NotificationCard>
          );
        })} */}
      </Container>
    );
  }
}

export default NotificationsPage;
import React, { Component } from "react";
import { Card, CardBody, CardFooter, Col, Container, FormGroup, Input, Label, Row, Table } from "reactstrap";
import { AdminBodyTitle } from "../../../components/Typography/Titles";
import { withRouter } from "../../../components/Router/withRouter";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { API_LINK, MAIN_LINK } from "../../../utils/constants";
import { BottomLeftAlert } from "../../../components/Alerts";
import { ConfirmationModal } from "../../../components/Modals/Confirmation";
import { MdDelete } from "react-icons/md";
import { IoEyeOutline } from "react-icons/io5";
import { DefaultButton } from "../../../components/Buttons/default";
import { DefaultModal } from "../../../components/Modals/Default";
import { DefaultInput } from "../../../components/Forms/Inputs/default";
import { DefaultSelect } from "../../../components/Forms/Inputs/Select";
import { FiEdit } from "react-icons/fi";
import SmartPagination from "../../../components/Pagination/Smart";

class PaymentsLinksPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      linksData: null,
      linksLoading: true,
      selectAll: false,
      paginationInfo: null,
      selectedRows: [],

      currentPage: props.params.page || 1,

      addModalShown: false,
      addLoading: false,
      editLoading: false,

      linkToAdd: {},
      linkToEdit: {},

      destroyLoading: false,
      destroyModalShown: false,

      alertText: null
    }
  }

  componentDidMount() {
    this.getLinks();
  }

  getLinks() {
    this.setState({linksLoading: true});
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/payments/links?page='+this.state.currentPage, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        this.setState({
          linksData: json.links.data.map((contact) => ({
            ...contact,
            selected: false
          })),
          paginationInfo: json.links,
          linksLoading: false
        });
      }
      else
      {
        this.setState({
          alertText: json.message,
          linksLoading: false
        });
      }
    })
  }

  storeLink() {
    this.setState({addLoading: true});
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/payments/link/store', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        title: this.state.linkToAdd.title,
        description: this.state.linkToAdd.description,
        amount: this.state.linkToAdd.amount,
        currency_code: this.state.linkToAdd.currency_code,
        due_date: this.state.linkToAdd.due_date,
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        this.setState({
          addLoading: false,
          addModalShown: false,
          linkToAdd: {}
        }, () => {
          this.getLinks();
        });
      }
      else
      {
        this.setState({
          alertText: json.message,
          addLoading: false
        });
      }
    })
  }

  updateLink() {
    this.setState({editLoading: true});
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/payments/link/update', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        link_id: this.state.linkToEdit.id,
        title: this.state.linkToEdit.title,
        description: this.state.linkToEdit.description,
        amount: this.state.linkToEdit.amount,
        currency_code: this.state.linkToEdit.currency_code,
        due_date: this.state.linkToEdit.due_date,
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        this.setState({
          editLoading: false,
          linkToEdit: {}
        }, () => {
          this.getLinks();
        });
      }
      else
      {
        this.setState({
          alertText: json.message,
          editLoading: false
        });
      }
    })
  }

  destroyLinks() {
    this.setState({destroyLoading: true});
    const user = localStorage.getItem('user');
    const SELECTED_IDS = this.state.selectedRows.map(row => row.id);

    fetch(API_LINK+'/payments/link/destroy', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        id: SELECTED_IDS
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.setState({
          selectedRows: [],
          destroyLoading: false,
          destroyModalShown: false,
          selectAll: false
        }, () => {
          this.getLinks();
        });
      }
    })
  }

  handlePageChange(page) {
    this.setState({
      currentPage: page
    }, () => {
      this.props.navigate('/dashboard/newsletter/'+page);
      this.getLinks();
    });
  }

  handleCheckboxChange(row) {
    const updatedRows = this.state.linksData.map((r) =>
      r.id === row.id ? { ...r, selected: !r.selected } : r
    );
  
    const updatedSelectedRows = updatedRows.filter((r) => r.selected);
  
    this.setState({
      linksData: updatedRows,
      selectedRows: updatedSelectedRows
    });
  }

  handleSelectAllChange() {
    const updatedRows = this.state.linksData?.map((row) => ({
      ...row,
      selected: !this.state.selectAll,
    }));
  
    const selectAll = !this.state.selectAll;
  
    this.setState({
      linksData: updatedRows,
      selectAll,
      selectedRows: selectAll ? updatedRows : [],
    });
  }

  handleChange(e) {
    const { name, value, type } = e.target;

    this.setState((prevState) => ({
      linkToAdd: {
        ...prevState.linkToAdd,
        [name]: type === 'file' ? e.target.files[0] : value
      }
    }));
  }

  handleEditChange(e) {
    const { name, value, type } = e.target;

    this.setState((prevState) => ({
      linkToEdit: {
        ...prevState.linkToEdit,
        [name]: type === 'file' ? e.target.files[0] : value
      }
    }));
  }

  render() {
    const s = this.state;

    return(
      <Container>
        <Row className="mt-5">
          <Col md="6" className="offset-md-3 offset-lg-4 text-center" lg="4">
            <img src={ require('../../../assets/payment-link-soon.png') } width={'100%'} />
            <h5 className="mt-4">هذه الميزة غير متوفرة في دولتك</h5>
          </Col>
        </Row>
      </Container>
    );

    // return(
    //   <Container>
    //     <Row>
    //       <Col xs="6">
    //         <AdminBodyTitle>روابط الدفع</AdminBodyTitle>
    //       </Col>
    //       <Col xs="6" className="text-end">
    //         <DefaultButton color={'dark'} onClick={() => this.setState({addModalShown: true})}>+ إضافة رابط</DefaultButton>
    //       </Col>
    //     </Row>
    //     <Card>
    //       <CardBody>
    //         <Table className="table-bliz" responsive>
    //           <thead>
    //             <tr>
    //               <th>
    //                 <Input type="checkbox" checked={s.selectAll} onChange={() => this.handleSelectAllChange()} />
    //               </th>
    //               <th>#</th>
    //               <th>العنوان</th>
    //               <th>القيمة</th>
    //               <th>آخر أجل الدفع</th>
    //               <th>الحالة</th>
    //               <th>خيارات</th>
    //             </tr>
    //           </thead>
    //           <tbody>
    //             {s.linksLoading ?
    //               <tr>
    //                 <td colSpan={'7'} align="center">
    //                   <AiOutlineLoading3Quarters className="animation-spin" />
    //                 </td>
    //               </tr>
    //             :
    //               s.linksData?.length > 0 ?
    //                 s.linksData.map((data, key) => {
    //                   return(
    //                     <tr key={key}>
    //                       <td>
    //                         <Input type="checkbox" checked={data.selected} onChange={() => this.handleCheckboxChange(data)} />
    //                       </td>
    //                       <td>{data.uniquid}</td>
    //                       <td>{data.title}</td>
    //                       <td>{data.amount} ر.س</td>
    //                       <td>{data.due_date}</td>
    //                       <td>{Number(data.status) === 0 ? <span className="text-danger">قيد الإنتظار</span> : <span className="text-success">مدفوع</span>}</td>
    //                       <td>
    //                         <a href={MAIN_LINK+'/pay/'+data.id} target="_blank">
    //                           <DefaultButton size={'xs'} color={'info'}>
    //                             <IoEyeOutline />
    //                           </DefaultButton>
    //                         </a>
    //                         {' '}
    //                         <DefaultButton size={'xs'} color={'success'} onClick={() => this.setState({linkToEdit: data})}>
    //                           <FiEdit />
    //                         </DefaultButton>
    //                         {' '}
    //                         <DefaultButton size={'xs'} color={'danger'} onClick={() => this.setState({selectedRows: [data], destroyModalShown: true})}>
    //                           <MdDelete />
    //                         </DefaultButton>
    //                       </td>
    //                     </tr>
    //                   );
    //                 })
    //               :
    //                 <tr>
    //                   <td colSpan={'7'} align="center">لا توجد بيانات</td>
    //                 </tr>
    //             }
    //           </tbody>
    //         </Table>
    //       </CardBody>

    //       {s.linksData?.length > 0 &&
    //         <CardFooter>
    //           {s.paginationInfo &&
    //             <SmartPagination 
    //               currentPage={s.currentPage}
    //               totalPages={s.paginationInfo.last_page}
    //               onPageChange={(page) => this.handlePageChange(page)}
    //             />
    //           }
    //         </CardFooter>
    //       }
    //     </Card>

    //     {s.alertText &&
    //       <BottomLeftAlert color={'danger'} onClose={() => this.setState({alertText: null})}>
    //         {s.alertText}
    //       </BottomLeftAlert>
    //     }

    //     {s.selectedRows?.length > 0 &&
    //       <div className="fixed-delete bg-danger rounded-pill shadow cursor-pointer p-3" onClick={() => this.setState({destroyModalShown: true})}>
    //         <MdDelete size={30} color="#fff" />
    //       </div>
    //     }

    //     <DefaultModal
    //       isOpen={s.addModalShown}
    //       toggle={() => this.setState({addModalShown: false})}
    //       title={'إضافة رابط'}
    //       isLoading={s.addLoading}
    //       confirmationText={'إضافة'}
    //       cancelText={'غلق'}
    //       onConfirm={() => this.storeLink()}
    //     >
    //       <FormGroup>
    //         <Label>العنوان: </Label>
    //         <DefaultInput 
    //           name={'title'}
    //           value={s.linkToAdd.title}
    //           onChange={(event) => this.handleChange(event)}
    //         />
    //       </FormGroup>
    //       <FormGroup>
    //         <Label>الوصف: </Label>
    //         <DefaultInput 
    //           type={'textarea'} 
    //           name={'description'}
    //           value={s.linkToAdd.description}
    //           onChange={(event) => this.handleChange(event)}
    //         />
    //       </FormGroup>
    //       <FormGroup>
    //         <Label>القيمة: </Label>
    //         <DefaultInput 
    //           name={'amount'}
    //           value={s.linkToAdd.amount}
    //           onChange={(event) => this.handleChange(event)}
    //         />
    //       </FormGroup>
    //       <FormGroup>
    //         <Label>العملة: </Label>
    //         <DefaultSelect 
    //           name={'currency_code'}
    //           value={s.linkToAdd.currency_code}
    //           onChange={(event) => this.handleChange(event)}
    //         >
    //           <option value="">اختر</option>
    //           <option value="SAR">ريال سعودي (SAR)</option>
    //         </DefaultSelect>
    //       </FormGroup>
    //       <FormGroup>
    //         <Label>التاريخ: </Label>
    //         <DefaultInput 
    //           type={'date'} 
    //           name={'due_date'} 
    //           value={s.linkToAdd.due_date}
    //           onChange={(event) => this.handleChange(event)}
    //         />
    //       </FormGroup>
    //     </DefaultModal>

    //     <DefaultModal
    //       isOpen={s.linkToEdit?.amount}
    //       toggle={() => this.setState({linkToEdit: {}})}
    //       title={'تعديل الرابط'}
    //       isLoading={s.addLoading}
    //       confirmationText={'حفظ'}
    //       cancelText={'غلق'}
    //       onConfirm={() => this.updateLink()}
    //     >
    //       <FormGroup>
    //         <Label>العنوان: </Label>
    //         <DefaultInput 
    //           name={'title'}
    //           value={s.linkToEdit.title}
    //           onChange={(event) => this.handleEditChange(event)}
    //         />
    //       </FormGroup>
    //       <FormGroup>
    //         <Label>الوصف: </Label>
    //         <DefaultInput 
    //           type={'textarea'} 
    //           name={'description'}
    //           value={s.linkToEdit.description}
    //           onChange={(event) => this.handleEditChange(event)}
    //         />
    //       </FormGroup>
    //       <FormGroup>
    //         <Label>القيمة: </Label>
    //         <DefaultInput 
    //           name={'amount'}
    //           value={s.linkToEdit.amount}
    //           onChange={(event) => this.handleEditChange(event)}
    //         />
    //       </FormGroup>
    //       <FormGroup>
    //         <Label>العملة: </Label>
    //         <DefaultSelect 
    //           name={'currency_code'}
    //           value={s.linkToEdit.currency_code}
    //           onChange={(event) => this.handleEditChange(event)}
    //         >
    //           <option value="">اختر</option>
    //           <option value="SAR">ريال سعودي (SAR)</option>
    //         </DefaultSelect>
    //       </FormGroup>
    //       <FormGroup>
    //         <Label>التاريخ: </Label>
    //         <DefaultInput 
    //           type={'date'} 
    //           name={'due_date'} 
    //           value={s.linkToEdit.due_date}
    //           onChange={(event) => this.handleEditChange(event)}
    //         />
    //       </FormGroup>
    //     </DefaultModal>

    //     <ConfirmationModal
    //       isOpen={s.destroyModalShown}
    //       confirmationText={'حذف'}
    //       cancelText={'إلغاء'}
    //       toggle={() => this.setState({destroyModalShown: false})}
    //       onConfirm={() => this.destroyLinks()}
    //       isLoading={s.destroyLoading}
    //     >
    //       هل حقا تريد حذف هذه البيانات؟
    //     </ConfirmationModal>
    //   </Container>
    // );
  }
}

export default withRouter(PaymentsLinksPage);
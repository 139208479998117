import React, { Component } from "react";
import { Card, CardBody, Container } from "reactstrap";
import { AdminBodyTitle } from "../../../components/Typography/Titles";
import { API_LINK } from "../../../utils/constants";
import { withRouter } from "../../../components/Router/withRouter";
import SmartPagination from "../../../components/Pagination/Smart";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { MdOutlineCreditCardOff } from "react-icons/md";

class PaymentHistoriesPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      historiesData: null,
      historiesLoading: true,

      currentPage: props.params?.page || 1
    }
  }

  componentDidMount() {
    this.getHistories();
  }

  getHistories() {
    this.setState({historiesLoading: true});
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/payments/histories?page='+this.state.currentPage, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        this.setState({
          historiesData: json.payments,
          historiesLoading: false
        });
      }
      else
      {
        this.setState({
          alertText: json.message,
          historiesLoading: false
        });
      }
    })
  }

  handlePageChange(page) {
    this.setState({
      currentPage: page
    }, () => {
      this.props.navigate('/dashboard/payment_histories/'+page);
      this.getHistories();
    });
  }

  render() {
    const s = this.state;

    return(
      <Container className="mb-5">
        <AdminBodyTitle>سجلات الدفع</AdminBodyTitle>

        {s.historiesLoading ?
          <div className="text-center py-5">
            <AiOutlineLoading3Quarters className="animation-spin" size={40} />
          </div>
        :
          s.historiesData?.data?.length > 0 ?
            s.historiesData.data.map((data, key) => (
              <Card className="mt-4" key={key}>
                <CardBody>
                  <div className="d-flex align-items-center">
                    <div className={data.operator === '-' ? 'fw-bold me-3 h4 text-danger' : 'fw-bold me-3 h4 text-success'}>{data.operator}{data.amount} ر.س</div>
                    <div>
                      <div className="fw-bold">{data.description}</div>
                      <div>{data.created_at.split('T')[0]}</div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            ))
          :
            <div className="text-center py-5">
              <MdOutlineCreditCardOff size={150} color="#dedede" />
              <h5 className="text-muted mt-3 mb-3">لا توجد بيانات</h5>
            </div>
        }

        <br />

        {s.historiesData?.data?.length > 0 &&
          s.historiesData &&
            <SmartPagination 
              currentPage={s.currentPage}
              totalPages={s.historiesData.last_page}
              onPageChange={(page) => this.handlePageChange(page)}
            />
        }
      </Container>
    );
  }
}

export default withRouter(PaymentHistoriesPage);
import React, { Component } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Container, Input, Row } from "reactstrap";
import { AdminBodyTitle } from "../../../components/Typography/Titles";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FaFacebookMessenger, FaInstagram, FaWhatsapp } from "react-icons/fa";
import { API_LINK } from "../../../utils/constants";
import { withRouter } from "../../../components/Router/withRouter";
import { DefaultButton } from "../../../components/Buttons/default";
import SubscribtionForm from "./Forms/Payment";
import { BottomLeftAlert } from "../../../components/Alerts";
import './styles.css';

class SubscribePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      packageData: null,
      packageLoading: true,

      selectedOffer: 12,
      total: 0,
      paymentMethodForm: false,

      alertText: null
    }
  }

  componentDidMount() {
    this.getPackage();
  }

  getPackage() {
    this.setState({packageLoading: true});
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/package/'+this.props.params.package, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        this.setState({
          packageData: json.package,
          subscribtion: json.subscribtion,
          packageLoading: false,
          total: json.package.price*this.state.selectedOffer
        });
      }
      else
      {
        this.setState({
          packageLoading: false
        });
      }
    })
  }

  render() {
    const s = this.state;

    return(
      <Container>
        {s.packageLoading ?
          <div className="text-center py-5">
            <AiOutlineLoading3Quarters className="animation-spin" size={40} />
          </div>
        :
          s.packageData &&
          <Row>
            <Col md="8" className="offset-md-2">
              <AdminBodyTitle className={'mb-4'}>
                {s.paymentMethodForm ?
                  'إختر وسيلة الدفع'
                :
                  'إختر مدة الإشتراك'
                }
              </AdminBodyTitle>
  
              {s.paymentMethodForm ?
                <div>
                  <SubscribtionForm 
                    package={s.packageData} 
                    total={Number(s.total).toFixed(2)} 
                    delay={s.selectedOffer} 
                    onError={(message) => this.setState({alertText: message})}
                  />
                  <Card className="mt-4 subscribtion-package-card">
                    <CardBody>
                      <CardTitle>تواجه مشكلة في الدفع؟ تواصل معنا لحل المشكلة عبر:</CardTitle>
                      <Row className="mt-3">
                        <Col md="4" xs="6">
                          <a href="https://wa.me/213779828745" target="_blank">
                            <DefaultButton block outline color={'success'}>
                              <FaWhatsapp size={22} />{' '}
                              واتساب
                            </DefaultButton>
                          </a>
                        </Col>
                        <Col md="4" xs="6">
                          <a href="https://facebook.com/blizcorporation" target="_blank">
                            <DefaultButton block outline color={'info'}>
                              <FaFacebookMessenger size={22} />{' '}
                              ماسنجر
                            </DefaultButton>
                          </a>
                        </Col>
                        <Col md="4" xs="6">
                          <a href="https://instagram.com/blizcorpo" target="_blank">
                            <DefaultButton block outline className={'payform-btn-instagram'}>
                              <FaInstagram size={22} />{' '}
                              انستغرام
                            </DefaultButton>
                          </a>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              :
                <Card className="subscribtion-package-card">
                  <CardHeader>
                    أنت على وشك الإشتراك في: <b>{s.packageData.name}</b>
                  </CardHeader>
                  <CardBody>
                    <div className="mb-4">
                      <Input 
                        type="radio" 
                        checked={s.selectedOffer === 1} 
                        onChange={() => this.setState({selectedOffer: 1, total: ((s.packageData.price)*1).toFixed(2)})} 
                        className="me-3"
                      /> 
                      <b>شهر</b> - {s.packageData.price} * 1 = {((s.packageData.price)*1).toFixed(2)} {s.packageData.country?.currency_symbol}
                    </div>
                    <div className="mb-4">
                      <Input 
                        type="radio" 
                        checked={s.selectedOffer === 12} 
                        onChange={() => this.setState({selectedOffer: 12, total: ((s.packageData.price)*12).toFixed(2)})} 
                        className="me-3"
                      /> 
                      <b>سنة</b> - {s.packageData.price} * 12 = {((s.packageData.price)*12).toFixed(2)} {s.packageData.country?.currency_symbol}
                    </div>
                    <DefaultButton
                      color={'dark'}
                      block
                      disabled={!s.selectedOffer}
                      onClick={() => this.setState({paymentMethodForm: true})}
                    >
                      التوجه لصفحة الدفع
                    </DefaultButton>
                  </CardBody>
                </Card>
              }
            </Col>
          </Row>
        }

        {s.alertText &&
          <BottomLeftAlert color={'danger'} onClose={() => this.setState({alertText: null})}>
            {s.alertText}
          </BottomLeftAlert>
        }
      </Container>
    );
  }
}

export default withRouter(SubscribePage);
import React, { Component } from "react";
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import { AdminBodyTitle } from "../../../components/Typography/Titles";
import { API_LINK } from "../../../utils/constants";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { DefaultButton } from "../../../components/Buttons/default";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { ConfirmationModal } from "../../../components/Modals/Confirmation";
import { BottomLeftAlert } from "../../../components/Alerts";
import AddBankModal from "../Modals/AddBank";
import EditBankModal from "../Modals/EditBank";

class BankInfosPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bankInfos: null,
      bankInfosLoading: true,
      
      selectedToDestroy: null,
      destroyLoading: false,

      bankInfosModalShown: false,
      dataToEdit: null
    }
  }

  componentDidMount() {
    this.getBankInfos();
  }

  getBankInfos() {
    this.setState({bankInfosLoading: true});
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/payments/bank_infos', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.setState({
          bankInfos: json.infos,
          bankInfosLoading: false
        });
      } else {
        this.setState({
          bankInfosLoading: false
        });
      }
    })
  }

  destroy() {
    this.setState({destroyLoading: true});
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/payments/bank_info/destroy', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        id: this.state.selectedToDestroy
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.getBankInfos();
      } else {
        this.setState({
          alertText: json.message
        });
      }

      this.setState({
        selectedToDestroy: null,
        destroyLoading: false
      });
    })
  }

  render() {
    const s = this.state;

    return(
      <Container>
        <Row className="align-items-center">
          <Col xs="8">
            <AdminBodyTitle>الحسابات البنكية</AdminBodyTitle>
          </Col>
          <Col xs="4" className="text-end">
            <DefaultButton color={'dark'} onClick={() => this.setState({bankInfosModalShown: true})}>إضافة حساب</DefaultButton>
          </Col>
        </Row>

        <Card>
          <CardBody>
            <Table responsive className="table-bliz">
              <thead>
                <tr>
                  <th>الإسم الكامل</th>
                  <th>الإيبان</th>
                  <th>كود السويفت</th>
                  <th>إسم البنك</th>
                  <th>خيارات</th>
                </tr>
              </thead>
              <tbody>
                {s.bankInfosLoading ?
                  <tr>
                    <td colSpan={5} align="center">
                      <AiOutlineLoading3Quarters className="animation-spin" />
                    </td>
                  </tr>
                :
                  s.bankInfos?.length > 0 ?
                    s.bankInfos.map((data, key) => (
                      <tr key={key}>
                        <td>{data.firstname} {data.middlename} {data.lastname}</td>
                        <td>{data.iban}</td>
                        <td>{data.swift_code}</td>
                        <td>{data.bank_name}</td>
                        <td>
                          <DefaultButton size={'xs'} color={'success'} onClick={() => this.setState({dataToEdit: data})}>
                            <FiEdit />
                          </DefaultButton>
                          {' '}
                          <DefaultButton size={'xs'} color={'danger'} onClick={() => this.setState({selectedToDestroy: data.id})}>
                            <MdDelete />
                          </DefaultButton>
                        </td>
                      </tr>
                    ))
                  :
                    <tr>
                      <td colSpan={5} align="center">لا توجد بيانات</td>
                    </tr>
                }
              </tbody>
            </Table>
          </CardBody>
        </Card>

        {s.alertText &&
          <BottomLeftAlert color={'danger'} onClose={() => this.setState({alertText: null})}>
            {s.alertText}
          </BottomLeftAlert>
        }

        <AddBankModal 
          isOpen={s.bankInfosModalShown}
          toggle={() => this.setState({bankInfosModalShown: false})}
          onSuccess={() => {this.setState({bankInfosModalShown: false}); this.getBankInfos()}}
          onError={(message) => this.setState({alertText: message})}
        />

        {s.dataToEdit &&
          <EditBankModal 
            isOpen={s.dataToEdit}
            toggle={() => this.setState({dataToEdit: null})}
            onSuccess={() => {this.setState({dataToEdit: null}); this.getBankInfos()}}
            onError={(message) => this.setState({alertText: message})}
            data={s.dataToEdit}
          />
        }

        <ConfirmationModal
          isOpen={s.selectedToDestroy}
          toggle={() => this.setState({selectedToDestroy: null})}
          confirmationText={'حذف'}
          cancelText={'إلغاء'}
          onConfirm={() => this.destroy()}
        >
          هل حقا تريد حذف الحساب؟
        </ConfirmationModal>
      </Container>
    );
  }
}

export default BankInfosPage;
import React, { Component } from "react";
import { Alert, Col, Container, Row } from "reactstrap";
import { AdminBodyTitle } from "../../../components/Typography/Titles";
import FolderCard from "../../../components/Cards/CMS/Folder";
import { DefaultButton } from "../../../components/Buttons/default";
import { MdOutlineCloudUpload } from "react-icons/md";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { API_LINK, MAIN_LINK } from "../../../utils/constants";
import { BottomLeftAlert } from "../../../components/Alerts";
import { ConfirmationModal } from "../../../components/Modals/Confirmation";
import FileCard from "../../../components/Cards/CMS/File";
import './styles.css';
import CMSFileUploadModal from "./Modals/FileUpload";
import CMSAddFolder from "./Modals/AddFolder";
import CMSMoveFile from "./Modals/MoveFile";
import { withRouter } from "../../../components/Router/withRouter";
import { IoFolderSharp } from "react-icons/io5";
import ImagePreview from "../../../components/Modals/CMS/ImagePerview";
import CMSMoveFolder from "./Modals/MoveFolder";

class CMSFilesPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFolder: props.params?.folder,
      selectedFolderData: null,
      currentPage: 1,
      selectedImageUrl: null,

      foldersData: null,
      filesData: null,
      foldersLoading: true,
      filesLoading: true,

      folderToAddData: {parent_id: '', name: '', description: '', color: ''},
      addFolderModalShown: false,
      addFolderLoading: false,

      editFolderModalShown: false,
      updateFolderLoading: false,
      folderToEditData: {id: '', parent_id: '', name: '', description: '', color: ''},

      folderToDeleteData: null,
      destroyFolderLoading: false,
      
      uploadFileModalShown: false,
      fileToDeleteData: null,
      destroyFileLoading: false,

      folderToMoveData: false,
      fileToMoveData: false,

      alertText: null,
      alertType: 'danger'
    }
  }

  componentDidMount() {
    this.getFolders();
  }

  componentDidUpdate(prevProps) {
    if(this.props.params?.folder !== prevProps.params?.folder) {
      this.setState({
        selectedFolder: this.props.params?.folder,
        foldersLoading: true,
        filesLoading: true
      }, () => {
        this.getFolders();
      });
    }
  }

  getFolders(withFiles = true) {
    this.setState({foldersLoading: true});
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/cms/folders/'+this.state.selectedFolder+'?page='+this.state.currentPage, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.setState({
          foldersData: json.folders.data,
          foldersLoading: false,
          selectedFolderData: json.parent
        }, () => {
          if(withFiles) {
            this.getFiles();
          }
        });
      }
    })
  }

  getFiles() {
    this.setState({filesLoading: true});
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/cms/files/'+this.state.selectedFolder+'?page='+this.state.currentPage, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.setState({
          filesData: json.files.data,
          filesLoading: false
        });
      }
    })
  }

  storeFolder() {
    this.setState({addFolderLoading: true});
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/cms/folder/store', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        parent_id: this.state.selectedFolder,
        name: this.state.folderToAddData.name,
        description: this.state.folderToAddData.description,
        color: this.state.folderToAddData.color
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.getFolders(false);
      } else {
        this.setState({
          alertText: json.message
        });
      }

      this.setState({
        addFolderLoading: false,
        addFolderModalShown: false
      });
    })
  }

  updateFolder() {
    this.setState({updateFolderLoading: true});
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/cms/folder/update/'+this.state.folderToEditData.id, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        name: this.state.folderToEditData.name,
        description: this.state.folderToEditData.description,
        color: this.state.folderToEditData.color
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.getFolders(false);
      } else {
        this.setState({
          alertText: json.message
        });
      }

      this.setState({
        updateFolderLoading: false,
        editFolderModalShown: false
      });
    })
  }

  destroyFolder() {
    this.setState({destroyFolderLoading: true});
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/cms/folder/destroy', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        id: this.state.folderToDeleteData
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.getFolders(false);
      } else {
        this.setState({
          alertText: json.message
        });
      }

      this.setState({
        destroyFolderLoading: false,
        folderToDeleteData: null
      });
    })
  }

  destroyFile() {
    this.setState({destroyFileLoading: true});
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/cms/file/destroy', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        id: this.state.fileToDeleteData
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.getFiles();
      } else {
        this.setState({
          alertText: json.message
        });
      }

      this.setState({
        destroyFileLoading: false,
        fileToDeleteData: null
      });
    })
  }

  folderToAddDataChange = (event) => {
    const { name, value } = event.target;
    this.setState(prevState => ({
      folderToAddData: {
        ...prevState.folderToAddData,
        [name]: value
      }
    }));
  };

  folderToEditDataChange = (event) => {
    const { name, value } = event.target;
    this.setState(prevState => ({
      folderToEditData: {
        ...prevState.folderToEditData,
        [name]: value
      }
    }));
  };

  render() {
    const s = this.state;

    return(
      <Container>
        <Row className="align-items-center">
          <Col md="8">
            <AdminBodyTitle>
              {s.selectedFolderData?.name ?
                <div>
                  <IoFolderSharp color={s.selectedFolderData?.color} />{' '}
                  {s.selectedFolderData?.name}
                </div>
              :
              
                'إدارة الملفات'
              }
            </AdminBodyTitle>
          </Col>
          <Col md="4" className="text-end">
            <Row>
              <Col xs="6">
                <DefaultButton color={'dark'} size={'md'} block onClick={() => this.setState({addFolderModalShown: true})}>
                  + مجلد جديد
                </DefaultButton>
              </Col>
              <Col xs="6">
                <DefaultButton color={'dark'} size={'md'} block onClick={() => this.setState({uploadFileModalShown: true})}>
                  <MdOutlineCloudUpload /> ملف جديد
                </DefaultButton>
              </Col>
            </Row>
          </Col>
        </Row>

        <h5 className="fw-bold mb-2 mt-4">المجلدات</h5>
        {s.foldersLoading ?
          <div className="text-center py-4">
            <AiOutlineLoading3Quarters className="animation-spin" size={30} />
          </div>
        :
          <Row>
            {s.foldersData?.length > 0 ?
              s.foldersData.map((data, key) => (
                <Col md="4" className="mb-2" key={key}>
                  <FolderCard 
                    data={data} 
                    onMove={() => this.setState({folderToMoveData: data.id})}
                    onDelete={() => this.setState({folderToDeleteData: data.id})}
                    onEdit={() => this.setState({folderToEditData: data, editFolderModalShown: true})}
                    onDoubleClick={(folderData) => this.setState({selectedFolderData: folderData})}
                  />
                </Col>
              ))
            :
              <Col md="12">
                <Alert color="info">لا توجد بيانات</Alert>
              </Col>
            }
          </Row>
        }

        <h5 className="fw-bold mb-2 mt-4">آخر الملفات</h5>
        {s.filesLoading ?
          <div className="text-center py-4">
            <AiOutlineLoading3Quarters className="animation-spin" size={30} />
          </div>
        :
          <Row>
            {s.filesData?.length > 0 ?
              s.filesData.map((data, key) => (
                <Col md="3" xs="6" className="mb-2" key={key}>
                  <FileCard 
                    data={data}
                    onDelete={() => this.setState({fileToDeleteData: data.id})}
                    onMove={() => this.setState({fileToMoveData: data.id})}
                    onDoubleClick={(url) => this.setState({selectedImageUrl: url})}
                    onCopyLink={() => {this.setState({alertType: 'success', alertText: 'لقد تم نسخ الرابط بنجاح'}); navigator.clipboard.writeText(MAIN_LINK+data.file_url)}}
                    onFailedOpen={() => this.setState({alertType: 'danger', alertText: 'لا يمكنك فتح هذا الملف في المتصفح'})}
                  />
                </Col>
              ))
            :
              <Col md="12">
                <Alert color="info">لا توجد بيانات</Alert>
              </Col>
            }
          </Row>
        }

        <CMSAddFolder 
          isOpen={s.addFolderModalShown}
          toggle={() => this.setState({addFolderModalShown: false})}
          onConfirm={() => this.storeFolder()}
          isLoading={s.addFolderLoading}
          data={s.folderToAddData}
          handleChange={(event) => this.folderToAddDataChange(event)}
        />

        <CMSAddFolder 
          isOpen={s.editFolderModalShown}
          toggle={() => this.setState({editFolderModalShown: false})}
          onConfirm={() => this.updateFolder()}
          isLoading={s.updateFolderLoading}
          data={s.folderToEditData}
          handleChange={(event) => this.folderToEditDataChange(event)}
        />

        <CMSFileUploadModal 
          isOpen={s.uploadFileModalShown}
          toggle={() => this.setState({uploadFileModalShown: false})}
          onSuccess={() => this.getFiles()}
          selectedFolder={s.selectedFolder}
        />

        <CMSMoveFile 
          isOpen={s.fileToMoveData ? true : false}
          foldersData={s.foldersData}
          toggle={() => this.setState({fileToMoveData: null})}
          file={s.fileToMoveData}
          onError={(message) => this.setState({alertText: message})}
          onSuccess={() => this.getFiles()}
        />

        <CMSMoveFolder 
          isOpen={s.folderToMoveData ? true : false}
          foldersData={s.foldersData}
          toggle={() => this.setState({folderToMoveData: null})}
          folder={s.folderToMoveData}
          onError={(message) => this.setState({alertText: message})}
          onSuccess={() => this.getFolders()}
        />

        {s.selectedImageUrl &&
          <ImagePreview 
            imageUrl={s.selectedImageUrl}
            onClick={() => this.setState({selectedImageUrl: null})}
          />
        }

        <ConfirmationModal
          isOpen={s.folderToDeleteData ? true : false}
          confirmationText={'حذف'}
          cancelText={'غلق'}
          onConfirm={() => this.destroyFolder()}
          toggle={() => this.setState({folderToDeleteData: null})}
          isLoading={s.destroyFolderLoading}
        >
          بتنفيذك لهذه العملية، سيتم حذف جميع محتوى المجلد
        </ConfirmationModal>

        <ConfirmationModal
          isOpen={s.fileToDeleteData ? true : false}
          confirmationText={'حذف'}
          cancelText={'غلق'}
          onConfirm={() => this.destroyFile()}
          toggle={() => this.setState({fileToDeleteData: null})}
          isLoading={s.addFolderLoading}
        >
          هل حقا تريد حذف هذا الملف؟
        </ConfirmationModal>

        {s.alertText &&
          <BottomLeftAlert color={s.alertType} onClose={() => this.setState({alertText: null})}>
            {s.alertText}
          </BottomLeftAlert>
        }
      </Container>
    );
  }
}

export default withRouter(CMSFilesPage);
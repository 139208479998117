import React, { Fragment } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { DefaultButton } from "../Buttons/default";
import propTypes from 'prop-types';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

export const DefaultModal = (props) => {
  return(
    <Modal 
      isOpen={props.isOpen} 
      toggle={props.toggle} 
      centered 
      className={props.className} 
      {...props}
    >
      <ModalHeader>
        {props.title}
      </ModalHeader>
      {props.isLoading ?
        <ModalBody className="text-center">
          <AiOutlineLoading3Quarters className="animation-spin h1" />
        </ModalBody>
      :
        <Fragment>
          <ModalBody style={props.bodyStyle}>
            {props.children}
          </ModalBody>
          <ModalFooter>
            {props.confirmationText &&
              <DefaultButton color="dark" disabled={props.confirmationButtonDisabled || false} onClick={props.onConfirm} size={props.size || 'lg'}>
                {props.confirmationText}
              </DefaultButton>
            }
            <DefaultButton color="secondary" onClick={props.toggle} size={props.size || 'lg'}>
              {props.cancelText}
            </DefaultButton>
          </ModalFooter>
        </Fragment>
      }
    </Modal>
  );
}

DefaultModal.propTypes = {
  isOpen: propTypes.bool,
  toggle: propTypes.func,
  title: propTypes.string,
  confirmationText: propTypes.string,
  cancelText: propTypes.string,
  isLoading: propTypes.bool,
  onConfirm: propTypes.func,
  size: propTypes.string,
  className: propTypes.string,
  backdrop: propTypes.bool,
  bodyStyle: propTypes.any,
  confirmationButtonDisabled: propTypes.bool
}
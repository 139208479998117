import React, { useState } from "react";
import './styles.css';

const COLORS = ['#FF6F61', '#40E0D0', '#9966CC', '#FFC312', '#87CEEB'];

const ColorsCircles = (props) => {
  return(
    <div className="color-selector">
      {COLORS.map(color => (
        <span
          key={color}
          className={props.selectedColor === color ? 'color-option selected' : 'color-option'}
          style={{ backgroundColor: color }}
          onClick={() => props.onSelectColor(color)}
        />
      ))}
    </div>
  );
}

export default ColorsCircles;
import React, { useState } from "react";
import { DefaultModal } from "../../../../components/Modals/Default";
import { IoFolderSharp } from "react-icons/io5";
import { API_LINK } from "../../../../utils/constants";

const CMSMoveFolder = (props) => {
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [moveLoading, setMoveLoading] = useState(false);

  const move = () => {
    setMoveLoading(true);
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/cms/folder/move_to_folder/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        folder_to_move_id: props.folder,
        parent_folder_id: selectedFolder
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        props.onSuccess();
        props.toggle();
      } else {
        props.onError(json.message);
      }

      setMoveLoading(false);
    })
  }

  return(
    <DefaultModal
      isOpen={props.isOpen}
      confirmationText={'تأكيد'}
      cancelText={'غلق'}
      title={'نقل المجلد'}
      confirmationButtonDisabled={!selectedFolder}
      toggle={props.toggle}
      isLoading={moveLoading}
      onConfirm={() => move()}
    >
      {props.foldersData?.length > 0 &&
        props.foldersData.map((data, key) => (
          data.id !== props.folder &&
            <div 
              key={key} 
              className={selectedFolder === data.id ? 'cms-move-folder-container active' : 'cms-move-folder-container'}
              onClick={() => setSelectedFolder(data.id)}
            >
              <IoFolderSharp size={30} color={data.color} className="me-2" />
              <h6 className="fw-bold">{data.name}</h6>
            </div>
        ))
      }
    </DefaultModal>
  );
}

export default CMSMoveFolder;
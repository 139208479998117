import React, { useState } from "react";
import { BsChevronDown, BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import { Collapse } from "reactstrap";
import propTypes from 'prop-types';
import { AiTwotoneCheckCircle } from "react-icons/ai";
import { i18n } from "../../i18n";

export const SidebarItem = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  if(!props.childrens || props.childrens.length <= 0)
  {
    if(props.name) {
      return(
        <li>
          <div className={`sidebar-element ${props.currentLocation === props.link ? 'active' : ''}`}>
            <Link to={props.link}>{props.icon}{' '}
            <span>{props.name}</span></Link>
          </div>
        </li>
      );
    } else {
      return null;
    }
  }
  else
  {
    return(
      <li>
        <div onClick={() => setIsOpen(!isOpen)} className={`sidebar-element ${props.currentLocation.includes(props.link) ? 'active' : ''}`}>
          <Link>
            {props.icon}
            &nbsp; &nbsp;
            <span>{props.name}</span>
            {isOpen ?
              <BsChevronDown className="sidebar-item-chevron float-end mt-2" />
            :
              i18n.language === 'ar' ?
                <BsChevronLeft className="sidebar-item-chevron float-end mt-2" />
              :
                <BsChevronRight className="sidebar-item-chevron float-end mt-2" />
            }
          </Link>
        </div>
        <Collapse isOpen={isOpen} className="list-child-container">
          <ul className="list-child ps-0" id="pagesList">
            {props.childrens.map((data, key) => {
              return(
                <li key={key}>
                  <div className={`sidebar-element ${props.currentLocation === data.link ? 'active' : ''}`}>
                    <Link to={data.link}>
                      <AiTwotoneCheckCircle color="#dedede" size={16} className="me-3" />
                      {data.name}
                    </Link>
                  </div>
                </li>
              );
            })}
          </ul>
        </Collapse>
      </li>
    );
  }
}

SidebarItem.propTypes = {
  name: propTypes.string,
  link: propTypes.string,
  icon: propTypes.element,
  childrens: propTypes.array,
  currentLocation: propTypes.string
}
import React, { useState } from "react";
import { AiOutlineDelete, AiOutlineLoading3Quarters } from "react-icons/ai";
import moment from 'moment';
import 'moment/locale/ar';
import 'moment/locale/fr';
import { API_LINK, MAIN_LINK } from "../../../../../utils/constants";
import './note.css';
import { BiEditAlt } from "react-icons/bi";
import { ConfirmationModal } from "../../../../../components/Modals/Confirmation";
import { DefaultModal } from "../../../../../components/Modals/Default";
import { FormGroup, Input } from "reactstrap";
import { DefaultInput } from "../../../../../components/Forms/Inputs/default";
import { IoMdAttach, IoMdDocument } from "react-icons/io";
import { i18n } from "../../../../../i18n";

const CRMContactNoteCard = (props) => {
  const [destroyLoading, setDestroyLoading] = useState(false);
  const [destroyModalShown, setDestroyModalShown] = useState(false);
  const [updateModalShown, setUpdateModalShown] = useState(false);
  const [updateLoading, setUpdateLoading] = useState();
  const [note, setNote] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);

  const destroy = () => {
    setDestroyLoading(true);
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/crm/note/destroy', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        id: props.data.id
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        setDestroyLoading(false);
        setDestroyModalShown(false);
        props.onDestroy();
      }
    })
  }

  const update = () => {
    setUpdateLoading(true);
    const user = localStorage.getItem('user');

    let formData = new FormData();
    formData.append('id', props.data.id);
    formData.append('content', note);
    
    selectedFiles.forEach((file) => {
      formData.append('files[]', file);
    });

    fetch(API_LINK+'/crm/note/update', {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: formData
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        setUpdateLoading(false);
        setUpdateModalShown(false);
        props.onDestroy();
      }
    })
  }

  const handleFileChange = (e) => {
    const files = e.target.files;
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const removeFile = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  return(
    <div>
      <div className="crm-contact-note-card bg-light p-3 mb-3 border border-gray">
        <div className="crm-contact-note-card-delete-btn float-end text-danger" onClick={() => setDestroyModalShown(true)}>
          {destroyLoading ?
            <AiOutlineLoading3Quarters className="animation-spin" />
          :
            <AiOutlineDelete />
          }
        </div>
        <div className="crm-contact-note-card-edit-btn float-end text-primary me-2" onClick={() => {setUpdateModalShown(true); setNote(props.data.content);}}>
          <BiEditAlt />
        </div>
        <p className="crm-contact-note-card-content fw-bold">
          {props.data.content}
        </p>
        <span className="crm-contact-note-card-date">{moment(props.data.created_at).local(i18n.language).fromNow()}</span>
        <span className="crm-contact-note-card-by d-none"> | </span>
        {props.data.files &&
          <div className="crm-contact-note-card-file mt-2">
            {props.data.files.map((data, key) => {
              return(
                <div>
                  <a href={MAIN_LINK+data.file_path} key={key} target="_blank">
                    {data.filename}
                  </a>
                </div>
              );
            })}
          </div>
        }
      </div>

      <ConfirmationModal
        isOpen={destroyModalShown}
        confirmationText={ i18n.t('modals.delete.buttons.delete') }
        cancelText={ i18n.t('modals.delete.buttons.cancel') }
        onConfirm={() => destroy()}
        toggle={() => setDestroyModalShown(false)}
        isLoading={destroyLoading}
      >
        { i18n.t('modals.delete.title') }
      </ConfirmationModal>

      <DefaultModal
        isOpen={updateModalShown}
        isLoading={updateLoading}
        title={ i18n.t('contact.form.edit_note') }
        toggle={() => setUpdateModalShown(false)}
        confirmationText={ i18n.t('contact.form.submit') }
        cancelText={ i18n.t('contact.form.close') }
        onConfirm={() => update()}
      >
        <div className="border border-2 border-dark mb-3">
          <FormGroup>
            <DefaultInput 
              type={'textarea'} 
              placeholder={ i18n.t('contact.form.note_placeholder') }
              onChange={(event) => setNote(event.target.value)}
              value={note}
              className="border-0"
            />
            <Input multiple type="file" id="fileToUpdate" onChange={(event) => handleFileChange(event)} hidden />
          </FormGroup>
          <hr />
          {selectedFiles &&
            selectedFiles.map((data, key) => {
              return(
                <div className="p-2" key={key}>
                  <IoMdDocument />
                  {data.name}
                  <AiOutlineDelete className="text-danger cursor-pointer" onClick={() => removeFile(key)} />
                </div>
              );
            })
          }
          <FormGroup className="px-2">
            <a onClick={() => document.getElementById('fileToUpdate').click()}>
              <IoMdAttach />
              { i18n.t('contact.form.attach_file') }
            </a>
          </FormGroup>
        </div>
      </DefaultModal>
    </div>
  );
}

export default CRMContactNoteCard;
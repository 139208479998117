import React from "react";
import { Alert, Col, Row } from "reactstrap";
import PropTypes from 'prop-types';
import './styles.css';
import { AiOutlineClose } from "react-icons/ai";

export const BottomLeftAlert = (props) => {
  return(
    <Alert className={`bg-${props.color} text-light border-${props.color} alert-bottom-left`}>
      <Row>
        <Col xs="10">
          {props.children}
        </Col>
        <Col xs="2" className="text-end">
          <AiOutlineClose onClick={props.onClose} />
        </Col>
      </Row>
    </Alert>
  );
}

export const DefaultAlert = (props) => {
  return(
    <Alert className={`bg-${props.color} text-light border-${props.color} rounded-0 ${props.className}`}>
      {props.children}
    </Alert>
  );
}

BottomLeftAlert.propTypes = {
  color: PropTypes.string,
  onClose: PropTypes.func
}

DefaultAlert.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string
}
import React from "react";
import PropTypes from 'prop-types';

export const AdminBodyTitle = (props) => {
  return(
    <h1 className={`mb-3 fw-bold ${props.className || ''}`}>
      {props.children}
    </h1>
  );
}

AdminBodyTitle.propTypes = {
  className: PropTypes.string
}
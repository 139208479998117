import React, { Component } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import './index.css';
import { AdminBodyTitle } from "../../components/Typography/Titles";
import { DefaultButton } from "../../components/Buttons/default";
import { BsGrid1X2 } from "react-icons/bs";
import { withRouter } from "../../components/Router/withRouter";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { API_LINK } from "../../utils/constants";
import { Link } from "react-router-dom";
import { FaRegHandPointRight, FaRegHandPointLeft } from "react-icons/fa6";
import { calculateDaysUntil } from "../../utils/helpers";
import { i18n } from "../../i18n";


class HomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: JSON.parse(localStorage.getItem('user')),

      subscribtionData: null,
      subscribtionLoading: true
    }
  }

  componentDidMount() {
    this.checkSubscribtion();
  }

  checkSubscribtion() {
    this.setState({subscribtionLoading: true});
    const user = localStorage.getItem('user');
    const website_id = JSON.parse(this.props.website.website)?.id;
    if(website_id) {
      fetch(API_LINK+'/subscribtion/check/'+website_id, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+JSON.parse(user).token
        }
      })
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        
        if(json.success)
        {
          this.setState({
            subscribtionData: json.subscribtion,
            subscribtionLoading: false
          })
        }
        else
        {
          this.setState({
            subscribtionLoading: false
          });
        }
      })
    } else {
      this.setState({
        subscribtionLoading: false
      });
    }
  }

  render() {
    const s = this.state;

    return(
      <Container fluid>
        <Row>
          <Col md="8" xs="8">
            <AdminBodyTitle>{ i18n.t('home.welcome') } {s.user?.firstname}</AdminBodyTitle>
          </Col>
          <Col md="4" xs="4" className="text-end">
            {JSON.parse(this.props.website.website)?.name &&
              <Link to={'/build/'+JSON.parse(this.props.website.website)?.name}>
                <DefaultButton color={'dark'} outline>
                  <BsGrid1X2 />{' '}
                  <span className="d-none d-sm-inline-block">{ i18n.t('home.button_build') }</span>
                </DefaultButton>
              </Link>
            }
          </Col>
        </Row>

        <Card>
          <CardBody>
            {i18n.language === 'ar' ?
              <FaRegHandPointRight size={20} />
            :
              <FaRegHandPointLeft size={20} />
            }
            {' '}
            { i18n.t('home.choose') }
          </CardBody>
        </Card>
      
        {s.subscribtionLoading ?
          <div className="text-center py-4">
            <AiOutlineLoading3Quarters className="animation-spin" size={30} />
          </div>
        :
          !s.subscribtionData || s.subscribtionData.length <= 0 ?
            JSON.parse(this.props.website.website)?.id &&
              <div className="home-subscribtion-alert">
                <Row className="align-items-center">
                  <Col md="9" lg="10">
                    <div>
                      إنتهى إشتراكك في الموقع. في حالة لم يتم تجديد الإشتراك خلال شهر سيتم حذف جميع بياناتك
                    </div>
                  </Col>
                  <Col md="3" lg="2" className="text-end">
                    <Link to={'/dashboard/subscribtion'}>
                      <DefaultButton size={'md'} color={'dark'}>تجديد الإشتراك</DefaultButton>
                    </Link>
                  </Col>
                </Row>
              </div>
          :
            calculateDaysUntil(s.subscribtionData?.end_date) <= 14 &&
              <div className="home-subscribtion-alert">
                <Row className="align-items-center">
                  <Col md="9" lg="10">
                    <div>
                      تبقى { calculateDaysUntil(s.subscribtionData?.end_date) } يوما فقط لينتهي إشتراكك في الموقع
                    </div>
                  </Col>
                  <Col md="3" lg="2" className="text-end">
                    <Link to={'/dashboard/packages'}>
                      <DefaultButton size={'md'} color={'dark'}>تجديد الإشتراك</DefaultButton>
                    </Link>
                  </Col>
                </Row>
              </div>
        }
      </Container>
    );
  }
}

export default withRouter(HomePage);
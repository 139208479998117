import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { WebsiteProvider } from './context/Website';
import { i18n, initPromise } from './i18n';
import { handleDirection } from './utils/directionHelper';

initPromise.then(() => {
  handleDirection(i18n.language);

  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <WebsiteProvider>
      <App />
    </WebsiteProvider>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
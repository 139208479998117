import React, { Component, Fragment } from "react";
import { Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import { AdminBodyTitle } from "../../../components/Typography/Titles";
import { API_LINK } from "../../../utils/constants";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { DefaultButton } from "../../../components/Buttons/default";
import { FaCheck } from "react-icons/fa6";
import { MdClose } from "react-icons/md";
import { Link } from "react-router-dom";
import { withRouter } from "../../../components/Router/withRouter";
import '../styles.css';
import { BottomLeftAlert } from "../../../components/Alerts";

class PackagesPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      packagesData: null,
      subscribtion: null,
      packagesLoading: true,
      countryData: null,

      activeCategory: null,
      getFreeTrialLoading: false,

      alertText: null
    }
  }

  componentDidMount() {
    this.getPackages();
  }

  getPackages() {
    this.setState({packagesLoading: true});
    const user = localStorage.getItem('user');
    const website_id = JSON.parse(this.props.website.website)?.id;
    if(website_id) {
      fetch(API_LINK+'/packages/'+website_id, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+JSON.parse(user).token
        }
      })
      .then((response) => response.json())
      .then((json) => {
        if(json.success)
        {
          this.setState({
            packagesData: json.packages,
            subscribtion: json.subscribtion,
            activeCategory: json.packages?.length > 0 ? String(json.packages[0].id) : null,
            countryData: json.country,
            packagesLoading: false
          });
        }
        else
        {
          this.setState({
            packagesLoading: false
          });
        }
      })
    } else {
      this.props.navigate('/website/new');
    }
  }

  getFreeTrial(packageId) {
    this.setState({getFreeTrialLoading: true});
    const user = localStorage.getItem('user');
    const website_id = JSON.parse(this.props.website.website)?.id;

    fetch(API_LINK+'/subscribe/free', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        package_id: packageId,
        website_id: website_id
      })
    })
    .then((response) => response.json())
      .then((json) => {
        if(json.success) {
          this.props.navigate('/dashboard/home');
        } else {
          this.setState({
            alertText: json.message
          })
        }

        this.setState({
          getFreeTrialLoading: false
        })
      })
  }

  render() {
    const s = this.state;

    return(
      <Container>
        <AdminBodyTitle>إختر الباقة المناسبة لك</AdminBodyTitle>

        {s.packagesLoading ?
          <div className="text-center py-5">
            <AiOutlineLoading3Quarters className="animation-spin" size={40} />
          </div>
        :
          <Fragment>
            <Nav tabs card>
              {s.packagesData?.length > 1 &&
                s.packagesData.map((data, key) => (
                  <NavItem key={key}>
                    <NavLink
                      className={String(s.activeCategory) === String(data.id) ? 'me-3 bg-dark text-white rounded-pill active' : 'me-3 bg-white text-dark rounded-pill'}
                      onClick={() => this.setState({activeCategory: String(data.id)})}
                    >
                      {data.name}
                    </NavLink>
                  </NavItem>
                ))
              }
            </Nav>
            <TabContent activeTab={String(s.activeCategory)} className="mb-4">
              {s.packagesData?.length > 0 &&
                s.packagesData.map((data, key) => (
                  <TabPane tabId={String(data.id)} key={key}>
                    {data?.package?.length > 0 &&
                      <Row>
                        {data.package.map((pkg, pkgIndex) => (
                          <Col md="4" key={pkgIndex} className="mt-4">
                            <Card className="package-selector-card">
                              <CardBody>
                                <h6>{pkg.name}</h6>
                                <h3 className="mb-1">{ pkg.price } {s.countryData.currency_symbol} / شهريا</h3>
                                <p className="text-muted" style={{fontSize: 12}}>
                                  {pkg.description}
                                </p>

                                <ul className="mt-3 pricing-list ps-0">
                                  <li className="mb-1">{ pkg.custom_domaine ? <FaCheck /> : <MdClose /> } دومين خاص</li>
                                  <li className="mb-1">{ pkg.free_domaine ? <FaCheck /> : <MdClose /> } دومين مجاني</li>
                                  <li className="mb-1">{ pkg.remove_ads ? <FaCheck /> : <MdClose /> } حذف الإعلانات</li>
                                  <li className="mb-1">{ pkg.free_ssl ? <FaCheck /> : <MdClose /> } شهادة أمان مجانية</li>
                                  <li className="mb-1"><b>{ pkg.bandwidth }GB</b> باندويدتش</li>
                                  <li className="mb-1"><b>{ pkg.storage_space }GB</b> مساحة التخزين</li>
                                  <li className="mb-1">{ pkg.social_media_control ? <FaCheck /> : <MdClose /> } التحكم في مواقع التواصل</li>
                                  <li className="mb-1">{ pkg.accept_payments ? <FaCheck /> : <MdClose /> } بوابات الدفع</li>
                                  <li className="mb-1">{ pkg.marketing_tools ? <FaCheck /> : <MdClose /> } أدوات التسويق</li>
                                  <li className="mb-1">{ pkg.ai_helper ? <FaCheck /> : <MdClose /> } مساعد الذكاء الإصطناعي</li>
                                </ul>

                                {s.subscribtion ?
                                  String(s.subscribtion?.package_id) === String(pkg.id) ?
                                    (new Date(s.subscribtion?.end_date) - 14 * 24 * 60 * 60 * 1000 < new Date()) ?
                                      <Link to={'/dashboard/subscribe/'+pkg.id}>
                                        <DefaultButton block color={'warning'}>
                                          تجديد الإشتراك
                                        </DefaultButton>
                                      </Link>
                                    :
                                      <DefaultButton block color={'success'} disabled>
                                        الباقة الحالية
                                      </DefaultButton>
                                  :
                                    (new Date(s.subscribtion?.end_date) < new Date()) ?
                                      <Link to={'/dashboard/subscribe/'+pkg.id}>
                                        <DefaultButton block color={'dark'}>
                                          إشترك الآن
                                        </DefaultButton>
                                      </Link>
                                    :
                                      <Link to={'/dashboard/subscribe/'+pkg.id}>
                                        <DefaultButton block color={'dark'}>
                                          ترقية الباقة
                                        </DefaultButton>
                                      </Link>
                                :
                                  <DefaultButton block color={'dark'} onClick={() => this.getFreeTrial(pkg.id)} isLoading={s.getFreeTrialLoading}>
                                    أحصل على 14 يوم مجانا
                                  </DefaultButton>
                                }
                              </CardBody>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    }
                  </TabPane>
                ))
              }
            </TabContent>
          </Fragment>
        }

        {s.alertText &&
          <BottomLeftAlert color={'danger'} onClose={() => this.setState({alertText: null})}>{s.alertText}</BottomLeftAlert>
        }
      </Container>
    );
  }
}

export default withRouter(PackagesPage);
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { loadBootstrap, loadCSS } from './utils/directionHelper';

const initPromise = i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['ar', 'en', 'fr'],
    fallbackLng: 'ar',
    detection: {
      order: ['cookie', 'localStorage', 'path', 'subdomain'],
      caches: ['cookie'],
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',
    },
    react: {
      useSuspense: false
    },
  });

// Load Bootstrap CSS based on the initial language
initPromise.then(() => {
  loadBootstrap(i18n.language);
  loadCSS(i18n.language);

  // Update Bootstrap CSS when the language changes
  i18n.on('languageChanged', loadBootstrap);
});

export { i18n, initPromise };
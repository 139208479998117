import React, { useState } from "react";
import { Badge, Card, CardBody, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from "reactstrap";
import { MAIN_LINK } from "../../../utils/constants";
import './selector.css';
import { AiOutlineDelete, AiOutlineEdit, AiOutlineEye, AiOutlineCopy } from "react-icons/ai";
import { LiaPeopleCarrySolid } from "react-icons/lia";
import { SlOptions } from 'react-icons/sl';
import { BsGrid1X2 } from "react-icons/bs";
import { LuExternalLink } from 'react-icons/lu';
import { DefaultButton } from "../../Buttons/default";
import { Link } from "react-router-dom";

const WebsiteSelector = (props) => {
  const [ dropdownOpen, setDropdownOpen ] = useState(false);

  return(
    <Card className="website-selector-card border mb-3">
      <CardBody>
        <div className="opacity" onClick={props.onClick}>
          <DefaultButton color={'dark'}>إختيار</DefaultButton>
        </div>
        <Row>
          <Col lg="2" md="3">
            <div className="website-selector-image-container border">
              <img 
                src={MAIN_LINK+props.data.template?.screenshot} 
                alt="" 
                width={'100%'} 
                height={'auto'} 
              />
            </div>
          </Col>
          <Col lg="9" md="8" xs="10">
            <div className="h4 fw-bold">{ props.data.name }</div>

            <div>
              {props.data.link ?
                <a href="" target="_blank">
                  {props.data.link}
                  {' '}
                  <LuExternalLink />
                </a>
                :
                <Badge color="secondary">لم يتم نشر الموقع بعد</Badge>
              }
            </div>
          </Col>
          <Col lg="1" md="1" className="text-end" xs="2">
            <Dropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
              <DropdownToggle className="dropdown-menu-button" color="dark" size="sm" outline><SlOptions /></DropdownToggle>
              <DropdownMenu className="shadow-sm">
                <DropdownItem className="p-3" disabled={props.data.link ? false : true}><AiOutlineEye /> مشاهدة مباشرة</DropdownItem>
                <DropdownItem className="p-3" onClick={props.onCopyClick}><AiOutlineCopy /> نسخ الموقع</DropdownItem>
                <DropdownItem className="p-3" onClick={props.onEditClick}><AiOutlineEdit /> تعديل</DropdownItem>
                <DropdownItem className="p-3">
                  <Link to={'/build/'+props.data.name} className="text-dark">
                    <BsGrid1X2 /> تخصيص
                  </Link>
                </DropdownItem>
                <DropdownItem className="p-3" onClick={() => props.onChangeOwner()}>
                  <LiaPeopleCarrySolid /> نقل الملكية
                </DropdownItem>
                <DropdownItem className="p-3" onClick={() => props.onDestroy(props.data.id)}>
                  <AiOutlineDelete /> حذف
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default WebsiteSelector;
import React, { Fragment, useEffect, useState } from "react";
import { Col, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { DefaultButton } from "../../../../components/Buttons/default";
import { MdOutlineCloudUpload } from "react-icons/md";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { CiImageOff } from "react-icons/ci";
import { ImEnlarge } from "react-icons/im";
import './styles.css';
import { DefaultInput } from "../../../../components/Forms/Inputs/default";
import { API_LINK, MAIN_LINK } from "../../../../utils/constants";
import SmartPagination from "../../../../components/Pagination/Smart";
import BuilderImageModal from "./Image";

const BuilderImagesModal = (props) => {
  const [selectedOption, setSelectedOption] = useState('local');
  const [localImages, setLocalImages] = useState(null);
  const [localImageLoading, setLocalImagesLoading] = useState(false);
  const [pixabaySearchKeyword, setPixabaySearchKeyword] = useState('');
  const [pixabaySearchLoading, setPixabaySearchLoading] = useState(false);
  const [pixabayCurrentPage, setPixabayCurrentPage] = useState(1);
  const [localCurrentPage, setLocalCurrentPage] = useState(1);
  const [pixabayData, setPixabayData] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagesUploadLoading, setImagesUploadLoading] = useState(false);

  useEffect(() => {
    getLocalImages();
  }, []);

  const getPixabayImages = () => {
    setPixabaySearchLoading(true);
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/builder/images/search?q='+pixabaySearchKeyword+'&page='+pixabayCurrentPage, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.result && JSON.parse(json.result)?.hits?.length > 0) {
        setPixabayData(JSON.parse(json.result));
        setPixabaySearchLoading(false);
      } else {
        setPixabaySearchLoading(false);
      }
    })
  }

  const getLocalImages = () => {
    setLocalImagesLoading(true);
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/cms/images?page='+localCurrentPage, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        setLocalImages(json.images);
        setLocalImagesLoading(false);
      } else {
        setLocalImagesLoading(false);
      }
    })
  }

  const uploadImages = (files) => {
    setImagesUploadLoading(true);
    const user = localStorage.getItem('user');

    let formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('files[]', files[i]);
    }

    fetch(API_LINK+'/cms/file/store', {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: formData
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        getLocalImages();
        setImagesUploadLoading(false);
      } else {
        setImagesUploadLoading(false);
      }
    })
  }

  return(
    <Modal isOpen={props.isOpen} toggle={props.toggle} centered size="lg" {...props} className="modal-no-overlay" backdrop={false}>
      <ModalHeader>
        مكتبة الصور
      </ModalHeader>
      <Row>
        <Col md="4">
          <div className="border-end border-gray h-100">
            <ModalBody>
              <div>
                <DefaultButton block color={'success'} size={'md'} onClick={() => document.getElementById('cms-files-input').click()}>
                  <MdOutlineCloudUpload size={22} />
                  {' '}
                  رفع صور
                </DefaultButton>
                <input 
                  type="file" 
                  className="d-none" 
                  name="files" 
                  multiple 
                  id="cms-files-input" 
                  accept="image/*" 
                  onChange={(event) => uploadImages(event.target.files)}
                />
              </div>
              <ul className="ps-0 builder-gallery-options mt-5">
                <li className={selectedOption === 'local' && "active"} onClick={() => {setSelectedOption('local'); getLocalImages()}}>مكتبة الصور</li>
                <li className={selectedOption === 'pixabay' && "active"} onClick={() => {setSelectedOption('pixabay'); getPixabayImages()}}>Pixabay</li>
              </ul>
            </ModalBody>
          </div>
        </Col>
        <Col md="8">
          <div>
            <ModalBody className="ps-0" style={{height: '400px'}}>
              {selectedOption === 'local' ?
                <div className="h-100 align-items-center">
                  {localImageLoading || imagesUploadLoading ?
                    <div className="text-center">
                      <AiOutlineLoading3Quarters className="animation-spin" size={40} />
                    </div>
                  :
                    localImages?.data?.length > 0 ?
                      <div className="builder-gallery-items">
                        <Row>
                          {localImages?.data?.map((data, key) => (
                            <Col md="4" key={key} className="mb-3">
                              <div className={selectedImage === data ? "builder-gallery-item text-center border active" : "builder-gallery-item text-center border"} onClick={() => setSelectedImage(data)}>
                                <div className="builder-gallery-item-preview-icon" onClick={() => setPreviewImage(MAIN_LINK+data.file_url)}>
                                  <ImEnlarge color="#fff" />
                                </div>
                                <img src={MAIN_LINK+data.file_url} width={'auto'} height={'100%'} />
                              </div>
                            </Col>
                          ))}
                        </Row>
                        
                        <SmartPagination 
                          currentPage={localCurrentPage}
                          totalPages={localImages.last_page}
                          onPageChange={(page) => {setLocalCurrentPage(page); getLocalImages()}}
                          size={'sm'}
                        />
                      </div>
                    :
                      <div className="text-center">
                        <CiImageOff size={50} color="dark" />
                        <h6 className="mb-4">لا توجد لديك صور في المكتبة</h6>
                        <DefaultButton color={'success'} size={'md'} onClick={() => document.getElementById('cms-files-input').click()}>
                          <MdOutlineCloudUpload size={22} />
                          {' '}
                          رفع صور
                        </DefaultButton>
                      </div>
                  }
                </div>
              :
                <div>
                  <FormGroup>
                    <Row>
                      <Col md="9" className="pe-0">
                        <DefaultInput 
                          size={'md'} 
                          placeholder={'إبحث بكلمات مفتاحية'} 
                          value={pixabaySearchKeyword}
                          onChange={(event) => setPixabaySearchKeyword(event.target.value)}
                          onKeyDown={(event) => {event.key === 'Enter' && getPixabayImages()}}
                        />
                      </Col>
                      <Col md="3" className="ps-0">
                        <DefaultButton size={'md'} block color={'dark'} onClick={() => getPixabayImages()}>بحث</DefaultButton>
                      </Col>
                    </Row>
                  </FormGroup>
                  {pixabaySearchLoading ?
                    <div className="text-center py-5">
                      <AiOutlineLoading3Quarters className="animation-spin" size={40} />
                    </div>
                  :
                    pixabayData?.hits?.length > 0 ?
                      <div className="builder-gallery-items">
                        <Row>
                          {pixabayData?.hits?.map((data, key) => (
                            <Col md="4" key={key} className="mb-3">
                              <div className={selectedImage === data ? "builder-gallery-item text-center border active" : "builder-gallery-item text-center border"} onClick={() => setSelectedImage(data)}>
                                <div className="builder-gallery-item-preview-icon" onClick={() => setPreviewImage(data.largeImageURL)}>
                                  <ImEnlarge color="#fff" />
                                </div>
                                <img src={data.previewURL} width={'auto'} height={'100%'} />
                              </div>
                            </Col>
                          ))}
                        </Row>
                        
                        <SmartPagination 
                          currentPage={pixabayCurrentPage}
                          totalPages={pixabayData.totalHits/20}
                          onPageChange={(page) => {setPixabayCurrentPage(page); getPixabayImages()}}
                          size={'sm'}
                        />
                      </div>
                    :
                      <div className="text-center py-5">
                        <CiImageOff size={50} color="dark" />
                        <h6 className="mb-4">أكتب كلمة البحث للحصول على النتيجة</h6>
                        <DefaultButton color={'success'} size={'md'} onClick={() => document.getElementById('cms-files-input').click()}>
                          <MdOutlineCloudUpload size={22} />
                          {' '}
                          رفع صور
                        </DefaultButton>
                      </div>
                  }
                </div>              
              }

              <BuilderImageModal 
                ImageURL={previewImage || false}
                toggle={() => setPreviewImage(null)}
              />
            </ModalBody>
          </div>
        </Col>
      </Row>
      <ModalFooter>
        <DefaultButton 
          color="dark" 
          onClick={() => props.onConfirm(selectedImage)}
          disabled={!selectedImage}
          size={'md'}
        >
          إختر الصورة
        </DefaultButton>
        <DefaultButton color="secondary" onClick={props.toggle} size={'md'}>
          غلق
        </DefaultButton>
      </ModalFooter>
    </Modal>
  );
}

export default BuilderImagesModal;
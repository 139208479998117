import React, { useState } from "react";
import { DefaultModal } from "../../../../components/Modals/Default";
import { FormGroup, Label } from "reactstrap";
import { DefaultSelect } from "../../../../components/Forms/Inputs/Select";
import { API_LINK } from "../../../../utils/constants";

const BuilderCopySectionModal = (props) => {
  const [selectedPage, setSelectedPage] = useState('');
  const [loading, setLoading] = useState();  

  const copy = () => {
    setLoading(true);
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/builder/section/copy', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        from_page_id: props.selectedPage.id,
        to_page_id: selectedPage,
        lang: props.selectedLanguage.language.short,
        blizId: props.data.parentInfo.sectionBlizId
      })
    })
    .then((response) => response.json())
    .then((json) => {
      setLoading(false);
    })
  }

  return(
    <DefaultModal
      isOpen={props.isOpen}
      title={'نسخ الوحدة لصفحة أخرى'}
      toggle={props.toggle}
      cancelText={'غلق'}
      confirmationText={'تأكيد'}
      confirmationButtonDisabled={selectedPage === ''}
      isLoading={loading}
      onConfirm={() => copy()}
    >
      <FormGroup>
        <Label>إختر الصفحة: </Label>
        <DefaultSelect name="page_id" value={selectedPage} onChange={(event) => setSelectedPage(event.target.value)}>
          <option value="">اختر الصفحة</option>
          {props.pages?.length > 0 && props.pages.map((data, key) => (
            <option value={data.id} key={key}>{data.name}</option>
          ))}
        </DefaultSelect>
      </FormGroup>
    </DefaultModal>
  );
}

export default BuilderCopySectionModal;
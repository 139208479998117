import React, { useEffect, useRef, useState } from "react";
import { IoCreateOutline, IoFolderSharp } from "react-icons/io5";
import './styles.css';
import { useNavigate } from "react-router-dom";
import { IoIosMove } from "react-icons/io";
import { AiOutlineDelete } from "react-icons/ai";

const FolderCard = (props) => {
  const navigate = useNavigate();
  const [menuShown, setMenuShown] = useState(false);
  const menuRef = useRef(null);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuShown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return(
    <div class="cms-folder-card" onDoubleClick={() => {props.onDoubleClick(props.data); navigate('/dashboard/cms/files/'+props.data.id)}}>
      <div class="cms-folder-icon">
        <IoFolderSharp size={34} color={props.data.color || ''} />
      </div>
      <div class="cms-folder-details">
        <h3>{props.data.name}</h3>
        <p>آخر تحديث: {props.data.updated_at.split('T')[0]}</p>
      </div>
      <div class="cms-folder-menu" ref={menuRef}>
        <button class="cms-folder-menu-dots" onClick={() => setMenuShown(!menuShown)}>⋮</button>
        {menuShown &&
          <div class="cms-folder-dropdown-menu">
            <a href="#" onClick={() => props.onMove()}>
              <IoIosMove size={18} />{' '}
              نقل لمجلد
            </a>
            <a href="#" onClick={() => props.onEdit()}>
              <IoCreateOutline size={18} />{' '}
              تعديل
            </a>
            <a href="#" onClick={() => props.onDelete()}>
              <AiOutlineDelete size={18} />{' '}
              حذف
            </a>
          </div>
        }
      </div>
    </div>
  );
}

export default FolderCard
import React from "react";
import { Card, CardBody, Col, FormGroup, Label, Row } from "reactstrap";
import { DefaultInput } from "../../../components/Forms/Inputs/default";
import { DefaultSelect } from "../../../components/Forms/Inputs/Select";
import { i18n } from "../../../i18n";

export const AdvancedSettingsForm = (props) => {
  return(
    <Card className="mb-3">
      <CardBody>
        <h4 className="fw-bold mb-3">{ i18n.t('profile.advanced_settings.title') }</h4>
        <form>
          <FormGroup>
            <Label>{ i18n.t('profile.advanced_settings.form.email') }: </Label>
            <DefaultInput readOnly type="email" value={props.values.email} />
            {props.values.email_verified_at === null ?
              <span>
                { i18n.t('profile.advanced_settings.email_not_confirmed') }
                <a className="text-primary" onClick={props.emailConfirmationModalShown}> { i18n.t('profile.advanced_settings.click_here') } </a>
                  { i18n.t('profile.advanced_settings.for_change') }
                <a className="text-danger" onClick={props.updateEmailModalShown}> { i18n.t('profile.advanced_settings.click_here') }</a>
              </span>
            :
              <span>
                { i18n.t('profile.advanced_settings.email_confirmed') } { i18n.t('profile.advanced_settings.for_change') }
                <a className="text-primary" onClick={props.updateEmailModalShown}> { i18n.t('profile.advanced_settings.click_here') }</a>
              </span>
            }
          </FormGroup>
          <FormGroup>
            <Label>{ i18n.t('profile.advanced_settings.form.phone_number') }: </Label>
            <Row>
              <Col xs="9" lg="10">
                <DefaultInput readOnly type="text" value={props.values.phone_number} />
                {props.values.phone_number_verified_at === null ?
                  <span>
                    { i18n.t('profile.advanced_settings.phone_not_confirmed') }
                    <a className="text-primary" onClick={props.phoneNumberConfirmationModalShown}> { i18n.t('profile.advanced_settings.click_here') } </a>
                      { i18n.t('profile.advanced_settings.for_change') }
                    <a className="text-danger" onClick={props.updatePhoneNumberModalShown}> { i18n.t('profile.advanced_settings.click_here') }</a>
                  </span>
                :
                  <span>
                    { i18n.t('profile.advanced_settings.phone_confirmed') } { i18n.t('profile.advanced_settings.for_change') } 
                    <a className="text-primary" onClick={props.updatePhoneNumberModalShown}> { i18n.t('profile.advanced_settings.click_here') }</a>
                  </span>
                }
              </Col>
              <Col xs="3" lg="2">
                <DefaultSelect disabled>
                  <option value="{props.values.country_code}">{props.values.country_code}</option>
                </DefaultSelect>
              </Col>
            </Row>
          </FormGroup>
        </form>
      </CardBody>
    </Card>
  );
}
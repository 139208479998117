import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import './smart.css';

const generatePaginationLinks = (currentPage, totalPages) => {
  const pageNumbers = [];
  const visiblePages = 10;

  if (totalPages <= visiblePages) {
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  } else {
    const middleIndex = Math.floor(visiblePages / 2);
    const startPage = Math.max(1, currentPage - middleIndex);
    const endPage = Math.min(totalPages, startPage + visiblePages - 1);

    if (startPage > 1) {
      pageNumbers.push(1, '...');
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    if (endPage < totalPages) {
      pageNumbers.push('...', totalPages);
    }
  }

  return pageNumbers;
};

const SmartPagination = ({ size, currentPage, totalPages, onPageChange }) => {
  const paginationLinks = generatePaginationLinks(currentPage, totalPages);

  return (
    <Pagination size={size || 'md'}>
      {paginationLinks.map((page, index) => (
        <PaginationItem key={index} active={currentPage === page}>
          {page === '...' ? (
            <PaginationLink disabled>...</PaginationLink>
          ) : (
            <PaginationLink onClick={() => onPageChange(page)}>{page}</PaginationLink>
          )}
        </PaginationItem>
      ))}
    </Pagination>
  );
};

export default SmartPagination;
import React from "react";
import { DefaultModal } from "../../../../components/Modals/Default";
import { FormGroup, Label } from "reactstrap";
import { DefaultInput } from "../../../../components/Forms/Inputs/default";
import ColorsCircles from "../../../../components/Forms/Colors";

const CMSAddFolder = (props) => {
  return(
    <DefaultModal 
      title={'مجلد جديد'}
      isOpen={props.isOpen}
      confirmationText={'حفظ'}
      cancelText={'غلق'}
      toggle={props.toggle}
      onConfirm={props.onConfirm}
      isLoading={props.isLoading}
    >
      <FormGroup>
        <Label>إسم المجلد: </Label>
        <DefaultInput 
          name={'name'}
          value={props.data.name}
          onChange={(event) => props.handleChange(event)}
        />
      </FormGroup>
      <FormGroup>
        <Label>ملاحظات حول المجلد: </Label>
        <DefaultInput 
          name={'description'}
          value={props.data.description}
          onChange={(event) => props.handleChange(event)}
          type={'textarea'} 
        />
      </FormGroup>
      <FormGroup>
        <Label>لون المجلد: </Label>
        <ColorsCircles 
          onSelectColor={(color) => props.handleChange({target: {name: 'color', value: color}})} 
          selectedColor={props.data.color}
        />
      </FormGroup>
    </DefaultModal>
  );
}

export default CMSAddFolder;
import React, { Component } from "react";
import { FiHome } from "react-icons/fi";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import './styles.css';

class NotFoundPage extends Component {
  render() {
    return(
      <div className="notfound-page text-center">
        <Row className="d-flex align-items-center h-100">
          <Col lg="3" md="4" className="mx-auto">
            <div className="notfound-page-logo mb-3">
              <img src={ require('../../assets/brand-black.png') } width="100%" />
            </div>
            <h3 className="fw-bold mb-3">الصفحة المطلوبة غير موجودة!</h3>

            <Link to="/login">
              <FiHome />{' '}
              عودة للرئيسية
            </Link>
          </Col>
        </Row>
      </div>
    );
  }
}

export default NotFoundPage;
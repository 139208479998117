import React, { useState } from "react";
import { Card, CardBody, FormGroup, Label } from "reactstrap";
import { API_LINK } from "../../../utils/constants";
import { DefaultInput } from "../../../components/Forms/Inputs/default";
import { DefaultButton } from "../../../components/Buttons/default";
import { i18n } from "../../../i18n";

export const AccountSettingsForm = (props) => {
  const [ updateLoading, setUpdateLoading ] = useState(false);

  const update = ()=> {
    setUpdateLoading(true);
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/account/general/update', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        firstname: props.values.firstname,
        lastname: props.values.lastname,
        username: props.values.username,
        birthdate: props.values.birthdate
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        localStorage.setItem('user', JSON.stringify(json.user));
        props.setUserData();
        props.setAlert(json.message, 'success');
      } else {
        props.setAlert(json.message, 'danger');
      }
      setUpdateLoading(false);
    })
  }

  return(
    <Card className="mb-3">
      <CardBody>
        <h4 className="fw-bold mb-3">{ i18n.t('profile.general_settings.title') }</h4>
        <form>
          <FormGroup>
            <Label>{ i18n.t('profile.general_settings.form.firstname') }: </Label>
            <DefaultInput 
              type="text" 
              name="firstname" 
              value={props.values.firstname} 
              onChange={props.handleInputChange} 
            />
          </FormGroup>
          <FormGroup>
            <Label>{ i18n.t('profile.general_settings.form.lastname') }: </Label>
            <DefaultInput 
              type="text" 
              name="lastname" 
              value={props.values.lastname} 
              onChange={props.handleInputChange} 
            />
          </FormGroup>
          <FormGroup>
            <Label>{ i18n.t('profile.general_settings.form.username') }: </Label>
            <DefaultInput 
              type="text" 
              name="username" 
              value={props.values.username} 
              onChange={props.handleInputChange} 
              readOnly
            />
          </FormGroup>
          <FormGroup>
            <Label>{ i18n.t('profile.general_settings.form.birthdate') }: </Label>
            <DefaultInput 
              type="date" 
              name="birthdate" 
              value={props.values.birthdate} 
              onChange={props.handleInputChange} 
            />
          </FormGroup>
          <FormGroup>
            <DefaultButton 
              color="dark" 
              block 
              onClick={update}
              disabled={updateLoading || !props.values.firstname || !props.values.lastname || !props.values.birthdate}
              isLoading={updateLoading}
            >
              { i18n.t('profile.general_settings.form.submit') }
            </DefaultButton>
          </FormGroup>
        </form>
      </CardBody>
    </Card>
  );
}
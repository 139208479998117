import React, { Component } from "react";
import { Container } from "reactstrap";
import { AdminBodyTitle } from "../../../components/Typography/Titles";

class CMSCollectionsPage extends Component {
  render() {
    return(
      <Container>
        <AdminBodyTitle>إدارة المجموعات</AdminBodyTitle>
      </Container>
    );
  }
}

export default CMSCollectionsPage;
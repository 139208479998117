import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Alert, Col, Container, Row } from "reactstrap";
import { DefaultButton } from "../../../components/Buttons/default";
import { DefaultInput } from "../../../components/Forms/Inputs/default";
import { withRouter } from "../../../components/Router/withRouter";
import { API_LINK } from "../../../utils/constants";
import { i18n } from "../../../i18n";
import { CiGlobe } from "react-icons/ci";
import LanguagesModal from "../../../components/Modals/Languages";

class PasswordRecoveryPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      changeLanguageModalShown: false,
      email: ''
    }
  }

  requestEmailCode() {
    this.setState({isLoading: true});
    fetch(API_LINK+'/password/email', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: this.state.email
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        this.setState({isLoading: false});
        this.props.navigate('/password/new/'+this.state.email);
      }
      else
      {
        this.setState({
          isLoading: false,
          alertText: json.message
        });
      }
    })
  }

  handleChange(name, event) {
    this.setState({
      ...this.state,
      [name]: event.target.value
    })
  }

  render() {
    const s = this.state;

    return(
      <Container>
        <Row style={{height: '100vh'}} className="align-items-center">
          <Col lg="5" md="6" className="mx-auto">
            <div className="login-card bg-white p-5">
              <div className="row align-items-center mb-3">
                <div className="col-8">
                  <img src={ require('../../../assets/brand-black.png') } width={50} />
                </div>
                <div className="col-4 text-end">
                  <CiGlobe 
                    size={26} 
                    onClick={() => this.setState({changeLanguageModalShown: true})} 
                    style={{cursor: "pointer"}}
                  />
                </div>
              </div>

              <h4 className="mb-5 fw-bold">{ i18n.t('auth.password_recovery.title') }</h4>
              <div className="mb-3">
                <label className="form-label">{ i18n.t('auth.password_recovery.form.email_label') }: </label>
                <DefaultInput bsSize={'lg'} type="email" value={s.email} onChange={(event) => this.handleChange('email', event)} />
              </div>
              <div className="mb-3">
                <DefaultButton 
                  size={'lg'} 
                  type="submit" 
                  color="dark"
                  disabled={!s.email || !s.email.includes('@') || s.isLoading}
                  isLoading={s.isLoading}
                  onClick={() => this.requestEmailCode()}
                  className={'rounded-pill px-5'}
                >
                  { i18n.t('auth.password_recovery.form.submit') }
                </DefaultButton>
              </div>

              <div className="mb-3 text-center">
                <Link to="/login" className="text-primary">{ i18n.t('auth.password_recovery.cancel') }</Link>
              </div>
              {s.alertText &&
                <Alert className="bg-danger text-light border-danger rounded-0">{ s.alertText }</Alert>
              }
            </div>
          </Col>
        </Row>

        {s.changeLanguageModalShown &&
          <LanguagesModal 
            toggle={() => this.setState({changeLanguageModalShown: false})}
          />
        }
      </Container>
    );
  }
}

export default withRouter(PasswordRecoveryPage);
import React, { Fragment } from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import { DefaultInput } from "../Inputs/default";

const PaymentForm = (props) => {
  return(
    <div className="mt-4">
      <FormGroup>
        <Label>رقم البطاقة: </Label>
        <DefaultInput 
          name={'card_number'}
          placeholder={'4242 4242 4242 4242'} 
          maxLength={16}
          value={props.data.card_number}
          onChange={(event) => props.handleChange(event)}
        />
      </FormGroup>
      <FormGroup>
        <Row>
          <Col md="6">
            <Label>الكود السري: </Label>
            <DefaultInput 
              name={'cvc'}
              placeholder={'CVC'} 
              maxLength={3}
              value={props.data.cvc}
              onChange={(event) => props.handleChange(event)}
            />
          </Col>
          <Col md="6">
            <Label>صالحة لغاية: </Label>
            <DefaultInput 
              name={'expiry'} 
              placeholder={'MM/YY'} 
              onChange={(event) => props.handleChange(event)}
              maxLength={5}
              value={props.data.expiry}
              dir="ltr"
            />
          </Col>
        </Row>
      </FormGroup>
      <FormGroup>
        <Label>الإسم على البطاقة: </Label>
        <DefaultInput 
          name={'cardholder'}
          placeholder={'Firstname Lastname'} 
          maxLength={'35'}
          value={props.data.cardholder}
          onChange={(event) => props.handleChange(event)}
        />
      </FormGroup>
      {props.forPay &&
        <Fragment>
          <FormGroup>
            <Input 
              type="checkbox" 
              value={1} 
              name="save"
              checked={props.data.save}
              onChange={(event) => props.handleChange(event)}
            />
            {' '}
            <Label>إحفظها في حسابي لتسهيل الدفع مستقبلا</Label>
          </FormGroup>
          <hr />
        </Fragment>
      }
    </div>
  );
}

export default PaymentForm;
import React from "react";
import { BiStoreAlt } from 'react-icons/bi';

const storeData = {
  name: 'المتجر',
  link: '/dashboard/store',
  icon: <BiStoreAlt />,
  childrens: [
    {
      name: 'التصنيفات',
      link: '/dashboard/categories'
    },
    {
      name: 'العلامات التجارية',
      link: '/dashboard/store/brands'
    },
    {
      name: 'المنتجات',
      link: '/dashboard/store/products'
    },
    {
      name: 'الطلبات',
      link: '/dashboard/store/orders'
    },
    {
      name: 'السلات المهملة',
      link: '/dashboard/store/cart_trash'
    },
    {
      name: 'الكوبونات',
      link: '/dashboard/store/coupons'
    }
  ]
}

const blogData = {
  name: 'المدونة',
  link: '/dashboard/blog',
  icon: <BiStoreAlt />,
  childrens: [
    {
      name: 'التصنيفات',
      link: '/dashboard/categories'
    },
    {
      name: 'المقالات',
      link: '/dashboard/blog/posts'
    },
    {
      name: 'التعليقات',
      link: '/dashboard/blog/comments'
    }
  ]
}

const formData = {
  name: 'الفورم',
  link: '/dashboard/store',
  icon: <BiStoreAlt />,
  childrens: [
    {
      name: 'المدخلات',
      link: '/dashboard/store/categories'
    },
    {
      name: 'التفاعل',
      link: '/dashboard/products'
    }
  ]
}

const portfolioData = {
  name: 'الفورم',
  link: '/dashboard/store',
  icon: <BiStoreAlt />,
  childrens: [
    {
      name: 'التصنيفات',
      link: '/dashboard/store/categories'
    },
    {
      name: 'الأعمال',
      link: '/dashboard/products'
    }
  ]
}

export const MenuData = (category) => {
  switch (category) {
    case 'store':
      return storeData
      break;

    case 'blog':
      return blogData
      break;

    case 'form':
      return formData
      break;

    case 'portfolio':
      return portfolioData
      break;
  
    default:
      return {}
      break;
  }
}
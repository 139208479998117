import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import { RiRadioButtonLine } from "react-icons/ri";
import { MdWebAssetOff } from "react-icons/md";
import { FaRegEye } from "react-icons/fa";
import { TbHandClick, TbWorld } from "react-icons/tb";
import { AdminBodyTitle } from "../../../components/Typography/Titles";
import { StatisticsCard } from "../../../components/Cards/Statistics";
import { VictoryAxis, VictoryBar, VictoryChart, VictoryPie, VictoryTheme } from "victory";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import DateRangePicker from "../../../components/Forms/Inputs/DateRange";
import { withRouter } from "../../../components/Router/withRouter";
import { API_LINK } from "../../../utils/constants";
import './styles.css';
import { i18n } from "../../../i18n";

class ReportsVisitesPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      endDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
      totalVisites: null,
      uniqueVisites: null,
      visitesByMonth: null,
      sourcePercentages: null,
      activeNow: null,
      dataLoading: true
    }
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    this.setState({dataLoading: true});
    const user = localStorage.getItem('user');
    const website_id = JSON.parse(this.props.website.website).id;
    const startDate = this.state.startDate.toISOString().split('T')[0];
    const endDate = this.state.endDate.toISOString().split('T')[0];

    fetch(API_LINK+'/analytics/'+website_id+'?start_date='+startDate+'&end_date='+endDate, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        this.setState({
          totalVisites: json.totalVisites,
          uniqueVisites: json.uniqueVisites,
          sourcePercentages: json.sourcePercentages,
          activeNow: json.activeNow,
          visitesByMonth: json.visitesByMonth,
          dataLoading: false
        });
      }
      else
      {
        this.setState({
          dataLoading: false
        });
      }
    })
  }

  render() {
    const s = this.state;
    const { sourcePercentages } = s;

    const existingData = [
      { x: "Google", y: 10 },
      { x: "Facebook", y: 15 },
      { x: "X", y: 8 },
      { x: "Instagram", y: 20 },
      { x: "Tiktok", y: 21 },
      { x: "Linkedin", y: 10 },
      { x: "Others", y: 16 }
    ];

    const labelColorScale = {
      "Google": '#34A853',
      "Facebook": '#1877F2',
      "X": '#000',
      "Instagram": '#fbad50',
      "Tiktok": '#ff0050',
      "Linkedin": '#0077b5',
      "Others": '#ababab'
    };

    const colorScale = existingData.map(item => (
      item.y > 0 ? (
        sourcePercentages && typeof sourcePercentages === 'object' && sourcePercentages[item.x.toLowerCase()] > 0
          ? labelColorScale[item.x] : null
      ) : null
    )).filter(color => color !== null);;

    const filteredData = existingData.filter(item => {
      const yValue = sourcePercentages && typeof sourcePercentages === 'object'
        ? sourcePercentages[item.x.toLowerCase()]
        : item.y;
      return yValue > 0;
    });

    const mergedData = filteredData.map(item => ({
      x: item.x,
      y: sourcePercentages && typeof sourcePercentages === 'object'
        ? sourcePercentages[item.x.toLowerCase()]
        : item.y
    }));

    return(
      <Container className="pb-5">
        <Row className="align-items-center border-bottom mb-4">
          <Col md="6">
            <AdminBodyTitle>{ i18n.t('reports.title') }</AdminBodyTitle>
          </Col>
          <Col md="6">
            <DateRangePicker 
              startDate={s.startDate}
              endDate={s.endDate}
              onChange={() => this.getData()}
              onSetStartDate={(value) => this.setState({startDate: value})}
              onSetEndDate={(value) => this.setState({endDate: value})}
            />
          </Col>
        </Row>
        <Row>
          <Col md="3">
            <StatisticsCard 
              title={ i18n.t('reports.active_now') }
              value={s.activeNow || 0}
              icon={<RiRadioButtonLine size={42} />}
              isLoading={s.dataLoading}
            />
          </Col>
          <Col md="3">
            <StatisticsCard 
              title={ i18n.t('reports.visits') }
              value={s.uniqueVisites || 0}
              icon={<TbWorld size={42} />}
              isLoading={s.dataLoading}
            />
          </Col>
          <Col md="3">
            <StatisticsCard 
              title={ i18n.t('reports.events') }
              value={'0'}
              icon={<TbHandClick size={42} />}
              isLoading={s.dataLoading}
            />
          </Col>
          <Col md="3">
            <StatisticsCard 
              title={ i18n.t('reports.pages_views') }
              value={s.totalVisites || 0}
              icon={<FaRegEye size={42} />}
              isLoading={s.dataLoading}
            />
          </Col>
        </Row>

        <Row>
          <Col md="6">
            <div className="reports-card-big border bg-white">
              <h5 className="p-3 fw-bold border-bottom">{ i18n.t('reports.visits_sources') }</h5>
              {s.dataLoading ?
                <div className="text-center p-5">
                  <AiOutlineLoading3Quarters className="animation-spin h1" />
                </div>
              :
                colorScale.length > 0 ?
                  <VictoryPie
                    data={mergedData}
                    labelPlacement={'parallel'}
                    animate={{
                      duration: 2000,
                      easing: 'bounce',
                      onLoad: {duration: 1000}
                    }}
                    style={{
                      labels: {
                        fontSize: 8,
                      }
                    }}
                    colorScale={colorScale}
                    cornerRadius={({ datum }) => datum.y * 0.3}
                    events={[
                      {
                        target: "data",
                        eventHandlers: {
                          onMouseOver: () => {
                            return [{
                              target: "labels",
                              mutation: (props) => {
                                return props.text === props.slice.value+'%' ?
                                  null : { text: props.slice.value.toFixed(1)+'%' }
                              }
                            }];
                          },
                          onMouseLeave: () => {
                            return [{
                              target: "labels",
                              mutation: (props) => {
                                return null
                              }
                            }];
                          }
                        }
                      }
                    ]}
                  />
                :
                  <div className="text-center py-5">
                    <MdWebAssetOff size={150} color="#dedede" />
                    <h5 className="text-muted">{ i18n.t('reports.no_data') }</h5>
                  </div>
              }
            </div>
          </Col>
          <Col md="6">
            <div className="reports-card-big border bg-white">
              <h5 className="p-3 fw-bold border-bottom">{ i18n.t('reports.year_chart') }</h5>
              {s.dataLoading ?
                <div className="text-center p-5">
                  <AiOutlineLoading3Quarters className="animation-spin h1" />
                </div>
              :
                s.visitesByMonth?.length > 0 ?
                  <VictoryChart
                    theme={VictoryTheme.material}
                    domainPadding={15}
                  >
                    <VictoryBar
                      data={s.visitesByMonth}
                    />
                    <VictoryAxis
                      tickValues={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
                      style={{
                        tickLabels: { fontSize: 6, angle: -60 },
                      }}
                    />
                    <VictoryAxis 
                      dependentAxis 
                      style={{
                        tickLabels: { fontSize: 8, angle: -20 },
                      }}
                    />
                  </VictoryChart>
                :
                  <div className="text-center py-5">
                    <MdWebAssetOff size={150} color="#dedede" />
                    <h5 className="text-muted">{ i18n.t('reports.no_data') }</h5>
                  </div>
              }
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(ReportsVisitesPage);
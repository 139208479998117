import React, { useEffect, useState } from "react";
import { DefaultModal } from "../../../../components/Modals/Default";
import { useWebsite } from "../../../../context/Website";
import { FormGroup, Input, Label } from "reactstrap";
import { DefaultSelect } from "../../../../components/Forms/Inputs/Select";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { API_LINK } from "../../../../utils/constants";

const BuilderAddLanguageModal = (props) => {
  const website = useWebsite();
  const [addLoading, setAddLoading] = useState(false);
  const [languagesLoading, setLanguagesLoading] = useState(true);
  const [languagesData, setLanguagesData] = useState(null);
  const [data, setData] = useState({
    language_id: '',
    is_default: 0
  });

  useEffect(() => {
    getLanguages();
  }, []);

  const getLanguages = () => {
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/languages', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        setLanguagesData(json.languages);
        setLanguagesLoading(false);
      } else {
        props.onError('Error');
      }
    })
  }

  const store = () => {
    setAddLoading(true);
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/builder/language/store', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        website_id: JSON.parse(website.website).id,
        language_id: data.language_id,
        is_default: data.is_default
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        props.toggle();
        props.onSuccess();
      } else {
        props.onError('Error');
      }
      setAddLoading(false);
    })
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    
    setData((prev) => ({ ...prev, [name]: value }));
  }

  return(
    <DefaultModal
      isOpen={props.isOpen}
      title={'لغة جديدة'}
      confirmationText={'حفظ'}
      cancelText={'غلق'}
      toggle={props.toggle}
      onConfirm={() => store()}
      isLoading={addLoading}
    >
      {languagesLoading ?
        <div className="text-center py-4">
          <AiOutlineLoading3Quarters className="animation-spin" size={22} />
        </div>
      :
        <FormGroup>
          <Label>إختر اللغة: </Label>
          <DefaultSelect name="language_id" value={data.language_id} onChange={(event) => handleChange(event)}>
            <option value="">اختر اللغة</option>
            {languagesData.map((data, key) => {
              return(
                <option key={key} value={data.id}>{data.name}</option>
              );
            })}
          </DefaultSelect>
        </FormGroup>
      }
      <FormGroup>
        <Input 
          type="checkbox" 
          name="is_default" 
          value={data.is_default === 1 ? 0 : 1} 
          checked={data.is_default === 1 ? 'true' : 'false'} 
          onChange={(event) => handleChange(event)} 
        />{' '}
        <Label>تعيين كإفتراضية</Label>
      </FormGroup>
    </DefaultModal>
  );
}

export default BuilderAddLanguageModal;
import React, { Component } from "react";
import { Card, CardBody, Col, Container, FormGroup, Label, Row } from "reactstrap";
import { AdminBodyTitle } from "../../../../components/Typography/Titles";
import { FaChevronRight } from "react-icons/fa";
import { withRouter } from "../../../../components/Router/withRouter";
import Select from "react-select";
import { DefaultInput } from "../../../../components/Forms/Inputs/default";
import { DefaultSelect } from "../../../../components/Forms/Inputs/Select";
import { DefaultButton } from "../../../../components/Buttons/default";
import { BottomLeftAlert } from "../../../../components/Alerts";
import { API_LINK } from "../../../../utils/constants";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

class CRMEditTaskPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      taskLoading: true,
      editLoading: false,
      alertText: null,

      contactsData: null,
      contactsLoading: true,
      contactsKeyword: '',
      contactsOptions: [],

      contact_id: '',
      title: '',
      type: '',
      date: '',
      time: '',
      status: '',
      priority: '',
      description: ''
    }
  }

  componentDidMount() {
    this.getTask();
  }

  getTask() {
    this.setState({taskLoading: true});
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/crm/task/'+this.props.params.task, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        this.setState({
          contact_id: json.task.contact_id || '',
          title: json.task.title || '',
          type: json.task.type || '',
          date: json.task.date || '',
          time: json.task.time || '',
          status: json.task.status || '',
          priority: json.task.priority || '',
          description: json.task.description || ''
        });
      }
      else
      {
        this.setState({
          alertText: json.message
        });
      }

      this.setState({
        taskLoading: false
      }, () => {
        this.getContacts();
      });
    })
  }

  getContacts() {
    this.setState({contactsLoading: true});
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/crm/contacts?keyword='+this.state.contactsKeyword, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        this.setState({
          contactsData: json.contacts.data.map((contact) => ({
            ...contact,
            selected: false
          })),
          contactsLoading: false
        }, () => {
          this.state.contactsData.forEach(contact => {
            this.setState((prevState) => {
              const newValue = {
                label: contact.firstname+' '+contact.lastname,
                value: contact.id
              };
            
              if (!prevState.contactsOptions.some(option => option.value === newValue.value)) {
                return {
                  contactsOptions: [...prevState.contactsOptions, newValue]
                };
              } else {
                return prevState;
              }
            });
          });
        });
      }
      else
      {
        this.setState({
          alertText: json.message,
          contactsLoading: false
        });
      }
    })
  }

  update() {
    this.setState({editLoading: true});
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/crm/task/update', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        id: this.props.params.task,
        contact_id: this.state.contact_id.value || '',
        title: this.state.title,
        type: this.state.type,
        date: this.state.date,
        time: this.state.time,
        status: this.state.status,
        priority: this.state.priority,
        description: this.state.description,
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.props.navigate('/dashboard/crm/tasks');
      } else {
        this.setState({
          alertText: json.message
        });
      }

      this.setState({editLoading: false});
    })
  }

  handleChange(event) {
    const value = event.target.value;
    this.setState({
      ...this.state,
      [event.target.name]: value
    });
  }

  render() {
    const s = this.state;

    if(s.taskLoading) {
      return(
        <Container className="text-center">
          <AiOutlineLoading3Quarters className="animation-spin h1" />
        </Container>
      );
    }

    return(
      <Container>
        <AdminBodyTitle>
          تعديل المهمة
        </AdminBodyTitle>

        <Card className="mb-3">
          <CardBody>
            <FormGroup>
              <Label>العميل: </Label>
              <Select
                className="bliz-form-control form-control"
                placeholder={'إختر العميل'}
                isLoading={s.contactsLoading}
                isClearable={true}
                isRtl={true}
                isSearchable={true}
                name="color"
                options={s.contactsOptions}
                value={s.contact_id}
                onChange={(selectedOption) => this.setState({contact_id: selectedOption})}
              />
            </FormGroup>
            <FormGroup>
              <Label>العنوان: </Label>
              <DefaultInput 
                name="title"
                value={s.title}
                onChange={(event) => this.handleChange(event)}
              />
            </FormGroup>
            <FormGroup>
              <Label>النوع: </Label>
              <DefaultSelect name="type" value={s.type} onChange={(event) => this.handleChange(event)}>
                <option value=""></option>
                <option value="email">بريد إلكتروني</option>
                <option value="call">مكالمة</option>
                <option value="meeting">إجتماع</option>
                <option value="other">آخر</option>
              </DefaultSelect>
            </FormGroup>
            <Row>
              <Col md="6" className="mb-3">
                <Label>التاريخ: </Label>
                <DefaultInput
                  type={'date'} 
                  name="date"
                  value={s.date}
                  onChange={(event) => this.handleChange(event)}
                />
              </Col>
              <Col md="6" className="mb-3">
                <Label>الوقت: </Label>
                <DefaultInput 
                  type={'time'} 
                  name="time"
                  value={s.time}
                  onChange={(event) => this.handleChange(event)}
                />
              </Col>
            </Row>
            <FormGroup>
              <Label>الحالة: </Label>
              <DefaultSelect name="status" value={s.status} onChange={(event) => this.handleChange(event)}>
                <option value=""></option>
                <option value="0">قيد الإنتظار</option>
                <option value="1">قيد التنفيذ</option>
                <option value="2">منتهية</option>
                <option value="3">ملفية</option>
              </DefaultSelect>
            </FormGroup>
            <FormGroup>
              <Label>الأولوية: </Label>
              <DefaultSelect name="priority" value={s.priority} onChange={(event) => this.handleChange(event)}>
                <option value=""></option>
                <option value="0">منخفضة</option>
                <option value="1">عادية</option>
                <option value="2">مرتفعة</option>
              </DefaultSelect>
            </FormGroup>
            <FormGroup>
              <Label>الوصف: </Label>
              <DefaultInput 
                type={'textarea'} 
                name="description"
                value={s.description}
                onChange={(event) => this.handleChange(event)}
              />
            </FormGroup>
            <FormGroup>
              <DefaultButton 
                color={'dark'}
                disabled={s.addLoading || !s.title || !s.type}
                isLoading={s.addLoading}
                onClick={() => this.update()}
                block
              >
                حفظ
              </DefaultButton>
            </FormGroup>
          </CardBody>
        </Card>

        {s.alertText &&
          <BottomLeftAlert color={'danger'} onClose={() => this.setState({alertText: null})}>
            {s.alertText}
          </BottomLeftAlert>
        }
      </Container>
    );
  }
}

export default withRouter(CRMEditTaskPage);
import React, { Fragment, useEffect, useState } from "react";
import './styles.css';
import { Col, Row } from "reactstrap";
import { API_LINK, MAIN_LINK } from "../../../utils/constants";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useWebsite } from "../../../context/Website";
import { IoMdClose } from "react-icons/io";
import { generateRandomHex } from "../../../utils/helpers";

const BuilderAddSection = (props) => {  
  const website = useWebsite();
  const [data, setData] = useState(null);
  const [dataLoading, setDataLoading] = useState(true);
  const [selectedSections, setSelectedSections] = useState(null);
  const [selectedSectionType, setSelectedSectionType] = useState(null);

  useEffect(() => {
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/builder/sections_by_type', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        setData(json.sections)
        setDataLoading(false);
        setSelectedSections(json.sections[0].sections)
      }
    })
  }, []);

  const onSelectType = (sections) => {
    const styleElement = document.createElement('style');


    let customStyles = '';

    sections.forEach(data => {
      customStyles += data.styles;
    });

    styleElement.innerHTML = customStyles;

    if(customStyles !== 'null')
    {
      document.head.appendChild(styleElement);
    }

    setSelectedSections(sections);
  }

  const addSection = (data) => {
    const user = localStorage.getItem('user');

    const iframe = document.getElementById('builder-iframe');
    const message = {
      type: 'addSection',
      sectionBlizId: props.infos.sectionBlizId,
      lang: props.selectedLanguage.language.short,
      htmlContent: data.content,
      styles: data.styles,
      position: props.infos.place
    };

    iframe.contentWindow.postMessage(message, '*');

    fetch(API_LINK+'/builder/section/store', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        website_id: JSON.parse(website.website).id,
        page_id: props.selectedPage.id,
        lang: props.selectedLanguage.language.short,
        section_id: data.id,
        section_type_id: selectedSectionType,
        htmlContent: data.content,
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        
      }
    })
  }

  return(
    <div className="builder-add-container shadow">
      <div className="builder-add-header p-3 shadow-sm">
        <Row className="align-items-center">
          <Col md="8" xs="8">
            <h6 className="fw-bold mb-0">إضافة وحدة</h6>
          </Col>
          <Col md="4" xs="4" className="text-end">
            <IoMdClose onClick={props.toggle} className="cursor-pointer" />
          </Col>
        </Row>
      </div>
      <div className="builder-add-body px-3">
        {dataLoading ?
          <div className="text-center">
            <AiOutlineLoading3Quarters className="animation-spin" size={40} />
          </div>
        :
          <Row>
            <Col md="4" className="builder-add-categories-container pt-3">
              {data?.length > 0 &&
                <ul className="builder-add-categories ps-0">
                  {data.map((data, key) => (
                    <li 
                      key={key} 
                      onClick={() => {onSelectType(data.sections); setSelectedSectionType(data.id)}} 
                      className={data.sections === selectedSections ? 'active' : ''}
                    >
                      {data.name}
                    </li>
                  ))}
                </ul>              
              }
            </Col>
            <Col md="8" className="pt-3 buiderl-add-sections-container">
              {selectedSections?.length > 0 &&
                <Fragment>
                  <div 
                    className="section-preview-empty d-flex justify-content-center align-items-center" 
                    onClick={() => addSection({content: '<section data-bliz-id="'+generateRandomHex(8)+'" class="py-5"><div data-bliz-id="'+generateRandomHex(8)+'" class="container"></div></section>', styles: '', id: null})}
                  >
                    <section>
                      <h6>وحدة فارغة</h6>
                    </section>
                  </div>
                  
                  {selectedSections.map((data, key) => (
                    <div className="section-preview-container" key={key}>
                      <div className="section-preview" onClick={() => addSection(data)}>
                        <iframe 
                          src={MAIN_LINK+'/template/section/'+data.id} 
                          frameborder="0" 
                          width="100%"
                        ></iframe>
                      </div>
                    </div>
                  ))}
                </Fragment>
              }
            </Col>
          </Row>
        }
      </div>
    </div>
  );
}

export default BuilderAddSection;
import React, { useEffect, useState } from "react";
import { DefaultModal } from "../../../../components/Modals/Default";
import { useWebsite } from "../../../../context/Website";
import { FormGroup, Label } from "reactstrap";
import { DefaultInput } from "../../../../components/Forms/Inputs/default";
import { DefaultSelect } from "../../../../components/Forms/Inputs/Select";
import { API_LINK } from "../../../../utils/constants";
import { DefaultButton } from "../../../../components/Buttons/default";

const BuilderEditNavbarLinkModal = (props) => {
  const website = useWebsite();
  const [addLoading, setAddLoading] = useState(false);
  const [selectedLink, setSelectedLink] = useState(props.link?.href.includes('http') ? '1' : '2');
  const [name, setName] = useState(props.link?.text);
  const [link, setLink] = useState(props.link?.href);  
  
  useEffect(() => {
    setName(props.link?.text || '');
    setLink(props.link?.href || '');
    setSelectedLink(props.link?.href.includes('http') ? '1' : '2')
  }, [props.link]);

  const updateIframe = () => {
    const iframe = document.getElementById('builder-iframe');
    const message = {
      type: 'editNavlink',
      sectionIndex: props.sectionIndex,
      linkIndex: props.linkKey,
      name: name,
      link: link
    };

    iframe.contentWindow.postMessage(message, '*');
  }

  const removeFromIframe = () => {
    const iframe = document.getElementById('builder-iframe');
    const message = {
      type: 'removeNavlink',
      sectionIndex: props.sectionIndex,
      linkIndex: props.linkKey
    };

    iframe.contentWindow.postMessage(message, '*');
  }

  const update = () => {
    setAddLoading(true);
    const user = localStorage.getItem('user');
    

    fetch(API_LINK+'/builder/navbar/link/update', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        website_id: JSON.parse(website.website).id,
        page_id: props.selectedPage.id,
        lang: props.selectedLanguage.language.short,
        sectionIndex: props.sectionIndex,
        linkIndex: props.linkKey,
        name: name,
        link: link
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        updateIframe();
        props.toggle();
      } else {
        props.onError(json.message);
      }
      setAddLoading(false);
    })
  }

  const destroy = () => {
    setAddLoading(true);
    const user = localStorage.getItem('user');
    

    fetch(API_LINK+'/builder/navbar/link/destroy', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        website_id: JSON.parse(website.website).id,
        page_id: props.selectedPage.id,
        lang: props.selectedLanguage.language.short,
        sectionIndex: props.sectionIndex,
        linkIndex: props.linkKey
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        removeFromIframe();
        props.toggle();
      } else {
        props.onError(json.message);
      }
      setAddLoading(false);
    })
  }

  return(
    <DefaultModal
      isOpen={props.isOpen}
      title={'تعديل الرابط'}
      confirmationText={'حفظ'}
      cancelText={'غلق'}
      toggle={props.toggle}
      onConfirm={() => update()}
      isLoading={addLoading}
      confirmationButtonDisabled={!name || !link}
    >
      <FormGroup>
        <Label>الإسم: </Label>
        <DefaultInput onChange={(event) => setName(event.target.value)} value={name} />
      </FormGroup>
      <FormGroup>
        <Label>الرابط: </Label>
        <DefaultSelect onChange={(event) => setSelectedLink(event.target.value)} value={selectedLink}>
          <option value="">اختر</option>
          <option value="1">لصفحة في الموقع</option>
          <option value="2">رابط خارجي</option>
        </DefaultSelect>
        {selectedLink === '1' ?
          <DefaultSelect className="mt-3" onChange={(event) => setLink(event.target.value)} value={link}>
            <option value="">اختر الصفحة</option>
            {props.pages && props.pages.map((data, key) => (
              <option value={JSON.parse(website.website).link+data.slug} key={key}>{ data.name }</option>
            ))}
          </DefaultSelect>
        :
          selectedLink === '2' &&
          <DefaultInput className="mt-3" onChange={(event) => setLink(event.target.value)} value={link} />
        }
      </FormGroup>
      <FormGroup>
        <DefaultButton color="danger" outline size={'md'} onClick={() => destroy()}>حذف العنصر</DefaultButton>
      </FormGroup>
    </DefaultModal>
  );
}

export default BuilderEditNavbarLinkModal;
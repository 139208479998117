import React, { Fragment, useState } from "react";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { useWebsite } from "../../../context/Website";
import { DefaultButton } from "../../../components/Buttons/default";
import { DefaultInput } from "../../../components/Forms/Inputs/default";
import { API_LINK } from "../../../utils/constants";
import { IoMdClose } from "react-icons/io";
import IconsList from "../../../components/Icons";
import { generateRandomHex, isURL } from "../../../utils/helpers";
import { FacebookWidget, TwitchWidget, UploadWidget, VimeoWidget, YoutubeWidget } from "../../../components/Cards/Builder/AddVideoWidgets";

const BuilderAddElement = (props) => {
  const website = useWebsite();
  const [selectedElement, setSelectedElement] = useState(props.data.place === 'layout' ? 'text' : 'layout');
  const [iconsSearchKeyword, setIconsSearchKeyword] = useState('');
  const [randomHex, setRandomHex] = useState(generateRandomHex(8));
  const [randomHexs, setRandomHexs] = useState([generateRandomHex(8), generateRandomHex(8), generateRandomHex(8), generateRandomHex(8)]);
  const [selectedVideoHost, setSelectedVideoHost] = useState(null);
  const [videoContent, setVideoContent] = useState('');

  const addVideProccess = (data) => {
    if(isURL(data)) {
      if(data.includes('https://youtube.com') || data.includes('https://www.youtube.com')) {
        const iframeSrc = data.replace('watch?v=', 'embed/');
        const iframe = '<iframe data-bliz-id="'+randomHexs[0]+'" src="'+iframeSrc+'" width="500px" height="280px" frameborder="0" allowfullscreen></iframe>';
        const iframeContainer = '<div class="bliz-iframe-container" data-bliz-id="'+randomHexs[1]+'">'+iframe+'</div>';
        addElement(iframeContainer);
        setVideoContent('');
        props.toggle();
      } else if(data.includes('vimeo.com')) {
        const videoIdMatch = data.match(/vimeo\.com\/(?:channels\/[a-zA-Z0-9]+\/)?(\d+)/);
        if (videoIdMatch && videoIdMatch[1]) {
          const videoId = videoIdMatch[1];
          const iframeSrc = `https://player.vimeo.com/video/${videoId}`;
          const iframe = '<iframe data-bliz-id="'+randomHexs[0]+'" src="'+iframeSrc+'" width="500px" height="280px" frameborder="0" allowfullscreen></iframe>';
          const iframeContainer = '<div class="bliz-iframe-container" data-bliz-id="'+randomHexs[1]+'">'+iframe+'</div>';
          addElement(iframeContainer);
          setVideoContent('');
          props.toggle();
        }
      } else if(data.includes('facebook.com')) {
        const iframeSrc = `https://www.facebook.com/plugins/video.php?href=${encodeURIComponent(data)}&show_text=0&width=560`;
        const iframe = '<iframe data-bliz-id="'+randomHexs[0]+'" src="'+iframeSrc+'" width="500px" height="280px" frameborder="0" allowfullscreen></iframe>';
        const iframeContainer = '<div class="bliz-iframe-container" data-bliz-id="'+randomHexs[1]+'">'+iframe+'</div>';
        addElement(iframeContainer);
        setVideoContent('');
        props.toggle();
      } else if(data.includes('twitch.tv')) {
        const videoMatch = data.match(/twitch\.tv\/videos\/(\d+)/);
        if (videoMatch && videoMatch[1]) {
          const videoId = videoMatch[1];
          const iframeSrc = `https://player.twitch.tv/?video=${videoId}&parent=bliz.one`;
          const iframe = '<iframe data-bliz-id="'+randomHexs[0]+'" src="'+iframeSrc+'" width="500px" height="280px" frameborder="0" allowfullscreen></iframe>';
          const iframeContainer = '<div class="bliz-iframe-container" data-bliz-id="'+randomHexs[1]+'">'+iframe+'</div>';
          addElement(iframeContainer);
          setVideoContent('');
          props.toggle();
        }
      }
    }
  }

  const addElement = (data) => {
    const user = localStorage.getItem('user');

    const iframe = document.getElementById('builder-iframe');
    const message = {
      type: 'addElement',
      data: props.data,
      htmlContent: data
    };

    setRandomHex(generateRandomHex(8));
    setRandomHexs([generateRandomHex(8), generateRandomHex(8), generateRandomHex(8), generateRandomHex(8)]);

    iframe.contentWindow.postMessage(message, '*');

    fetch(API_LINK+'/builder/section/element/store', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        website_id: JSON.parse(website.website).id,
        page_id: props.selectedPage.id,
        lang: props.selectedLanguage.language.short,
        data: props.data,
        element: data
      })
    })
    .then((response) => response.json())
    .then((json) => {
      
    })
  }

  return(
    <div className="builder-add-container">
      <div className="builder-add-header p-3 border-bottom">
        <Row className="align-items-center">
          <Col md="8" xs="8">
            <h6 className="fw-bold mb-0">إضافة عنصر</h6>
          </Col>
          <Col md="4" xs="4" className="text-end">
            <IoMdClose onClick={props.toggle} className="cursor-pointer" />
          </Col>
        </Row>
      </div>
      <div className="builder-add-body px-3">
        <Row>
          <Col md="4" className="builder-add-categories-container pt-3">
            <ul className="builder-add-categories ps-0">
              <li onClick={() => setSelectedElement('layout')} className={selectedElement === 'layout' ? 'active' : ''}>نمط</li>
              <li onClick={() => setSelectedElement('text')} className={selectedElement === 'text' ? 'active' : ''}>نص</li>
              <li onClick={() => setSelectedElement('button')} className={selectedElement === 'button' ? 'active' : ''}>زر</li>
              <li onClick={() => addElement('<img src="https://placehold.co/600x400?text=BLIZ+IMAGE" data-bliz-id="'+randomHex+'" />')} className={selectedElement === 'image' ? 'active' : ''}>صورة</li>
              <li onClick={() => setSelectedElement('video')} className={selectedElement === 'video' ? 'active' : ''}>فيديو</li>
              <li onClick={() => setSelectedElement('icons')} className={selectedElement === 'icons' ? 'active' : ''}>أيقونات</li>
              <li onClick={() => setSelectedElement('map')} className={selectedElement === 'map' ? 'active' : ''}>خريطة</li>
            </ul>
          </Col>
          <Col md="8" className="pt-3">
            {selectedElement === 'layout' ?
              <div className="builder-add-elements-layouts">
                <div 
                  className="layout-vertical-1-split-container mb-3" 
                  onClick={() => addElement(`
                            <div data-bliz-id="${randomHex}" class="row bliz-layouts w-100">
                              <div data-bliz-id="${randomHexs[0]}" class="col-md-6 p-5 bliz-layout-item"></div>
                              <div data-bliz-id="${randomHexs[1]}" class="col-md-6 p-5 bliz-layout-item"></div>
                            </div>`
                          )}
                >
                  <div className="split-line"></div>
                  <div className="content"></div>
                </div>
                
                <div 
                  className="layout-vertical-2-split-container mb-3" 
                  onClick={() => addElement(`
                            <div data-bliz-id="${randomHex}" class="row bliz-layouts w-100">
                              <div data-bliz-id="${randomHexs[0]}" class="col-md-4 p-5 bliz-layout-item"></div>
                              <div data-bliz-id="${randomHexs[1]}" class="col-md-4 p-5 bliz-layout-item"></div>
                              <div data-bliz-id="${randomHexs[2]}" class="col-md-4 p-5 bliz-layout-item"></div>
                            </div>`
                          )}
                >
                  <div className="split-line"></div>
                  <div className="split-line"></div>
                  <div className="content"></div>
                </div>

                <div 
                  className="layout-vertical-3-split-container mb-3" 
                  onClick={() => addElement(`
                            <div data-bliz-id="${randomHex}" class="row bliz-layouts w-100">
                              <div data-bliz-id="${randomHex[0]}" class="col-md-3 p-5 bliz-layout-item"></div>
                              <div data-bliz-id="${randomHex[1]}" class="col-md-3 p-5 bliz-layout-item"></div>
                              <div data-bliz-id="${randomHex[2]}" class="col-md-3 p-5 bliz-layout-item"></div>
                              <div data-bliz-id="${randomHex[3]}" class="col-md-3 p-5 bliz-layout-item"></div>
                            </div>`
                          )}
                >
                  <div className="split-line"></div>
                  <div className="split-line"></div>
                  <div className="split-line"></div>
                  <div className="content"></div>
                </div>

                <div 
                  className="layout-vertical-4-split-container mb-3" 
                  onClick={() => addElement(`
                            <div data-bliz-id="${randomHex}" class="row bliz-layouts w-100">
                              <div data-bliz-id="${randomHexs[0]}" class="col-md-4 p-5 bliz-layout-item"></div>
                              <div data-bliz-id="${randomHexs[1]}" class="col-md-8 p-5 bliz-layout-item"></div>
                            </div>`
                          )}
                >
                  <div className="split-line"></div>
                  <div className="content"></div>
                </div>

                <div 
                  className="layout-vertical-5-split-container mb-3" 
                  onClick={() => addElement(`
                            <div data-bliz-id="${randomHex}" class="row bliz-layouts w-100">
                              <div data-bliz-id="${randomHexs[0]}" class="col-md-8 p-5 bliz-layout-item"></div>
                              <div data-bliz-id="${randomHexs[1]}" class="col-md-4 p-5 bliz-layout-item"></div>
                            </div>`
                          )}
                >
                  <div className="split-line"></div>
                  <div className="content"></div>
                </div>
              </div>
            :
            selectedElement === 'text' ?
              <div className="builder-add-elements-titles">
                <h1 data-bliz-id={randomHex} className="mb-4" onClick={(event) => addElement(event.target.outerHTML)}>عنوان رقم 1</h1>
                <h2 data-bliz-id={randomHex} className="mb-4" onClick={(event) => addElement(event.target.outerHTML)}>عنوان رقم 2</h2>
                <h3 data-bliz-id={randomHex} className="mb-4" onClick={(event) => addElement(event.target.outerHTML)}>عنوان رقم 3</h3>
                <h4 data-bliz-id={randomHex} className="mb-4" onClick={(event) => addElement(event.target.outerHTML)}>عنوان رقم 4</h4>
                <h5 data-bliz-id={randomHex} className="mb-4" onClick={(event) => addElement(event.target.outerHTML)}>عنوان رقم 5</h5>
                <h6 data-bliz-id={randomHex} className="mb-4" onClick={(event) => addElement(event.target.outerHTML)}>عنوان رقم 6</h6>
                
                <p data-bliz-id={randomHex} className="mb-3 fw-bold" onClick={(event) => addElement(event.target.outerHTML)}>فقرة تجريبية، يمكنك تغيير محتوى النص بعد إضافته للصفحة.</p>
                <p data-bliz-id={randomHex} className="mb-3" onClick={(event) => addElement(event.target.outerHTML)}>فقرة تجريبية، يمكنك تغيير محتوى النص بعد إضافته للصفحة.</p>
                <p data-bliz-id={randomHex} className="mb-3" onClick={(event) => addElement(event.target.outerHTML)} style={{fontWeight: 200}}>فقرة تجريبية، يمكنك تغيير محتوى النص بعد إضافته للصفحة.</p>
              </div>
            :
            selectedElement === 'button' ?
              <Row className="builder-add-elements-buttons">
                <Col xs="6" className="mb-4">
                  <DefaultButton data-bliz-id={randomHex} className={'rounded-0'} color={'dark'} onClick={(event) => addElement(event.target.outerHTML)}>أساسي</DefaultButton>
                </Col>
                <Col xs="6" className="mb-4">
                  <DefaultButton data-bliz-id={randomHex} className={'rounded-0'} outline color={'dark'} onClick={(event) => addElement(event.target.outerHTML)}>ثانوي</DefaultButton>
                </Col>
                <Col xs="6" className="mb-4">
                  <DefaultButton data-bliz-id={randomHex} className={'rounded'} color={'dark'} onClick={(event) => addElement(event.target.outerHTML)}>منحني أساسي</DefaultButton>
                </Col>
                <Col xs="6" className="mb-4">
                  <DefaultButton data-bliz-id={randomHex} outline className={'rounded'} color={'dark'} onClick={(event) => addElement(event.target.outerHTML)}>منحني ثانوي</DefaultButton>
                </Col>
                <Col xs="6" className="mb-4">
                  <DefaultButton data-bliz-id={randomHex} className={'rounded-pill'} color={'dark'} onClick={(event) => addElement(event.target.outerHTML)}>دائري أساسي</DefaultButton>
                </Col>
                <Col xs="6" className="mb-4">
                  <DefaultButton data-bliz-id={randomHex} outline className={'rounded-pill'} color={'dark'} onClick={(event) => addElement(event.target.outerHTML)}>دائري ثانوي</DefaultButton>
                </Col>
              </Row>
            :
            selectedElement === 'video' ?
              <div>
                <Row>
                  <Col md="12" className="mb-3">
                    <UploadWidget />
                  </Col>
                  <Col md="6" className="mb-3">              
                    <YoutubeWidget 
                      active={selectedVideoHost === 'youtube' ? true : false}
                      onClick={() => setSelectedVideoHost('youtube')}
                    />
                  </Col>
                  <Col md="6" className="mb-3">
                    <FacebookWidget 
                      active={selectedVideoHost === 'facebook' ? true : false}
                      onClick={() => setSelectedVideoHost('facebook')}
                    />
                  </Col>
                  <Col md="6" className="mb-3">
                    <VimeoWidget 
                      active={selectedVideoHost === 'vimeo' ? true : false}
                      onClick={() => setSelectedVideoHost('vimeo')}
                    />
                  </Col>
                  <Col md="6" className="mb-3">
                    <TwitchWidget 
                      active={selectedVideoHost === 'twitch' ? true : false}
                      onClick={() => setSelectedVideoHost('twitch')}
                    />
                  </Col>
                </Row>

                {selectedVideoHost &&
                  <Fragment>
                    <FormGroup>
                      <Label>رابط الفيديو: </Label>
                      <DefaultInput 
                        value={videoContent}
                        onChange={(event) => setVideoContent(event.target.value)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <DefaultButton 
                        block 
                        color={'dark'}
                        onClick={() => addVideProccess(videoContent)}
                      >
                        إضافة
                      </DefaultButton>
                    </FormGroup>
                  </Fragment>
                }
              </div>
            :
            selectedElement === 'icons' ?
              <div>
                <FormGroup>
                  <DefaultInput 
                    placeholder={'إبحث'} 
                    value={iconsSearchKeyword}
                    onChange={(event) => setIconsSearchKeyword(event.target.value)}
                    size={'md'}
                  />
                </FormGroup>

                <IconsList 
                  filterValue={iconsSearchKeyword} 
                  onClick={(data) => addElement(data)} 
                />
              </div>
            :
              <div data-bliz-id={randomHex} className="blizbuilderMap" onClick={(event) => addElement(event.target.outerHTML)}>
                <iframe
                  width="100%"
                  height="200"
                  loading="lazy"
                  allowfullscreen
                  referrerpolicy="no-referrer-when-downgrade"
                  src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAdBgGjVe4AWbZEb3M8egoiF7FnP549Ok8&q=Center+Point,+King+Abdullah+Rd,+King+Fahd,+Riyadh+12271">
                </iframe>
              </div>
            }
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default BuilderAddElement;
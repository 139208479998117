import React, { Fragment, useEffect, useState } from "react";
import { Alert, Col, FormGroup, Label, Row, Tooltip } from "reactstrap";
import { PiQuestionFill } from "react-icons/pi";
import { DefaultInput } from "../../../../components/Forms/Inputs/default";
import { DefaultButton } from "../../../../components/Buttons/default";
import { PasswordInput } from "../../../../components/Forms/Inputs/Password";
import { API_LINK } from "../../../../utils/constants";
import { DefaultSelect } from "../../../../components/Forms/Inputs/Select";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { i18n } from "../../../../i18n";

const RegisterUserInfos = (props) => {
  const [usernameTooltipOpen, setUsernameTooltipOpen] = useState(false);
  const toggleUsernameTooltip = () => setUsernameTooltipOpen(!usernameTooltipOpen);
  const [ countriesData, setCountriesData ] = useState(null);
  const [ countriesLoading, setCountriesLoading ] = useState(true);

  useEffect(() => {
    fetch(API_LINK+'/countries')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((json) => {
        setCountriesLoading(false);
        setCountriesData(json.countries);
      })
      .catch((fetchError) => {
        setCountriesLoading(false);
      });
  }, []);

  return(
    <Row style={{height: '100vh'}} className="align-items-center">
      <Col lg="5" md="6" className="mx-auto">
        <div className="login-card bg-white p-5">
          <img src={ require('../../../../assets/brand-black.png') } className="mb-3" width={50} />
          <h4 className="fw-bold mb-5">{ i18n.t('auth.register.infos.title') }</h4>
          <FormGroup>
            <Label>{ i18n.t('auth.register.infos.form.phone_number') }: </Label>
            <Row className="align-items-center">
              <Col xs="9">
                <DefaultInput 
                  bsSize={'lg'} 
                  type="tel" 
                  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" 
                  dir="ltr" 
                  value={props.phone_number} 
                  onChange={(event) => props.handleChange('phone_number', event)} 
                  maxLength={'10'}
                  placeholder={'12-345-6789'}
                />
              </Col>
              <Col xs="3" className="ps-0">
                {countriesLoading ?
                  <div className="text-center">
                    <AiOutlineLoading3Quarters className="animation-spin h2" />
                  </div>
                :
                  <DefaultSelect dir="ltr" value={props.country_code} onChange={(event) => props.handleChange('country_code', event)} >
                    {countriesData && countriesData.length > 0 &&
                      countriesData.map((data, key) => {
                        return(
                          <option value={data.country_code} key={key}>{data.country_code}</option>
                        );
                      })
                    }
                  </DefaultSelect>
                }
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Label>
              { i18n.t('auth.register.infos.form.username') }: <PiQuestionFill className="cursor-pointer" id="TooltipExample" /> 
              <Tooltip
                isOpen={usernameTooltipOpen}
                target="TooltipExample"
                toggle={toggleUsernameTooltip}
              >
                { i18n.t('auth.register.infos.form.username_description') }
              </Tooltip>
            </Label>
            <DefaultInput 
              bsSize={'lg'} 
              type="text" 
              value={props.username}
              onChange={(event) => props.handleChange('username', event)}
            />
          </FormGroup>
          <FormGroup>
            <Label>{ i18n.t('auth.register.infos.form.firstname') }: </Label>
            <DefaultInput 
              bsSize={'lg'} 
              type="text" 
              value={props.firstname}
              onChange={(event) => props.handleChange('firstname', event)}
            />
          </FormGroup>
          <FormGroup>
            <Label>{ i18n.t('auth.register.infos.form.lastname') }: </Label>
            <DefaultInput 
              bsSize={'lg'} 
              type="text" 
              value={props.lastname}
              onChange={(event) => props.handleChange('lastname', event)}
            />
          </FormGroup>
          <FormGroup>
            <Label>{ i18n.t('auth.register.infos.form.password') }: </Label>
            <PasswordInput 
              bsSize={'lg'} 
              value={props.password}
              onChange={(event) => props.handleChange('password', event)}
            />
          </FormGroup>
          <FormGroup>
            <DefaultButton 
              size={'lg'} 
              type="submit" 
              color="dark"
              disabled={props.phone_number.length < 9 || props.username.length < 2 || props.firstname.length < 2 || props.lastname.length < 2 || !props.email.includes('@') || props.password.length < 8 || props.buttonLoading}
              isLoading={props.buttonLoading}
              onClick={() => props.register()}
              className={'rounded-pill px-5'}
            >
              { i18n.t('auth.register.infos.form.submit') }
            </DefaultButton>
          </FormGroup>

          {props.alertText &&
            <Alert className="bg-danger text-light border-danger rounded-0">{ props.alertText }</Alert>
          }
        </div>
      </Col>
    </Row>
  );
}

export default RegisterUserInfos;
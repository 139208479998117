import React from "react";
import { Alert, Col, Row } from "reactstrap";
import { DefaultInput } from "../../../../components/Forms/Inputs/default";
import { DefaultButton } from "../../../../components/Buttons/default";
import { i18n } from "../../../../i18n";

const RegisterEmailConfirmation = (props) => {
  return(
    <Row style={{height: '100vh'}} className="align-items-center">
      <Col lg="5" md="6" className="mx-auto">
        <div className="login-card bg-white p-5">
          <img src={ require('../../../../assets/brand-black.png') } className="mb-3" width={50} />

          <h4 className="fw-bold mb-2">{ i18n.t('auth.register.code.title') }</h4>
          <p className="text-muted mb-5">
            { i18n.t('auth.register.code.description') } {' '}
            <span dir="ltr">{props.email}</span>
          </p>
          <div className="mb-3">
            <DefaultInput 
              bsSize={'lg'} 
              type="text" 
              value={props.confirmation_code} 
              onChange={(event) => props.handleChange('confirmation_code', event)}
              maxLength={'6'} 
              dir="ltr" 
            />
          </div>
          <div className="mb-3">
            <DefaultButton 
              size={'lg'} 
              type="submit" 
              color="dark"
              onClick={props.onConfirm}
              disabled={!props.confirmation_code || props.confirmation_code.length < 6 || props.buttonLoading}
              isLoading={props.buttonLoading}
              className={'rounded-pill px-5'}
            >
              { i18n.t('auth.register.code.form.submit') }
            </DefaultButton>
          </div>
          <div className="mb-3 text-center">
            { i18n.t('auth.register.code.resend_description') }
            <a className="text-primary" onClick={props.onBack}> { i18n.t('auth.register.code.resend_button') }</a>
            <hr />
            <p className="text-center text-muted">
              { i18n.t('auth.register.code.wrong_email_description') } 
              <a className="text-primary" onClick={props.onBack}> { i18n.t('auth.register.code.wrong_email_button') }</a>
            </p>
          </div>

          {props.alertText &&
            <Alert className="bg-danger text-light border-danger rounded-0">{ props.alertText }</Alert>
          }
        </div>
      </Col>
    </Row>
  );
}

export default RegisterEmailConfirmation;
import React, { useEffect, useState } from "react";
import { DefaultModal } from "../../../components/Modals/Default";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { DefaultInput } from "../../../components/Forms/Inputs/default";
import { DefaultSelect } from "../../../components/Forms/Inputs/Select";
import { API_LINK } from "../../../utils/constants";
import { PasswordInput } from "../../../components/Forms/Inputs/Password";
import { i18n } from "../../../i18n";

export const UpdatePhoneNumberModal = (props) => {
  const [ countriesData, setCountriesData ] = useState(null);
  const [ countriesLoading, setCountriesLoading ] = useState(true);
  const [ updateLoading, setUpdateLoading ] = useState(false);
  const [ countryCode, setCountryCode ] = useState(props.countryCode);
  const [ phoneNumber, setPhoneNumber ] = useState(props.phoneNumber);
  const [ password, setPassword ] = useState(null);

  useEffect(() => {
    getCountries();
  }, []);

  const getCountries = () => {
    fetch(API_LINK+'/countries', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    })
    .then((response) => response.json())
    .then((json) => {
      setCountriesData(json.countries);
      setCountriesLoading(false);
    })
  }

  const update = () => {
    setUpdateLoading(true);
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/account/phone_number/update', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        country_code: countryCode,
        phone_number: phoneNumber,
        password: password
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        localStorage.setItem('user', JSON.stringify(json.user));
        props.setUserData();
        props.setAlert(json.message, 'success');
        props.toggle();
      } else {
        props.setAlert(json.message, 'danger');
      }
      setUpdateLoading(false);
    })
  }

  return(
    <DefaultModal
      isOpen={props.isOpen}
      title={ i18n.t('profile.advanced_settings.change_phone_number_modal.title') }
      confirmationText={i18n.t('modals.default.buttons.confirm')}
      cancelText={i18n.t('modals.default.buttons.close')}
      toggle={props.toggle}
      isLoading={updateLoading || countriesLoading}
      onConfirm={update}
    >
      <FormGroup>
        <Label>{ i18n.t('profile.advanced_settings.form.phone_number') }: </Label>
        <Row>
          <Col xs="8" lg="9">
            <DefaultInput value={phoneNumber} onChange={(event) => setPhoneNumber(event.target.value)} />
          </Col>
          <Col xs="4" lg="3">
            <DefaultSelect dir="ltr" value={countryCode} onChange={(event) => setCountryCode(event.target.value)}>
              {countriesData?.length > 0 &&
                countriesData.map((data, key) => {
                  return(
                    <option value={data.country_code} key={key}>{data.country_code}</option>
                  );
                })
              }
            </DefaultSelect>
          </Col>
        </Row>
      </FormGroup>
      <FormGroup>
        <Label>{ i18n.t('profile.security_settings.form.current_password') }: </Label>
        <PasswordInput 
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
      </FormGroup>
    </DefaultModal>
  );
}
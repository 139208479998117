import React, { Component } from "react";
import { Badge, Card, CardBody, CardHeader, CardTitle, Col, Container, FormGroup, Row } from "reactstrap";
import { withRouter } from "../../../../components/Router/withRouter";
import { API_LINK, MAIN_LINK } from "../../../../utils/constants";
import { AiOutlineDelete, AiOutlineLoading3Quarters } from "react-icons/ai";
import { BiEditAlt, BiEnvelope } from "react-icons/bi";
import { Link } from "react-router-dom";
import './styles.css';
import CRMContactNotes from "./Forms/Notes";
import { BottomLeftAlert, DefaultAlert } from "../../../../components/Alerts";
import { ConfirmationModal } from "../../../../components/Modals/Confirmation";
import { AdminBodyTitle } from "../../../../components/Typography/Titles";
import { setTaskPriority, setTaskStatus } from "../../../../utils/helpers";
import { DefaultButton } from "../../../../components/Buttons/default";
import SendEmailModal from "../../../../components/Modals/SendEmail";
import { i18n } from "../../../../i18n";

class CRMViewContactPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contactLoading: true,
      contactData: null,

      destroyLoading: false,
      destroyModalShown: false,

      sendEmailModalShown: false,

      informationsShown: true,
      addressShown: true,

      alertText: null
    }
  }

  componentDidMount() {
    this.getContact();
  }

  getContact() {
    this.setState({contactLoading: true});
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/crm/contact/'+this.props.params.contact, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        this.setState({
          contactData: json.contact,
          contactLoading: false
        });
      }
      else
      {
        this.setState({
          alertText: json.message,
          contactLoading: false
        });
      }
    })
  }

  destroy() {
    this.setState({destroyLoading: true});
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/crm/contact/destroy', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        id: [this.state.contactData.id]
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.props.navigate('/dashboard/crm/contacts');
      }
    })
  }

  render() {
    const s = this.state;

    if(s.contactLoading) {
      return(
        <Container className="text-center">
          <AiOutlineLoading3Quarters className="animation-spin h1" />
        </Container>
      );
    }

    return(
      <Container>
        <AdminBodyTitle>
          { s.contactData.firstname } { s.contactData.lastname }
        </AdminBodyTitle>

        <Row>
          <Col md="8">
            <Card className="mb-3">
              <CardBody>
                <Row>
                  <Col md="3" lg="2" sm="4" xs="5" className="d-flex align-items-center">
                    <div className="crm-contact-add-profile_picture">
                      <img src={ s.contactData.profile_picture_path ? MAIN_LINK+s.contactData.profile_picture_path : require('../../../../assets/default-user.png') } width={'100%'} height={'100%'} />
                    </div>
                  </Col>
                  <Col md="9" lg="10" sm="8" xs="7" className="d-flex align-items-center">
                    <div className="h5 fw-bold mb-0">
                      <Link to={'/dashboard/crm/contact/edit/'+s.contactData.id}>
                        <DefaultButton size={'md'} color={'secondary'} className="me-3">
                          <BiEditAlt />{' '}
                          { i18n.t('contact.actions.edit') }
                        </DefaultButton>
                      </Link>
                      <DefaultButton size={'md'} outline color={'dark'} className="me-3" onClick={() => this.setState({sendEmailModalShown: true})}>
                        <BiEnvelope />{' '}
                        { i18n.t('contact.actions.email') }
                      </DefaultButton>
                      <DefaultButton size={'md'} color={'danger'} onClick={() => this.setState({destroyModalShown: true})}>
                        <AiOutlineDelete />{' '}
                        { i18n.t('contact.actions.delete') }
                      </DefaultButton>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card className="mb-3">
              <CardHeader onClick={() => this.setState({informationsShown: !s.informationsShown})}>
                <CardTitle className="mb-0">{ i18n.t('contact.contact_informations_heading') }</CardTitle>
              </CardHeader>
              {s.informationsShown &&
                <CardBody>
                  <FormGroup>
                    <b>{ i18n.t('contact.fullname') }: </b> {s.contactData.firstname+' '+s.contactData.lastname}
                  </FormGroup>
                  <FormGroup>
                    <b>{ i18n.t('contact.email') }: </b> {s.contactData.email}
                  </FormGroup>
                  <FormGroup>
                    <b>{ i18n.t('contact.phone_number') }: </b> <span dir="ltr">{s.contactData.country_code}{s.contactData.phone_number}</span>
                  </FormGroup>
                  <FormGroup>
                    <b>{ i18n.t('contact.source') }: </b> {s.contactData.lead_source}
                  </FormGroup>
                  <FormGroup>
                    <b>{ i18n.t('contact.birthdate') }: </b> {s.contactData.birthdate}
                  </FormGroup>
                  <FormGroup>
                    <b>{ i18n.t('contact.description') }: </b> {s.contactData.description}
                  </FormGroup>
                </CardBody>
              }
            </Card>

            <Card className="mb-3">
              <CardHeader onClick={() => this.setState({addressShown: !s.addressShown})}>
                <CardTitle className="mb-0">{ i18n.t('contact.address') }</CardTitle>
              </CardHeader>
              {s.addressShown &&
                <CardBody>
                  <FormGroup>
                    <b>{ i18n.t('contact.country') }: </b> {s.contactData.country}
                  </FormGroup>
                  <FormGroup>
                    <b>{ i18n.t('contact.city') }: </b> {s.contactData.city}
                  </FormGroup>
                  <FormGroup>
                    <b>{ i18n.t('contact.address') }: </b> {s.contactData.address}
                  </FormGroup>
                  <FormGroup>
                    <b>{ i18n.t('contact.zipcode') }: </b> {s.contactData.zip_code}
                  </FormGroup>
                </CardBody>
              }
            </Card>

            <CRMContactNotes 
              notes={s.contactData.notes}
              contact={s.contactData}
              onFailedAdd={(error) => this.setState({alertText: error})}
            />
          </Col>
          <Col md="4">
            <Card>
              <CardHeader>
                <Row>
                  <Col md="6">
                    <CardTitle className="mb-0">المهام</CardTitle>
                  </Col>
                  <Col md="6" className="text-end">
                    <Link to={'/dashboard/crm/task/add'}>
                      <DefaultButton size={'sm'}>+ إضافة مهمة</DefaultButton>
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {s.contactData?.tasks?.length > 0 ?
                  s.contactData.tasks.map((data, key) => {
                    return(
                      <Link to={'/dashboard/crm/task/view/'+data.id}>
                        <div key={key} className="bg-light p-2 border-gray border mb-3">
                          <div className="h6 fw-bold">{data.title}</div>
                          <div className="h6 fw-bold">{data.date}</div>
                          <div>
                            <Badge className={setTaskStatus(data.status).bg}>{setTaskStatus(data.status).label}</Badge>
                          </div>
                          <div>
                            <Badge className={setTaskPriority(data.priority).bg}>{setTaskPriority(data.priority).label}</Badge>
                          </div>
                        </div>
                      </Link>
                    );
                  })
                :
                  <DefaultAlert color={'info'}>لا توجد مهام</DefaultAlert>
                }
              </CardBody>
            </Card>
          </Col>
        </Row>

        {s.alertText &&
          <BottomLeftAlert color={'danger'} onClose={() => this.setState({alertText: null})}>
            {s.alertText}
          </BottomLeftAlert>
        }

        {s.contactData && s.contactData.email &&
          <SendEmailModal 
            isOpen={s.sendEmailModalShown}
            toggle={() => this.setState({sendEmailModalShown: !s.sendEmailModalShown})}
            emails={[s.contactData.email]}
          />
        }

        <ConfirmationModal
          isOpen={s.destroyModalShown}
          confirmationText={'حذف'}
          cancelText={'إلغاء'}
          toggle={() => this.setState({destroyModalShown: false})}
          onConfirm={() => this.destroy()}
          isLoading={s.destroyLoading}
        >
          هل حقا تريد حذف هذا العميل؟
        </ConfirmationModal>
      </Container>
    );
  }
}

export default withRouter(CRMViewContactPage);
import React, { Component } from "react";
import { Container } from "reactstrap";
import { AdminBodyTitle } from "../../../../components/Typography/Titles";

class WebsitesDataOrdersPage extends Component {
  render() {
    return(
      <Container>
        <AdminBodyTitle>الطلبات</AdminBodyTitle>
      </Container>
    );
  }
}

export default WebsitesDataOrdersPage;
import React, { Component } from "react";
import { AiFillCamera, AiOutlineLoading3Quarters } from "react-icons/ai";
import { Card, CardBody, Col, Container, FormGroup, Label, Row } from "reactstrap";
import { DefaultInput } from "../../../../components/Forms/Inputs/default";
import { DefaultSelect } from "../../../../components/Forms/Inputs/Select";
import { DefaultButton } from "../../../../components/Buttons/default";
import { API_LINK, MAIN_LINK } from "../../../../utils/constants";
import { withRouter } from "../../../../components/Router/withRouter";
import { BottomLeftAlert } from "../../../../components/Alerts";
import { AdminBodyTitle } from "../../../../components/Typography/Titles";
import { i18n } from "../../../../i18n";

class CRMEditContactPage extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      contactLoading: false,
      updateLoading: false,
      alertText: null,

      firstname: '',
      lastname: '',
      email: '',
      country_code: '',
      phone_number: '',
      lead_source: '',
      birthdate: '',
      country: '',
      city: '',
      address: '',
      zip_code: '',
      profile_picture: '',
      profile_picture_path: '',
      description: ''
    }
  }

  componentDidMount() {
    this.getContact();
  }

  getContact() {
    this.setState({contactLoading: true});
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/crm/contact/'+this.props.params.contact, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        this.setState({
          firstname: json.contact.firstname || '',
          lastname: json.contact.lastname || '',
          email: json.contact.email || '',
          country_code: json.contact.country_code || '',
          phone_number: json.contact.phone_number || '',
          lead_source: json.contact.lead_source || '',
          birthdate: json.contact.birthdate || '',
          country: json.contact.country || '',
          city: json.contact.city || '',
          address: json.contact.address || '',
          zip_code: json.contact.zip_code || '',
          profile_picture_path: json.contact.profile_picture_path || '',
          description: json.contact.description || '',
          contactLoading: false
        });
      }
      else
      {
        this.setState({
          alertText: json.message,
          contactLoading: false
        });
      }
    })
  }

  update() {
    this.setState({updateLoading: true});
    const user = localStorage.getItem('user');

    let formData = new FormData();
    formData.append('profile_picture', this.state.profile_picture);
    formData.append('id', this.props.params.contact);
    formData.append('firstname', this.state.firstname);
    formData.append('lastname', this.state.lastname);
    formData.append('email', this.state.email);
    formData.append('country_code', this.state.country_code);
    formData.append('phone_number', this.state.phone_number);
    formData.append('lead_source', this.state.lead_source);
    formData.append('birthdate', this.state.birthdate);
    formData.append('country', this.state.country);
    formData.append('city', this.state.city);
    formData.append('address', this.state.address);
    formData.append('zip_code', this.state.zip_code);
    formData.append('description', this.state.description);

    fetch(API_LINK+'/crm/contact/update', {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: formData
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.props.navigate('/dashboard/crm/contacts');
      } else {
        this.setState({
          alertText: json.message
        });
      }

      this.setState({updateLoading: false});
    })
  }

  handleFileChange(event) {
    this.setState({
      profile_picture: event.target.files[0]
    });
  }

  handleChange(event) {
    const value = event.target.value;
    this.setState({
      ...this.state,
      [event.target.name]: value
    });
  }

  render() {
    const s = this.state;

    if(s.contactLoading) {
      return(
        <Container className="text-center">
          <AiOutlineLoading3Quarters className="animation-spin h1" />
        </Container>
      );
    }

    return(
      <Container>
        <AdminBodyTitle>
          { i18n.t('edit_contact.title') }
        </AdminBodyTitle>

        <Card className="mb-3">
          <CardBody>
            <Row>
              <Col md="3" lg="2" sm="4" xs="5" className="d-flex align-items-center">
                <div className="crm-contact-add-profile_picture" onClick={() => document.getElementById('pimage').click()}>
                  <div className="crm-contact-add-profile_picture-opacity">
                    <AiFillCamera className="text-white" size={'60'} />
                  </div>
                  <img src={ s.profile_picture ? URL.createObjectURL(s.profile_picture) : s.profile_picture_path ? MAIN_LINK+s.profile_picture_path : require('../../../../assets/default-user.png') } width={'100%'} height={'100%'} />
                  <DefaultInput 
                    type="file" 
                    id="pimage" 
                    name="image"
                    onChange={(event) => this.handleFileChange(event)}
                    accept="image/*" 
                    hidden
                  />
                </div>
              </Col>
              <Col md="9" lg="10" sm="8" xs="7" className="d-flex align-items-center">
                <div className="h5 fw-bold mb-0">{ i18n.t('add_contact.profile_picture') }</div>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card className="mb-5">
          <CardBody>
            <div className="fw-bold h4">{ i18n.t('add_contact.form.contact_informations_heading') }</div>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label>{ i18n.t('add_contact.form.firstname') }: </Label>
                  <DefaultInput 
                    name="firstname"
                    value={s.firstname}
                    onChange={(event) => this.handleChange(event)}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>{ i18n.t('add_contact.form.lastname') }: </Label>
                  <DefaultInput 
                    name="lastname"
                    value={s.lastname}
                    onChange={(event) => this.handleChange(event)}
                  />
                </FormGroup>
              </Col>

              <Col md="6">
                <FormGroup>
                  <Label>{ i18n.t('add_contact.form.email') }: </Label>
                  <DefaultInput 
                    type="email"
                    name="email"
                    value={s.email}
                    onChange={(event) => this.handleChange(event)}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>{ i18n.t('add_contact.form.phone_number') }: </Label>
                  <Row>
                    <Col xs="9">
                      <DefaultInput 
                        bsSize={'lg'} 
                        type="tel" 
                        pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" 
                        dir="ltr" 
                        maxLength={'10'}
                        placeholder={'12-345-6789'}
                        name="phone_number"
                        value={s.phone_number}
                        onChange={(event) => this.handleChange(event)}
                      />
                    </Col>
                    <Col xs="3">
                      <DefaultSelect dir="ltr" name="country_code" value={s.country_code} onChange={(event) => this.handleChange(event)}>
                        <option value="+966">+966</option>
                      </DefaultSelect>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>

              <Col md="6">
                <FormGroup>
                  <Label>{ i18n.t('add_contact.form.birthdate') }: </Label>
                  <DefaultInput 
                    type="date" 
                    name="birthdate"
                    value={s.birthdate}
                    onChange={(event) => this.handleChange(event)}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>{ i18n.t('add_contact.form.source') }: </Label>
                  <DefaultSelect 
                    name="lead_source"
                    value={s.lead_source}
                    onChange={(event) => this.handleChange(event)}
                  >
                    <option value="facebook">Facebook</option>
                    <option value="twitter">X (Twitter)</option> 
                    <option value="instagram">Instagram</option>
                    <option value="google">Google</option>
                    <option value="other">Other</option>
                  </DefaultSelect>
                </FormGroup>
              </Col>

              <Col md="12">
                <Label>{ i18n.t('add_contact.form.description') }: </Label>
                <DefaultInput 
                  type="textarea" 
                  name="description"
                  value={s.description}
                  onChange={(event) => this.handleChange(event)}
                />
              </Col>

              <hr className="mt-3" />
              <div className="fw-bold h4">{ i18n.t('add_contact.form.address_heading') }</div>

              <Col md="6">
                <FormGroup>
                  <Label>{ i18n.t('add_contact.form.country') }: </Label>
                  <DefaultInput 
                    name="country"
                    value={s.country}
                    onChange={(event) => this.handleChange(event)}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>{ i18n.t('add_contact.form.city') }: </Label>
                  <DefaultInput 
                    name="city"
                    value={s.city}
                    onChange={(event) => this.handleChange(event)}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>{ i18n.t('add_contact.form.address') }: </Label>
                  <DefaultInput 
                    name="address"
                    value={s.address}
                    onChange={(event) => this.handleChange(event)}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>{ i18n.t('add_contact.form.zipcode') }: </Label>
                  <DefaultInput 
                    name="zip_code"
                    value={s.zip_code}
                    onChange={(event) => this.handleChange(event)}
                  />
                </FormGroup>
              </Col>
            </Row>

            <FormGroup>
              <DefaultButton 
                block 
                color={'dark'} 
                disabled={!s.firstname || !s.lastname || s.addLoading}
                isLoading={s.updateLoading}
                onClick={() => this.update()}
              >
                { i18n.t('add_contact.form.submit') }
              </DefaultButton>
            </FormGroup>
          </CardBody>
        </Card>

        {s.alertText &&
          <BottomLeftAlert color={'danger'} onClose={() => this.setState({alertText: null})}>
            {s.alertText}
          </BottomLeftAlert>
        }
      </Container>
    );
  }
}

export default withRouter(CRMEditContactPage);
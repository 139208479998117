import React, { useEffect, useState } from "react";
import {$getRoot, $getSelection, $createParagraphNode, $createTextNode} from 'lexical';
import ExampleTheme from "./themes/ExampleTheme";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin';
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { TRANSFORMERS } from "@lexical/markdown";
import './styles.css';
import ActionsPlugin from "./plugins/ActionsPlugin.jsx";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";

const LoadHtmlPlugin = ({htmlString}) => {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    editor.update(() => {
      const parser = new DOMParser();
      const dom = parser.parseFromString(htmlString || '', 'text/html');
      const nodes = $generateNodesFromDOM(editor, dom);

      // Select the root
      $getRoot().select();

      // Insert them at a selection.
      const selection = $getSelection();
      selection.insertNodes(nodes);
    });
  }, [editor]);
  return null;
};

const TextEditor = (props) => {
  const  Placeholder = () => {
    return (
      <div className="editor-placeholder">
        أكتب بحرية هنا...
      </div>
    );
  }

  const handleChange = (editor) => {
    editor.update(() => {
      const editorState = editor.getEditorState();
      const jsonString = JSON.stringify(editorState);
  
      const htmlString = $generateHtmlFromNodes(editor, null);
      props.onChange(htmlString)
    });
  };

  const editorConfig = {
    namespace: 'bliz-editor',
    theme: ExampleTheme,
    // Handling of errors during update
    onError(error) {
      throw error;
    },
    // Any custom nodes go here
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      CodeNode,
      CodeHighlightNode,
      TableNode,
      TableCellNode,
      TableRowNode,
      AutoLinkNode,
      LinkNode
    ]
  };

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <div className="editor-container">
        <ToolbarPlugin />
        <div className="editor-inner">
          <RichTextPlugin
            contentEditable={<ContentEditable className="editor-input" />}
            placeholder={<Placeholder />}
          />
          <AutoFocusPlugin />
          <ListPlugin />
          <LinkPlugin />
          <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
          <CodeHighlightPlugin />
          <OnChangePlugin onChange={(editorState, editor) => handleChange(editor)}/>
        </div>
        <ActionsPlugin />
      </div>
      <LoadHtmlPlugin htmlString={props.value} />
    </LexicalComposer>
  );
}

export default TextEditor;
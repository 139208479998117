import React, { useState } from 'react';
import useScript from 'react-script-hook';
import PlacesAutocomplete from 'react-places-autocomplete';
import { DefaultInput } from './default';

const LocationSearchInput = ({ handleChange, handleSelect, address }) => {
  const [loading, error] = useScript({ src: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCXwu6rEsXoiOo3_Rcjd6mlk4xHtj_3Yg4&libraries=places' });

  if(loading) {
    return(
      <div>...</div>
    );
  }

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <DefaultInput
            {...getInputProps({
              placeholder: 'إبحث عن الموقع',
              className: 'location-search-input',
            })}
            size={'md'}
          />
          {suggestions && suggestions.length > 0 &&
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion, key) => {
                const style = {
                  backgroundColor: suggestion.active ? '#41b6e6' : '#fff',
                };
                return (
                  <div className="autocomplete-dropdown-item" {...getSuggestionItemProps(suggestion, { style })} key={key}>
                    {suggestion.description}
                  </div>
                );
              })}
            </div>
          }
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default LocationSearchInput;

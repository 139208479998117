import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import { AdminBodyTitle } from "../../components/Typography/Titles";
import './styles.css';

class AppsPage extends Component {
  render() {
    return(
      <Container>
        <AdminBodyTitle>متجر التطبيقات</AdminBodyTitle>

        <Row>
          <Col md="4" className="mb-3">
            <div class="app-card border" onClick={() => alert('سيكون هذا التطبيق متوفرا قريبا...')}>
              <div class="app-icon mb-3">
                <img src={ require('../../assets/icons/apps/google-analytics.png') } alt="App Icon" />
              </div>
              <div class="app-details">
                <div class="app-title">Google Analytics</div>
                <div class="app-description">
                  تتبع حركة زوارك داخل موقعك الإلكتروني
                </div>
              </div>
              <div class="app-footer mt-3">
                <span class="app-status">مجاني</span>
              </div>
            </div>
          </Col>

          <Col md="4" className="mb-3">
            <div class="app-card border" onClick={() => alert('سيكون هذا التطبيق متوفرا قريبا...')}>
              <div class="app-icon mb-3">
                <img src={ require('../../assets/icons/apps/live-chat.png') } alt="App Icon" />
              </div>
              <div class="app-details">
                <div class="app-title">LiveChat</div>
                <div class="app-description">
                  تواصل مع زوار موقعك مباشرة من لوحة التحكم
                </div>
              </div>
              <div class="app-footer mt-3">
                <span class="app-status">مجاني</span>
              </div>
            </div>
          </Col>

          <Col md="4" className="mb-3">
            <div class="app-card border" onClick={() => alert('سيكون هذا التطبيق متوفرا قريبا...')}>
              <div class="app-icon mb-3">
                <img src={ require('../../assets/icons/apps/sitemap.png') } alt="App Icon" />
              </div>
              <div class="app-details">
                <div class="app-title">Sitemap</div>
                <div class="app-description">
                  تحكم في ظهورة صفحات موقعك في محركات البحث
                </div>
              </div>
              <div class="app-footer mt-3">
                <span class="app-status">مجاني</span>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default AppsPage;
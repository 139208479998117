import { i18n } from "../i18n";

export const handleDirection = (language) => {
  if (language === 'ar') {
    document.body.dir = 'rtl';
  } else {
    document.body.dir = 'ltr';
  }
};

export const loadBootstrap = (language) => {
  const existingBootstrapLink = document.querySelector('link[data-bootstrap="true"]');
  if (existingBootstrapLink) {
    existingBootstrapLink.remove();
  }

  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.crossOrigin = 'anonymous';
  link.dataset.bootstrap = 'true';

  if (language === 'ar') {
    link.href = 'https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.rtl.min.css';
  } else {
    link.href = 'https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css';
  }

  const head = document.head;
  const firstStyleOrLink = head.querySelector('link[rel="stylesheet"], style');
  if (firstStyleOrLink) {
    head.insertBefore(link, firstStyleOrLink);
  } else {
    head.appendChild(link);
  }
};

export const loadCSS = (language) => {
  const existingLTRLink = document.querySelector('link[data-ltr="true"]');
  if (existingLTRLink) {
    existingLTRLink.remove();
  }

  if (language !== 'ar') {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = '/ltr.css';
    link.dataset.ltr = 'true';
    document.head.appendChild(link);
  }
};

i18n.on('languageChanged', handleDirection);
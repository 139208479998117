import React from "react";
import { BiHome, BiChart, BiCog } from 'react-icons/bi';
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import { BsSend } from 'react-icons/bs';
import { FiUsers } from 'react-icons/fi';
import { SiMarketo } from 'react-icons/si';
import { MdOutlinePayment, MdOutlineFolderOpen } from 'react-icons/md';
import { TbArrowsExchange } from "react-icons/tb";
import './styles.css';
import { SidebarItem } from "./Item";
import { useLocation } from "react-router-dom";
import { MenuData } from "./MenuData";
import { useWebsite } from "../../context/Website";
import { DefaultButton } from "../Buttons/default";
import { i18n } from "../../i18n";

export const Sidebar = ({ size, onClose }) => {
  const location = useLocation();
  const { website } = useWebsite();

  const data = [
    {
      name: i18n.t('navigation.home'),
      link: '/dashboard/home',
      icon: <BiHome />,
      childrens: []
    },
    website !== 'null' &&
    {
      name: i18n.t('navigation.reports.title'),
      link: '/dashboard/reports',
      icon: <BiChart />,
      childrens: [
        {
          name: i18n.t('navigation.reports.visites'),
          link: '/dashboard/reports/visites'
        }
      ]
    },
    MenuData(JSON.parse(website)?.category?.slug),
    website !== 'null' && 1 === 3 &&
    {
      name: 'المستخدمين',
      link: '/dashboard/users',
      icon: <FiUsers />,
      childrens: []
    },
    website !== 'null' &&
    {
      name: i18n.t('navigation.newsletter'),
      link: '/dashboard/newsletter',
      icon: <BsSend />,
      childrens: []
    },
    {
      name: i18n.t('navigation.crm.title'),
      link: '/dashboard/crm',
      icon: <SiMarketo />,
      childrens: [
        {
          name: i18n.t('navigation.crm.contacts'),
          link: '/dashboard/crm/contacts'
        },
        {
          name: i18n.t('navigation.crm.tasks'),
          link: '/dashboard/crm/tasks'
        },
        {
          name: i18n.t('navigation.crm.email'),
          link: '/dashboard/crm/webmail'
        }
      ]
    },
    website !== 'null' &&
    {
      name: i18n.t('navigation.marketing.title'),
      link: '/dashboard/marketing',
      icon: <TbArrowsExchange />,
      childrens: [
        {
          name: i18n.t('navigation.marketing.socialmedia'),
          link: '/dashboard/marketing/socialmedia'
        },
        {
          name: i18n.t('navigation.marketing.seo'),
          link: '/dashboard/marketing/seo'
        }
      ]
    },
    {
      name: i18n.t('navigation.payments.title'),
      link: '/dashboard/payments',
      icon: <MdOutlinePayment />,
      childrens: [
        {
          name: i18n.t('navigation.payments.invoices'),
          link: '/dashboard/payments/invoices'
        },
        {
          name: i18n.t('navigation.payments.links'),
          link: '/dashboard/payments/payment_links'
        }
      ]
    },
    website !== 'null' &&
    {
      name: i18n.t('navigation.settings'),
      link: '/dashboard/settings',
      icon: <BiCog />,
      childrens: []
    },
    {
      name: i18n.t('navigation.cms.title'),
      link: '/dashboard/cms',
      icon: <MdOutlineFolderOpen />,
      childrens: [
        {
          name: i18n.t('navigation.cms.files'),
          link: '/dashboard/cms/files'
        },
        {
          name: i18n.t('navigation.cms.collections'),
          link: '/dashboard/cms/collections'
        }
      ]
    },
    {
      name: i18n.t('navigation.apps'),
      link: '/dashboard/apps',
      icon: <AiOutlineAppstoreAdd />,
      childrens: []
    }
  ];

  return(
    <div className={"sidebar shadow-sm "+size} id="sidebar">
      <div className="sidebar-logo shadow-sm text-center">
        {size === 'toggled' ?
          <img src={ require('../../assets/brand-black.png') } height="40px" className="sidebar-brand-img" />
        :
          i18n.language === 'ar' ?
            <img src={ require('../../assets/logo-rtl-black.png') } height="50px" className="sidebar-logo-img" />
          :
            <img src={ require('../../assets/logo-ltr-black.png') } height="50px" className="sidebar-logo-img" />
        }
        
        {window.innerWidth <= 792 && 
          <DefaultButton size={'md'} outline color={'dark'} onClick={() => onClose()}>X</DefaultButton>
        }
      </div>
      <ul className="sidebar-elements px-3 mt-3" id="sidebarElements">
        {data.map((data, key) => {
          return(
            <SidebarItem 
              key={key}
              name={data.name}
              link={data.link}
              icon={data.icon}
              childrens={data.childrens}
              currentLocation={location.pathname}
            />
          );
        })}
      </ul>
    </div>
  );
}
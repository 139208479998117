import React, { Component, Fragment } from "react";
import { Badge, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Container, Row } from "reactstrap";
import { AdminBodyTitle } from "../../components/Typography/Titles";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { API_LINK } from "../../utils/constants";
import { MdClose, MdWebAssetOff } from "react-icons/md";
import { FaCheck } from "react-icons/fa6";
import { DefaultButton } from "../../components/Buttons/default";
import { Link } from "react-router-dom";
import { StatisticsCard } from "../../components/Cards/Statistics";
import { RiRadioButtonLine } from "react-icons/ri";
import { DefaultAlert } from "../../components/Alerts";
import { withRouter } from "../../components/Router/withRouter";
import './styles.css';

class SubscribtionPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subscribtionData: null,
      memory: null,
      subscribtionLoading: true
    }
  }

  componentDidMount() {
    this.getSubscribtion();
  }

  getSubscribtion() {    
    this.setState({subscribtionLoading: true});
    const user = localStorage.getItem('user');
    const website_id = JSON.parse(this.props.website.website)?.id;
    
    if(website_id) {      
      fetch(API_LINK+'/subscribtion/show/'+website_id, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+JSON.parse(user).token
        }
      })
      .then((response) => response.json())
      .then((json) => {
        if(json.success)
        {
          this.setState({
            subscribtionData: json.subscribtion,
            memory: json.memory,
            subscribtionLoading: false
          });
        }
        else
        {
          this.setState({
            subscribtionLoading: false
          });
        }
      })
    } else {
      this.props.navigate('/website/new');
    }
  }

  render() {
    const s = this.state;

    return(
      <Container>
        <AdminBodyTitle>الإشتراك</AdminBodyTitle>
        {s.subscribtionLoading ?
          <div className="text-center py-5">
            <AiOutlineLoading3Quarters className="animation-spin" size={40} />
          </div>
        :
          s.subscribtionData ?
            <Fragment>
              {s.memory >= s.subscribtionData.package.storage_space &&
                <DefaultAlert color={'danger'} className={'mb-3'}>
                  <Row className="align-items-center">
                    <Col md="9" lg="10">
                      لقد قمت بإستهلاك مساحة التخزين المتوفرة في حسابك، يرجى ترقية الباقة لتتمكن من إضافة بيانات جديدة
                    </Col>
                    <Col md="3" lg="2" className="text-end">
                      <Link to={'/dashboard/packages'}>
                        <DefaultButton size={'md'} color={'dark'}>ترقية الإشتراك</DefaultButton>
                      </Link>
                    </Col>
                  </Row>
                </DefaultAlert>
              }
              <Card className="mb-2 subscribtion-package-card">
                <CardHeader>
                  <CardTitle className="d-flex justify-content-between">
                    باقة {s.subscribtionData.package.name}
                    {(new Date(s.subscribtionData.end_date) < new Date()) &&
                      <Badge color="danger">منتهية</Badge>
                    }
                  </CardTitle>
                  <p className="mb-0 text-muted mt-2">{s.subscribtionData.package.description}</p>
                </CardHeader>
                <CardBody>
                  <div className="mb-2">
                    <b>تاريخ الإشتراك:</b> {s.subscribtionData.start_date}
                  </div>
                  <div>
                    <b>تنتهي يوم:</b> {s.subscribtionData.end_date}
                  </div>

                  <Row className="mt-3">
                    <Col md="4" className="mb-2">{ s.subscribtionData.package.custom_domaine ? <FaCheck /> : <MdClose /> } دومين خاص</Col>
                    <Col md="4" className="mb-2">{ s.subscribtionData.package.free_domaine ? <FaCheck /> : <MdClose /> } دومين مجاني</Col>
                    <Col md="4" className="mb-2">{ s.subscribtionData.package.remove_ads ? <FaCheck /> : <MdClose /> } حذف الإعلانات</Col>
                    <Col md="4" className="mb-2">{ s.subscribtionData.package.free_ssl ? <FaCheck /> : <MdClose /> } شهادة أمان مجانية</Col>
                    <Col md="4" className="mb-2"><b>{ s.subscribtionData.package.bandwidth }GB</b> باندويدتش</Col>
                    <Col md="4" className="mb-2"><b>{ s.subscribtionData.package.storage_space }GB</b> مساحة التخزين</Col>
                    <Col md="4" className="mb-2">{ s.subscribtionData.package.social_media_control ? <FaCheck /> : <MdClose /> } التحكم في مواقع التواصل</Col>
                    <Col md="4" className="mb-2">{ s.subscribtionData.package.accept_payments ? <FaCheck /> : <MdClose /> } بوابات الدفع</Col>
                    <Col md="4" className="mb-2">{ s.subscribtionData.package.marketing_tools ? <FaCheck /> : <MdClose /> } أدوات التسويق</Col>
                    <Col md="4" className="mb-2">{ s.subscribtionData.package.ai_helper ? <FaCheck /> : <MdClose /> } مساعد الذكاء الإصطناعي</Col>
                  </Row>
                </CardBody>
                {(new Date(s.subscribtionData.end_date) - 14 * 24 * 60 * 60 * 1000 <= new Date()) &&
                  <CardFooter>
                    <Link to={'/dashboard/packages'}>
                      <DefaultButton size={'md'} color={'dark'}>
                        تجديد الإشتراك
                      </DefaultButton>
                    </Link>
                  </CardFooter>
                }
              </Card>
              <hr />
              <AdminBodyTitle>الإستهلاك</AdminBodyTitle>
              <Row>
                <Col xs="6">
                  <StatisticsCard 
                    title={'مساحة التخزين'}
                    value={ s.memory+'/'+s.subscribtionData.package.storage_space+'GB' }
                    icon={<RiRadioButtonLine size={42} />}
                    isLoading={s.subscribtionLoading}
                  />
                </Col>
              </Row>
            </Fragment>
          :
            <div className="text-center py-5">
              <MdWebAssetOff size={150} color="#dedede" />
              <h5 className="text-muted mb-4">لا توجد بيانات</h5>
              <Link to={'/dashboard/packages'}>
                <DefaultButton size={'md'} color={'dark'}>
                  + إشتراك جديد
                </DefaultButton>
              </Link>
            </div>
        }
      </Container>
    );
  }
}

export default withRouter(SubscribtionPage);
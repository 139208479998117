import React from "react";
import useScript from 'react-script-hook';
import './styles.css';
import { generateRandomHex } from "../../utils/helpers";

const ClassesList = [
  'fa-brands fa-facebook',
  'fa-brands fa-facebook-messenger',
  'fa-brands fa-square-facebook',
  'fa-brands fa-facebook-f',
  'fa-brands fa-x-twitter',
  'fa-brands fa-square-x-twitter',
  'fa-brands fa-instagram',
  'fa-brands fa-tiktok',
  'fa-brands fa-linkedin',
  'fa-brands fa-linkedin-in',
  'fa-brands fa-github',
  'fa-brands fa-apple',
  'fa-brands fa-windows',
  'fa-brands fa-android',
  'fa-brands fa-paypal',
  'fa-brands fa-youtube',
  'fa-brands fa-figma',
  'fa-brands fa-slack',
  'fa-brands fa-google',
  'fa-brands fa-discord',
  'fa-brands fa-medium',
  'fa-brands fa-codepen',
  'fa-brands fa-whatsapp',
  'fa-brands fa-behance',
  'fa-brands fa-telegram',
  'fa-brands fa-pinterest',
  'fa-brands fa-cc-visa',
  'fa-brands fa-google-play',
  'fa-brands fa-amazon',
  'fa-brands fa-python',
  'fa-brands fa-skype',
  'fa-brands fa-linux',
  'fa-brands fa-playstation',
  'fa-brands fa-steam',
  'fa-brands fa-meta',

  'fa-solid fa-laptop',
  'fa-solid fa-laptop-code',
  'fa-solid fa-hashtag',
  'fa-solid fa-tornado',
  'fa-solid fa-magnifying-glass',
  'fa-solid fa-user',
  'fa-solid fa-users',
  'fa-solid fa-check',
  'fa-solid fa-download',
  'fa-solid fa-phone',
  'fa-solid fa-envelope',
  'fa-solid fa-star',
  'fa-solid fa-cart-shopping',
  'fa-solid fa-truck-fast',
  'fa-solid fa-arrow-up',
  'fa-solid fa-arrow-down',
  'fa-solid fa-arrow-right',
  'fa-solid fa-arrow-left',
  'fa-solid fa-pen',
  'fa-solid fa-trash',
  'fa-solid fa-inbox',
  'fa-solid fa-book',
  'fa-solid fa-barcode',
  'fa-solid fa-headphones',
  'fa-solid fa-print',
  'fa-solid fa-share',
  'fa-solid fa-hand',
  'fa-solid fa-code',
  'fa-solid fa-key',
  'fa-solid fa-truck',
  'fa-solid fa-globe',
  'fa-solid fa-person',
  'fa-solid fa-gamepad',
  'fa-solid fa-link',
  'fa-solid fa-fish',
  'fa-solid fa-shop',
  'fa-solid fa-city',
  'fa-solid fa-chart-simple',
  'fa-solid fa-chart-pie',
  'fa-solid fa-chart-line',
  'fa-solid fa-chart-gantt',
  'fa-solid fa-people-group',
  'fa-solid fa-bars',
  'fa-solid fa-wordpress',

  'fa-regular fa-user',
  'fa-regular fa-envelope',
  'fa-regular fa-star',
  'fa-regular fa-heart',
  'fa-regular fa-bell',
  'fa-regular fa-bookmark',
  'fa-regular fa-eye',
  'fa-regular fa-eye-slash',
  'fa-regular fa-hand',
  'fa-regular fa-folder',
  'fa-regular fa-folder-open',
  'fa-regular fa-thumbs-up',
  'fa-regular fa-thumbs-down',
  'fa-regular fa-moon',
  'fa-regular fa-clock',
  'fa-regular fa-circle',
  'fa-regular fa-map',
  'fa-regular fa-futbol',
  'fa-regular fa-face-smile',
  'fa-regular fa-address-card',
  'fa-regular fa-calendar-check',
  'fa-regular fa-file',
  'fa-regular fa-paste',
  'fa-regular fa-circle',
  'fa-regular fa-flag',
  'fa-regular fa-images',
];

const IconsList = (props) => {
  const [loading, error] = useScript({ src: 'https://kit.fontawesome.com/0a540f4b0c.js' });

  if (loading) {
    return (
      <div>...</div>
    );
  }

  const filteredIcons = ClassesList.filter(icon => icon.toLowerCase().includes(props.filterValue.toLowerCase()));

  return (
    <div className="builder-icons-container">
      {filteredIcons.map((data, key) => (
        <div className="builder-icon-container" key={key} onClick={() => props.onClick(props.onlyClass ? data : '<i data-bliz-id="'+generateRandomHex(8)+'" class="'+data+'"></i>')}>
          <i className={data}></i>
        </div>
      ))}
    </div>
  );
}

export default IconsList;
import React from "react";
import { Alert, Col, FormGroup, Row } from "reactstrap";
import { DefaultInput } from "../../../../components/Forms/Inputs/default";
import { DefaultButton } from "../../../../components/Buttons/default";
import { Link } from "react-router-dom";
import { i18n } from "../../../../i18n";

const RegisterEmail = (props) => {
  return(
    <Row style={{height: '100vh'}} className="align-items-center">
      <Col lg="5" md="6" className="mx-auto">
        <div className="login-card bg-white p-5">
          <img src={ require('../../../../assets/brand-black.png') } className="mb-3" width={50} />

          <h4 className="fw-bold mb-2">{ i18n.t('auth.register.email.title') }</h4>
          <p className="text-muted mb-5">
            { i18n.t('auth.register.email.description') }
          </p>
          <div className="mb-3">
            <label className="form-label">{ i18n.t('auth.register.email.form.email_label') }: </label>
            <DefaultInput 
              bsSize={'lg'} 
              type="email" 
              dir="ltr" 
              value={props.email} 
              onChange={(event) => props.handleChange('email', event)} 
              placeholder={'example@bliz.one'}
            />
          </div>
          <div className="mb-3">
            <DefaultButton 
              size={'lg'} 
              type="submit" 
              color="dark"
              onClick={props.onConfirm}
              disabled={props.buttonLoading}
              isLoading={props.buttonLoading}
              className={'rounded-pill px-5'}
            >
              { i18n.t('auth.register.email.form.submit') }
            </DefaultButton>
          </div>
          <FormGroup className="text-center mt-4">
            <Link to={'/login'}>{ i18n.t('auth.register.email.login') }</Link>
          </FormGroup>

          {props.alertText &&
            <Alert className="bg-danger text-light border-danger rounded-0">{ props.alertText }</Alert>
          }
        </div>
      </Col>
    </Row>
  );
}

export default RegisterEmail;
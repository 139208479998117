import React from "react";
import { LiaTimesSolid } from "react-icons/lia";
import "./styles.css";
import { useTranslation } from "react-i18next";

const LanguagesModal = (props) => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    window.location.reload();
  };

  return(
    <div className="modal-languages">
      <div className="container">
        <div className="modal-languages-close-btn" onClick={props.toggle}>
          <LiaTimesSolid size={30} />
        </div>

        <div className="modal-languages-body">
          <h3 className="fw-bold">Your language</h3>
          <ul>
            <li className={i18n.language === "ar" ? "active" : ""}><a onClick={() => changeLanguage("ar")}>العربية</a></li>
            <li className={i18n.language === "fr" ? "active" : ""}><a onClick={() => changeLanguage("fr")}>Français</a></li>
            <li className={i18n.language === "en" ? "active" : ""}><a onClick={() => changeLanguage("en")}>English</a></li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default LanguagesModal;
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Alert, Col, Container, FormGroup, Label, Row } from "reactstrap";
import { DefaultButton } from "../../../components/Buttons/default";
import { DefaultInput } from "../../../components/Forms/Inputs/default";
import { WebsiteContext } from "../../../context/Website";
import { CiGlobe } from "react-icons/ci";
import "./index.css"
import { PasswordInput } from "../../../components/Forms/Inputs/Password";
import { API_LINK } from "../../../utils/constants";
import { withRouter } from "../../../components/Router/withRouter";
import { i18n } from "../../../i18n";
import LanguagesModal from "../../../components/Modals/Languages";

class LoginPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      changeLanguageModalShown: false,

      email: '',
      password: '',
      validateEmail: false,

      alertText: null
    }
  }

  handleChange(name, event) {
    this.setState({
      ...this.state,
      [name]: event.target.value
    })
  }

  handleEmailChange(event) {
    this.setState({
      email: event.target.value
    }, () => {
      const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
      this.setState({
        validateEmail: emailRegex.test(this.state.email)
      });
    });
  }

  static contextType = WebsiteContext;

  login() {
    this.setState({isLoading: true});
    fetch(API_LINK+'/login', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: this.state.email,
        password: this.state.password
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        localStorage.setItem('user', JSON.stringify(json.user));

        // store website
        if(json.website) {
          localStorage.setItem('website', JSON.stringify(json.website));
        }

        this.setState({isLoading: false});
        this.props.onLogin();
        const { updateWebsite } = this.context;

        const newWebsiteValue = json.website;
        updateWebsite(newWebsiteValue);

        this.props.navigate('/dashboard/home');
      }
      else
      {
        this.setState({
          isLoading: false,
          alertText: json.message
        });
      }
    })
  }

  handleKeyDown(event) {
    if (event.key === 'Enter') {
      const { email, password, isLoading, validateEmail } = this.state;
      if (email && password && password.length >= 8 && validateEmail && !isLoading) {
        this.login();
      }
    }
  }

  render() {
    const s = this.state;

    return(
      <Container>
        <Row style={{height: '100vh'}} className="align-items-center">
          <Col lg="5" md="6" className="mx-auto">
            <div className="login-card bg-white p-5">
              <div className="row align-items-center mb-3">
                <div className="col-8">
                  <img src={ require('../../../assets/brand-black.png') } width={50} />
                </div>
                <div className="col-4 text-end">
                  <CiGlobe 
                    size={26} 
                    onClick={() => this.setState({changeLanguageModalShown: true})} 
                    style={{cursor: "pointer"}}
                  />
                </div>
              </div>

              <h4 className="fw-bold mb-5">{ i18n.t('auth.login.title') }</h4>
              <FormGroup>
                <Label>{ i18n.t('auth.login.form.email') }: </Label>
                <DefaultInput 
                  bsSize={'lg'} 
                  type="email" 
                  value={s.email} 
                  onChange={(event) => this.handleEmailChange(event)} 
                  onKeyUp={(event) => this.handleKeyDown(event)}
                />
              </FormGroup>
              <FormGroup>
                <Label>{ i18n.t('auth.login.form.password') }: </Label>
                <PasswordInput 
                  bsSize={'lg'} 
                  value={s.password} 
                  onChange={(event) => this.handleChange('password', event)} 
                  onKeyUp={(event) => this.handleKeyDown(event)}
                />
                <Row>
                  <Col xs="6"></Col>
                  <Col xs="6" className="text-end">
                    <Link to="/password/recovery" className="text-danger" style={{fontSize: 12}}>{ i18n.t('auth.login.form.password_recovery') }</Link>
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <DefaultButton 
                  size={'lg'} 
                  type="submit" 
                  color="dark" 
                  disabled={!s.email || !s.password || s.password.length < 8 || !s.validateEmail || s.isLoading}
                  onClick={() => this.login()}
                  isLoading={s.isLoading}
                  className={'rounded-pill px-5'}
                >
                  { i18n.t('auth.login.form.submit') }
                </DefaultButton>
              </FormGroup>
              <FormGroup className="text-center mt-4">
                <Link to={'/register'}>{ i18n.t('auth.login.register') }</Link>
              </FormGroup>
              
              {s.alertText &&
                <Alert className="bg-danger text-light border-danger rounded-0">{ s.alertText }</Alert>
              }
            </div>
          </Col>
        </Row>

        {s.changeLanguageModalShown &&
          <LanguagesModal 
            toggle={() => this.setState({changeLanguageModalShown: false})}
          />
        }
      </Container>
    );
  }
}

export default withRouter(LoginPage);
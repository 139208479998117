import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Container, FormGroup, Label, Row } from "reactstrap";
import { DefaultInput } from "../../../components/Forms/Inputs/default";
import { DefaultButton } from "../../../components/Buttons/default";
import { API_LINK, MAIN_LINK } from "../../../utils/constants";
import { withRouter } from "../../../components/Router/withRouter";
import { BottomLeftAlert } from "../../../components/Alerts";
import { WebsiteContext } from "../../../context/Website";

class CreateWebsiteInfos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addLoading: false,

      name: null,
      email: null,
      phone_number: null,
      address: null,
      description: null,

      alertText: null
    }
  }

  store() {
    this.setState({addLoading: true});
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/website/store', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        template_id: this.props.params.template,
        name: this.state.name,
        email: this.state.email,
        phone_number: this.state.phone_number,
        address: this.state.address,
        description: this.state.description
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.setWebsite(json.website);
        this.props.navigate('/dashboard/packages');
      } else {
        this.setState({
          alertText: json.message
        });
      }

      this.setState({addLoading: false});
    })
  }

  static contextType = WebsiteContext;

  setWebsite(website) {
    const { updateWebsite } = this.context;

    const newWebsiteValue = website;
    updateWebsite(newWebsiteValue);

    this.props.navigate('/dashboard/home');
  };

  handleChange(event) {
    const value = event.target.value;
    this.setState({
      ...this.state,
      [event.target.name]: value
    });
  }

  render() {
    const s = this.state;

    return(
      <div className="page-auth">
        <header className="py-3 bg-transparent">
          <Container>
            <Row>
              <Col xs="6">
                <div>
                  <a href={MAIN_LINK}>
                    <img src={ require('../../../assets/logo-rtl-black.png') } width="100px" />
                  </a>
                </div>
              </Col>
              <Col xs="6" className="text-end">
                <Link to="/register">تسجيل الخروج</Link>
              </Col>
            </Row>
          </Container>
        </header>

        <main>
          <Container className="mb-5 pb-5 pt-5">
            <h4 className="fw-bold text-center mb-5">أخبرنا بالمزيد حول موقعك الإلكتروني</h4>
            <Row>
              <Col md="4" className="offset-md-4">
                <FormGroup>
                  <Label>إسم الموقع: </Label>
                  <DefaultInput 
                    bsSize={'lg'} 
                    name="name"
                    value={s.name}
                    onChange={(event) => this.handleChange(event)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>بريد الموقع: </Label>
                  <DefaultInput 
                    bsSize={'lg'} 
                    name="email"
                    value={s.email}
                    onChange={(event) => this.handleChange(event)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>رقم الهاتف: </Label>
                  <DefaultInput 
                    bsSize={'lg'} 
                    name="phone_number"
                    value={s.phone_number}
                    onChange={(event) => this.handleChange(event)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>العنوان: </Label>
                  <DefaultInput 
                    bsSize={'lg'} 
                    name="address"
                    value={s.address}
                    onChange={(event) => this.handleChange(event)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>وصف الموقع: </Label>
                  <DefaultInput 
                    type={'textarea'} 
                    bsSize={'lg'} 
                    name="description"
                    value={s.description}
                    onChange={(event) => this.handleChange(event)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Container>

          <div className="website-create-footer shadow-sm">
            <Container>
              <Row>
                <Col md="6">
                </Col>
                <Col md="6" className="text-end">
                  <DefaultButton 
                    color="light" 
                    className="text-danger me-3 px-5 rounded-pill"
                    onClick={() => this.props.navigate(-1)}
                  >
                    رجوع
                  </DefaultButton>

                  <DefaultButton 
                    color="dark" 
                    className="px-5 rounded-pill"
                    isLoading={s.addLoading}
                    disabled={!s.name || s.addLoading}
                    onClick={() => this.store()}
                  >
                    التالي
                  </DefaultButton>
                </Col>
              </Row>
            </Container>
          </div>
        </main>

        {s.alertText &&
          <BottomLeftAlert color={'danger'} onClose={() => this.setState({alertText: null})}>{s.alertText}</BottomLeftAlert>
        }
      </div>
    );
  }
}

export default withRouter(CreateWebsiteInfos);
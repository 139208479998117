import React, { Component } from "react";
import { Container } from "reactstrap";
import { withRouter } from "../../components/Router/withRouter";
import { AdminBodyTitle } from "../../components/Typography/Titles";

class UsersPage extends Component {
  render() {
    return(
      <Container>
        <AdminBodyTitle>المستخدمين</AdminBodyTitle>
      </Container>
    );
  }
}

export default UsersPage;
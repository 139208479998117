import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useWebsite } from '../../context/Website';

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const navigate = useNavigate();
    const website = useWebsite();
    const location = useLocation();
    
    return (
      <Component
        navigate={navigate}
        params={useParams()}
        website={website}
        location={location}
        {...props}
      />
    );
  };
  
  return Wrapper;
};
import React, { Fragment, useEffect, useState } from "react";
import useScript from 'react-script-hook';
import { ButtonGroup, Col, FormGroup, Input, InputGroup, Label, Row } from "reactstrap";
import { DefaultInput } from "../../../components/Forms/Inputs/default";
import { DefaultButton } from "../../../components/Buttons/default";
import { useWebsite } from "../../../context/Website";
import { API_LINK, MAIN_LINK } from "../../../utils/constants";
import { FaImage } from "react-icons/fa6";
import LocationSearchInput from "../../../components/Forms/Inputs/LocationSearch";
import { addBlizIdToTags, getQueryParamValue, isURL, updateQueryParam } from "../../../utils/helpers";
import BuilderImagesModal from "../Modals/Images";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { DefaultModal } from "../../../components/Modals/Default";
import IconsList from "../../../components/Icons";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { i18n } from "../../../i18n";

const EditElementModal = (props) => {
  const [iconsLoading, iconsError] = useScript({ src: 'https://kit.fontawesome.com/0a540f4b0c.js' });
  const website = useWebsite();
  const [iconsModalShown, setIconsModalShown] = useState(false);
  const [iconsFilterValue, setIconsFilterValue] = useState('');
  const [clonedData, setClonedData] = useState(props.data);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [fontSizeUnit, setFontSizeUnit] = useState('px');
  const [widthUnit, setWidthUnit] = useState('px');
  const [heightUnit, setHeightUnit] = useState('px');
  const [edited, setEdited] = useState(false);
  const [mapSrc, setMapSrc] = useState(props.data.src);
  const [galleryModalShown, setGalleryModalShown] = useState(false);

  const parser = new DOMParser();
  const doc = parser.parseFromString(props.data.content, 'text/html');
  let newContent = '';
  if(doc.querySelectorAll('p').length > 1) {
    newContent = addBlizIdToTags(props.data.content);
  } else {
    newContent = addBlizIdToTags(doc.querySelector('p')?.innerHTML?.trim()) || addBlizIdToTags(props.data.content);
  }

  const getMessageTypeString = (tag) => {
    if(tag.toLowerCase() === 'img') {
      return 'editImage';
    } else if(tag.toLowerCase() === 'iframe') {
      if(props.data.iframeFor === 'video') {
        return 'updateIframeVideo';
      } else {
        return 'updateMap';
      }
    } else if(tag.toLowerCase() === 'i') {
      return 'updateIcon'
    } else if (tag.toLowerCase() === 'input' || tag.toLowerCase() === 'textarea') {
      return 'updateInput';
    } else {
      return 'updateElement';
    }
  }

  const revertSrc = (src) => {    
    if(isURL(src)) {
      if(src.includes('https://youtube.com') || src.includes('https://www.youtube.com')) {
        return src.replace('embed/', 'watch?v=');
      } else if(src.includes('vimeo.com')) {
        return src;
      } else if(src.includes('facebook.com')) {
        return getQueryParamValue(src, 'href');
      } else if(src.includes('twitch.tv')) {
        const videoParam = getQueryParamValue(src, 'video');
        if(videoParam) {
          return 'https://www.twitch.tv/videos/'+videoParam
        }
      }
    }
  }

  const processVideoSrc = (src) => {
    if(isURL(src)) {
      if(src.includes('https://youtube.com') || src.includes('https://www.youtube.com')) {
        return src.replace('watch?v=', 'embed/');
      } else if(src.includes('vimeo.com')) {
        const videoIdMatch = src.match(/vimeo\.com\/(?:channels\/[a-zA-Z0-9]+\/)?(\d+)/);
        if (videoIdMatch && videoIdMatch[1]) {
          const videoId = videoIdMatch[1];
          return `https://player.vimeo.com/video/${videoId}`;
        }
      } else if(src.includes('facebook.com')) {
        return `https://www.facebook.com/plugins/video.php?href=${encodeURIComponent(src)}&show_text=0&width=560`;
      } else if(src.includes('twitch.tv')) {
        const videoMatch = src.match(/twitch\.tv\/videos\/(\d+)/);
        if (videoMatch && videoMatch[1]) {
          const videoId = videoMatch[1];
          return `https://player.twitch.tv/?video=${videoId}&parent=bliz.one`;
        }
      }
    }
  }

  const updateIframe = () => {
    const iframe = document.getElementById('builder-iframe');
    const message = {
      type: getMessageTypeString(props.data.tagName),
      blizId: props.data.blizId,
      sectionIndex: props.data.parentInfo.sectionIndex,
      sectionTag: props.data.parentInfo.tagName,
      newElement: props.data.element,
      newContent: newContent,
      placeholder: props.data.placeholder,
      tag: props.data.tagName,
      backgroundColor: props.data.backgroundColor,
      color: props.data.textColor,
      font_size: props.data.fontSize?.replace('px', '')+fontSizeUnit,
      textAlign: props.data.textAlign,
      link: props.data.link,
      paddingTop: props.data.paddingTop?.includes('px') ? props.data.paddingTop : props.data.paddingTop+fontSizeUnit,
      paddingRight: props.data.paddingRight?.includes('px') ? props.data.paddingRight : props.data.paddingRight+fontSizeUnit,
      paddingLeft: props.data.paddingLeft?.includes('px') ? props.data.paddingLeft : props.data.paddingLeft+fontSizeUnit,
      paddingBottom: props.data.paddingBottom?.includes('px') ? props.data.paddingBottom : props.data.paddingBottom+fontSizeUnit,
      marginTop: props.data.marginTop?.includes('px') ? props.data.marginTop : props.data.marginTop+fontSizeUnit,
      marginRight: props.data.marginRight?.includes('px') ? props.data.marginRight : props.data.marginRight+fontSizeUnit,
      marginLeft: props.data.marginLeft?.includes('px') ? props.data.marginLeft : props.data.marginLeft+fontSizeUnit,
      marginBottom: props.data.marginBottom?.includes('px') ? props.data.marginBottom : props.data.marginBottom+fontSizeUnit,
      width: props.data.width?.includes('px') ? props.data.width : props.data.width === 'auto' ? 'auto' : props.data.width+widthUnit,
      height: props.data.height?.includes('px') ? props.data.height : props.data.height === 'auto' ? 'auto' : props.data.height+heightUnit,
      src: props.data.iframeFor === 'video' ? processVideoSrc(props.data.src) : props.data.src,
      borderRadius: props.data.borderRadius?.includes('px') ? (extractNumberAndUnit(props.data.borderRadius).number)+'px' : (props.data.borderRadius)+'px',
      borderSize: props.data.borderSize ? props.data.borderSize?.includes('px') ? extractNumberAndUnit(props.data.borderSize).number+'px' : props.data.borderSize+'px' : '0px',
      borderColor: props.data.borderColor,
      className: props.data.className
    };

    iframe.contentWindow.postMessage(message, '*');
  }

  useEffect(() => {
    updateIframe();
  }, [clonedData, fontSizeUnit, widthUnit, heightUnit]);

  useEffect(() => {
    updateBackend();
  }, [props.needUpdate]);

  const getApiLink = (tag) => {
    if(tag.toLowerCase() === 'img') {
      return API_LINK+'/builder/section/image/update';
    } else if(tag.toLowerCase() === 'iframe') {
      if(props.data.iframeFor === 'video') {
        return API_LINK+'/builder/section/iframevideo/update';
      } else {
        return API_LINK+'/builder/section/map/update';
      }
    } else if(tag.toLowerCase() === 'i') {
      return API_LINK+'/builder/section/icon/update';
    } else if(tag.toLowerCase() === 'input' || tag.toLowerCase() === 'textarea') {
      return API_LINK+'/builder/section/input/update';
    } else {
      return API_LINK+'/builder/section/element/update';
    }
  }

  const updateBackend = (action = null) => {
    setUpdateLoading(true);

    const user = localStorage.getItem('user');
    const LINK = getApiLink(props.data.tagName);

    fetch(LINK, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        website_id: JSON.parse(website.website).id,
        page_id: props.selectedPage.id,
        lang: props.selectedLanguage.language.short,
        parentIndex: props.data.parentInfo.sectionIndex,
        blizId: props.data.blizId,
        parent_tag: props.data.parentInfo.tagName,
        content: newContent,
        placeholder: props.data.placeholder,
        tag: props.data.tagName,
        backgroundColor: props.data.backgroundColor,
        color: props.data.textColor,
        fontSize: props.data.fontSize?.replace('px', '')+fontSizeUnit,
        textAlign: props.data.textAlign,
        link: props.data.link,
        paddingTop: props.data.paddingTop?.includes('px') ? props.data.paddingTop : props.data.paddingTop+fontSizeUnit,
        paddingRight: props.data.paddingRight?.includes('px') ? props.data.paddingRight : props.data.paddingRight+fontSizeUnit,
        paddingLeft: props.data.paddingLeft?.includes('px') ? props.data.paddingLeft : props.data.paddingLeft+fontSizeUnit,
        paddingBottom: props.data.paddingBottom?.includes('px') ? props.data.paddingBottom : props.data.paddingBottom+fontSizeUnit,
        marginTop: props.data.marginTop?.includes('px') ? props.data.marginTop : props.data.marginTop+fontSizeUnit,
        marginRight: props.data.marginRight?.includes('px') ? props.data.marginRight : props.data.marginRight+fontSizeUnit,
        marginLeft: props.data.marginLeft?.includes('px') ? props.data.marginLeft : props.data.marginLeft+fontSizeUnit,
        marginBottom: props.data.marginBottom?.includes('px') ? props.data.marginBottom : props.data.marginBottom+fontSizeUnit,
        width: props.data.width?.includes('px') ? props.data.width : props.data.width+widthUnit,
        height: props.data.height?.includes('px') ? props.data.height : props.data.height+heightUnit,
        src: props.data.iframeFor === 'video' ? processVideoSrc(props.data.src) : props.data.src,
        borderRadius: props.data.borderRadius?.includes('px') ? (extractNumberAndUnit(props.data.borderRadius).number)+'px' : (props.data.borderRadius)+'px',
        borderSize: props.data.borderSize ? props.data.borderSize?.includes('px') ? extractNumberAndUnit(props.data.borderSize).number+'px' : props.data.borderSize+'px' : '0px',
        borderColor: props.data.borderColor,
        className: props.data.className
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        if(action === 'close') {
          props.toggle();
        }
        setClonedData(props.data);
      }

      setUpdateLoading(false);
    })
  }

  const extractNumberAndUnit = (value) => {
    const matches = value?.match(/([\d.]+)(\D+)/);
    if (matches) {
      return {
        number: Number(matches[1]),
        unit: matches[2].trim()
      };
    }
    return null;
  }

  useEffect(() => {
    setFontSizeUnit(extractNumberAndUnit(props.data.fontSize)?.unit || 'px');
  }, []);

  const handleClonedDataChange = (event) => {
    const { name, value } = event.target;

    setClonedData(prevState => ({
      ...prevState,
      [name]: value,
    }));

    props.handleChange(event);

    setEdited(!edited);
  }

  const handleAddressChange = (value) => {
    setClonedData(prevState => ({
      ...prevState,
      'src': updateQueryParam(props.data.src, 'q', value),
    }));

    setMapSrc(value);

    props.handleChange(
      {target: {
        name: 'src',
        value: updateQueryParam(props.data.src, 'q', value)
      }}
    );

    setEdited(!edited);
  }

  const handleImageChange = (image) => {
    setClonedData(prevState => ({
      ...prevState,
      'src': image.largeImageURL || MAIN_LINK+image.file_url,
    }));

    props.handleChange(
      {target: {
        name: 'src',
        value: image.largeImageURL || MAIN_LINK+image.file_url
      }}
    );

    setEdited(!edited);
    setGalleryModalShown(false);
  }

  const handleDeleteImage = () => {
    setUpdateLoading(true);
    const iframe = document.getElementById('builder-iframe');
    const message = {
      type: 'deleteImage',
      sectionIndex: props.data.parentInfo.sectionIndex,
      blizId: props.data.blizId
    };

    iframe.contentWindow.postMessage(message, '*');

    const user = localStorage.getItem('user');

    fetch(API_LINK+'/builder/section/image/remove', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        website_id: JSON.parse(website.website).id,
        page_id: props.selectedPage.id,
        lang: props.selectedLanguage.language.short,
        sectionIndex: props.data.parentInfo.sectionIndex,
        blizId: props.data.blizId
      })
    })
    .then((response) => response.json())
    .then((json) => {
      setUpdateLoading(false);
      props.toggle();
    })
  }

  const handleDeleteMap = () => {
    setUpdateLoading(true);
    const iframe = document.getElementById('builder-iframe');
    const message = {
      type: 'deleteMap',
      sectionIndex: props.data.parentInfo.sectionIndex,
      blizId: props.data.blizId
    };

    iframe.contentWindow.postMessage(message, '*');

    const user = localStorage.getItem('user');

    fetch(API_LINK+'/builder/section/map/remove', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        website_id: JSON.parse(website.website).id,
        page_id: props.selectedPage.id,
        lang: props.selectedLanguage.language.short,
        sectionIndex: props.data.parentInfo.sectionIndex,
        blizId: props.data.blizId
      })
    })
    .then((response) => response.json())
    .then((json) => {
      setUpdateLoading(false);
      props.toggle();
    })
  }

  const handleDeleteIframeVideo = () => {
    setUpdateLoading(true);
    const iframe = document.getElementById('builder-iframe');
    const message = {
      type: 'deleteIframeVideo',
      blizId: props.data.blizId
    };

    iframe.contentWindow.postMessage(message, '*');

    const user = localStorage.getItem('user');

    fetch(API_LINK+'/builder/section/iframevideo/remove', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        website_id: JSON.parse(website.website).id,
        page_id: props.selectedPage.id,
        lang: props.selectedLanguage.language.short,
        sectionIndex: props.data.parentInfo.sectionIndex,
        blizId: props.data.blizId
      })
    })
    .then((response) => response.json())
    .then((json) => {
      setUpdateLoading(false);
      props.toggle();
    })
  }
  
  const handleDeleteIcon = () => {
    setUpdateLoading(true);
    const iframe = document.getElementById('builder-iframe');
    const message = {
      type: 'deleteIcon',
      sectionIndex: props.data.parentInfo.sectionIndex,
      blizId: props.data.blizId
    };

    iframe.contentWindow.postMessage(message, '*');

    const user = localStorage.getItem('user');

    fetch(API_LINK+'/builder/section/icon/remove', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        website_id: JSON.parse(website.website).id,
        page_id: props.selectedPage.id,
        lang: props.selectedLanguage.language.short,
        sectionIndex: props.data.parentInfo.sectionIndex,
        blizId: props.data.blizId
      })
    })
    .then((response) => response.json())
    .then((json) => {
      setUpdateLoading(false);
      props.toggle();
    })
  }  

  return(
    <div className="builder-edit-container">
      <div className="builder-add-header p-3 border-bottom">
        <Row className="align-items-center">
          <Col md="8" xs="8">
            <h6 className="fw-bold mb-0">{ i18n.t('builder.sidebar.titles.edit_element') }</h6>
          </Col>
          <Col md="4" xs="4" className="text-end">
            <DefaultButton 
              size={'sm'} 
              color={'dark'} 
              onClick={() => updateBackend('close')}
              isLoading={updateLoading}
            >
              { i18n.t('builder.sidebar.save') }
            </DefaultButton>
          </Col>
        </Row>
      </div>
      <div className="builder-add-body px-3 pt-3">
        {props.data.tagName?.toLowerCase()  === 'img' ?
          <FormGroup>
            <Label>الصورة: </Label>
            <div className="builder-add-image-picker position-relative mb-1">
              <img 
                src={props.data.src?.includes('http') ? props.data.src : MAIN_LINK+props.data.src} 
                alt="" 
                width={'100%'} 
                className="border" 
              />
              <div 
                className="builder-add-overlay align-items-center justify-content-center" 
                onClick={() => setGalleryModalShown(true)}
              >
                <FaImage size={80} color="#fff" />
              </div>
            </div>
            <DefaultButton 
              size={'sm'} 
              outline 
              color={'danger'} 
              className={'mt-3'} 
              block 
              onClick={() => handleDeleteImage()}
            >
              { i18n.t('builder.sidebar.delete') }
            </DefaultButton>
          </FormGroup>
        :
        props.data.tagName?.toLowerCase() === 'iframe' ?
          <div>
            <FormGroup>
              <Label>العرض: </Label>
              {props.data.iframeFor !== 'video' &&
                <iframe
                  width="100%"
                  height="200"
                  loading="lazy"
                  allowfullscreen
                  referrerpolicy="no-referrer-when-downgrade"
                  src={props.data.src}
                >
                </iframe>
              }
            </FormGroup>
            <DefaultButton 
              size={'sm'} 
              outline 
              color={'danger'} 
              className={'mb-3'} 
              block 
              onClick={() => props.data.iframeFor === 'video' ? handleDeleteIframeVideo() : handleDeleteMap()}
            >
              { i18n.t('builder.sidebar.delete') }
            </DefaultButton>
            {props.data.iframeFor === 'video' ?
              <FormGroup>
                <Label>رابط الفيديو: </Label>
                <DefaultInput 
                  name="src"
                  value={revertSrc(props.data.src)}
                  type={'textarea'}
                  onChange={(event) => handleClonedDataChange(event)}
                />
              </FormGroup>
            :
              <FormGroup>
                <Label>الموقع: </Label>
                <LocationSearchInput
                  address={getQueryParamValue(mapSrc, 'q')}
                  handleChange={(value) => setMapSrc(value)}
                  handleSelect={(address) => handleAddressChange(address)}
                />
              </FormGroup>
            }
          </div>
        :
        props.data.tagName.toLowerCase() === 'i' ?
          iconsLoading ?
            <div className="p-3 text-center">
              <AiOutlineLoading3Quarters className="animation-spin" />
            </div>
          :
          <FormGroup>
            <Label>الأيقونة:<small style={{fontSize: 10}}>(إضغط عليها لتغييرها)</small> </Label>
            <div className="py-3 text-center" style={{backgroundColor: '#f2f2f2'}}>
              <i 
                className={props.data.className} 
                style={{fontSize: 100}} 
                onClick={() => setIconsModalShown(true)}
              ></i>
            </div>
            <DefaultButton 
              size={'sm'} 
              outline 
              color={'danger'} 
              className={'mt-3'} 
              block
              onClick={() => handleDeleteIcon()}
            >
              { i18n.t('builder.sidebar.delete') }
            </DefaultButton>
          </FormGroup>
        :
        props.data.tagName.toLowerCase() === 'input' || props.data.tagName.toLowerCase() === 'textarea' ?
          <FormGroup>
            <Label>{ i18n.t('builder.sidebar.content') }: </Label>
            <DefaultInput 
              value={props.data.placeholder} 
              name="placeholder" 
              onChange={(event) => handleClonedDataChange(event)} 
            />
          </FormGroup>
        :
          <FormGroup>
            <Label>{ i18n.t('builder.sidebar.content') }: </Label>
            <CKEditor
              editor={ClassicEditor}
              config={{
                language: 'ar',
                toolbar: {
                  items: [
                    'bold',
                    'italic',
                    '|',
                    'link',
                    '|',
                    'undo',
                    'redo'
                  ]
                }
              }}
              data={props.data.content} 
              onChange={(event, editor) => handleClonedDataChange({target: {name: 'content', value: editor.getData()}})}
            />
          </FormGroup>
        }
        {props.data.tagName?.toLowerCase() === 'i' || props.data.tagName?.toLowerCase() === 'img' ?
          <div className="px-2 pt-2 mb-3 bg-light border-gray border">
            <Input 
              type="checkbox" 
              name="hasLink"
              value={props.data.hasLink ? true : false}
              onChange={(event) => handleClonedDataChange(event)} 
              checked={props.data.hasLink}
            />
            {' '}
            <Label>{ i18n.t('builder.sidebar.link') }</Label>
            {props.data.hasLink &&
              <FormGroup className="mt-2">
                <Label>{ i18n.t('builder.sidebar.the_link') }: </Label>
                <DefaultInput 
                  size={'md'} 
                  value={props.data.link} 
                  onChange={(event) => handleClonedDataChange(event)}  
                  name={'link'}
                />
              </FormGroup>
            }
          </div>
          :
          <div></div>
        }
        {props.data.tagName?.toLowerCase() !== 'img' && props.data.tagName?.toLowerCase() !== 'iframe' &&
          <FormGroup>
            <Label>{ i18n.t('builder.sidebar.font_size') }: </Label>
            <InputGroup dir="ltr">
              <Input
                type="select"
                name="fontSizeUnit"
                value={fontSizeUnit}
                onChange={(event) => setFontSizeUnit(event.target.value)}
                className="border-start-0"
              >
                <option value="px">px</option>
                <option value="cm">cm</option>
                <option value="rem">rem</option>
                <option value="%">%</option>
              </Input>
              <DefaultInput 
                value={extractNumberAndUnit(props.data.fontSize)?.number || Number(props.data.fontSize)}
                size={'md'}
                type={'number'}
                name={'fontSize'}
                onChange={(event) => handleClonedDataChange(event)} 
                step={4}
              />
            </InputGroup>
          </FormGroup>
        }
        <FormGroup>
          <Label>{ i18n.t('builder.sidebar.align') }: </Label>
          <ButtonGroup className="w-100">
            <DefaultButton
              size={'md'}
              color="dark"
              outline
              active={props.data.textAlign === 'right'}
              onClick={(event) => handleClonedDataChange(event)}
              value={'right'}
              name={'textAlign'}
            >
              يمين
            </DefaultButton>
            <DefaultButton
              size={'md'}
              color="dark"
              outline
              active={props.data.textAlign === 'center'}
              onClick={(event) => handleClonedDataChange(event)}
              value={'center'}
              name={'textAlign'}
            >
              وسط
            </DefaultButton>
            <DefaultButton
              size={'md'}
              color="dark"
              outline
              active={props.data.textAlign === 'left'}
              onClick={(event) => handleClonedDataChange(event)}
              value={'left'}
              name={'textAlign'}
            >
             يسار
            </DefaultButton>
          </ButtonGroup>
        </FormGroup>
        {props.data.tagName?.toLowerCase() !== 'img' && props.data.tagName?.toLowerCase() !== 'iframe' &&
          <FormGroup>
            <Label>{ i18n.t('builder.sidebar.text_color') }: </Label>
            <Input 
              type={'color'} 
              size={'md'} 
              className="p-0" 
              value={props.data.textColor} 
              onChange={(event) => handleClonedDataChange(event)}  
              name="textColor"
            />
          </FormGroup>
        }
        <FormGroup>
          <Label>{ i18n.t('builder.sidebar.background_color') }: </Label>
          <Input 
            type={'color'} 
            size={'md'} 
            className="p-0" 
            value={props.data.backgroundColor || '#ffffff'} 
            onChange={(event) => handleClonedDataChange(event)} 
            name="backgroundColor"
          />
        </FormGroup>

        <hr />

        <h6>{ i18n.t('builder.sidebar.border_title') }</h6>
        <FormGroup>
          <Label>{ i18n.t('builder.sidebar.border_radius') }: </Label>
          <Input 
            type="range" 
            value={extractNumberAndUnit(props.data.borderRadius)?.number || props.data.borderRadius} 
            name="borderRadius"
            onChange={(event) => handleClonedDataChange(event)} 
            step={4}
          />
        </FormGroup>
        <Row>
          <Col xs="6">
            <FormGroup>
              <Label>{ i18n.t('builder.sidebar.border_color') }: </Label>
              <Input 
                type="color" 
                className="p-0" 
                name="borderColor"
                value={props.data.borderColor}
                onChange={(event) => handleClonedDataChange(event)} 
              />
            </FormGroup>
          </Col>
          <Col xs="6">
            <FormGroup>
              <Label>{ i18n.t('builder.sidebar.border_width') }: </Label>
              <DefaultInput 
                type="number" 
                size={'sm'} 
                value={extractNumberAndUnit(props.data.borderSize)?.number || props.data.borderSize}
                name="borderSize"
                onChange={(event) => handleClonedDataChange(event)} 
                max="20"
              />
            </FormGroup>
          </Col>
        </Row>

        <hr />

        {!['i', 'a', 'button', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'span', 'label', 'p'].includes(props.data.tagName?.toLowerCase()) &&
          <Fragment>
            <h6>{ i18n.t('builder.sidebar.dimensions') }</h6>
            <Row>
              <Col xs="6">
                <FormGroup>
                  <Label>{ i18n.t('builder.sidebar.width') }: </Label>
                  <InputGroup dir="ltr">
                    <Input
                      type="select"
                      name="widthUnit"
                      value={widthUnit}
                      onChange={(event) => setWidthUnit(event.target.value)}
                      className="border-start-0 input-group-select-small"
                    >
                      <option value="px">px</option>
                      <option value="cm">cm</option>
                      <option value="rem">rem</option>
                      <option value="%">%</option>
                    </Input>
                    <DefaultInput 
                      type={'number'} 
                      size={'md'} 
                      value={extractNumberAndUnit(props.data.width)?.number || props.data.width}
                      name={'width'}
                      onChange={(event) => handleClonedDataChange(event)} 
                      step={4}
                    />
                  </InputGroup>
                  <Input name="width" type="checkbox" value={'auto'} onChange={(event) => handleClonedDataChange(event)} /> أوتوماتيكي
                </FormGroup>
              </Col>
              <Col xs="6">
                <FormGroup>
                  <Label>{ i18n.t('builder.sidebar.height') }: </Label>
                  <InputGroup dir="ltr">
                    <Input
                      type="select"
                      name="heightUnit"
                      value={heightUnit}
                      onChange={(event) => setHeightUnit(event.target.value)}
                      className="border-start-0 input-group-select-small"
                    >
                      <option value="px">px</option>
                      <option value="cm">cm</option>
                      <option value="rem">rem</option>
                      <option value="%">%</option>
                    </Input>
                    <DefaultInput 
                      type={'number'} 
                      size={'md'} 
                      value={extractNumberAndUnit(props.data.height)?.number || props.data.height}
                      name={'height'}
                      onChange={(event) => handleClonedDataChange(event)} 
                      step={4}
                    />
                  </InputGroup>
                  <Input name="height" type="checkbox" value={'auto'} onChange={(event) => handleClonedDataChange(event)} /> أوتوماتيكي
                </FormGroup>
              </Col>
            </Row>
    
            <hr />
          </Fragment>
        }

        {clonedData.tagName?.toLowerCase() !== 'iframe' &&
          <div>
            <h6>{ i18n.t('builder.sidebar.padding_title') }</h6>
            <Row>
              <Col xs="6">
                <FormGroup>
                  <Label>{ i18n.t('builder.sidebar.top') }: </Label>
                  <DefaultInput 
                    type={'number'} 
                    size={'md'} 
                    name={'paddingTop'}
                    value={extractNumberAndUnit(props.data.paddingTop)?.number}
                    onChange={(event) => handleClonedDataChange(event)}
                    step={4}
                  />
                </FormGroup>
              </Col>
              <Col xs="6">
                <FormGroup>
                  <Label>{ i18n.t('builder.sidebar.bottom') }: </Label>
                  <DefaultInput 
                    type={'number'} 
                    size={'md'} 
                    name={'paddingBottom'}
                    value={extractNumberAndUnit(props.data.paddingBottom)?.number}
                    onChange={(event) => handleClonedDataChange(event)}
                    step={4}
                  />
                </FormGroup>
              </Col>
              <Col xs="6">
                <FormGroup>
                  <Label>{ i18n.t('builder.sidebar.right') }: </Label>
                  <DefaultInput 
                    type={'number'} 
                    size={'md'} 
                    name={'paddingRight'}
                    value={extractNumberAndUnit(props.data.paddingRight)?.number}
                    onChange={(event) => handleClonedDataChange(event)}
                    step={4}
                  />
                </FormGroup>
              </Col>
              <Col xs="6">
                <FormGroup>
                  <Label>{ i18n.t('builder.sidebar.left') }: </Label>
                  <DefaultInput 
                    type={'number'} 
                    size={'md'} 
                    name={'paddingLeft'}
                    value={extractNumberAndUnit(props.data.paddingLeft)?.number}
                    onChange={(event) => handleClonedDataChange(event)}
                    step={4}
                  />
                </FormGroup>
              </Col>
            </Row>
    
            <hr />
          </div>
        }

        <h6>{ i18n.t('builder.sidebar.margin_title') }</h6>
        <Row>
          <Col xs="6">
            <FormGroup>
              <Label>{ i18n.t('builder.sidebar.top') }: </Label>
              <DefaultInput 
                type={'number'} 
                size={'md'} 
                name={'marginTop'}
                value={extractNumberAndUnit(props.data.marginTop)?.number}
                onChange={(event) => handleClonedDataChange(event)}
                step={4}
              />
            </FormGroup>
          </Col>
          <Col xs="6">
            <FormGroup>
              <Label>{ i18n.t('builder.sidebar.bottom') }: </Label>
              <DefaultInput 
                type={'number'} 
                size={'md'} 
                name={'marginBottom'}
                value={extractNumberAndUnit(props.data.marginBottom)?.number}
                onChange={(event) => handleClonedDataChange(event)}
                step={4}
              />
            </FormGroup>
          </Col>
          <Col xs="6">
            <FormGroup>
              <Label>{ i18n.t('builder.sidebar.right') }: </Label>
              <DefaultInput 
                type={'number'} 
                size={'md'} 
                name={'marginRight'}
                value={extractNumberAndUnit(props.data.marginRight)?.number}
                onChange={(event) => handleClonedDataChange(event)}
                step={4}
              />
            </FormGroup>
          </Col>
          <Col xs="6">
            <FormGroup>
              <Label>{ i18n.t('builder.sidebar.left') }: </Label>
              <DefaultInput 
                type={'number'} 
                size={'md'} 
                name={'marginLeft'}
                value={extractNumberAndUnit(props.data.marginLeft)?.number}
                onChange={(event) => handleClonedDataChange(event)}
                step={4}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>

      <BuilderImagesModal 
        isOpen={galleryModalShown}
        toggle={() => setGalleryModalShown(false)}
        onConfirm={(image) => handleImageChange(image)}
      />

      <DefaultModal 
        isOpen={iconsModalShown}
        title={'إختر أيقونة'}
        bodyStyle={{height: 400, overflow: 'auto'}}
        confirmationText={'تأكيد'}
        cancelText={'غلق'}
        toggle={() => setIconsModalShown(false)}
        size={'md'}
        onConfirm={() => setIconsModalShown(false)}
      >
        <FormGroup>
          <DefaultInput 
            placeholder={'إبحث'} 
            value={iconsFilterValue}
            onChange={(event) => setIconsFilterValue(event.target.value)}
          />
        </FormGroup>
        <IconsList 
          filterValue={iconsFilterValue} 
          onlyClass
          onClick={(data) => handleClonedDataChange({target: {name: 'className', value: data}})}
        />
      </DefaultModal>
    </div>
  );
}

export default EditElementModal;
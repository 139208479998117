import React, { Fragment, useEffect, useState } from "react";
import { Alert, Card, CardBody, CardHeader, CardTitle, Col, FormGroup, Input, Row } from "reactstrap";
import { DefaultButton } from "../../../../components/Buttons/default";
import PaymentForm from "../../../../components/Forms/CreditCard";
import { API_LINK } from "../../../../utils/constants";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const SubscribtionForm = (props) => {
  const [ selectedMethod, setSelectedMethod ] = useState(null);
  const [ paymentLoading, setPaymentLoading ] = useState(false);
  const [ availablePaymentMethods, setAvailablePaymentMethods ] = useState(null);
  const [ availablePaymentMethodsLoading, setAvailablePaymentMethodsLoading ] = useState(true);
  const [state, setState] = useState({
    card_number: '',
    expiry: '',
    cvc: '',
    cardholder: '',
    save: 0
  });

  useEffect(() => {
    checkAvailablePaymentMethods();
  }, []);

  const checkAvailablePaymentMethods = () => {
    setAvailablePaymentMethodsLoading(true);
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/payments/available_payment_methods', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      setAvailablePaymentMethods(json)
      setAvailablePaymentMethodsLoading(false);
    })
  }

  const payNow = () => {
    setPaymentLoading(true);
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/subscribe', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        payment_method: selectedMethod,
        package_id: props.package.id,
        delay: props.delay,
        card_number: state.card_number,
        expiry: state.expiry,
        cvc: state.cvc,
        cardholder: state.cardholder,
        save: state.save
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        window.location.assign(json.transactionUrl);
      }
      else
      {
        setPaymentLoading(false);
        props.onError(json.message);
      }
    })
  }

  const handleChange = (evt) => {
    const { name, value } = evt.target;
    
    if(name === 'expiry') {
      // Use regex to insert a "/" between the first two and last two characters
      const formattedValue = value.replace(/^(\d{2})(\d{0,2})$/, "$1/$2");
  
      setState((prev) => ({ ...prev,
        expiry: formattedValue
      }));
    } else {
      setState((prev) => ({ ...prev, [name]: value }));
    }
  }

  return(
    <Fragment>
      <Card className="mb-3 subscribtion-package-card">
        <CardBody>
          <Row className="align-items-center">
            <Col md="8">
              <b>{ props.package.name }</b>
              <br />
              <small>{props.delay} شهر بسعر {props.package.price} {props.package.country?.currency_symbol} للشهر</small>
            </Col>
            <Col md="4" className="text-end">
              <h4 className="fw-bold">{props.total} {props.package.country?.currency_symbol}</h4>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card className="subscribtion-package-card">
        <CardHeader>
          <CardTitle>وسيلة الدفع</CardTitle>
        </CardHeader>
        {availablePaymentMethodsLoading ?
          <div className="text-center py-5">
            <AiOutlineLoading3Quarters className="animation-spin" size={40} />
          </div>
        :
          <CardBody>
            {availablePaymentMethods.moyasar === 1 &&
              <FormGroup>
                <div>
                  <Input 
                    type="radio" 
                    className="me-3" 
                    onChange={() => setSelectedMethod('credit_card')}
                    checked={selectedMethod === 'credit_card'}
                  />
                  بطاقة بنكية
                </div>
                {selectedMethod === 'credit_card' &&
                  <PaymentForm 
                    data={state}
                    handleChange={(event) => handleChange(event)}
                  />
                }
              </FormGroup>
            }
            {availablePaymentMethods.paypal === 1 &&
              <FormGroup>
                <div>
                  <Input 
                    type="radio" 
                    className="me-3" 
                    onChange={() => setSelectedMethod('paypal')}
                    checked={selectedMethod === 'paypal'}
                  />
                  بايبال
                </div>
              </FormGroup>
            }

            {availablePaymentMethods.moyasar !== 1 && availablePaymentMethods.paypal !== 1 ?
              <Alert color="danger">لا توجد طرق دفع في دولتك، تواصل معنا لحل المشكلة.</Alert>
            :
              <DefaultButton
                size={'md'}
                block
                color={'dark'}
                disabled={!selectedMethod || paymentLoading}
                onClick={() => payNow()}
                isLoading={paymentLoading}
              >
                إدفع الآن
              </DefaultButton>
            }
          </CardBody>
        }
      </Card>
    </Fragment>
  );
}

export default SubscribtionForm;
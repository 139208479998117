import React, { useEffect, useState } from "react";
import { DefaultModal } from "../../../components/Modals/Default";
import { Col, FormGroup, FormText, Label, Row } from "reactstrap";
import { DefaultInput } from "../../../components/Forms/Inputs/default";
import { DefaultSelect } from "../../../components/Forms/Inputs/Select";
import { API_LINK } from "../../../utils/constants";
import AddBankModal from "./AddBank";
import { Link } from "react-router-dom";

const WithdrawModal = (props) => {
  const [state, setState] = useState({amount: '', bank: ''});
  const [bankInfos, setBankInfos] = useState(null);
  const [loading, setLoading] = useState(true);
  const [bankInfosModalShown, setBankInfosModalShown] = useState(false);

  const getBankInfos = () => {
    setLoading(true);
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/payments/bank_infos', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        setBankInfos(json.infos);
        setLoading(false);
      } else {
        setLoading(false);
      }
    })
  }

  const withdraw = () => {
    setLoading(true);
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/payments/withdraw', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        amount: state.amount,
        bank_infos_id: state.bank
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        props.onSuccess();
      } else {
        props.onError(json.message);
      }

      setLoading(false);
    })
  }

  const updateState = (key, value) => {
    setState(prevState => ({
      ...prevState,
      [key]: value
    }));
  };

  useEffect(() => {
    getBankInfos();
  }, []);

  return(
    <DefaultModal
      isOpen={props.isOpen}
      toggle={props.toggle}
      confirmationText={'تأكيد'}
      cancelText={'غلق'}
      title={'سحب الرصيد'}
      confirmationButtonDisabled={!state.amount || !state.bank}
      isLoading={loading}
      onConfirm={() => withdraw()}
    >
      <FormGroup>
        <Label>المبلغ: </Label>
        <DefaultInput 
          onChange={(event) => updateState('amount', event.target.value)}
          value={state.amount}
          type={'number'}
        />
      </FormGroup>
      <FormGroup>
        <Row className="align-items-center">
          <Col xs="6">
            <Label>الحساب البنكي: </Label>
          </Col>
          <Col xs="6" className="text-end small text-info">
            <a onClick={() => setBankInfosModalShown(true)}>+ إضافة حساب بنكي جديد</a>
          </Col>
        </Row>
        <DefaultSelect
          onChange={(event) => updateState('bank', event.target.value)}
          value={state.bank}
        >
          <option value="">اختر الحساب البنكي</option>
          {bankInfos?.length > 0 &&
            bankInfos.map((data, key) => {
              return(
                <option value={data.id}>{data.firstname} {data.lastname} - {data.iban}</option>
              );
            })
          }
        </DefaultSelect>
        <FormText>
          <Link to={'/dashboard/bank_informations'}>التحكم في الحسابات البنكية</Link>
        </FormText>
      </FormGroup>

      <AddBankModal 
        isOpen={bankInfosModalShown}
        toggle={() => setBankInfosModalShown(!bankInfosModalShown)}
        onSuccess={() => {setBankInfosModalShown(!bankInfosModalShown); getBankInfos()}}
        onError={(message) => props.onError(message)}
      />
    </DefaultModal>
  );
}

export default WithdrawModal;
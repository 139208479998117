import React, { Component, Fragment } from "react";
import { Alert, Card, CardBody, CardHeader, CardTitle, Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import { AdminBodyTitle } from "../../../components/Typography/Titles";
import { DefaultButton } from "../../../components/Buttons/default";
import PaymentForm from "../../../components/Forms/CreditCard";
import { withRouter } from "../../../components/Router/withRouter";
import { DefaultInput } from "../../../components/Forms/Inputs/default";
import { DefaultSelect } from "../../../components/Forms/Inputs/Select";
import { API_LINK } from "../../../utils/constants";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { BottomLeftAlert } from "../../../components/Alerts";
import { FaFacebookMessenger, FaInstagram, FaWhatsapp } from "react-icons/fa";
import { i18n } from "../../../i18n";

class PayDomainPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      countriesData: null,
      countriesLoading: true,

      availablePaymentMethods: null,
      availablePaymentMethodsLoading: true,

      payLoading: false,

      selectedMethod: '',

      firstname: JSON.parse(localStorage.getItem('user')).firstname,
      lastname: JSON.parse(localStorage.getItem('user')).lastname,
      company_name: '',
      email: JSON.parse(localStorage.getItem('user')).email,
      address1: '',
      address2: '',
      city: '',
      country: '',
      country_code: JSON.parse(localStorage.getItem('user')).country_code,
      phone_number: JSON.parse(localStorage.getItem('user')).phone_number,
      zipcode: '',
      card_number: '',
      expiry: '',
      cvc: '',
      cardholder: '',

      alertText: null
    }
  }

  componentDidMount() {
    this.getCountries();
  }

  checkAvailablePaymentMethods() {
    this.setState({
      availablePaymentMethodsLoading: true
    });
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/payments/available_payment_methods', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      this.setState({
        availablePaymentMethods: json,
        availablePaymentMethodsLoading: false
      });
    })
  }

  getCountries() {
    fetch(API_LINK+'/countries')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((json) => {
        this.setState({
          countriesData: json.countries,
          countriesLoading: false
        }, () => {
          this.checkAvailablePaymentMethods();
        });
      })
  }

  payNow() {
    this.setState({payLoading: true});
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/domaines/purchase', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        domain_name: this.props.location.state?.domain,
        payment_method: this.state.selectedMethod,
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        company_name: this.state.company_name,
        email: this.state.email,
        address1: this.state.address1,
        address2: this.state.address2,
        city: this.state.city,
        country: this.state.country,
        country_code: this.state.country_code,
        phone_number: this.state.phone_number,
        zipcode: this.state.zipcode,
        card_number: this.state.card_number,
        expiry: this.state.expiry,
        cvc: this.state.cvc,
        cardholder: this.state.cardholder,
        save: this.state.save
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        window.location.assign(json.transactionUrl);
      } else {
        this.setState({
          alertText: json.message
        });
      }

      this.setState({payLoading: false});
    })
  }

  handleChange(event) {
    const { name, value } = event.target;

    if (name === "expiry") {
      // Use regex to insert a "/" between the first two and last two characters
      const formattedValue = value.replace(/^(\d{2})(\d{0,2})$/, "$1/$2");
  
      this.setState({
        expiry: formattedValue
      });
    } else {
      this.setState({
        [name]: value
      });
    }
  };

  handleFocus(event) {
    const { name } = event.target;
    this.setState({
      focus: name
    });
  }

  render() {
    const s = this.state;

    return(
      <Container>
        <Row>
          <Col md="8" className="offset-md-2">
            <AdminBodyTitle>{ i18n.t('add_domain.pay.title') }</AdminBodyTitle>

            {s.countriesLoading ?
              <Card className="mt-4 py-4">
                <CardBody className="text-center">
                  <AiOutlineLoading3Quarters className="animation-spin" size={40} />
                </CardBody>
              </Card>
            :
              <Fragment>
                <Card className="mt-4 mb-3">
                  <CardBody>
                    <Row className="align-items-center">
                      <Col md="8">
                        <b>{ i18n.t('add_domain.pay.summary.domain') }: {this.props.location.state?.domain}</b>
                      </Col>
                      <Col md="4" className="text-end">
                        <h4 className="fw-bold">{this.props.location.state?.price} $</h4>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <Card className="mb-3">
                  <CardHeader>
                    <CardTitle>{ i18n.t('add_domain.pay.billing.title') }</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md="6" className="mb-3">
                        <Label>{ i18n.t('add_domain.pay.billing.form.firstname') }: </Label>
                        <DefaultInput 
                          name={'firstname'}
                          value={s.firstname}
                          onChange={(event) => this.handleChange(event)} 
                        />
                      </Col>
                      <Col md="6" className="mb-3">
                        <Label>{ i18n.t('add_domain.pay.billing.form.lastname') }: </Label>
                        <DefaultInput 
                          name={'lastname'}
                          value={s.lastname}
                          onChange={(event) => this.handleChange(event)}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6" className="mb-3">
                        <Label>{ i18n.t('add_domain.pay.billing.form.company_name') }: </Label>
                        <DefaultInput 
                          name={'company_name'}
                          value={s.company_name}
                          onChange={(event) => this.handleChange(event)}
                        />
                      </Col>
                      <Col md="6" className="mb-3">
                        <Label>{ i18n.t('add_domain.pay.billing.form.email') }: </Label>
                        <DefaultInput 
                          name={'email'}
                          value={s.email}
                          onChange={(event) => this.handleChange(event)}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6" className="mb-3">
                        <Label>{ i18n.t('add_domain.pay.billing.form.address') }: </Label>
                        <DefaultInput 
                          name={'address1'}
                          value={s.address1}
                          onChange={(event) => this.handleChange(event)}
                        />
                      </Col>
                      <Col md="6" className="mb-3">
                        <Label>{ i18n.t('add_domain.pay.billing.form.address_2') }: ({ i18n.t('add_domain.pay.billing.form.optional') }) </Label>
                        <DefaultInput 
                          name={'address2'}
                          value={s.address2}
                          onChange={(event) => this.handleChange(event)}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6" className="mb-3">
                        <Label>{ i18n.t('add_domain.pay.billing.form.city') }: </Label>
                        <DefaultInput 
                          name={'city'}
                          value={s.city}
                          onChange={(event) => this.handleChange(event)}
                        />
                      </Col>
                      <Col md="6" className="mb-3">
                        <Label>{ i18n.t('add_domain.pay.billing.form.country') }: </Label>
                        <DefaultSelect 
                          value={s.country}
                          name={'country'}
                          onChange={(event) => this.handleChange(event)}
                        >
                          <option value="">{ i18n.t('add_domain.pay.billing.form.country_select') }</option>
                          {s.countriesData &&
                            s.countriesData.map((data, key) => {
                              return(
                                <option key={key} value={data.name}>{data.name}</option>
                              );
                            })
                          }
                        </DefaultSelect>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6" className="mb-3">
                        <Label>{ i18n.t('add_domain.pay.billing.form.phone_number') }: </Label>
                        <Row>
                          <Col xs="9">
                            <DefaultInput 
                              bsSize={'lg'} 
                              type="tel" 
                              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" 
                              dir="ltr" 
                              value={s.phone_number} 
                              name={'phone_number'}
                              maxLength={'10'}
                              placeholder={'12-345-6789'}
                              onChange={(event) => this.handleChange(event)}
                            />
                          </Col>
                          <Col xs="3" className="ps-0">
                            <DefaultSelect 
                              dir="ltr" 
                              value={s.country_code}
                              name={'country_code'}
                              onChange={(event) => this.handleChange(event)}
                            >
                              {s.countriesData &&
                                s.countriesData.map((data, key) => {
                                  return(
                                    <option key={key} value={data.country_code}>{data.country_code}</option>
                                  );
                                })
                              }
                            </DefaultSelect>
                          </Col>
                        </Row>
                      </Col>
                      <Col md="6" className="mb-3">
                        <Label>{ i18n.t('add_domain.pay.billing.form.zipcode') }: </Label>
                        <DefaultInput 
                          value={s.zipcode}
                          name={'zipcode'}
                          onChange={(event) => this.handleChange(event)}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <Card className="mb-3">
                  <CardHeader>
                    <CardTitle>{ i18n.t('add_domain.pay.payment.title') }</CardTitle>
                  </CardHeader>
                  {s.availablePaymentMethodsLoading ?
                    <CardBody className="text-center">
                      <AiOutlineLoading3Quarters className="animation-spin" size={40} />
                    </CardBody>
                  :
                    <CardBody>
                      {s.availablePaymentMethods === 1 &&
                        <FormGroup>
                          <div>
                            <Input 
                              type="radio" 
                              className="me-3" 
                              checked={s.selectedMethod === 'credit_card'}
                              onChange={() => this.setState({selectedMethod: 'credit_card'})}
                            />
                            بطاقة بنكية
                          </div>
                          {s.selectedMethod === 'credit_card' &&
                            <PaymentForm 
                              data={s}
                              handleChange={(event) => this.handleChange(event)}
                              handleFocus={(event) => this.handleFocus(event)}
                              forPay
                            />
                          }
                        </FormGroup>
                      }

                      {s.availablePaymentMethods.paypal === 1 &&
                        <FormGroup>
                          <Input 
                            type="radio" 
                            className="me-3" 
                            checked={s.selectedMethod === 'paypal'}
                            onChange={() => this.setState({selectedMethod: 'paypal'})}
                          />
                          بايبال
                        </FormGroup>
                      }

                      {s.availablePaymentMethods.moyasar !== 1 && s.availablePaymentMethods.paypal !== 1 ?
                        <Alert color="danger">{ i18n.t('add_domain.pay.payment.error') }.</Alert>
                      :
                        <DefaultButton
                          size={'md'}
                          block
                          color={'dark'}
                          disabled={!s.selectedMethod || s.payLoading}
                          onClick={() => this.payNow()}
                          isLoading={s.payLoading}
                        >
                          إدفع الآن ({this.props.location.state?.price} $)
                        </DefaultButton>
                      }
                    </CardBody>
                  }
                </Card>

                <Card className="mt-4 mb-4">
                  <CardBody>
                    <CardTitle>{ i18n.t('add_domain.pay.contact.title') }:</CardTitle>
                    <Row className="mt-3">
                      <Col md="4" xs="6">
                        <a href="https://wa.me/213779828745" target="_blank">
                          <DefaultButton block outline color={'success'}>
                            <FaWhatsapp size={22} />{' '}
                            { i18n.t('add_domain.pay.contact.whatsapp') }
                          </DefaultButton>
                        </a>
                      </Col>
                      <Col md="4" xs="6">
                        <a href="https://facebook.com/blizcorporation" target="_blank">
                          <DefaultButton block outline color={'info'}>
                            <FaFacebookMessenger size={22} />{' '}
                            { i18n.t('add_domain.pay.contact.messenger') }
                          </DefaultButton>
                        </a>
                      </Col>
                      <Col md="4" xs="6">
                        <a href="https://instagram.com/blizcorpo" target="_blank">
                          <DefaultButton block outline className={'payform-btn-instagram'}>
                            <FaInstagram size={22} />{' '}
                            { i18n.t('add_domain.pay.contact.instagram') }
                          </DefaultButton>
                        </a>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Fragment>
            }
          </Col>
        </Row>

        {s.alertText &&
          <BottomLeftAlert color={'danger'} onClose={() => this.setState({alertText: null})}>
            {s.alertText}
          </BottomLeftAlert>
        }
      </Container>
    );
  }
}

export default withRouter(PayDomainPage);
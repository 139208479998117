import React, { Component } from "react";
import { Container } from "reactstrap";
import RegisterUserInfos from "./Steps/UserInfos";
import { API_LINK } from "../../../utils/constants";
import { withRouter } from "../../../components/Router/withRouter";
import RegisterEmail from "./Steps/Email";
import RegisterEmailConfirmation from "./Steps/EmailConfirmation";

class RegisterPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: 1,

      buttonLoading: false,

      phone_number: '',
      country_code: '+966',
      confirmation_code: '',
      username: '',
      firstname: '',
      lastname: '',
      email: '',
      password: '',

      alertText: null
    }
  }

  sendVerificationCode() {
    this.setState({buttonLoading: true});

    fetch(API_LINK+'/email/code/send', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: this.state.email
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        this.setState({
          currentStep: 2,
          buttonLoading: false,
          alertText: null
        });
      }
      else
      {
        this.setState({
          alertText: json.message,
          buttonLoading: false
        });
      }
    })
  }

  confirmVerificationCode() {
    this.setState({buttonLoading: true});

    fetch(API_LINK+'/email/code/check', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: this.state.email,
        confirmation_code: this.state.confirmation_code
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        this.setState({
          currentStep: 3,
          buttonLoading: false,
          alertText: null
        });
      }
      else
      {
        this.setState({
          alertText: json.message,
          buttonLoading: false
        });
      }
    })
  }

  register() {
    this.setState({buttonLoading: true});
    fetch(API_LINK+'/register', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: this.state.username,
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        email: this.state.email,
        phone_number: this.state.phone_number,
        country_code: this.state.country_code,
        confirmation_code: this.state.confirmation_code,
        password: this.state.password
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        this.setState({
          alertText: null,
          buttonLoading: false
        }, () => {
          localStorage.setItem('user', JSON.stringify(json.user));
          this.props.onLogin();
          this.props.navigate('/website/new');
        });
      }
      else
      {
        this.setState({
          alertText: json.message,
          buttonLoading: false
        });
      }
    })
  }

  handleChange(name, event) {
    this.setState({
      ...this.state,
      [name]: event.target.value
    })
  }

  render() {
    const s = this.state;

    return(
      <Container>
        {s.currentStep === 1 ?
          <RegisterEmail 
            country_code={s.country_code}
            phone_number={s.email}
            onConfirm={() => this.sendVerificationCode()}
            handleChange={(name, event) => this.handleChange(name, event)}
            alertText={s.alertText}
            buttonLoading={s.buttonLoading}
          />
        :
          s.currentStep === 2 ?
            <RegisterEmailConfirmation 
              email={s.email}
              onConfirm={() => this.confirmVerificationCode()}
              onBack={() => this.setState({currentStep: 1})}
              confirmation_code={s.confirmation_code}
              handleChange={(name, event) => this.handleChange(name, event)}
              alertText={s.alertText}
              buttonLoading={s.buttonLoading}
            />
          :
            <RegisterUserInfos 
              phone_number={s.phone_number}
              username={s.username}
              firstname={s.firstname}
              lastname={s.lastname}
              email={s.email}
              password={s.password}
              handleChange={(name, event) => this.handleChange(name, event)}
              register={() => this.register()}
              alertText={s.alertText}
              buttonLoading={s.buttonLoading}
            />
        }
      </Container>
    );
  }
}

export default withRouter(RegisterPage);
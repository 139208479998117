import React, { Component } from "react";
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap";
import { IoWalletOutline } from "react-icons/io5";
import { withRouter } from "../../components/Router/withRouter";
import { AdminBodyTitle } from "../../components/Typography/Titles";
import { StatisticsCard } from "../../components/Cards/Statistics";
import { DefaultButton } from "../../components/Buttons/default";
import { API_LINK } from "../../utils/constants";
import { BottomLeftAlert } from "../../components/Alerts";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import WithdrawModal from "./Modals/Withdraw";

class WalletPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      balanceLoading: true,
      balance: 0,
      withdrawRequests: null,
      withdrawRequestsLoading: true,

      withdrawModalShown: false,

      alertText: null
    }
  }

  componentDidMount() {
    this.getBalance();
  }

  getBalance() {
    this.setState({balanceLoading: true});
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/payments/wallet', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        this.setState({
          balance: json.balance,
          balanceLoading: false
        }, () => {
          this.getWithdrawRequests();
        });
      }
      else
      {
        this.setState({
          alertText: json.message,
          balanceLoading: false
        });
      }
    })
  }

  getWithdrawRequests() {
    this.setState({withdrawRequestsLoading: true});
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/payments/withdraw_requests', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        this.setState({
          withdrawRequests: json.requests,
          withdrawRequestsLoading: false
        });
      }
      else
      {
        this.setState({
          alertText: json.message,
          withdrawRequestsLoading: false
        });
      }
    })
  }

  render() {
    const s = this.state;

    return(
      <Container>
        <AdminBodyTitle>المحفظة</AdminBodyTitle>

        <Row>
          <Col md="4">
            <StatisticsCard 
              title={'الرصيد'}
              isLoading={s.balanceLoading}
              value={s.balance+' ر.س'}
              icon={<IoWalletOutline size={42} />}
            />
          </Col>
        </Row>

        <hr />

        <Row>
          <Col xs="8">
            <AdminBodyTitle>طلبات سحب الرصيد</AdminBodyTitle>
          </Col>
          <Col className="text-end" xs="4">
            <DefaultButton color={'dark'} onClick={() => this.setState({withdrawModalShown: true})}>سحب الرصيد</DefaultButton>
          </Col>
        </Row>

        <Card>
          <CardBody>
            {s.withdrawRequestsLoading ?
              <AiOutlineLoading3Quarters className="animation-spin" />
            :
              s.withdrawRequests?.data?.length > 0 ?
                <div>
                  {s.withdrawRequests.data.map((data, key) => {
                    return(
                      <Row className="border-bottom py-3" key={key}>
                        <Col xs="4">
                          <b>{data.balance} ر.س</b>
                        </Col>
                        <Col xs="4" className="text-center">
                          {data.status === 1 ? 
                            <span className="text-success fw-bold">منتهية</span> 
                          : 
                            <span className="text-info fw-bold">قيد الإنتظار</span>
                          }
                        </Col>
                        <Col xs="4" className="text-end fw-bold">
                          {data.created_at.split('T')[0]}
                        </Col>
                      </Row>
                    );
                  })}
                </div>
              :
                <Alert className="mb-0" color="info">لا توجد بيانات.</Alert>
            }
          </CardBody>
        </Card>

        {s.alertText &&
          <BottomLeftAlert color={'danger'} onClose={() => this.setState({alertText: null})}>
            {s.alertText}
          </BottomLeftAlert>
        }

        <WithdrawModal 
          isOpen={s.withdrawModalShown} 
          toggle={() => this.setState({withdrawModalShown: !s.withdrawModalShown})}
          onError={(message) => this.setState({alertText: message})}
          onSuccess={() => this.setState({withdrawModalShown: false}, () => this.getBalance())}
        />
      </Container>
    );
  }
}

export default withRouter(WalletPage);
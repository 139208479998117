import React from "react";
import { FaFacebook, FaTwitch, FaVimeoV, FaYoutube } from "react-icons/fa";
import { IoCloudUploadOutline } from "react-icons/io5";
import './styles.css';

export const UploadWidget = (props) => {
  return(
    <div className={props.active ? 'border widget active' : 'border widget' } onClick={() => alert('الميزة غير متاحة.')}>
      <IoCloudUploadOutline size={30} />
      <div>رفع فيديو</div>
    </div>
  );
}

export const YoutubeWidget = (props) => {
  return(
    <div className={props.active ? 'border widget active' : 'border widget' } onClick={props.onClick}>
      <FaYoutube size={30} color="#FF0000" />
      <div>YouTube</div>
    </div>
  );
}

export const FacebookWidget = (props) => {
  return(
    <div className={props.active ? 'border widget active' : 'border widget' } onClick={props.onClick}>
      <FaFacebook size={30} color="#1877F2" />
      <div>Facebook</div>
    </div>
  );
}

export const VimeoWidget = (props) => {
  return(
    <div className={props.active ? 'border widget active' : 'border widget' } onClick={props.onClick}>
      <FaVimeoV size={30} color="#1AB7EA" />
      <div>Vimeo</div>
    </div>
  );
}

export const TwitchWidget = (props) => {
  return(
    <div className={props.active ? 'border widget active' : 'border widget' } onClick={props.onClick}>
      <FaTwitch size={30} color="#6441a5" />
      <div>Twitch</div>
    </div>
  );
}
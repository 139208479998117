import React, { Fragment, useState } from "react";
import { AiOutlineEye, AiOutlineMenu, AiOutlineHistory, AiOutlineCheckSquare } from 'react-icons/ai';
import { BsArrowLeftCircle, BsArrowRightCircle, BsBell, BsEnvelope } from 'react-icons/bs';
import { HiOutlineLogout } from "react-icons/hi";
import { IoPersonOutline, IoWalletOutline } from "react-icons/io5";
import { MdOutlineDns } from 'react-icons/md';
import { RiSecurePaymentLine } from 'react-icons/ri';
import { CiGlobe } from "react-icons/ci";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import './styles.css';
import { DefaultButton } from "../Buttons/default";
import { useWebsite } from "../../context/Website";
import { MAIN_DOMAIN, MAIN_LINK } from "../../utils/constants";
import LanguagesModal from "../Modals/Languages";
import { i18n } from "../../i18n";

export const Header = (props) => {
  const [profileDropdownShown, setProfileDropdownShown] = useState(false);
  const [languagesModalShown, setLanguageModalShown] = useState(false);
  const { website } = useWebsite();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user'));

  const logout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('website');
    props.onLogout();
  }

  return(
    <div>
      <header className="clearfix shadow-sm">
        <div className="container-fluid">
          <AiOutlineMenu 
            className="header-sidebar-toggle-btn float-start mt-2 pt-1 cursor-pointer" 
            onClick={() => props.onSidebarToggle()}
          />

          <div className="header-buttons float-start pt-2 mt-1 ms-3 me-3">
            {i18n.language === 'ar' ?
              <Fragment>
                <BsArrowRightCircle size={28} className="me-2 cursor-pointer" onClick={() => navigate(-1)} />
                <BsArrowLeftCircle size={28} className="cursor-pointer" onClick={() => navigate(+1)} />
              </Fragment>
            :
              <Fragment>
                <BsArrowLeftCircle size={28} className="me-2 cursor-pointer" onClick={() => navigate(-1)} />
                <BsArrowRightCircle size={28} className="cursor-pointer" onClick={() => navigate(+1)} />
              </Fragment>
            }
          </div>

          <Link to={website !== 'null' ? '/dashboard/websites/all' : '/website/new'}>
            <DefaultButton size={'md'} className="header-visite-btn bg-white text-dark float-start btn btn-outline-dark border-dark my-2 mx-3">
              <span>{ website !== 'null' ? JSON.parse(website)?.name.substring(0, 3)+'..' : '+ إنشاء موقع جديد' }</span>{' '}
            </DefaultButton>
          </Link>
          {JSON.parse(website) !== null &&
            <a href={JSON.parse(website)?.domaine ? 'https://'+JSON.parse(website).domaine.domain_name : 'https://'+user.username+'.'+MAIN_DOMAIN+'/'+JSON.parse(website)?.name} target="_blank">
              <button className="header-visite-btn float-start btn btn-outline-dark border-dark my-2">
                <AiOutlineEye />
              </button>
            </a>
          }
          <ul className="header-list clearfix float-end">
            <Dropdown 
              isOpen={profileDropdownShown} 
              toggle={() => setProfileDropdownShown(!profileDropdownShown)} 
              className="float-end header-list-profile"
            >
              <DropdownToggle>
                <img src={ user?.profile_picture_path ? MAIN_LINK+user.profile_picture_path : require('../../assets/brand-black.png') } width="28px" className="border border-2 border-muted rounded-pill bg-white" />
              </DropdownToggle>
              <DropdownMenu>
                <Link to="/dashboard/profile" className="text-dark">
                  <DropdownItem className="p-3">
                    <IoPersonOutline /> <span>{ i18n.t('header.account_settings') }</span>
                  </DropdownItem>
                </Link>
                <Link to="/dashboard/domaines" className="text-dark">
                  <DropdownItem className="p-3">
                    <MdOutlineDns /> <span>{ i18n.t('header.domaines') }</span>
                  </DropdownItem>
                </Link>
                <Link to="/dashboard/wallet" className="text-dark">
                  <DropdownItem className="p-3">
                    <IoWalletOutline /> <span>{ i18n.t('header.wallet') }</span>
                  </DropdownItem>
                </Link>
                <Link to="/dashboard/payment_histories" className="text-dark">
                  <DropdownItem className="p-3">
                    <AiOutlineHistory /> <span>{ i18n.t('header.payment_histories') }</span>
                  </DropdownItem>
                </Link>
                <Link to="/dashboard/subscribtion" className="text-dark">
                  <DropdownItem className="p-3">
                    <AiOutlineCheckSquare /> <span>{ i18n.t('header.subscribtions') }</span>
                  </DropdownItem>
                </Link>
                <Link to="/dashboard/payment_methods" className="text-dark">
                  <DropdownItem className="p-3">
                    <RiSecurePaymentLine /> <span>{ i18n.t('header.payment_methods') }</span>
                  </DropdownItem>
                </Link>
                <a className="text-dark" onClick={() => setLanguageModalShown(true)}>
                  <DropdownItem className="p-3">
                    <CiGlobe /> <span>{ i18n.t('header.language') }</span>
                  </DropdownItem>
                </a>
                <Link onClick={logout} className="text-dark">
                  <DropdownItem className="p-3">
                    <HiOutlineLogout /> <span>{ i18n.t('header.logout') }</span>
                  </DropdownItem>
                </Link>
              </DropdownMenu>
            </Dropdown>

            <Link to="/dashboard/notifications" className="header-notification-icon float-end">
              <span className="bliz-badge bg-danger"></span>
              <BsBell className="h4 text-dark" />
            </Link>

            {JSON.parse(website)?.name &&
              <Link to="/dashboard/messages" className="header-message-icon float-end">
                <span className="bliz-badge bg-danger"></span>
                <BsEnvelope className="h4 text-dark" />
              </Link>
            }
          </ul>
        </div>
      </header>

      {languagesModalShown &&
        <LanguagesModal 
          toggle={() => setLanguageModalShown(false)}
        />
      }
    </div>
  );
}
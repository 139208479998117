import React, { useEffect, useState } from "react";
import { Badge, FormGroup, Modal, ModalBody, ModalFooter } from "reactstrap";
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { API_LINK, MAIN_LINK } from "../../utils/constants";
import { DefaultInput } from "../Forms/Inputs/default";
import { SlClose } from "react-icons/sl";
import { DefaultButton } from "../Buttons/default";
import Select from "react-select";

const SendEmailModal = (props) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const [state, setState] = useState({
    emails: props.emails,
  });
  const [subject, setSubject] = useState();
  const [content, setContent] = useState();
  const [contactsKeyword, setContactsKeyword] = useState('');
  const [contactsLoading, setContactsLoading] = useState();
  const [contactsData, setContactsData] = useState([]);
  const [contactsOptions, setContactsOptions] = useState([]);
  const [sendEmailsLoading, setSendEmailsLoading] = useState(false);

  useEffect(() => {
    getContacts();
  }, []);

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      [{'direction': 'rtl'}]
    ],
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ];

  const getContacts = () => {
    setContactsLoading(true);

    fetch(API_LINK+'/crm/contacts?keyword='+contactsKeyword, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+user.token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        setContactsData(json.contacts.data);
        setContactsOptions((prevState) => {
          let newOptions = [...prevState];

          contactsData.forEach((contact) => {
            const newValue = {
              label: contact.firstname + ' ' + contact.lastname,
              value: contact.id,
              email: contact.email
            };

            if (!newOptions.some((option) => option.value === newValue.value)) {
              newOptions = [...newOptions, newValue];
            }
          });

          return newOptions;
        });
        setContactsLoading(false);
      }
      else
      {
        this.setState({
          alertText: json.message,
          contactsLoading: false
        });
      }
    })
  };

  const sendEmails = () => {
    setSendEmailsLoading(true);
  };

  const addEmail = (newEmail) => {
    if(newEmail) {
      if(!state.emails.includes(newEmail)) {
        setState((prevState) => ({
          emails: [...prevState.emails, newEmail],
        }));
      }
    } else {
      alert('هذا العميل لا يملك بريدا إلكترونيا');
    }
  };

  const removeEmail = (emailToRemove) => {
    setState((prevState) => ({
      emails: prevState.emails.filter((email) => email !== emailToRemove),
    }));
  };

  return(
    <Modal isOpen={props.isOpen} className="left-modal" toggle={props.toggle}>
      <ModalBody>
        <div className="modal-email-user-infos pb-3 border-bottom">
          <img src={MAIN_LINK+user.profile_picture_path} width={'50px'} className="rounded-pill me-2" />
          <span>{user.firstname+' '+user.lastname}</span>
          &nbsp;
          <span>{'<'+user.email+'>'}</span>
        </div>
        <div className="py-3 border-bottom clearfix">
          <div className="float-start">
            إلى{' '}
            {state.emails.map((data, key) => {
              return(
                <Badge color="secondary" className="me-2 rounded-pill" key={key}>
                  {data.split('@')[0]+' '}
                  <SlClose className="cursor-pointer" onClick={() => removeEmail(data)} />
                </Badge>
              );
            })}
          </div>

          <div className="float-start">
            <Select
              placeholder={'إختر عميل'}
              isLoading={contactsLoading}
              isClearable={true}
              isRtl={true}
              isSearchable={true}
              name="color"
              options={contactsOptions}
              value={null}
              onInputChange={(inputValue) => {setContactsKeyword(inputValue); getContacts()}}
              onChange={(selectedOption) => addEmail(selectedOption.email)}
            />
          </div>
        </div>
        <FormGroup>
          <DefaultInput 
            placeholder="العنوان" 
            value={subject} 
            onChange={(event) => setSubject(event.target.value)} 
          />
        </FormGroup>
        <ReactQuill 
          theme="snow" 
          modules={modules} 
          formats={formats} 
          value={content}
          onChange={(editor) => setContent(editor)}
        />
      </ModalBody>
      <ModalFooter>
        <DefaultButton 
          color={'dark'}
          disabled={state.emails?.length <= 0 || !subject || !content || sendEmailsLoading}
          onClick={() => sendEmails()}
          isLoading={sendEmailsLoading}
        >
          إرسال
        </DefaultButton>
      </ModalFooter>
    </Modal>
  );
}

export default SendEmailModal;

SendEmailModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func
}
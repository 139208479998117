import React, { useEffect, useRef, useState } from "react";
import { IoCloudDownloadOutline, IoDocumentOutline } from "react-icons/io5";
import { IoIosLink, IoIosMove } from "react-icons/io";
import './styles.css';
import { MAIN_LINK } from "../../../../utils/constants";
import { AiOutlineDelete } from "react-icons/ai";

const FileCard = (props) => {
  const [menuShown, setMenuShown] = useState(false);
  const menuRef = useRef(null);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuShown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDoubleClick = () => {
    if(props.data.file_type === 'image') {
      props.onDoubleClick(props.data.file_url);
    } else {
      props.onFailedOpen();
    }
  }

  return(
    <div class="cms-file-card" onDoubleClick={handleDoubleClick}>
      <div class="cms-file-header">
        <h6 class="cms-file-filename">{props.data.title || 'بدون عنوان'}</h6>
        <button class="cms-file-menu-btn" onClick={() => setMenuShown(!menuShown)}>⋮</button>
        {menuShown &&
          <div class="cms-file-menu" ref={menuRef}>
            <a href="#" onClick={() => props.onMove()}>
              <IoIosMove size={18} />{' '}
              نقل لمجلد
            </a>
            <a href="#" onClick={() => {props.onCopyLink(); setMenuShown(false)}}>
              <IoIosLink size={18} />{' '}
              نسخ الرابط
            </a>
            <a href="#" onClick={() => window.open(MAIN_LINK+props.data.file_url, '_blank')}>
              <IoCloudDownloadOutline size={18} />{' '}
              تحميل
            </a>
            <a href="#" onClick={() => props.onDelete()}>
              <AiOutlineDelete size={18} />{' '}
              حذف
            </a>
          </div>
        }
      </div>
      <div class="cms-file-preview d-flex align-items-center justify-content-center">
        {props.data.file_type === 'image' ?
          <img src={MAIN_LINK+props.data.file_url} alt="cms-File Preview" width={'100%'} />
        :
          <div className="text-center">
            <IoDocumentOutline size={200} />
          </div>
        }
      </div>
    </div>
  );
}

export default FileCard;
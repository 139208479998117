import React from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { DefaultButton } from "../../../../components/Buttons/default";

const BuilderImageModal = ({ImageURL, toggle}) => {
  return(
    <Modal isOpen={ImageURL} size="lg" toggle={toggle}>
      <ModalBody className="text-center py-5">
        <img src={ImageURL} height={440} style={{maxWidth: '100%'}} />
      </ModalBody>
      <ModalFooter>
        <DefaultButton color={'dark'}>إختيار الصورة</DefaultButton>
        <DefaultButton onClick={toggle}>غلق</DefaultButton>
      </ModalFooter>
    </Modal>
  );
}

export default BuilderImageModal;
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { DefaultButton } from "../../../components/Buttons/default";
import { AiOutlineEye, AiOutlineLoading3Quarters } from "react-icons/ai";
import { DefaultAlert } from "../../../components/Alerts";
import { API_LINK, MAIN_LINK } from "../../../utils/constants";
import { withRouter } from "../../../components/Router/withRouter";
import './styles.css';

class CreateWebsiteTemplate extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      templatesLoading: true,
      templatesData: null,
      selectedTemplate: null
    }
  }

  componentDidMount() {
    this.getTemplates();
  }

  getTemplates() {
    this.setState({templatesLoading: true});
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/templates/'+this.props.params.category, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        this.setState({
          templatesData: json.templates,
          templatesLoading: false
        });
      }
      else
      {
        this.setState({
          alertText: json.message,
          templatesLoading: false
        });
      }
    })
  }

  render() {
    const s = this.state;

    return(
      <div className="page-auth">
        <header className="py-3 bg-transparent">
          <Container>
            <Row>
              <Col xs="6">
                <div>
                  <a href={MAIN_LINK}>
                    <img src={ require('../../../assets/logo-rtl-black.png') } width="100px" />
                  </a>
                </div>
              </Col>
              <Col xs="6" className="text-end">
                <Link to="/register">تسجيل الخروج</Link>
              </Col>
            </Row>
          </Container>
        </header>

        <main>
          <Container className="mb-5 pb-5 mt-5">
            <h4 className="fw-bold text-center mb-5">إختر القالب المناسب لك لتخصيصه</h4>
            {s.templatesLoading ?
              <div className="text-center">
                <AiOutlineLoading3Quarters className="animation-spin h1" />
              </div>
            :
              s.templatesData?.length > 0 ?
                <Row>
                  {s.templatesData.map((data, key) => {
                    return(
                      <Col md="4" className="mb-3" key={key}>
                        <div className={`card-website-create-template bg-white ${s.selectedTemplate === data.id ? 'active' : ''}`} onClick={() => this.setState({selectedTemplate: data.id})}>
                          <div className="card-website-create-template-img-container">
                            <img src={ MAIN_LINK+data.screenshot } width="100%" height="auto" />
                          </div>
                          <h6 className="text-center mb-3 fw-bold py-2">{data.name}</h6>
                          <a href={ MAIN_LINK+'/templates/'+data.template_path.split('/')[data.template_path.split('/').length - 1]+'/index.html' } target="_blank">
                            <DefaultButton className="card-website-create-template-preview-btn" block size={'md'} color={'success'}>
                              <AiOutlineEye />{' '}
                              معاينة
                            </DefaultButton>
                          </a>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              :
                <DefaultAlert color={'secondary'}>لا توجد بيانات</DefaultAlert>
            }
          </Container>

          <div className="website-create-footer shadow-sm">
            <Container>
              <Row>
                <Col md="6">
                </Col>
                <Col md="6" className="text-end">
                  <DefaultButton 
                    color="light" 
                    className="text-danger me-3 px-5 rounded-pill"
                    onClick={() => this.props.navigate(-1)}
                  >
                    رجوع
                  </DefaultButton>
                  <DefaultButton 
                    color="dark" 
                    className="px-5 rounded-pill" 
                    disabled={!s.selectedTemplate}
                    onClick={() => this.props.navigate('/website/infos/'+s.selectedTemplate)}
                  >
                    التالي
                  </DefaultButton>
                </Col>
              </Row>
            </Container>
          </div>
        </main>
      </div>
    );
  }
}

export default withRouter(CreateWebsiteTemplate);
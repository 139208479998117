import React, { Component } from "react";
import { Card, CardBody, Col, Container, FormGroup, Label, Row, Table } from "reactstrap";
import { AiOutlineCloseCircle, AiOutlineCloudDownload, AiOutlineLoading3Quarters, AiOutlineMail, AiOutlinePlusCircle, AiOutlinePrinter, AiOutlineSave } from 'react-icons/ai';
import { DefaultButton } from "../../../../components/Buttons/default";
import { API_LINK, MAIN_LINK } from "../../../../utils/constants";
import { BottomLeftAlert } from "../../../../components/Alerts";
import { withRouter } from "../../../../components/Router/withRouter";
import { DefaultSelect } from "../../../../components/Forms/Inputs/Select";

class PaymentsAddInvoicePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      createLoading: true,
      updateLoading: false,
      logoLoading: false,

      id: props?.params?.id || '',
      logo: null,
      ownerCompanyName: 'إسم شركتك',
      ownerName: 'إسمك الكامل',
      ownerAddress: 'عنوانك الكامل',
      ownerCountry: 'دولتك',
      contactId: null,
      customerCompanyName: 'إسم شركة عميلك',
      customerName: 'إسم الزبون الكامل',
      customerAddress: 'عنوان الزبون الكامل',
      customerCountry: 'دولة الزبون الكاملة',
      date: '2023-01-01',
      dueDate: '2023-01-01',
      products: [
        {
          name: '',
          description: '',
          unit_price: '0',
          qty: '1',
          tax: '0'
        }
      ],
      discount: 0,
      amount: 0,
      currencyCode: '',
      paymentStatus: '',

      totalBeforeTax: 0,
      totalAfterTax: 0,

      alertText: null,
      alertType: 'success'
    }
  }

  componentDidMount() {
    this.createInvoice();
  }

  createInvoice() {
    this.setState({createLoading: true});
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/payments/invoice/store', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        id: this.state.id
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        this.setState({
          id: json.invoice.id,
          logo: json.invoice.logo_path,
          ownerCompanyName: json.invoice.owner_company_name,
          ownerName: json.invoice.owner_name,
          ownerAddress: json.invoice.owner_address,
          ownerCountry: json.invoice.owner_country,
          customerCompanyName: json.invoice.contact_company_name,
          customerName: json.invoice.contact_name,
          customerAddress: json.invoice.contact_address,
          customerCountry: json.invoice.contact_country,
          date: json.invoice.date,
          dueDate: json.invoice.due_date,
          products: json.invoice.products,
          paymentStatus: json.invoice.payment_status,
          discount: json.invoice.discount,
          currencyCode: json.invoice.currency_code,

          createLoading: false
        }, () => {
          this.calcTotal();
        });
      }
    })
  }

  updateInvoice() {
    this.setState({updateLoading: true});
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/payments/invoice/update', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        id: this.state.id,
        owner_company_name: this.state.ownerCompanyName,
        owner_name: this.state.ownerName,
        owner_address: this.state.ownerAddress,
        owner_country: this.state.ownerCountry,
        contact_id: this.state.contactId,
        contact_name: this.state.customerName,
        contact_address: this.state.customerAddress,
        contact_country: this.state.customerCountry,
        date: this.state.date,
        due_date: this.state.dueDate,
        amount: this.state.amount,
        payment_status: this.state.paymentStatus,
        products: this.state.products,
        discount: this.state.discount,
        currency_code: this.state.currencyCode
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.setState({
          alertType: 'success',
          alertText: json.message,
          updateLoading: false
        });
      } else {
        this.setState({
          alertType: 'danger',
          alertText: json.message,
          updateLoading: false
        });
      }
    })
  }

  updateLogo() {
    this.setState({logoLoading: true});
    const user = localStorage.getItem('user');

    let formData = new FormData();
    formData.append('id', this.state.id);
    formData.append('logo', this.state.logo);

    fetch(API_LINK+'/payments/invoice/logo/update', {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: formData
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.setState({
          alertType: 'success',
          alertText: json.message
        });
      } else {
        this.setState({
          alertType: 'danger',
          alertText: json.message
        });
      }
    })
  }

  addProduct() {
    this.setState({
      products: [
        ...this.state.products,
        {name: '', description: '', qty: '1', unit_price: '0', tax: '0'}
      ]
    });
  }

  removeProduct(index) {
    if(this.state.products?.length > 1) {
      const updatedProducts = this.state.products;
      updatedProducts.splice(index, 1);
      this.setState({
        products: updatedProducts
      });
    }
  }

  handleLogoChange(event) {
    this.setState({
      logo: event.target.files[0]
    }, () => {
      this.updateLogo();
    });
  }

  handleChange(event) {
    const value = event.target.value;
    this.setState({
      ...this.state,
      [event.target.name]: value
    });
  }

  handleProductChange(index, field, event) {
    const updatedProducts = [...this.state.products];
    updatedProducts[index][field] = event.target.value;
    this.setState({ 
      products: updatedProducts 
    }, () => {
      this.calcTotal();
    });
  }

  printDiv() {
    const content = document.getElementById('invoice');
    if (content) {
      const printWindow = window.open('', '', 'width=600,height=600');
      printWindow.document.open();
      printWindow.document.write('<html dir="rtl"><head><title>Print</title></style></head><body>');
      printWindow.document.write(content.innerHTML);
      printWindow.document.write('</body></html>');
      printWindow.document.close();
      printWindow.print();
      printWindow.close();
    }
  }

  downloadInvoice() {
    
  }

  calcTotal() {
    let total = 0;
    let amount = 0;
    this.state.products.forEach(product => {
      total += Number(product.unit_price*product.qty);
      amount += Number(product.unit_price*product.qty)+Number(((product.unit_price*product.tax)/100)*product.qty);
    });

    this.setState({
      totalBeforeTax: total,
      amount: Number(amount)-Number((amount*this.state.discount)/100)
    })
  }

  render() {
    const s = this.state;

    return(
      <Container>
        {s.createLoading ?
          <AiOutlineLoading3Quarters className="animation-spin" />
        :
          <Row>
            <Col md="9">
              <Card className="mb-5">
                <CardBody className="p-5">
                  <div id="invoice">
                    <Row>
                      <Col md="6">
                        <div className="h1">فاتورة</div>
                      </Col>
                      <Col md="6" className="text-end">
                        <img src={ s.logo ? s.logo?.includes('invoices') ? MAIN_LINK+s.logo : URL.createObjectURL(s.logo) : require('../../../../assets/brand-black.png') } style={{maxHeight: '60px'}} onClick={() => document.getElementById('logoInput').click()} />
                        <input type="file" className="d-none" onChange={(event) => this.handleLogoChange(event)} id="logoInput" />
                      </Col>
                    </Row>

                    <div className="mb-5">
                      <div>
                        <input 
                          type="text" 
                          className="border-0" 
                          value={s.ownerCompanyName} 
                          name="ownerCompanyName" 
                          onChange={(event) => this.handleChange(event)} 
                          placeholder="إسم شركتك"
                        />
                      </div>
                      <div>
                        <input 
                          type="text" 
                          value={s.ownerName} 
                          className="border-0" 
                          name="ownerName" 
                          onChange={(event) => this.handleChange(event)} 
                          placeholder="إسمك الكامل"
                        />
                      </div>
                      <div>
                        <input 
                          type="text" 
                          value={s.ownerAddress} 
                          className="border-0" 
                          name="ownerAddress" 
                          onChange={(event) => this.handleChange(event)} 
                          placeholder="عنوانك الكامل هنا"
                        />
                      </div>
                      <div>
                        <input 
                          type="text" 
                          value={s.ownerCountry} 
                          className="border-0" 
                          name="ownerCountry" 
                          onChange={(event) => this.handleChange(event)} 
                          placeholder="دولتك"
                        />
                      </div>
                    </div>

                    <Row className="mb-5">
                      <Col md="6">
                        <b>المسؤول</b>
                        <div>
                          <input 
                            type="text" 
                            value={s.customerName} 
                            className="border-0" 
                            name="customerName" 
                            onChange={(event) => this.handleChange(event)} 
                            placeholder="إسم الزبون"
                          />
                        </div>
                        <div>
                          <input 
                            type="text" 
                            value={s.customerCompanyName} 
                            className="border-0" 
                            name="customerCompanyName" 
                            onChange={(event) => this.handleChange(event)} 
                            placeholder="إسم شركة الزبون"
                          />
                        </div>
                        <div>
                          <input 
                            type="text" 
                            value={s.customerAddress} 
                            className="border-0" 
                            name="customerAddress" 
                            onChange={(event) => this.handleChange(event)} 
                            placeholder="العنوان الكامل للزبون"
                          />
                        </div>
                        <div>
                          <input 
                            type="text" 
                            value={s.customerCountry} 
                            className="border-0" 
                            name="customerCountry" 
                            onChange={(event) => this.handleChange(event)} 
                            placeholder="دولة الزبون"
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <Row className="mb-2">
                          <Col xs="6">
                            <b>الفاتورة: </b>
                          </Col>
                          <Col xs="6">
                            <span>INV-{s.id}</span>
                          </Col>
                        </Row>
                        <Row className="mb-2">
                          <Col xs="6">
                            <b>التاريخ: </b>
                          </Col>
                          <Col xs="6">
                            <input 
                              type="date" 
                              value={s.date} 
                              className="border-0" 
                              name="date" 
                              onChange={(event) => this.handleChange(event)} 
                            />
                          </Col>
                        </Row>
                        <Row className="mb-2">
                          <Col xs="6">
                            <b>تاريخ الدفع: </b>
                          </Col>
                          <Col xs="6">
                            <input 
                              type="date" 
                              value={s.dueDate} 
                              className="border-0"
                              name="dueDate" 
                              onChange={(event) => this.handleChange(event)}  
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <div>
                      <Table className="table-bliz" responsive>
                        <thead>
                          <tr>
                            <th>إسم المنتج</th>
                            <th>الكمية</th>
                            <th>سعر الوحدة</th>
                            <th>الضريبة %</th>
                            <th>الإجمالي</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {s.products?.map((data, key) => {
                            return(
                              <tr key={key}>
                                <td>
                                  <textarea style={{width: '100%'}} rows={'1'} onChange={(event) => this.handleProductChange(key, 'name', event)}>
                                    {data.name}
                                  </textarea>
                                </td>
                                <td>
                                  <input 
                                    type="number"
                                    style={{width: '60px'}} 
                                    maxLength={7} 
                                    value={data.qty} 
                                    onChange={(event) => this.handleProductChange(key, 'qty', event)} 
                                  />
                                </td>
                                <td>
                                  <input 
                                    type="text" 
                                    style={{width: '100px'}} 
                                    maxLength={10} 
                                    value={data.unit_price} 
                                    onChange={(event) => this.handleProductChange(key, 'unit_price', event)} 
                                  />
                                </td>
                                <td>
                                  <input 
                                    type="text" 
                                    style={{width: '100px'}} 
                                    value={data.tax} 
                                    maxLength={3} 
                                    onChange={(event) => this.handleProductChange(key, 'tax', event)} 
                                  />
                                </td>
                                <td>
                                  {data.qty*data.unit_price} + {(((data.unit_price*data.tax)/100)*data.qty).toFixed(2)}
                                </td>
                                <td>
                                  <AiOutlineCloseCircle className="text-danger cursor-pointer" onClick={() => this.removeProduct(key)} />
                                </td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td colSpan={'3'} rowSpan={'30'} className="border-0">
                              <DefaultButton color={'dark'} size={'sm'} onClick={() => this.addProduct()}>
                                <AiOutlinePlusCircle />{' '}
                                إضافة
                              </DefaultButton>
                            </td>
                            <td>
                              المجموع قبل الضريبة
                            </td>
                            <td colSpan={'2'} className="text-end align-middle">
                              <b>{s.totalBeforeTax}</b>
                            </td>
                          </tr>
                          {s.products?.map((data, key) => {
                            if(data.tax && Number(data.tax) > 0) {
                              return(
                                <tr key={key}>
                                  <td>
                                    الضريبة ({Number(data.tax).toFixed(1)}%)
                                  </td>
                                  <td colSpan={'2'} className="text-end">
                                    <b>{(((data.unit_price*data.tax)/100)*data.qty).toFixed(2)}</b>
                                  </td>
                                </tr>
                              );
                            }
                          })}
                          <tr>
                            <td>
                              الخصم %
                            </td>
                            <td colSpan={'2'} className="text-end">
                              <input 
                                type="text" 
                                name="discount" 
                                className="border-0 fw-bold" 
                                dir="ltr" 
                                value={s.discount} 
                                onChange={(event) => {this.handleChange(event); setTimeout(() => {this.calcTotal()},100)}}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0">
                              المجموع
                            </td>
                            <td colSpan={'2'} className="text-end border-0">
                              <b>{s.amount.toFixed(2)} {s.currencyCode}</b>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card className="mb-3">
                <CardBody>
                  <FormGroup>
                    <Label>العملة: </Label>
                    <DefaultSelect size={'md'} name="currencyCode" value={s.currencyCode} onChange={(event) => this.handleChange(event)}>
                      <option value="">اختر</option>
                      <option value="SAR">ريال سعودي (ر.س)</option>
                    </DefaultSelect>
                  </FormGroup>
                  <div>
                    <Label>حالة الدفع: </Label>
                    <DefaultSelect name={'paymentStatus'} size={'md'} value={s.paymentStatus} onChange={(event) => this.handleChange(event)}>
                      <option value="0">غير مدفوعة</option>
                      <option value="1">مدفوعة</option>
                    </DefaultSelect>
                  </div>
                </CardBody>
              </Card>
              <Card className="mb-5">
                <CardBody>
                  <DefaultButton 
                    block 
                    color={'dark'} 
                    onClick={() => this.updateInvoice()}
                    isLoading={s.updateLoading}
                    disabled={s.updateLoading}
                    className="mb-3"
                  >
                    <AiOutlineSave />{' '}
                    حفظ
                  </DefaultButton>

                  {/* <DefaultButton block color={'primary'} className="mb-3">
                    <AiOutlineMail />{' '}
                    إرسال عبر البريد
                  </DefaultButton> */}

                  {/* <DefaultButton block className="mb-3" onClick={() => this.printDiv()}>
                    <AiOutlinePrinter />{' '}
                    طباعة
                  </DefaultButton>
            
                  <DefaultButton block color={'info'} onClick={() => this.downloadInvoice()}>
                    <AiOutlineCloudDownload />{' '}
                    تحميل
                  </DefaultButton> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        }

        {s.alertText &&
          <BottomLeftAlert color={s.alertType} onClose={() => this.setState({alertText: null})}>
            {s.alertText}
          </BottomLeftAlert>
        }
      </Container>
    );
  }
}

export default withRouter(PaymentsAddInvoicePage);
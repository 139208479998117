import React, { Component } from "react";
import { Outlet } from "react-router-dom";
import { Header } from "../components/Header";
import { Sidebar } from "../components/Sidebar";
import './index.css';

class MainLayout extends Component {
  state = {
    sidebarToggle: false
  }

  render() {
    const s = this.state;

    return(
      <div>
        <Sidebar size={s.sidebarToggle ? 'toggled' : ''} onClose={() => this.setState({sidebarToggle: !this.state.sidebarToggle})} />
        <div className={s.sidebarToggle ? "admin-body toggled" : "admin-body"}>
          <Header 
            onLogout={this.props.onLogout} 
            onSidebarToggle={() => this.setState({sidebarToggle: !this.state.sidebarToggle})}
          />

          <div className="admin-content float-end w-100">
            <Outlet />
          </div>
          <div className="clearfix"></div>
        </div>
      </div>
    );
  }
}

export default MainLayout;
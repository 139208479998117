import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import './index.css';
import { DefaultButton } from "../../../components/Buttons/default";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { DefaultAlert } from "../../../components/Alerts";
import { API_LINK, MAIN_LINK } from "../../../utils/constants";
import { withRouter } from "../../../components/Router/withRouter";

class CreateWebsitePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      categotiesLoading: true,
      categoriesData: null,
      selectedCatgory: null
    }
  }

  componentDidMount() {
    this.getCategories();
  }
  
  getCategories() {
    this.setState({categotiesLoading: true});
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/template/categories', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        this.setState({
          categoriesData: json.categories,
          categotiesLoading: false
        });
      }
      else
      {
        this.setState({
          alertText: json.message,
          categotiesLoading: false
        });
      }
    })
  }

  render() {
    const s = this.state;
    return(
      <div className="page-auth">
        <header className="py-3 bg-transparent">
          <Container>
            <Row>
              <Col xs="6">
                <div>
                  <a href={MAIN_LINK}>
                    <img src={ require('../../../assets/logo-rtl-black.png') } width="100px" />
                  </a>
                </div>
              </Col>
              <Col xs="6" className="text-end">
                <Link to="/register">تسجيل الخروج</Link>
              </Col>
            </Row>
          </Container>
        </header>

        <main>
          <Container className="mb-5 pb-5 mt-5">
            <h4 className="fw-bold text-center mb-5">أخبرنا ماذا تريد بالضبط؟</h4>
            {s.categotiesLoading ?
              <div className="text-center">
                <AiOutlineLoading3Quarters className="animation-spin h1" />
              </div>
            :
              s.categoriesData?.length > 0 ?
                <Row>
                  {s.categoriesData.map((data, key) => {
                    return(
                      <Col lg="3" md="4" className="mb-3" key={key}>
                        <div 
                          className={`card-website-create-category bg-white ${s.selectedCatgory === data.id ? 'active' : ''}`} 
                          onClick={() => this.setState({selectedCatgory: data.id})}
                        >
                          <div className="card-website-create-img">
                            <img src={ MAIN_LINK+data.image } width="100%" />
                          </div>
                          <h6 className="text-center py-2">{data.name}</h6>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              :
                <DefaultAlert color={'secondary'}>لا توجد بيانات</DefaultAlert>
            }
          </Container>

          <div className="website-create-footer shadow-sm">
            <Container>
              <Row>
                <Col md="6">
                </Col>
                <Col md="6" className="text-end">
                  <Link to={'/dashboard/home'}>
                    <DefaultButton color="light" className="text-danger px-5 rounded-pill me-3">غلق</DefaultButton>
                  </Link>
                  <DefaultButton 
                    color="dark" 
                    className="px-5 rounded-pill" 
                    disabled={!s.selectedCatgory}
                    onClick={() => this.props.navigate('/website/template/'+s.selectedCatgory)}
                  >
                    التالي
                  </DefaultButton>
                </Col>
              </Row>
            </Container>
          </div>
        </main>
      </div>
    );
  }
}

export default withRouter(CreateWebsitePage);
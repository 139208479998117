import React, { Component } from "react";
import BuilderHeader from "./Header";
import { GoDeviceDesktop } from "react-icons/go";
import { withRouter } from "../../components/Router/withRouter";
import { API_LINK, MAIN_LINK } from "../../utils/constants";
import { BottomLeftAlert } from "../../components/Alerts";
import { ConfirmationModal } from "../../components/Modals/Confirmation";
import BuilderAddSection from "./Add/Section";
import BuilderPagesModal from "./Modals/Pages";
import BuilderAddElement from "./Add/Element";
import EditElementModal from "./Edit/Element";
import BuilderSectionSettingsModal from "./Edit/Section";
import './styles.css';
import BuilderLayoutSettingsModal from "./Edit/Layout";
import BuilderLanguagesModal from "./Modals/Languages";
import { i18n } from "../../i18n";
import BuilderEditNavbarModal from "./Edit/Navbar";
import BuilderCopySectionModal from "./Modals/Sections/Copy";

class BuilderPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      iframeViewport: 'large',
      websiteLoading: true,
      websiteData: null,
      addSectionInfos: null,
      pagesModalShown: false,
      languagesModalShown: false,
      addElementData: null,
      selectedElement: null,
      editElement: null,
      deleteElementLoading: false,
      deleteElement: null,
      itemToClone: null,

      sectionToEditInfos: null,
      navbarToEditInfos: null,
      layoutToEditInfos: null,

      selectedPage: null,
      selectedLanguage: null,

      preview: 'desktop',

      text: null,
      selectedImageData: null,
      sectionToDestroy: null,
      sectionToCopy: null,

      updateSectionLoading: false,
      destroySectionLoading: false,
      alertText: null,
      alertType: 'danger',
      
      needUpdate: false,

      publishLoading: false
    }
  }

  componentDidMount() {
    this.getWebsite();
    window.addEventListener('message', this.handleIframeMessage);
  }

  getWebsite() {
    const user = localStorage.getItem('user');
    const websiteName = this.props.params.website;

    fetch(API_LINK+'/builder/website/'+websiteName, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        this.setState({
          websiteData: json.website,
          selectedPage: json.website.pages[0],
          selectedLanguage: json.website.languages[0],
          websiteLoading: false
        });
      }
      else
      {
        this.setState({
          alertText: json.message,
          websiteLoading: false
        }, () => {
          this.props.navigate('/notfound');
        });
      }
    })
  }
  
  handleIframeMessage = (event) => {
    this.setState({
      needUpdate: !this.state.needUpdate,
    }, () => {
      setTimeout(() => {
        if(event.data.type !== 'updateSectionOrder') {
          this.setState({
            addSectionInfos: null,
            addElementData: null,
            sectionToEditInfos: null,
            layoutToEditInfos: null,
            editElement: null,
            navbarToEditInfos: null,
            iframeViewport: 'large'
          });
        }

        if (event.data.type === 'imageClick') {
          this.setState({
            editElement: event.data.element,
            iframeViewport: 'medium'
          });
        }

        if(event.data.type === 'iframeVideoClick') {
          this.setState({
            editElement: event.data.element,
            iframeViewport: 'medium'
          });
        }
    
        if(event.data.type === 'sectionDelete') {
          this.setState({
            sectionToDestroy: event.data.parentInfo
          });
        }
    
        if(event.data.type === 'sectionEdit') {
          this.setState({
            sectionToEditInfos: event.data.element,
            iframeViewport: 'medium'
          });
        }
    
        if(event.data.type === 'sectionAddElement') {
          this.setState({
            addElementData: event.data,
            iframeViewport: 'small'
          });
        }

        if(event.data.type === 'copySection') {
          this.setState({
            sectionToCopy: event.data
          });
        }
    
        if(event.data.type === 'addAfterElement') {
          this.setState({
            addElementData: event.data,
            iframeViewport: 'small'
          });
        }
    
        if (event.data.type === 'updateSectionOrder') {
          this.updateSectionsOrder(event.data.orderData);
        }
    
        if(event.data.type === 'addSectionButtonClick') {
          this.setState({
            addSectionInfos: event.data, 
            iframeViewport: false
          });
        }
    
        if(event.data.type === 'removeSelectable') {
          this.setState({
            selectedElement: false
          });
        }
    
        if(event.data.type === 'setSelectable') {
          this.setState({
            selectedElement: event.data.element
          });
        }
    
        if(event.data.type === 'editElement') {
          this.setState({
            editElement: event.data.element,
            iframeViewport: 'medium'
          });
        }
    
        if(event.data.type === 'deleteElement') {
          this.setState((prevState) => ({
            deleteElement: {
              ...prevState.deleteElement,
              parent: event.data.element.parentInfo,
              old_content: event.data.element.element,
              blizId: event.data.element.blizId
            }
          }));
        }
    
        if(event.data.type === 'cloneItem') {
          this.setState({
            itemToClone: event.data.element
          }, () => {
            this.cloneElement();
          });
        }
    
        if(event.data.type === 'cloneLayoutItem') {
          this.cloneLayoutItem(event.data);
        }
    
        if(event.data.type === 'editLayoutItem') {
          this.setState({
            layoutToEditInfos: event.data.element,
            iframeViewport: 'medium'
          });
        }
    
        if(event.data.type === 'deleteItem') {
          this.setState({
            itemToClone: event.data.element
          }, () => {
            this.deleteComponent();
          });
        }
    
        if(event.data.type === 'editMap') {
          this.setState({
            editElement: event.data.element,
            iframeViewport: 'medium'
          });
        }
    
        if(event.data.type === 'editIcon') {
          this.setState({
            editElement: event.data.element,
            iframeViewport: 'medium'
          });
        }
    
        if(event.data.type === 'deleteLayoutItem') {
          this.deleteLayoutItem(event.data);
        }
    
        if(event.data.type === 'aiGenerateSection') {
          this.aiGenerateSection(event.data.parentInfo.sectionIndex);
        }
    
        if(event.data.type === 'showAlert') {
          this.setState({
            alertType: 'warning',
            alertText: event.data.message
          });
        }

        if(event.data.type === 'editNav') {          
          this.setState({
            navbarToEditInfos: event.data,
            iframeViewport: 'medium'
          });
        }
      }, 10);

    });
  }

  aiGenerateSection(sectionIndex) {
    const user = localStorage.getItem('user');
    const website_id = JSON.parse(this.props.website.website).id;

    fetch(API_LINK+'/builder/ai/section/generate', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        website_id: website_id,
        page_id: this.state.selectedPage.id,
        lang: this.state.selectedLanguage.language.short,
        sectionIndex: sectionIndex
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(!json.success) {
        this.setState({
          alertText: json.message
        })
      }

      const iframe = document.getElementById('builder-iframe');
      const message = {
        type: 'generateSection',
        htmlContent: json?.html,
        styles: json?.css,
        sectionIndex: sectionIndex
      };

      iframe.contentWindow.postMessage(message, '*');
    })
  }

  deleteLayoutItem(data) {
    const user = localStorage.getItem('user');
    const website_id = JSON.parse(this.props.website.website).id;

    fetch(API_LINK+'/builder/section/layout/item/remove', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        website_id: website_id,
        page_id: this.state.selectedPage.id,
        lang: this.state.selectedLanguage.language.short,
        data: data
      })
    });
  }

  handleEditElementChange(event) {
    const { name, value } = event.target;

    this.setState((prevState) => ({
      editElement: {
        ...prevState.editElement,
        [name]: value,
      },
    }));
  }

  handleSectionSettingsChange(event) {
    const { name, value } = event.target;

    this.setState((prevState) => ({
      sectionToEditInfos: {
        ...prevState.sectionToEditInfos,
        [name]: value,
      },
    }));
  }

  handleLayoutSettingsChange(event) {
    const { name, value } = event.target;

    this.setState((prevState) => ({
      layoutToEditInfos: {
        ...prevState.layoutToEditInfos,
        [name]: value,
      },
    }));
  }

  cloneElement() {
    const user = localStorage.getItem('user');
    const website_id = JSON.parse(this.props.website.website).id;

    fetch(API_LINK+'/builder/section/element/clone', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        website_id: website_id,
        page_id: this.state.selectedPage.id,
        lang: this.state.selectedLanguage.language.short,
        sectionIndex: this.state.itemToClone.parent.sectionIndex,
        itemIndex: this.state.itemToClone.itemIndex,
        itemTag: this.state.itemToClone.itemTag
      })
    })
  }

  cloneLayoutItem(data) {
    const user = localStorage.getItem('user');
    const website_id = JSON.parse(this.props.website.website).id;

    fetch(API_LINK+'/builder/section/layout/item/clone', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        website_id: website_id,
        page_id: this.state.selectedPage.id,
        lang: this.state.selectedLanguage.language.short,
        data: data
      })
    })
  }

  deleteComponent() {
    const user = localStorage.getItem('user');
    const website_id = JSON.parse(this.props.website.website).id;

    fetch(API_LINK+'/builder/section/item/remove', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        website_id: website_id,
        page_id: this.state.selectedPage.id,
        lang: this.state.selectedLanguage.language.short,
        sectionIndex: this.state.itemToClone.parent.sectionIndex,
        itemIndex: this.state.itemToClone.itemIndex,
        itemTag: this.state.itemToClone.itemTag
      })
    })
    .then((response) => response.json())
    .then((json) => {
      this.setState({
        alertText: json.message
      });
    })
  }

  deleteElement() {
    this.setState({deleteElementLoading: true});
    const user = localStorage.getItem('user');
    const website_id = JSON.parse(this.props.website.website).id;

    const iframe = document.getElementById('builder-iframe');
    const message = {
      type: 'removeElement',
      sectionIndex: this.state.deleteElement.parent.sectionIndex,
      blizId: this.state.deleteElement.blizId
    };

    iframe.contentWindow.postMessage(message, '*');

    fetch(API_LINK+'/builder/section/element/remove', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        website_id: website_id,
        page_id: this.state.selectedPage.id,
        lang: this.state.selectedLanguage.language.short,
        sectionIndex: this.state.deleteElement.parent.sectionIndex,
        blizId: this.state.deleteElement.blizId
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(!json.success) {
        this.setState({
          alertText: json.message
        });
      }

      this.setState({
        deleteElementLoading: false,
        deleteElement: null
      });
    })
  }
  
  componentWillUnmount() {
    window.removeEventListener('message', this.handleIframeMessage);
  }

  updateSectionsOrder(data) {
    this.setState({updateSectionLoading: true});
    const user = localStorage.getItem('user');
    const website_id = JSON.parse(this.props.website.website).id;

    fetch(API_LINK+'/builder/section/order/update', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        website_id: website_id,
        page_id: this.state.selectedPage.id,
        lang: this.state.selectedLanguage.language.short,
        data: data
      })
    });
  }

  destroySection() {
    this.setState({destroySectionLoading: true});
    const user = localStorage.getItem('user');
    const website_id = JSON.parse(this.props.website.website).id;

    fetch(API_LINK+'/builder/section/destroy', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        website_id: website_id,
        page_id: this.state.selectedPage.id,
        lang: this.state.selectedLanguage.language.short,
        index: this.state.sectionToDestroy.sectionIndex
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(!json.success) {
        this.setState({
          alertText: json.message,
          destroySectionLoading: false
        });
      } else {
        const iframe = document.getElementById('builder-iframe');
        const message = {
          type: 'confirmSectionDelete',
          index: this.state.sectionToDestroy.sectionIndex
        };

        iframe.contentWindow.postMessage(message, '*');

        this.setState({
          sectionToDestroy: null,
          destroySectionLoading: false
        })
      }
    })
  }

  publish() {
    this.setState({
      publishLoading: true
    }, () => {
      setTimeout(() => {
        this.setState({
          publishLoading: false,
          alertType: 'success',
          alertText: 'لقد تم نشر الموقع بنجاح'
        });
      }, 1500);
    });
  }

  render() {
    const s = this.state;

    if(s.websiteLoading || !s.websiteData) {
      return(
        <div className="d-flex justify-content-center align-items-center" style={{minHeight: '100vh'}}>
          <img src={require('../../assets/brand-black.png')} width="80px" className="animation-spin" />
        </div>
      );
    } else {
      return(
        <div>
          <div className="builder-mobile-display">
            <div className="container">
              <div className="text-center mb-4">
                <GoDeviceDesktop size={80} />
              </div>
              <h5 className="text-center fw-bold">{ i18n.t('builder.use_desktop') }</h5>
            </div>
          </div>

          <BuilderHeader 
            preview={s.preview}
            onPreviewChange={(preview) => this.setState({preview: preview})}
            onPublish={() => this.publish()}
            isPublishLoading={s.publishLoading}
            websiteData={s.websiteData}
            selectedPage={s.selectedPage}
            selectedLanguage={s.selectedLanguage}
            onPageClick={() => this.setState({pagesModalShown: true})}
            onLanguageClick={() => this.setState({languagesModalShown: true})}
          />
  
          <div style={{height: window.innerHeight-62, overflow: 'hidden'}}>
            <div 
              className={s.iframeViewport === 'large' ? 
                "builder-container text-center float-end "+s.preview : 
                "builder-container builder-container-transformed text-center float-end "+s.preview
              } 
              style={
                {width: s.iframeViewport === 'large' ? 
                  '100%' : 
                s.iframeViewport === 'medium' ?
                  (window.innerWidth-106) :
                  (window.innerWidth-364)
                }
              }
            >
              <iframe 
                src={MAIN_LINK+"/w/"+JSON.parse(localStorage.getItem('user')).username+"/"+s.websiteData.name+"/"+s.selectedPage.slug+"/"+s.selectedLanguage?.language.short+"?editable=true&reload="}
                width={'100%'} 
                height={
                        s.iframeViewport === 'large' ? 
                          window.innerHeight-70 : 
                          window.innerHeight+130
                }
                className="shadow"
                id="builder-iframe"
              ></iframe>
            </div>
          </div>

          <BuilderPagesModal 
            isOpen={s.pagesModalShown}
            pages={s.websiteData.pages}
            toggle={() => this.setState({pagesModalShown: false})}
            onSuccess={() => this.getWebsite()}
            onError={(error) => this.setState({alertText: error})}
            selectedPage={s.selectedPage}
            selectedLanguage={s.selectedLanguage}
            onPageChange={(page) => this.setState({selectedPage: page, pagesModalShown: false})}
          />

          <BuilderLanguagesModal 
            isOpen={s.languagesModalShown}
            toggle={() => this.setState({languagesModalShown: false})}
            languages={s.websiteData.languages}
            selectedLanguage={s.selectedLanguage}
            onLanguageChange={(language) => this.setState({selectedLanguage: language, languagesModalShown: false})}
            onSuccess={() => this.getWebsite()}
            onError={(error) => this.setState({alertText: error})}
          />

          {s.sectionToEditInfos &&
            <BuilderSectionSettingsModal 
              toggle={() => this.setState({sectionToEditInfos: null, iframeViewport: 'large'})}
              data={s.sectionToEditInfos}
              selectedPage={s.selectedPage}
              selectedLanguage={s.selectedLanguage}
              handleChange={(event) => this.handleSectionSettingsChange(event)}
              needUpdate={s.needUpdate}
            />
          }

          {s.navbarToEditInfos &&
            <BuilderEditNavbarModal 
              toggle={() => this.setState({navbarToEditInfos: null, iframeViewport: 'large'})}
              data={s.navbarToEditInfos}
              selectedPage={s.selectedPage}
              selectedLanguage={s.selectedLanguage}
              needUpdate={s.needUpdate}
              pages={s.websiteData.pages}
              onError={(error) => this.setState({alertText: error})}
            />
          }

          {s.addSectionInfos &&
            <BuilderAddSection 
              selectedPage={s.selectedPage} 
              selectedLanguage={s.selectedLanguage}
              infos={s.addSectionInfos} 
              toggle={() => this.setState({addSectionInfos: null, iframeViewport: 'large'})} 
            />
          }

          {s.addElementData &&
            <BuilderAddElement 
              data={s.addElementData}
              toggle={() => this.setState({addElementData: null, iframeViewport: 'large'})} 
              selectedPage={s.selectedPage}
              selectedLanguage={s.selectedLanguage}
            />
          }

          {s.editElement &&
            <EditElementModal 
              toggle={() => this.setState({editElement: null, iframeViewport: 'large'})}
              data={s.editElement}
              selectedPage={s.selectedPage}
              selectedLanguage={s.selectedLanguage}
              handleChange={(event) => this.handleEditElementChange(event)}
              needUpdate={s.needUpdate}
            />
          }

          {s.layoutToEditInfos &&
            <BuilderLayoutSettingsModal 
              data={s.layoutToEditInfos}
              toggle={() => this.setState({layoutToEditInfos: null, iframeViewport: 'large'})}
              selectedPage={s.selectedPage}
              handleChange={(event) => this.handleLayoutSettingsChange(event)}
              needUpdate={s.needUpdate}
              selectedLanguage={s.selectedLanguage}
            />
          }

          {s.sectionToCopy &&
            <BuilderCopySectionModal 
              isOpen={s.sectionToCopy ? true : false}
              toggle={() => this.setState({sectionToCopy: null})}
              pages={s.websiteData.pages}
              onError={(error) => this.setState({alertText: error})}
              selectedPage={s.selectedPage}
              selectedLanguage={s.selectedLanguage}
              data={s.sectionToCopy}
            />
          }

          <ConfirmationModal 
            isOpen={s.sectionToDestroy}
            confirmationText={'حذف'}
            cancelText={'إلغاء'}
            onConfirm={() => this.destroySection()}
            toggle={() => this.setState({sectionToDestroy: null})}
            isLoading={s.destroySectionLoading}
          >
            هل حقا تريد حذف هذه الوحدة؟
          </ConfirmationModal>

          <ConfirmationModal 
            isOpen={s.deleteElement ? true : false}
            confirmationText={'حذف'}
            cancelText={'إلغاء'}
            onConfirm={() => this.deleteElement()}
            toggle={() => this.setState({deleteElement: null, deleteElement: null})}
            isLoading={s.deleteElementLoading}
          >
            هل حقا تريد حذف هذا العنصر؟
          </ConfirmationModal>

          {s.alertText &&
            <BottomLeftAlert color={s.alertType} onClose={() => this.setState({alertText: null})}>
              {s.alertText}
            </BottomLeftAlert>
          }
        </div>
      );
    }
  }
}

export default withRouter(BuilderPage);
import React, { Component } from "react";
import { Container } from "reactstrap";
import { AdminBodyTitle } from "../../../../components/Typography/Titles";

class WebsitesDataCommentsPage extends Component {
  render() {
    return(
      <Container>
        <AdminBodyTitle>التعليقات</AdminBodyTitle>
      </Container>
    );
  }
}

export default WebsitesDataCommentsPage;
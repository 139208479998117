import React, { Component } from "react";
import { Card, CardBody, Col, Container, FormGroup, Label, Row } from "reactstrap";
import { CiImageOn } from "react-icons/ci";
import { withRouter } from "../../components/Router/withRouter";
import { AdminBodyTitle } from "../../components/Typography/Titles";
import { DefaultInput } from "../../components/Forms/Inputs/default";
import './styles.css';
import { DefaultButton } from "../../components/Buttons/default";
import { API_LINK, MAIN_LINK } from "../../utils/constants";
import { BottomLeftAlert } from "../../components/Alerts";

class SettingsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      websiteData: null,
      websiteLoading: true,

      updateLoading: false,
      alertText: null,
      alertType: 'danger'
    }
  }

  componentDidMount() {
    this.getWebsiteData();
  }

  getWebsiteData() {
    this.setState({websiteLoading: true});
    const user = localStorage.getItem('user');
    const website_id = JSON.parse(this.props.website.website).id;

    fetch(API_LINK+'/website/'+website_id, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        this.setState({
          websiteData: json.website,
          websiteLoading: false
        });
      }
      else
      {
        this.setState({
          alertText: json.message,
          websiteLoading: false
        });
      }
    })
  }

  update() {
    this.setState({updateLoading: true});
    const user = localStorage.getItem('user');
    const website_id = JSON.parse(this.props.website.website).id;

    let formData = new FormData();
    Object.keys(this.state.websiteData).forEach(key => {
      if(this.state.websiteData[key] !== null && this.state.websiteData[key] !== '')
      {
        formData.append(key, this.state.websiteData[key]);
      }
    });

    fetch(API_LINK+'/website/'+website_id+'/update', {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: formData
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        this.setState({
          alertType: 'success',
          alertText: json.message,
          updateLoading: false
        }, () => {
          this.props.website.updateWebsite(json.website)
        });
      }
      else
      {
        this.setState({
          alertType: 'danger',
          alertText: json.message,
          updateLoading: false
        });
      }
    })
  }

  handleChange(event) {
    const { name, value, type } = event.target;

    this.setState((prevState) => ({
      websiteData: {
        ...prevState.websiteData,
        [name]: type === 'file' ? event.target.files[0] : value
      }
    }));
  }

  render() {
    const s = this.state;

    return(
      <Container>
        <AdminBodyTitle>إعدادات الموقع</AdminBodyTitle>
        <Card className="mb-5">
          <CardBody>
            <FormGroup>
              <Label>إسم الموقع: </Label>
              <DefaultInput 
                name={'name'}
                value={s.websiteData?.name}
                onChange={(event) => this.handleChange(event)}
              />
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md="6" className="mb-3">
                  <Label>الشعار: </Label>
                    
                  <div className="settings-image-picker" onClick={() => document.getElementById('settings-logo-input').click()}>
                    {s.websiteData?.logo ?
                      <img src={String(s.websiteData.logo)?.includes('storage') ? MAIN_LINK+s.websiteData.logo : URL.createObjectURL(s.websiteData.logo)} width={'100%'} height={'100%'} />
                    :
                      <CiImageOn size={80} />
                    }
                    <input 
                      name="logo"
                      type="file" 
                      className="d-none" 
                      id="settings-logo-input" 
                      accept="image/*" 
                      onChange={(event) => this.handleChange(event)}
                    />
                  </div>
                </Col>
                <Col md="3" className="mb-3">
                  <Label>البراند: </Label>
                  <div className="settings-image-picker" onClick={() => document.getElementById('settings-brand-input').click()}>
                    {s.websiteData?.brand ?
                      <img src={String(s.websiteData.brand)?.includes('storage') ? MAIN_LINK+s.websiteData.brand : URL.createObjectURL(s.websiteData.brand)} width={'100%'} height={'100%'} />
                    :
                      <CiImageOn size={80} />
                    }
                    <input 
                      name="brand"
                      type="file" 
                      className="d-none" 
                      id="settings-brand-input" 
                      accept="image/*" 
                      onChange={(event) => this.handleChange(event)}
                    />
                  </div>
                </Col>
                <Col md="3" className="mb-3">
                  <Label>أيقونة العنوان: </Label>
                  <div className="settings-image-picker" onClick={() => document.getElementById('settings-favicon-input').click()}>
                    {s.websiteData?.favicon ?
                      <img src={String(s.websiteData.favicon)?.includes('storage') ? MAIN_LINK+s.websiteData.favicon : URL.createObjectURL(s.websiteData.favicon)} width={'100%'} height={'100%'} />
                    :
                      <CiImageOn size={80} />
                    }
                    <input 
                      name="favicon"
                      type="file" 
                      className="d-none" 
                      id="settings-favicon-input" 
                      accept="image/*" 
                      onChange={(event) => this.handleChange(event)}
                    />
                  </div>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Label>بريد الموقع: </Label>
              <DefaultInput 
                type={'email'}
                name={'email'}
                value={s.websiteData?.email}
                onChange={(event) => this.handleChange(event)}
              />
            </FormGroup>
            <FormGroup>
              <Label>رقم الهاتف: </Label>
              <DefaultInput 
                name={'phone_number'}
                value={s.websiteData?.phone_number}
                onChange={(event) => this.handleChange(event)}
              />
            </FormGroup>
            <FormGroup>
              <Label>العنوان: </Label>
              <DefaultInput 
                name={'address'}
                value={s.websiteData?.address}
                onChange={(event) => this.handleChange(event)}
              />
            </FormGroup>
            <FormGroup>
              <Label>وصف الموقع: </Label>
              <DefaultInput 
                type={'textarea'} 
                name={'description'}
                value={s.websiteData?.description}
                onChange={(event) => this.handleChange(event)}
              />
            </FormGroup>
            <DefaultButton
              color={'dark'}
              block
              disabled={s.updateLoading || !s.websiteData?.name}
              isLoading={s.updateLoading}
              onClick={() => this.update()}
            >
              حفظ
            </DefaultButton>
          </CardBody>
        </Card>

        {s.alertText &&
          <BottomLeftAlert color={s.alertType} onClose={() => this.setState({alertText: null})}>
            {s.alertText}
          </BottomLeftAlert>
        }
      </Container>
    );
  }
}

export default withRouter(SettingsPage);
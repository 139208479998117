import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";

class MarketingSocialmediaPage extends Component {
  render() {
    return(
      <Container>
        <Row className="mt-5">
          <Col md="6" className="offset-md-3 offset-lg-4 text-center" lg="4">
            <img src={ require('../../../assets/social-media.png') } width={'100%'} />
            <h5 className="mt-4">نعمل جاهدا لتوفير هذه الميزة قريبا</h5>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default MarketingSocialmediaPage;
import React from "react";
import './styles.css';
import { MAIN_LINK } from "../../../../utils/constants";
import { MdClose } from "react-icons/md";

const ImagePreview = ({imageUrl, onClick}) => {
  return(
    <div className="modal-image-preview">
      <span className="modal-image-close" onClick={() => onClick()}>
        <MdClose size={20} />
      </span>
      <div className="modal-image-preview-body">
        <img src={MAIN_LINK+imageUrl} />
      </div>
    </div>
  );
}

export default ImagePreview;
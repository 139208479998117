import React, { useState } from "react";
import { DefaultModal } from "../../../../components/Modals/Default";
import { useWebsite } from "../../../../context/Website";
import { FormGroup, Label } from "reactstrap";
import { DefaultInput } from "../../../../components/Forms/Inputs/default";
import { DefaultSelect } from "../../../../components/Forms/Inputs/Select";
import { API_LINK } from "../../../../utils/constants";

const BuilderAddNavbarLinkModal = (props) => {
  const website = useWebsite();
  const [addLoading, setAddLoading] = useState(false);
  const [selectedLink, setSelectedLink] = useState(null);
  const [name, setName] = useState(null);
  const [link, setLink] = useState(null);

  const updateIframe = () => {
    const iframe = document.getElementById('builder-iframe');
    const message = {
      type: 'addNavlink',
      sectionIndex: props.sectionIndex,
      name: name,
      link: link
    };

    iframe.contentWindow.postMessage(message, '*');
  }

  const store = () => {
    setAddLoading(true);
    const user = localStorage.getItem('user');
    

    fetch(API_LINK+'/builder/navbar/link/store', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        website_id: JSON.parse(website.website).id,
        page_id: props.selectedPage.id,
        lang: props.selectedLanguage.language.short,
        sectionIndex: props.sectionIndex,
        name: name,
        link: link
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        updateIframe();
        props.toggle();
      } else {
        props.onError(json.message);
      }
      setAddLoading(false);
    })
  }

  return(
    <DefaultModal
      isOpen={props.isOpen}
      title={'رابط جديد'}
      confirmationText={'حفظ'}
      cancelText={'غلق'}
      toggle={props.toggle}
      onConfirm={() => store()}
      isLoading={addLoading}
      confirmationButtonDisabled={!name || !link}
    >
      <FormGroup>
        <Label>الإسم: </Label>
        <DefaultInput onChange={(event) => setName(event.target.value)} />
      </FormGroup>
      <FormGroup>
        <Label>الرابط: </Label>
        <DefaultSelect onChange={(event) => setSelectedLink(event.target.value)}>
          <option value="">اختر</option>
          <option value="1">لصفحة في الموقع</option>
          <option value="2">رابط خارجي</option>
        </DefaultSelect>
        {selectedLink === '1' ?
          <DefaultSelect className="mt-3" onChange={(event) => setLink(event.target.value)}>
            <option value="">اختر الصفحة</option>
            {props.pages && props.pages.map((data, key) => (
              <option value={JSON.parse(website.website).link+data.slug} key={key}>{ data.name }</option>
            ))}
          </DefaultSelect>
        :
          selectedLink === '2' &&
          <DefaultInput className="mt-3" onChange={(event) => setLink(event.target.value)} />
        }
      </FormGroup>
    </DefaultModal>
  );
}

export default BuilderAddNavbarLinkModal;
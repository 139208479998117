// WebsiteContext.js
import React, { createContext, useContext, useState } from 'react';

const WebsiteContext = createContext();

function WebsiteProvider({ children }) {
  const [website, setWebsite] = useState(localStorage.getItem('website') || null);

  const updateWebsite = (newWebsite) => {
    setWebsite(JSON.stringify(newWebsite));
    localStorage.setItem('website', JSON.stringify(newWebsite));
  };

  return (
    <WebsiteContext.Provider value={{ website, updateWebsite }}>
      {children}
    </WebsiteContext.Provider>
  );
}

function useWebsite() {
  return useContext(WebsiteContext);
}

export { WebsiteContext, WebsiteProvider, useWebsite };

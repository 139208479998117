import React, { useEffect, useRef, useState } from "react";
import { TbPhoto } from "react-icons/tb";
import ReactDOM from 'react-dom';
import './picker.css';
import { API_LINK } from "../../utils/constants";
import propTypes from 'prop-types';

const ImagePicker = (props) => {
  const pickerRef = useRef(null);
  const inputRef = useRef(null);
  const [ pickerWidth, setPickerWidth ] = useState();
  const [ image, setImage ] = useState(null);

  useEffect(() => {
    const currentElement = pickerRef.current;

    if (currentElement) {
      const width = currentElement.clientWidth;
      setPickerWidth(width);
    }

    if (image !== null) {
      storeImage();
    }
  }, [image]);

  const storeImage = () => {
    const user = localStorage.getItem('user');

    let formData = new FormData();
    formData.append('product_id', props.product_id);
    formData.append('image', image);

    fetch(API_LINK+'/product/image/store', {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: formData
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        setImage(null);
        props.onSuccessAdd();
      } else {
        props.onErrorAdd();
      }
    })
  };

  const handleChange = (event) => {
    setImage(event.target.files[0]);
  };

  return(
    <div 
      ref={pickerRef} 
      className="d-grid justify-content-center align-items-center image-picker cursor-pointer border" 
      style={{height: pickerWidth}}
      onClick={() => ReactDOM.findDOMNode(inputRef.current).click()}
    >
      {image ?
        <img src={URL.createObjectURL(image)} width={'100%'} height={'100%'} />
      :
        <TbPhoto size={100} />
      }

      <input 
        type="file" 
        className="d-none" 
        ref={inputRef} 
        accept="image/*"
        onChange={handleChange}
      />
    </div>
  );
}

export default ImagePicker;

ImagePicker.propTypes = {
  product_id: propTypes.number,
  onSuccessAdd: propTypes.func,
  onErrorAdd: propTypes.func
};
import React, { Component } from "react";
import { Card, CardBody, Col, Container, FormGroup, Row } from "reactstrap";
import { AdminBodyTitle } from "../../../components/Typography/Titles";
import { DefaultButton } from "../../../components/Buttons/default";
import './styles.css';
import { API_LINK } from "../../../utils/constants";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { MdOutlineCreditCardOff } from "react-icons/md";
import { ConfirmationModal } from "../../../components/Modals/Confirmation";
import { DefaultModal } from "../../../components/Modals/Default";
import PaymentForm from "../../../components/Forms/CreditCard";
import { BottomLeftAlert } from "../../../components/Alerts";

class PaymentMethodsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cardsData: null,
      cardsLoading: true,

      destroyLoading: false,
      selectedIdToDestroy: null,

      addCreditCardModalShown: false,
      addCreditCardLoading: false,
      card_number: '',
      cvc: '',
      expiry: '',
      cardholder: '',

      alertText: null
    }
  }

  componentDidMount() {
    this.getCards();
  }

  getCards() {
    this.setState({cardsLoading: true});
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/credit_cards', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      }
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        this.setState({
          cardsData: json.cards,
          cardsLoading: false
        });
      }
    })
  }

  destroyCard() {
    this.setState({cardsLoading: true});
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/credit_card/destroy', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        id: this.state.selectedIdToDestroy
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success)
      {
        this.setState({
          selectedIdToDestroy: null
        }, () => {
          this.getCards();
        });
      }
    })
  }

  storeCard() {
    this.setState({addCreditCardLoading: true});
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/credit_card/store', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        card_number: this.state.card_number,
        expiry: this.state.expiry,
        cvc: this.state.cvc,
        cardholder: this.state.cardholder
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        this.setState({
          addCreditCardLoading: false,
          addCreditCardModalShown: false,
          card_number: '',
          cvc: '',
          expiry: '',
          cardholder: ''
        }, () => {
          this.getCards();
        });
      } else {
        this.setState({
          addCreditCardLoading: false,
          alertText: json.message
        })
      }
    })
  }

  handleChange(event) {
    const { name, value } = event.target;
    
    if(name === 'expiry') {
      // Use regex to insert a "/" between the first two and last two characters
      const formattedValue = value.replace(/^(\d{2})(\d{0,2})$/, "$1/$2");
  
      this.setState({
        expiry: formattedValue
      });
    } else {
      this.setState({
        [name]: value 
      });
    }
  }

  render() {
    const s = this.state;

    return(
      <Container>
        <AdminBodyTitle>طرق الدفع</AdminBodyTitle>

        {s.cardsLoading ?
          <div className="text-center py-5">
            <AiOutlineLoading3Quarters className="animation-spin" size={40} />
          </div>
        :
          s.cardsData?.length > 0 ?
            <Row>
              {s.cardsData.map((data, key) => (
                <Col md="6" key={key} className="mb-3">
                  <Card>
                    <CardBody>
                      <div className="mb-2 d-flex align-items-center">
                        <div className="me-4">
                          <img src={require(`../../../assets/${data.card_type}.png`)} width={80} alt="Credit Card" />
                        </div>
                        <div>
                          <div className="fw-bold h5">{data.card_type_ar} <span dir="ltr">**** {data.number.slice(-4)}</span></div>
                          <div className="pm-card-expiry text-muted mb-0">تنتهي بتاريخ: {data.expiry}</div>
                        </div>
                      </div>

                      <div className="text-center">
                        <DefaultButton 
                          color={'white'} 
                          className={'text-danger'} 
                          size={'md'}
                          onClick={() => this.setState({selectedIdToDestroy: data.id})}
                        >
                          حذف
                        </DefaultButton>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}
              <Col md="6" className="text-center" onClick={() => this.setState({addCreditCardModalShown: true})}>
                <Card style={{height: '158px', border: '2px dashed black'}}>
                  <CardBody className="d-flex align-items-center justify-content-center">
                    <DefaultButton color={'white'}>إضافة بطاقة</DefaultButton>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          :
            <div className="text-center py-5">
              <MdOutlineCreditCardOff size={150} color="#dedede" />
              <h5 className="text-muted mt-3 mb-3">لا توجد طرق دفع في حسابك</h5>
              <DefaultButton color={'dark'} size={'md'} onClick={() => this.setState({addCreditCardModalShown: true})}>+ إضافة طريقة دفع</DefaultButton>
            </div>
        }

        {s.alertText &&
          <BottomLeftAlert color={'danger'} onClose={() => this.setState({alertText: null})}>
            {s.alertText}
          </BottomLeftAlert>
        }

        <DefaultModal
          title={'إضافة بطاقة جديدة'}
          isOpen={s.addCreditCardModalShown}
          toggle={() => this.setState({addCreditCardModalShown: false})}
          confirmationText={'حفظ'}
          cancelText={'غلق'}
          isLoading={s.addCreditCardLoading}
          onConfirm={() => this.storeCard()}
        >
          <PaymentForm data={s} handleChange={(event) => this.handleChange(event)} />
        </DefaultModal>

        <ConfirmationModal
          isOpen={s.selectedIdToDestroy}
          confirmationText={'حذف'}
          cancelText={'إلغاء'}
          toggle={() => this.setState({selectedIdToDestroy: null})}
          onConfirm={() => this.destroyCard()}
          isLoading={s.destroyLoading}
        >
          هل حقا تريد حذف هذه البيانات؟
        </ConfirmationModal>
      </Container>
    );
  }
}

export default PaymentMethodsPage;